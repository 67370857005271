/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Icons
import { 
    IconActiveProfile, 
    IconArrowRigthDown, 
    IconExternalLink, 
    IconFinishedAccept, 
    IconInProgress, 
    IconSquarePlus, 
    IconStatus, 
    IconTaskLine, 
    IconWrong 
} from "../../components/icons";

// Components
import CardServices from "../../components/Ui/CardServices";
import CardWelcomeOperator from "../../components/Ui/CardWelcomeOperator";
import CardWellcome from "../../components/Ui/CardWellcome";

// Context
import { useAuth } from "../../contexts/UserContext";
import api from "../../services/api";

// Styles
import { 
    Container,
    SectionCardDashInfo,
    SectionAllCardsDashInfo,
    OperatorDemands,
    DemandCardColumn,
    WrapperLittleCards,
    CardInfo,
    WideCard,
    ColumnInfo,
} from "./styles";

// Libraries
import toast, { Toaster } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

export type PropsDate = {
    id: any;
    title: string;
    subtitle: string;
    total:  any;
    description: string;
    icon: any;  
    isgrafic?: boolean;
}

interface DemandInfo {
    demandas_ultimo_dia: any,
    finalizadas_ano_atual: any,
    finalizadas_mes_atual: any,
    finalizadas_por_mes: any,
    pendente_aceite_profissional: any,
    qtd_atrasadas: any,
    qtd_em_andamento: any,
    valor_parcial_mes: any, 
}

interface OperatorDashProps {
    acautelamento_atrasadas: any
    acautelamento_com_pendencia: any
    acautelamento_concluidas: any
    acautelamento_em_andamento: any
    acautelamento_propostas_criadas: any
    audiencias_atrasadas: any
    audiencias_com_pendencia: any
    audiencias_concluidas: any
    audiencias_em_andamento: any
    audiencias_pendentes_de_orientacao: any
    audiencias_propostas_criadas: any
    demandas_canceladas: any
    diligencias_atrasadas: any
    diligencias_com_pendencia: any
    diligencias_concluidas: any
    diligencias_em_andamento: any
    diligencias_propostas_criadas: any
    finalizadas_com_aceite: any
    profissionais_ativos: any
    propostas_em_andamento: any
}

export default function Dashboard() {
    const { setPageName, user } = useAuth();
    const [ loading, setLoading ] = useState<boolean>();
    const [ demandsData, setDemandsData ] = useState<DemandInfo>();
    const [ demandsInfo, setDemandsInfo ] = useState<OperatorDashProps>();
    const date = new Date();  
    const month = date.toLocaleString('pt-BR', { month: 'long' });
    const year = date.toLocaleString('pt-BR', { year: 'numeric' });

    useEffect(() => {

        setPageName('Visão geral');

        dashboardInfos();
    }, [])

    async function dashboardInfos() {
        try {

            if (user.profile === 'comum') {
                setLoading(true);

                const response = await api.get('usuario-dashboard');
                setDemandsData(response.data.result);

                if (response.data.status !== 'success') {
                    
                    if (response.data.result[0] === undefined) {
                    
                        throw new Error(response.data.message);

                    } else {
                    
                        throw new Error(response.data.result[0].error);

                    }
                }

                setLoading(false);
            }

            if (user.profile !== 'comum') {

                setLoading(true);

                const response = await api.get('dashboard-geral');
                setDemandsInfo(response.data.result);

                if (response.data.status !== 'success') {

                    if (response.data.result[0] === undefined) {

                        throw new Error(response.data.message);

                    } else {

                        throw new Error(response.data.result[0].error);

                    }
                }

                setLoading(false);

            }
            
        } catch (error: any) {
            console.log('Log error dashboard', error)

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
        }
    }
    
    const data: PropsDate[] = [
        {
            id: 1,
            title: `Demandas finalizadas em ${year}`,
            subtitle: 'Concluídas e aprovadas por nós!',
            total: demandsData?.finalizadas_ano_atual,
            description: 'total este ano',
            icon: <IconTaskLine />,
            isgrafic: true,
        },
        {
            id: 2,
            title: 'Oportunidades aguardando aceite',
            subtitle: 'Propostas enviadas para você.',
            total: demandsData?.pendente_aceite_profissional,
            description: 'total',
            icon: <IconSquarePlus />
        },
        {
            id: 3,
            title: 'Demandas em andamento',
            subtitle: 'Serviços a concluir.',
            total: demandsData?.qtd_em_andamento,
            description: 'total',
            icon: <IconTaskLine />
        },
        {
            id: 4,
            title: 'Demandas no último dia',
            subtitle: 'Precisam da sua atenção hoje!',
            total: demandsData?.demandas_ultimo_dia,
            description: 'total',
            icon: <IconTaskLine />
        },
        {
            id: 5,
            title: 'Demandas em atraso',
            subtitle: 'Prazo expirou!',
            total: demandsData?.qtd_atrasadas,
            description: 'total',
            icon: <IconTaskLine />
        },
        {
            id: 6,
            title: 'Parcial do valor a receber',
            subtitle: 'Dos serviços deste mês:',
            total: demandsData?.valor_parcial_mes,
            description: `em ${month}/${year}`,
            icon: <IconArrowRigthDown />
        },
    ]

    const latestCards = data.slice(0, 2);
    const allCards = data.slice(2, data.length);

    return (
        <Container>
            {
                loading &&
                <Oval
                    height={150}
                    width={150}
                    color="#FA9604"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#FA9604"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            }
            
            {
                user.profile === 'comum' &&
                !loading &&
                <>
                    <CardWellcome />
                    <SectionCardDashInfo>
                        {latestCards.map((row) => (
                            <CardServices key={row.id} data={row} graphic={demandsData?.finalizadas_por_mes.length > 0 ? demandsData?.finalizadas_por_mes : 0} />
                        ))}
                    </SectionCardDashInfo>

                    <SectionAllCardsDashInfo>
                        {allCards.map((row) => (
                            <CardServices key={row.id} data={row} />    
                        ))}
                    </SectionAllCardsDashInfo>
                </>
            }

            {
                user.profile !== 'comum' &&
                <OperatorDemands>
                    <DemandCardColumn>
                        <CardWelcomeOperator month={month} year={year} />
                        <WrapperLittleCards>
                            <CardInfo>
                                <div className="icons">
                                    <div className="icon active">
                                        <IconActiveProfile />
                                    </div>
                                    <Link
                                        to={{
                                            pathname: '/profissionais',
                                            state: {
                                                selected: '2'
                                            }
                                        }}
                                    >
                                        <div className="button">
                                            <IconExternalLink />
                                        </div>
                                    </Link>
                                </div>
                                <div className="title">Profissionais <br /> ativos</div>
                                <div className="numbers active">
                                    {demandsInfo?.profissionais_ativos} <span>total</span>
                                </div>
                            </CardInfo>
                            <CardInfo>
                                <div className="icons">
                                    <div className="icon ongoing">
                                        <IconInProgress />
                                    </div>
                                    <Link
                                        to={{
                                            pathname: '/demandas',
                                            state: {
                                                selected: '2'
                                            }
                                        }}
                                    >
                                        <div className="button">
                                            <IconExternalLink />
                                        </div>                                    
                                    </Link>
                                </div>
                                <div className="title">Demandas <br /> em andamento</div>
                                <div className="numbers ongoing">
                                    {demandsInfo?.propostas_em_andamento} <span>total</span>
                                </div>
                            </CardInfo>
                        </WrapperLittleCards>
                        <WrapperLittleCards>
                            <CardInfo>
                                <div className="icons">
                                    <div className="icon canceled">
                                        <IconWrong />
                                    </div>
                                    <Link
                                        to={{
                                            pathname: '/demandas',
                                            state: {
                                                selected: '3',
                                                demands: 'canceled',
                                            }
                                        }}
                                    >
                                        <div className="button">
                                            <IconExternalLink />
                                        </div>
                                    </Link>
                                </div>
                                <div className="title">Demandas <br /> canceladas</div>
                                <div className="numbers canceled">
                                    {demandsInfo?.demandas_canceladas} <span>total</span>
                                </div>
                            </CardInfo>
                            <CardInfo>
                                <div className="icons">
                                    <div className="icon accept">
                                        <IconFinishedAccept />
                                    </div>
                                    <Link
                                        to={{
                                            pathname: '/demandas',
                                            state: {
                                                selected: '3',
                                                demands: 'accepted',
                                            }
                                        }}
                                    >
                                        <div className="button">
                                            <IconExternalLink />
                                        </div>
                                    </Link>
                                </div>
                                <div className="title">Demandas finalizadas <br /> com aceite</div>
                                <div className="numbers accept">
                                    {demandsInfo?.finalizadas_com_aceite} <span>total</span>
                                </div>
                            </CardInfo>
                        </WrapperLittleCards>
                    </DemandCardColumn>

                    <DemandCardColumn>
                        <WideCard>
                            <div className="title">
                                <p>Audiências</p>
                                <Link
                                    to={{
                                        pathname: '/demandas',
                                        state: {
                                            selected: '2',
                                            type: 'Audiência',
                                        }
                                    }}
                                >
                                    <div className="button">
                                        <IconExternalLink />
                                    </div>
                                </Link>
                            </div>
                            <div className="info">
                                <ColumnInfo>
                                    <div className="line">
                                        <span>{demandsInfo?.audiencias_propostas_criadas}</span> 
                                        <div className="status proposal"><IconStatus /></div> 
                                        <p>Propostas</p>
                                    </div>
                                    <div className="line">
                                        <span>{demandsInfo?.audiencias_em_andamento}</span> 
                                        <div className="status progress"><IconStatus /></div> 
                                        <p>Em andamento</p>
                                    </div>
                                    <div className="line">
                                        <span>{demandsInfo?.audiencias_pendentes_de_orientacao}</span>
                                        <div className="status orientation"><IconStatus /></div>
                                        <p>Enviar orientação</p>
                                    </div>
                                </ColumnInfo>
                                <ColumnInfo>
                                    <div className="line">
                                        <span>{demandsInfo?.audiencias_com_pendencia}</span>
                                        <div className="status orientation"><IconStatus /></div>
                                        <p>Com pendência</p>
                                    </div>
                                    <div className="line">
                                        <span>{demandsInfo?.audiencias_concluidas}</span>
                                        <div className="status finish"><IconStatus /></div>
                                        <p>Concluídas</p>
                                    </div>
                                    <div className="line">
                                        <span>{demandsInfo?.audiencias_atrasadas}</span>
                                        <div className="status delayed"><IconStatus /></div>
                                        <p>Em atraso</p>
                                    </div>
                                </ColumnInfo>
                            </div>
                        </WideCard>
                        <WideCard>
                            <div className="title">
                                <p>Diligências e petições</p>
                                <Link
                                    to={{
                                        pathname: '/demandas',
                                        state: {
                                            selected: '2',
                                            type: 'Diligencia',
                                        }
                                    }}
                                >
                                    <div className="button">
                                        <IconExternalLink />
                                    </div>
                                </Link>
                            </div>
                            <div className="info">
                                <ColumnInfo>
                                    <div className="line">
                                        <span>{demandsInfo?.diligencias_propostas_criadas}</span>
                                        <div className="status proposal"><IconStatus /></div>
                                        <p>Propostas</p>
                                    </div>
                                    <div className="line">
                                        <span>{demandsInfo?.diligencias_em_andamento}</span>
                                        <div className="status progress"><IconStatus /></div>
                                        <p>Em andamento</p>
                                    </div>
                                    <div className="line">
                                        <span>{demandsInfo?.diligencias_com_pendencia}</span>
                                        <div className="status orientation"><IconStatus /></div>
                                        <p>Com pendência</p>
                                    </div>
                                </ColumnInfo>
                                <ColumnInfo>
                                    <div className="line">
                                        <span>{demandsInfo?.diligencias_concluidas}</span>
                                        <div className="status finish"><IconStatus /></div>
                                        <p>Concluídas</p>
                                    </div>
                                    <div className="line">
                                        <span>{demandsInfo?.diligencias_atrasadas}</span>
                                        <div className="status delayed"><IconStatus /></div>
                                        <p>Em atraso</p>
                                    </div>
                                </ColumnInfo>
                            </div>
                        </WideCard>
                        <WideCard>
                            <div className="title">
                                <p>Protocolos e acautelamentos</p>
                                <Link
                                    to={{
                                        pathname: '/demandas',
                                        state: {
                                            selected: '2',
                                            type: 'Protocolo',
                                        }
                                    }}
                                >
                                    <div className="button">
                                        <IconExternalLink />
                                    </div>
                                </Link>
                            </div>
                            <div className="info">
                                <ColumnInfo>
                                    <div className="line">
                                        <span>{demandsInfo?.acautelamento_propostas_criadas}</span>
                                        <div className="status proposal"><IconStatus /></div>
                                        <p>Propostas</p>
                                    </div>
                                    <div className="line">
                                        <span>{demandsInfo?.acautelamento_em_andamento}</span>
                                        <div className="status progress"><IconStatus /></div>
                                        <p>Em andamento</p>
                                    </div>
                                    <div className="line">
                                        <span>{demandsInfo?.acautelamento_com_pendencia}</span>
                                        <div className="status orientation"><IconStatus /></div>
                                        <p>Com pendência</p>
                                    </div>
                                </ColumnInfo>
                                <ColumnInfo>
                                    <div className="line">
                                        <span>{demandsInfo?.acautelamento_concluidas}</span>
                                        <div className="status finish"><IconStatus /></div>
                                        <p>Concluídas</p>
                                    </div>
                                    <div className="line">
                                        <span>{demandsInfo?.acautelamento_atrasadas}</span>
                                        <div className="status delayed"><IconStatus /></div>
                                        <p>Em atraso</p>
                                    </div>
                                </ColumnInfo>
                            </div>
                        </WideCard>
                    </DemandCardColumn>
                </OperatorDemands>
            }

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}