/* eslint-disable react-hooks/exhaustive-deps */
//  React
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Components
import ButtonDefault from "../../components/form/ButtonDefault";

// Icons
import { IconLogoJuridicoWhite } from "../../components/icons";

// Styles
import { Container, Header, Main } from "./styles";

// Assets
import RegisterComplete from '../../assets/images/OperatorComplete.png';

export interface User {
    userName: string,
}

export default function CompleteRegisterOperator() {
    const history = useHistory();
    const location = useLocation<User>();
    const [user, setUser] = useState<any>();

    useEffect(() => {

        setUser(location?.state?.userName)

    }, [])
    
    return (
        <Container>

            <Header>
                <div className="logo"><IconLogoJuridicoWhite /></div>                
            </Header>

            <Main>
                <div className="image">
                    <img src={RegisterComplete} alt="Imagem de cadastro concluído" />
                </div>

                <div className="status">
                    <div className="progress">
                        <div className="progress-bar" />
                        <div>100%</div>
                    </div>
                    <div className="status-title">Cadastro concluído com sucesso!</div>
                    <div className="status-subtitle">Enviamos um e-mail para <span>{user ? user : 'operador'}</span> com informações sobre acesso à plataforma.</div>
                    <div className="buttons">
                        <div 
                            className="new"
                            onClick={() => history.push('/cadastrar/operador')}
                        >
                            Novo cadastro
                        </div>
                        <ButtonDefault
                            className="continue"
                            onClick={() => history.push('/operadores')}
                        >
                            Concluir
                        </ButtonDefault>
                    </div>
                </div>
            </Main>
        </Container>
    )
}