//  React
import { useState } from "react";
import { useHistory } from "react-router-dom";

// Libraries
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import * as yup from 'yup';
import getValidationErrors from "../../Utils/validationErrors";
import axios from "axios";

// Styles
import { Container, Footer, Header, Hr1, Main } from "./styles";

// Components
import ButtonDefault from "../../components/form/ButtonDefault";
import InputDefault from "../../components/form/InputDefault";
import OperatorIslands from "../../components/OperatorIslands";
import OperatorPermissions from "../../components/OperatorPermissions";

// Icons
import { IconAsteristik, IconFlag, IconKey, IconLogoJuridico, IconProfile, IconWarning } from "../../components/icons";

// Services
import api from "../../services/api";

// Data
import { EstadosBrasileiros } from "../../components/Data/StatesData";

interface Operator {
    nome?: string,
    telefone?: string,
    email_pessoal?: string,
    whatsapp?: string,
    email_profissional?: string,
    uf?: string,
    cidade?: string,
    audiencias?: string,
    protocolos?: string,
    diligencias?: string,
    peticoes?: string,
    acautelamentos?: string,
    ilhas?: any,
}

export default function RegisterOperator() {
    const history = useHistory(); 
    const [ activePage, setActivePage ] = useState<number>(1);
    const [ advancePageError, setAdvancePageError ] = useState<Operator>();
    const [ DTOoperator, setDTOoperator ] = useState<Operator>({
        nome: '',
        telefone: '',
        email_pessoal: '',
        whatsapp: '',
        email_profissional: '',
        uf: '',
        cidade: '',
        audiencias: '',
        protocolos: '',
        diligencias: '',
        peticoes: '',
        acautelamentos: '',
        ilhas: [],
    });
    const [cities, setCities] = useState<any>([]);

    const states = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advancePageError?.uf ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const city = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advancePageError?.cidade ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    function handleOnChange(name: any, value: any) {
        
        const newDTO: any = DTOoperator
        newDTO[name] = value
        setDTOoperator({ ...newDTO })   

    }

    function handleSelect(selected: any) {

        if (selected.value.length < 3) {
            handleOnChange('uf', selected.value);   
            getCitiesForUF(selected.value);    
        }
        
        handleOnChange ('cidade', selected.value)
    }

    function scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    async function advancePage() {

        try {

            if (activePage === 1) {
                
                const schema = yup.object().shape({
                    nome: yup.string().required('Nome é obrigatório'),
                    telefone: yup.string().required('Telefone é obrigatório'),
                    email_pessoal: yup.string().required('Email pessoal é obrigatório').email("Digite um e-mail válido"),
                    whatsapp: yup.string().required('Whatsapp pessoal é obrigatório'),
                    email_profissional: yup.string().required('Email profissional é obrigatório').email("Digite um e-mail válido"),
                    uf: yup.string().required('Estado é obrigatório'),
                    cidade: yup.string().required('Cidade é obrigatório'),                    
                });
                await schema.validate(DTOoperator, {
                    abortEarly: false,
                });

                setActivePage(activePage + 1);
            }

            if (activePage === 2) {

                if (DTOoperator.ilhas.length === 0) {
                    
                    const schema = yup.object().shape({
                        ilhas: yup.array().min(1, 'Selecione no mínimo UMA ilha')
                    });                    

                    await schema.validate(DTOoperator, {
                        abortEarly: false,
                    });

                } else {
                    setActivePage(activePage + 1);
                }
                
            }

            if (activePage === 3) {

                if (DTOoperator.audiencias !== '' || DTOoperator.acautelamentos !== '' || DTOoperator.diligencias !== '' || DTOoperator.peticoes !== '' || DTOoperator.protocolos !== '') {
                    // console.log('log para enviar o form')

                } else {                    
                    if (DTOoperator.audiencias === '') {
                        setAdvancePageError({audiencias: 'vazio'});                        
                    }
                    if (DTOoperator.acautelamentos === '') {
                        setAdvancePageError({acautelamentos: 'vazio'});
                    }
                    if (DTOoperator.diligencias === '') {
                        setAdvancePageError({diligencias: 'vazio'});
                    }
                    if (DTOoperator.peticoes === '') {
                        setAdvancePageError({peticoes: 'vazio'});
                    }
                    if (DTOoperator.protocolos === '') {
                        setAdvancePageError({protocolos: 'vazio'});
                    }
                    throw new Error()
                }
                
                // mandar o DTO para o backend e chamar a pagina de registro completo
                handleSubmit();
                
            }            

            scrollTop();            
            setAdvancePageError({})            


        } catch (err: any) {
           
            if (activePage === 1) {

                if (err instanceof yup.ValidationError) {
                    const errors = getValidationErrors(err);
    
                    setAdvancePageError(errors)
                    
                    toast.error('Preencha os campos corretamente.', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                        },
                        iconTheme: {
                            primary: '#F24343',
                            secondary: '#FFFAEE',
                        },
                    });
                }
            }

            if (activePage === 2) {
                if (err instanceof yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    setAdvancePageError(errors)
                    console.log('log do erro da page 2', errors)
                    
                }
                toast(<div className="warning"><span><IconWarning /></span> Selecione no mínimo UMA ilha.</div>,
                {
                    duration: 3000,
                    style: {
                        padding: '16px',
                        minWidth: '220px',
                        color: '#1A2858',
                        background: '#E4F5FF',
                        border: '2px solid #88D3FE',
                    },
                });
            }

            if (activePage === 3) {
                
                toast(<div className="warning"><span><IconWarning /></span> Selecione no mínimo UM setor.</div>,
                {
                    duration: 3000,
                    style: {
                        padding: '16px',
                        minWidth: '220px',
                        color: '#1A2858',
                        background: '#E4F5FF',
                        border: '2px solid #88D3FE',
                    },
                });
            }

            
        }
    }

    async function handleSubmit() {

        try {

            const response = await api.post('/operador', DTOoperator)
            console.log('Log de cadastro do operador', response.data.status)

            if (response.data.status !== 'success') {
                throw new Error(response.data.result[0].error);
            }

            if (response.data.status === 'success') {
                history.push({
                    pathname: '/operador/concluir',
                    state: {
                        userName: DTOoperator.nome,
                    }
                })
            }
            
        } catch (error: any) {

            console.log('log do erro criando operador', error)

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

        }


    }

    async function getCitiesForUF(uf: any) {
        const newCities: any = []
       
        await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`).then(response => {
            response.data.map((row: any) => (
                newCities.push({
                    value: row.nome,
                    label: row.nome
                })
            ))            
            setCities([...newCities])
        });      
        
    }

    return (
        <Container>

            <Header>
                <div className="logo"><IconLogoJuridico /></div>

                <div className="status">
                    <Hr1
                        className={
                            activePage === 1 ? "first" : '' ||
                            activePage === 2 ? "second" : '' ||
                            activePage === 3 ? "third" : ''                                        
                        }
                    />
                    <div
                        className="status-square first"
                    >
                        <div className={"square active"}>
                            <IconProfile />
                        </div>
                        <p className="first">Identidade</p>
                    </div>
                    <div
                        className="status-square"
                    >
                        <div className={activePage > 1 ? "square active" : 'square'}>
                            <IconFlag />
                        </div>
                        <p className={activePage > 1 ? 'on second' : 'second'} >Antedimento</p>
                    </div>
                    <div
                        className="status-square"
                    >
                        <div className={activePage > 2 ? "square active" : 'square'}>
                            <IconKey />
                        </div>
                        <p className={activePage > 2 ? 'on third' : 'third'} >Permissões</p>
                    </div>                    
                </div>
            </Header>

            <Main>
                <div className="top">
                    {
                        activePage === 1 &&
                        <div className="one" >
                            <div className="register operator">
                                <h3>Cadastre um <span>operador!</span></h3>
                                <p>Informe os dados necessários para identificar o colaborador.</p>
                            </div>
                        </div>
                    }

                    {
                        activePage === 2 &&
                        <div className="two">
                            <div className="register services">
                                <h3>Escolha ilhas de atendimento para o operador!</h3>
                                <p className="title">Vincule aos grupos nos quais ele deve participar durante a rotina:</p>                                
                            </div>
                        </div>
                    }

                    {
                        activePage === 3 &&
                        <div className="three">
                            <div className="register permission">
                                <h3>Configure permissões de acesso!</h3>
                                <div className="subtitle">
                                    Selecione os setores que <span>{DTOoperator.nome}</span> poderá acessar e defina quais tarefas poderá realizar.
                                </div>
                            </div>
                        </div>
                    }

                    {   advancePageError && activePage === 1 &&
                        Object.keys(advancePageError).length > 0                            
                            ?   <div>
                                    <div className="attention">
                                        <div className="star"><IconAsteristik /></div>
                                        <span>Atenção:</span>
                                        <p>Existem campos obrigatórios a serem preenchidos</p>
                                    </div>
                                </div>
                            : ''
                    }

                    {   advancePageError && activePage === 2 &&
                        Object.keys(advancePageError).length > 0                            
                            ?   <div>
                                    <div className="attention">
                                        <div className="star"><IconAsteristik /></div>
                                        <span>Atenção:</span>
                                        <p>{advancePageError.ilhas}</p>
                                    </div>
                                </div>
                            : ''
                    }
                    
                    {   advancePageError && activePage === 3 &&
                        Object.keys(advancePageError).length > 0                            
                            ?   <div>
                                    <div className="attention">
                                        <div className="star"><IconAsteristik /></div>
                                        <span>Atenção:</span>
                                        <p>Selecione no mínimo 1 setor.</p>
                                    </div>
                                </div>
                            : ''
                    }

                </div>

               {
                activePage === 1 &&
                <form className="register-form">
                    <div className="name">
                        <InputDefault
                            title="Nome:"
                            name='nome'
                            value={DTOoperator.nome}
                            onChangeText={(value) => handleOnChange('nome', value)}
                            className={advancePageError?.nome ? 'big error' : 'big'}
                            error={advancePageError?.nome ? '*' : ''}
                        />

                        <InputDefault
                            title="Telefone:"
                            name="telefone"
                            mask="(99) 99999-9999"
                            value={DTOoperator.telefone}
                            onChangeText={(value) => handleOnChange('telefone', value)}
                            className={advancePageError?.telefone ? 'small error' : 'small'}
                            error={advancePageError?.telefone ? '*' : ''}
                        />
                    </div>

                    <div className="email">
                        <InputDefault
                            title="E-mail pessoal:"
                            name="email_pessoal"
                            value={DTOoperator.email_pessoal}
                            onChangeText={(value) => handleOnChange('email_pessoal', value)}
                            className={advancePageError?.email_pessoal ? 'big error' : 'big'}
                            error={advancePageError?.email_pessoal ? '*' : ''}
                        />

                        <InputDefault
                            title="Whatsapp:"
                            name="whatsapp"
                            mask="(99) 99999-9999"
                            value={DTOoperator.whatsapp}
                            onChangeText={(value) => handleOnChange('whatsapp', value)}
                            className={advancePageError?.whatsapp ? 'small error' : 'small'}
                            error={advancePageError?.whatsapp ? '*' : ''}
                        />
                    </div>

                    <div className="pro">
                        <InputDefault
                            title="E-mail profissional:"
                            name="email_profissional"
                            value={DTOoperator.email_profissional}
                            onChangeText={(value) => handleOnChange('email_profissional', value)}
                            className={advancePageError?.email_profissional ? 'big error' : 'big'}
                            error={advancePageError?.email_profissional ? '*' : ''}
                        />
                    </div>

                    <div className="state">
                        <div className="label-state">
                            <p>Estado:</p>
                            <Select
                                name="estado_oab"
                                styles={states}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 8,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#DDFAF4',
                                        primary50: '#DDFAF4',
                                        primary: '#02E8BE',
                                    },
                                })}
                                placeholder='Selecionar'
                                defaultValue={DTOoperator.uf !== '' ? { value: DTOoperator.uf, label: DTOoperator.uf } : ''}
                                onChange={handleSelect}
                                options={EstadosBrasileiros}
                            />
                            <span className="error">{advancePageError?.uf ? '*' : ''}</span>
                        </div>

                        <div className="label-state">
                            <p>Cidade da comarca:</p>                   
                            <Select
                                styles={city}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 8,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#DDFAF4',
                                        primary50: '#DDFAF4',
                                        primary: '#02E8BE',
                                    },
                                })}
                                name='cidade'
                                placeholder="Selecionar"
                                defaultValue={DTOoperator.cidade !== '' ? { value: DTOoperator.cidade, label: DTOoperator.cidade } : ''}
                                options={cities}
                                onChange={(e: any) => handleOnChange('cidade', e.value)}
                            />
                            <span className="error">{advancePageError?.cidade ? '*' : ''}</span>
                        </div>


                    </div>
                </form>
               } 

               {
                activePage === 2 &&
                    <OperatorIslands handleOnChange={handleOnChange} />
               }

               {
                activePage === 3 &&
                    <OperatorPermissions handleOnChange={handleOnChange} DTOoperator={DTOoperator} />
               }
            </Main>

            <Footer>
                <div className="container">
                    <div className="buttons">
                        {
                            activePage === 1 &&
                            <ButtonDefault
                                className="cancel"
                                onClick={() => history.push('/operadores')}
                            >
                                Cancelar
                            </ButtonDefault>
                        }
                        {
                            activePage > 1 &&
                            <ButtonDefault
                                className="cancel"
                                onClick={() => setActivePage(activePage - 1)}
                            >
                                Voltar
                            </ButtonDefault>
                        }

                        <ButtonDefault
                            className="continue"
                            type="submit"
                            onClick={() => advancePage()}
                        >
                            Continuar
                        </ButtonDefault>
                    </div>

                    <div className="progress">
                        <div className="progressbar">
                            <div className={
                                activePage === 1 ? "percent" : '' ||
                                activePage === 2 ? "percent two" : '' ||
                                activePage === 3 ? "percent three" : ''                                            
                            }>
                            </div>
                        </div>
                        <div>
                            <span>
                                {
                                    activePage === 1 ? "30%" : '' ||
                                    activePage === 2 ? "50%" : '' ||
                                    activePage === 3 ? "80%" : ''                                     
                                }
                            </span> do perfil completo
                        </div>
                    </div>
                </div>
            </Footer>    

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}