// React
import { useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

// Components
import Footer from "../../../components/Footer";
import Header from "../../../components/HeaderSite";
import RegisterPage5 from "../../../components/RegisterPage5";
import Card from "../../../components/PlanCard";

// Styles
import { 
    AreaHeader, 
    AreaImage2, 
    AreaSite, 
    AttendenceCard, 
    AttendenceWrapper, 
    Container, 
    DemandButton, 
    DemandCard, 
    DemandDescription, 
    DemandInfos, 
    DemandTitle, 
    Description, 
    DescriptionTitle, 
    HeaderWrapper, 
    Image, 
    InfoWrapper, 
    NotebookCard,
    PlanWrapper, 
    Representative, 
    StructureCardWrapper, 
    VectorCard, 
    VectorColumn, 
    WideCard 
} from "./styles";

// Images
import GreenCircle from "../../../assets/images/WomanGreen.png";
import SearchDemand from "../../../assets/images/Search.svg";
import PcDemand from "../../../assets/images/Pc.svg";
import TriPeople from "../../../assets/images/Trio.svg";
import MapPeople from "../../../assets/images/MapPeople.svg";
import PeopleJJ from "../../../assets/images/PeopleJJ.svg";
import Bill from "../../../assets/images/Bill.svg";
import Notebook from "../../../assets/images/Notebook.png";
import Represent from "../../../assets/images/Represent.png";

// Icons
import { 
    IconAddFile, 
    IconCash, 
    IconCheckedSquare, 
    IconFinancialSecurity, 
    IconHouse, 
    IconMiniClock, 
    IconOneVectorRight, 
    IconOrangeStar, 
    IconPinPoint, 
    IconPlanFree, 
    IconPlanPremium, 
    IconPlanPro, 
    IconRight, 
    IconSquareCircle, 
    IconSquarePlus, 
    IconStats, 
    IconVectorDoubleRight, 
    IconVectorRight, 
    IconWrong 
} from "../../../components/icons";

// Libraries
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export default function ReceiveOpportunities() {

    const history = useHistory<any>();

    useEffect(() => {
        function scrollTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        scrollTop();
    }, []);

    const solutions = useRef<any>(null);

    const scrollSolutions = () => solutions.current.scrollIntoView();

    return (
        <Container>
            <AreaHeader>
                <Header />
            </AreaHeader>

            <HeaderWrapper>
   
                <AreaSite>
                    <AreaImage2>
                        <div className='circle'>
                            <Image src={GreenCircle} />
                            <div className='green-circle'></div>
                        </div>
                    </AreaImage2>

                    <Description>

                        <DescriptionTitle>
                            <div className='green-right'>
                                Suas demandas <br />
                                organizadas <br />
                                no seu mais novo <br />
                                <span>escritório virtual, Já! </span>
                            </div>

                            <div className='subtitle'>
                                Nossa plataforma conecta você ao mercado Jurídico <br />
                                com inovação, tecnologia e eficiência.
                            </div>

                            <div className='icon green'>
                                <IconOrangeStar />
                            </div>

                            <DemandButton 
                                className='first-green'
                                onClick={() => history.push('/cadastrar')}
                            >
                                Faça seu cadastro e comece a atender já!
                            </DemandButton>

                            <DemandButton 
                                className='second-green'
                                onClick={scrollSolutions}                                
                            >
                                Conheça as funcionalidades
                            </DemandButton>
                        </DescriptionTitle>

                    </Description>

                </AreaSite>

            </HeaderWrapper>

            <InfoWrapper ref={solutions}>
                <DemandInfos>

                    <DemandTitle className='green'>
                        As melhores soluções para a sua rotina <br /> jurídica <span>num só lugar e sem custo!</span>
                    </DemandTitle>

                    <DemandDescription>
                        Com a nossa plataforma, você recebe diariamente novas oportunidades, pode <br />
                        escolher em quais comarcas deseja atuar, quando atender e quanto cobrar <br />
                        pelos serviços. Tudo na palma da sua mão!
                    </DemandDescription>

                    <StructureCardWrapper>
                        <DemandCard className='green'>
                            <IconSquareCircle />
                            <div className='info'>Solicitação e acompanhamento de diversos tipos de demandas</div>
                        </DemandCard>
                        <DemandCard className='green'>
                            <IconHouse />
                            <div className='info'>
                                Agilidade por meio de <br /> valores fixos de serviços
                            </div>
                        </DemandCard>
                        <DemandCard className='green'>
                            <IconStats />
                            <div className='info'>
                                Qualidade e transparência <br /> de atendimento e resultados
                            </div>
                        </DemandCard>
                        <DemandCard className='green'>
                            <IconFinancialSecurity />
                            <div className='info'>
                                Controle financeiro com <br /> fatura única mensal
                            </div>
                        </DemandCard>
                    </StructureCardWrapper>

                    <DemandTitle className='light green'>
                        <span>Deixe com a gente ;)</span> <br />
                        Organizaremos todo o fluxo das suas demandas.
                    </DemandTitle>

                    <DemandDescription>
                        Suas primeiras oportunidades podem chegar assim que você se 
                        cadastrar. Você decide quais poderá atender e, assim que aceitar, 
                        estaremos por perto ao longo de todo o processo. Você recebe por 
                        todas as demandas realizadas no mês em uma fatura única.
                    </DemandDescription>

                    <WideCard className='green'>
                        <VectorColumn>
                            <div className='vector'>
                                <div className='icon'>
                                    <Image src={SearchDemand} />
                                </div>
                                <VectorCard className='green'>
                                    Nova <br /> oportunidade
                                </VectorCard>
                            </div>
                            <div className='vector-icon'>
                                <IconVectorRight />
                            </div>
                        </VectorColumn>

                        <VectorColumn>
                            <div className='vector'>
                                <div className='icon'>
                                    <Image src={PcDemand} />
                                </div>
                                <VectorCard className='orange'>
                                    Nossa <br /> plataforma
                                </VectorCard>
                            </div>
                            <div className="vector-double">
                                <IconVectorDoubleRight />
                            </div>
                        </VectorColumn>

                        <VectorColumn>
                            <div className='no-card'>
                                <Image src={TriPeople} />
                                <Image src={MapPeople} className='bottom' />
                            </div>
                        </VectorColumn>

                        <VectorColumn>
                            <div className='vector cards'>
                                <VectorCard className='orange'>
                                    Ilhas de <br /> atendimento
                                </VectorCard>
                                <VectorCard className='orange'>
                                    Representante <br /> regional
                                </VectorCard>
                            </div>
                            <div className='one'>
                                <IconOneVectorRight />
                            </div>
                        </VectorColumn>

                        <VectorColumn>
                            <div className='vector people'>
                                <div className='icon people'>
                                    <Image src={PeopleJJ} />
                                </div>
                                <VectorCard className='orange'>
                                    Profissionais <br /> jurídicos
                                </VectorCard>
                            </div>
                            <div className='vector-icon'>
                                <IconVectorRight />
                            </div>
                        </VectorColumn>

                        <VectorColumn>
                            <div className='vector'>
                                <div className='icon'>
                                    <Image src={Bill} />
                                </div>
                                <VectorCard className='green'>
                                    Fatura única <br /> no fim do mês
                                </VectorCard>
                            </div>
                        </VectorColumn>

                    </WideCard>

                </DemandInfos>
            </InfoWrapper>

            <AttendenceWrapper>
                <div className='left'>
                    <AttendenceCard className='one'>
                        <div className='top'>
                            <div className='title green'>
                                <IconSquarePlus />
                                nova oportunidade
                            </div>
                            <div className='icon green'><IconRight /></div>
                        </div>

                        <div className='bottom'>
                            <div className='title'>Audiência trabalhista</div>
                            <div className='end'>
                                <div className='financial'>
                                    <IconCash />
                                    <div className='price'></div>
                                </div>
                                <div className='icon grey'><IconWrong /></div>
                            </div>
                        </div>
                    </AttendenceCard>

                    <AttendenceCard className='two'>
                        <div className='top'>
                            <div className='title blue'>
                                <IconCheckedSquare />
                                demanda em andamento
                            </div>
                            <div className='icon blue'><IconRight /></div>
                        </div>

                        <div className='bottom'>
                            <div className='title'>Cópia parcial</div>
                            <div className='end'>
                                <div className='financial'>
                                    <IconPinPoint />
                                    <div className='price blue'></div>
                                </div>
                                <div className='financial'>
                                    <IconMiniClock />
                                    <div className='price blue'></div>
                                </div>
                                <div className='icon pool'><IconAddFile /></div>
                            </div>
                        </div>
                    </AttendenceCard>

                    <AttendenceCard className='three'>
                        <div className='top'>
                            <div className='title cash'>
                                <IconCheckedSquare />
                                demanda concluída
                            </div>
                            <div className='icon cash'><IconRight /></div>
                        </div>

                        <div className='bottom'>
                            <div className='title'>Acautelamento</div>
                            <div className='end'>
                                <div className='subtitle'>
                                    Aguardando aprovação
                                </div>
                            </div>
                        </div>
                    </AttendenceCard>
                </div>

                <div className='right'>
                    <div className='title'>
                        <span>Atenda já</span> todas as suas <br />
                        demandas em um só lugar:
                    </div>
                    <div className='subtitle'>
                        <p>Você recebe todo tipo de oportunidade, basta escolher quais gostaria de atender!</p>

                        <span>Preocupe-se apenas com a demanda</span>, enquanto nós acompanhamos o desenvolvimento e garantimos a satisfação do cliente.
                    </div>
                </div>
            </AttendenceWrapper>

            <NotebookCard>
                <div className='text'>
                    <div className='title'>
                        Acompanhe de perto
                        sua <span>performance</span>
                    </div>
                    <div className='subtitle'>
                        Nossa dashboard mostra tudo o
                        que você precisa saber sobre a sua
                        produtividade mês a mês!

                        Desta forma, você pode controlar
                        melhor sua agenda e aumentar
                        seu faturamento!
                    </div>
                </div>

                <div className='image'>
                    <Image src={Notebook} />
                </div>
            </NotebookCard>

            <PlanWrapper>
                <div className='text'>Escolha o melhor plano para <span>otimizar sua rotina jurídica!</span></div>
                
                <div className="web">
                    <RegisterPage5
                        value={'Em breve!'}
                        hover={false}
                    />
                </div>

                <div className="mobile">
                    <Carousel
                        autoPlay={false}
                        showArrows={true}
                        showIndicators={true}
                        showStatus={false}
                        showThumbs={false}
                    >
                        <div className="plan">
                            <Card
                                id={1}
                                className={'free'}
                                iconTop={<IconPlanFree />}
                                title={'Jurídico Já Free'}
                                subtitle={<p>Você poderá <span>visualizar</ span> oportunidades.</p>}
                                value={'R$ 0,00'}
                                onClick={() => ('')}
                                selected={false}
                                page={false}
                                width={'100%'}
                            />  
                        </div>
                        <div className="plan">
                            <Card
                                id={2}
                                className={'pro'}
                                iconTop={<IconPlanPro />}
                                title={'Jurídico Já Pro'}
                                subtitle={<p>Você poderá <span>responder</span> oportunidades!</p>}
                                value={'R$ 19,90'}
                                onClick={() => ('')}
                                selected={false}
                                page={false}
                                width={'100%'}
                            />
                        </div>
                        <div className="plan">
                            <Card
                                id={3}
                                className={'premium'}
                                iconTop={<IconPlanPremium />}
                                title={'Jurídico Já Premium'}
                                subtitle={'Em breve, um plano para você se destacar no mercado.'}
                                value={'Em breve'}
                                onClick={() => ('')}
                                selected={false}
                                page={false}
                                width={'100%'}
                            />
                        </div>
                    </Carousel>
                </div>
            </PlanWrapper>

            <Representative>

                <div className='title'>
                    Quer se tornar um Representante Regional?
                    Se você atende em mais de 5 Comarcas, fale conosco!

                    <Link
                        to={'/contato'}
                    >
                        <DemandButton className='button'>
                            Junte-se a nós!
                        </DemandButton>
                    </Link>
                </div>

                <div className='image'>
                    <Image src={Represent} />
                </div>

            </Representative>

            <Footer />
        </Container>
    )
}