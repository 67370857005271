/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Checkbox from "../form/InputCheckbox";
import { Container, PermissionCard, Permissions } from "./styles";
import Select from 'react-select';

interface OptionsProps {
    handleOnChange?: any,
    editProfile?: any,
    profile?: any,  
    DTOoperator?: any,  
}

export default function OperatorPermissions({handleOnChange, editProfile, profile, DTOoperator}: OptionsProps) {

    const [checkedAll, setCheckedAll] = useState<any>(false);
    const [checked, setChecked] = useState<any>({
        courtHearing: false,
        dueDiligence: false,
        protocols: false,
        petitions: false,
        cautions: false,
    });
    const [courtHearing, setCourtHearing ] = useState<boolean>(false);
    const [dueDiligence, setDueDiligence ] = useState<boolean>(false);
    const [protocols, setProtocols ] = useState<boolean>(false);
    const [petitions, setPetitions ] = useState<boolean>(false);
    const [cautions, setCautions ] = useState<boolean>(false);

    const toggleCheck = (inputName: string) => {
        setChecked((prevState: { [x: string]: any; }) => {
            const newState = { ...prevState };
            newState[inputName] = !prevState[inputName];
            return newState;
        });        
    };

    const selectAll = (value: any) => {
        setCheckedAll(value);
        setChecked((prevState: any) => {
            const newState = { ...prevState };
            for (const inputName in newState) {
                newState[inputName] = value;
            }
            return newState;
        });
    };

    useEffect(() => {
        let allChecked = true;
        for (const inputName in checked) {
            if (checked[inputName] === false) {
                allChecked = false;
            }
        }
        if (allChecked) {

            setCheckedAll(true);
        } else {

            setCheckedAll(false);
        }        


        
    }, [checked]);   

    useEffect(() => {
        if (profile?.audiencias !== '') {
            setCourtHearing(true);
        }
        if (profile?.protocolos !== '') {
            setProtocols(true)
        }
        if (profile?.diligencias !== '') {
            setDueDiligence(true)
        }
        if (profile?.peticoes !== '') {
            setPetitions(true)
        }
        if (profile?.acautelamentos !== '') {
            setCautions(true)
        }

    }, [])     

    const selectStyles = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #F18F00',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    };

    const options = [
        { value: '', label: 'Excluir' },
        { value: 'editar', label: 'Editar' },
        { value: 'visualizar', label: 'Visualizar' }        
    ];

    const newOptions = [
        { value: 'editar', label: 'Editar' },
        { value: 'visualizar', label: 'Visualizar' }        
    ];

    return (
        <Container>
            <div className="select-all">
                <Checkbox
                    name='true'
                    label="Selecionar todas"
                    value={checkedAll}
                    onClick={() => selectAll(!checkedAll)}
                />
            </div>
            {
                profile &&
                <Permissions>
                    <PermissionCard>
                        <div className={editProfile ? "card-title" : "card-title inactive"}>
                            {
                                editProfile 
                                ?   <Checkbox 
                                        name="courtHearing" 
                                        // value={checked["courtHearing"] || profile.audiencias}
                                        value={courtHearing}
                                        onClick={() => setCourtHearing(!courtHearing)}
                                        onChange={(e: any) => console.log('log do change audiencias', e)}
                                    />
                                :   <Checkbox
                                        name="courtHearing"
                                        value={checked["courtHearing"] || profile.audiencias}
                                        onClick={() => ('')}
                                    />
                            }
                            <div className="tag">Audiências</div>
                        </div>                    
                        <div className="select-label">
                            {
                                courtHearing ?
                                <Select
                                    name="permission"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={selectStyles}
                                    placeholder='Selecionar'
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#FFF2DE',
                                            primary50: '#FFF2DE',
                                            primary: '#FDB141',
                                        },
                                    })}
                                    defaultValue={{ value: profile.audiencias, label: profile.audiencias }}
                                    onChange={(value) => handleOnChange('audiencias', value?.value)}
                                    options={options}
                                    isDisabled={editProfile ? false : true}
                                /> 
                                : ''                           
                            }
                        </div>                    
                    </PermissionCard>

                    <PermissionCard>
                        <div className={editProfile ? "card-title" : "card-title inactive"}>
                            {
                                editProfile 
                                ?   <Checkbox
                                        name="dueDiligence"
                                        value={dueDiligence}
                                        onClick={() => setDueDiligence(!dueDiligence)}
                                    />
                                :   <Checkbox
                                        name="dueDiligence"
                                        value={checked["dueDiligence"] || profile.diligencias}
                                        onClick={() => ('')}
                                    />
                            }                            
                            <div className="tag">Diligências</div>
                        </div>
                        <div className="select-label">
                            {
                                dueDiligence ?
                                <Select
                                    name="permission"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={selectStyles}
                                    placeholder='Selecionar'
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#FFF2DE',
                                            primary50: '#FFF2DE',
                                            primary: '#FDB141',
                                        },
                                    })}
                                    defaultValue={{ value: profile.diligencias, label: profile.diligencias }}
                                    onChange={(value) => handleOnChange('diligencias', value?.value)}
                                    options={options}
                                    isDisabled={editProfile ? false : true}
                                />
                                : ''
                            }
                        </div>    
                    </PermissionCard>

                    <PermissionCard>
                        <div className={editProfile ? "card-title" : "card-title inactive"}>
                            {
                                editProfile
                                    ? <Checkbox
                                        name="protocols"
                                        value={protocols}
                                        onClick={() => setProtocols(!protocols)}
                                    />
                                    : <Checkbox
                                        name="protocols"
                                        value={checked["protocols"] || profile.protocolos}
                                        onClick={() => ('')}
                                    />
                            }                            
                            <div className="tag">Protocolos</div>
                        </div>
                        <div className="select-label">
                            {
                                protocols ?
                                <Select
                                    name="permission"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={selectStyles}
                                    placeholder='Selecionar'
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#FFF2DE',
                                            primary50: '#FFF2DE',
                                            primary: '#FDB141',
                                        },
                                    })}
                                    defaultValue={{ value: profile.protocolos, label: profile.protocolos }}
                                    onChange={(value) => handleOnChange('protocolos', value?.value)}
                                    options={options}
                                    isDisabled={editProfile ? false : true}
                                />
                                : ''                            
                            }
                        </div>    
                    </PermissionCard>

                    {/* <PermissionCard>
                        <div className={editProfile ? "card-title" : "card-title inactive"}>
                            {
                                editProfile
                                    ? <Checkbox
                                        name="petitions"
                                        value={petitions}
                                        onClick={() => setPetitions(!petitions)}
                                    />
                                    : <Checkbox
                                        name="petitions"
                                        value={checked["petitions"] || profile.peticoes}
                                        onClick={() => ('')}
                                    />
                            }                                     
                            <div className="tag">Petições</div>
                        </div>
                        <div className="select-label">
                            {
                                petitions ?
                                <Select
                                    name="permission"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={selectStyles}
                                    placeholder='Selecionar'
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#FFF2DE',
                                            primary50: '#FFF2DE',
                                            primary: '#FDB141',
                                        },
                                    })}
                                    defaultValue={{ value: profile.peticoes, label: profile.peticoes }}
                                    onChange={(value) => handleOnChange('peticoes', value?.value)}
                                    options={options}
                                    isDisabled={editProfile ? false : true}
                                /> 
                                : ''                           
                            }
                        </div>    
                    </PermissionCard>

                    <PermissionCard>
                        <div className={editProfile ? "card-title" : "card-title inactive"}>
                            {
                                editProfile
                                    ? <Checkbox
                                        name="cautions"
                                        value={cautions}
                                        onClick={() => setCautions(!cautions)}
                                    />
                                    : <Checkbox
                                        name="cautions"
                                        value={checked["cautions"] || profile.acautelamentos}
                                        onClick={() => ('')}
                                    />
                            } 
                            <div className="tag">Acautelamentos</div>
                        </div>
                        <div className="select-label">
                            {
                                cautions ?
                                <Select
                                    name="permission"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={selectStyles}
                                    placeholder='Selecionar'
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#FFF2DE',
                                            primary50: '#FFF2DE',
                                            primary: '#FDB141',
                                        },
                                    })}
                                    defaultValue={{ value: profile.acautelamentos, label: profile.acautelamentos }}
                                    onChange={(value) => handleOnChange('acautelamentos', value?.value)}
                                    options={options}
                                    isDisabled={editProfile ? false : true}
                                />  
                                : ''                          
                            }
                        </div>    
                    </PermissionCard> */}

                </Permissions>
            }

            {
                !profile &&
                <Permissions>
                    <PermissionCard>
                        <div className="card-title">
                            <Checkbox
                                name="courtHearing"
                                value={checked["courtHearing"]}
                                onClick={(value) => toggleCheck(value)}
                            />
                            <div className="tag">Audiências</div>
                        </div>
                        <div className="select-label">
                            {
                                checked["courtHearing"] &&
                                <Select
                                    name="permission"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={selectStyles}
                                    placeholder='Selecionar'
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#FFF2DE',
                                            primary50: '#FFF2DE',
                                            primary: '#FDB141',
                                        },
                                    })}
                                    defaultValue={DTOoperator.audiencias !== '' ? { value: DTOoperator.audiencias, label: DTOoperator.audiencias } : ''}
                                    onChange={(value: any) => handleOnChange('audiencias', value?.value)}
                                    options={newOptions}
                                />
                            }
                        </div>
                    </PermissionCard>

                    <PermissionCard>
                        <div className="card-title">
                            <Checkbox
                                name="dueDiligence"
                                value={checked["dueDiligence"]}
                                onClick={(value) => toggleCheck(value)}
                            />
                            <div className="tag">Diligências</div>
                        </div>
                        <div className="select-label">
                            {
                                checked["dueDiligence"] &&
                                <Select
                                    name="permission"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={selectStyles}
                                    placeholder='Selecionar'
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#FFF2DE',
                                            primary50: '#FFF2DE',
                                            primary: '#FDB141',
                                        },
                                    })}
                                    defaultValue={DTOoperator.diligencias !== '' ? { value: DTOoperator.diligencias, label: DTOoperator.diligencias } : ''}
                                    onChange={(value: any) => handleOnChange('diligencias', value?.value)}
                                    options={newOptions}
                                />
                            }
                        </div>
                    </PermissionCard>

                    <PermissionCard>
                        <div className="card-title">
                            <Checkbox
                                name="protocols"
                                value={checked["protocols"]}
                                onClick={(value) => toggleCheck(value)}
                            />
                            <div className="tag">Protocolos</div>
                        </div>
                        <div className="select-label">
                            {
                                checked["protocols"] &&
                                <Select
                                    name="permission"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={selectStyles}
                                    placeholder='Selecionar'
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#FFF2DE',
                                            primary50: '#FFF2DE',
                                            primary: '#FDB141',
                                        },
                                    })}
                                    defaultValue={DTOoperator.protocolos !== '' ? { value: DTOoperator.protocolos, label: DTOoperator.protocolos } : ''}
                                    onChange={(value: any) => handleOnChange('protocolos', value?.value)}
                                    options={newOptions}
                                />
                            }
                        </div>
                    </PermissionCard>

                    {/* <PermissionCard>
                        <div className="card-title">
                            <Checkbox
                                name="petitions"
                                value={checked["petitions"]}
                                onClick={(value) => toggleCheck(value)}
                            />
                            <div className="tag">Petições</div>
                        </div>
                        <div className="select-label">
                            {
                                checked["petitions"] &&
                                <Select
                                    name="permission"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={selectStyles}
                                    placeholder='Selecionar'
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#FFF2DE',
                                            primary50: '#FFF2DE',
                                            primary: '#FDB141',
                                        },
                                    })}
                                    defaultValue={DTOoperator.peticoes !== '' ? { value: DTOoperator.peticoes, label: DTOoperator.peticoes } : ''}
                                    onChange={(value: any) => handleOnChange('peticoes', value?.value)}
                                    options={newOptions}
                                />
                            }
                        </div>
                    </PermissionCard>

                    <PermissionCard>
                        <div className="card-title">
                            <Checkbox
                                name="cautions"
                                value={checked["cautions"]}
                                onClick={(value) => toggleCheck(value)}
                            />
                            <div className="tag">Acautelamentos</div>
                        </div>
                        <div className="select-label">
                            {
                                checked["cautions"] &&
                                <Select
                                    name="permission"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={selectStyles}
                                    placeholder='Selecionar'
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#FFF2DE',
                                            primary50: '#FFF2DE',
                                            primary: '#FDB141',
                                        },
                                    })}
                                    defaultValue={DTOoperator.acautelamentos !== '' ? { value: DTOoperator.acautelamentos, label: DTOoperator.acautelamentos } : ''}
                                    onChange={(value: any) => handleOnChange('acautelamentos', value?.value)}
                                    options={newOptions}
                                />
                            }
                        </div>
                    </PermissionCard> */}

                </Permissions>
            }

        </Container>
    )
}