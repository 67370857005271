// React
import { useHistory } from "react-router-dom";

// Components
import ButtonDefault from "../../components/form/ButtonDefault";

// Styles
import { Container, Header, Main } from "./styles";

// Icons
import { IconLogoJuridicoWhite } from "../../components/icons";

// Assets
import ProposalComplete from '../../assets/images/ProposalComplete.png';

export interface User {
    userName: string,
    id: number,
    prazo_aceite: string,
}

export default function CreateServiceComplete() {
    const history = useHistory();
    

    return (
        <Container>

            <Header>
                <div className="logo"><IconLogoJuridicoWhite /></div>
            </Header>

            <Main>
                <div className="image">
                    <img src={ProposalComplete} alt="Imagem de cadastro concluído" />
                </div>

                <div className="status">
                    <div className="progress">
                        <div className="progress-bar" />
                        <div>100%</div>
                    </div>
                    <div className="status-title">Demanda solicitada <br /> com sucesso!</div>
                    <div className="status-subtitle">
                        Recebemos seu pedido.<br />
                        Entraremos em contato em até 6 horas.
                    </div>
                    <div className="buttons">
                        <div
                            className="new"
                            onClick={() => history.push('/solicitarservico')}
                        >
                            Solicitar novamente
                        </div>
                        <ButtonDefault
                            className="continue"
                            onClick={() => history.push('/')}
                        >
                            Voltar ao site
                        </ButtonDefault>
                    </div>
                </div>
            </Main>
        </Container>
    )
}