import styled, { css } from "styled-components";

interface Props {
  hasNotification?: any;
  hasSelected?: boolean;
  menuSidebar: boolean;
  isAdmin?: boolean;
  inProfile?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: ${({ menuSidebar }) => (menuSidebar ? "80px" : "304px")};
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.secondaryGreen500};
  background-color: ${(props) =>
    props.isAdmin
      ? props.theme.colors.primaryOrange600
      : props.theme.colors.secondaryGreen500};
  transition: width 0.35s ease;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  padding: 0 16px;
  margin-top: 32px;

  /* overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    } */
`;

export const Header = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: ${({ menuSidebar }) =>
    !menuSidebar ? "space-between" : "center"};
  width: 100%;
  margin-bottom: 32px;

  .sideButtonBackToPage {
    display: ${({ menuSidebar }) => (!menuSidebar ? "grid" : "none")};
    place-items: center;
    border: none;
    background-color: transparent;
    border-radius: 50%;
  }
`;

export const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  height: 100%;
  gap: 1rem;

  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Li = styled.li<Props>`
  position: relative;

  &:first-child {
    .sideLiRight {
      &::after {
        background: transparent;
      }
      .sideButtonLi {
        display: none;
      }
    }
  }

  &::after {
    content: '•';
    display: ${({ hasNotification, menuSidebar }) => (hasNotification && menuSidebar ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    line-height: 18px;
    position: absolute;
    right: 5px;
    top: -5px;
    font-size: 40px;
    color: ${(props) => props.theme.colors.primaryBlue500};    
  }

  a {
    display: flex;
    align-items: center;
    justify-content: ${({ menuSidebar }) =>
      !menuSidebar ? "space-between" : "center"};
    padding: 10px 9px;
    border-radius: 8px;
    background-color: transparent;
    
    cursor: pointer;
    .sideLiLeft {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .sideTitleLi {
        display: ${({ menuSidebar }) => (!menuSidebar ? "block" : "none")};
        font-size: 1rem;
        font-weight: 600;
        color: #FFF;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .sideLiRight {
      display: ${({ menuSidebar }) => (!menuSidebar ? "flex" : "none")};
      place-items: center;
      position: relative;

      .sideButtonLi {
        display: grid;
        place-items: center;
        border: none;
        background-color: transparent;
      }

      &::after {
        width: auto;
        min-width: 16px;
        height: 16px;
        line-height: 18px;
        padding: 4px;
        position: absolute;
        right: 22px;
        border-radius: 4px;
        background-color: ${(props) => props.hasNotification === undefined ? 'transparent' : '#23367C' };
        border: none;
        text-align: center;
        font-size: 11px;
        font-weight: 600;
        color: #FFF;
        content: "${(props) => props.hasNotification && props.hasNotification}";
        display: ${(props) => props.hasNotification < 0 ? 'none' : 'inline' };
        /* display: 'inline'; */
      }
    }

    ${(props) =>
      props.hasSelected &&
      css`
        background-color: #FFF;
        color: ${({ theme }) => theme.colors.primaryBlue500};
        font-weight: 600;

        svg {
          path {
            fill: ${(props) =>
                  props.isAdmin
                    ? props.theme.colors.primaryOrange600
                    : props.theme.colors.secondaryGreen500};
          }
        }

        .sideLiLeft {
          .sideTitleLi {
            color: ${({ theme }) => theme.colors.primaryBlue500};
          }
        }

        .sideLiRight {
          .sideButtonLi {
            svg {
              path {
                fill: ${(props) =>
                      props.isAdmin
                        ? props.theme.colors.primaryOrange600
                        : props.theme.colors.secondaryGreen500};
              }
            }
          }
        }
      `}
  }
`;

export const SectionFooter = styled.div<Props>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.isAdmin
      ? props.inProfile 
        ? props.theme.colors.interfacePure0
        : props.theme.colors.primaryOrange500
      : props.inProfile
        ? props.theme.colors.interfacePure0
        : props.theme.colors.secondaryGreen600};
  border-radius: 8px 8px 0 0;
  padding: 12px 20px;
  position: relative;
`;

export const ProfileFooter = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  position: relative;

  .avatarUser {
    position: absolute;
    top: -28px;
    left: 0px;
    padding: 8px;
    border-radius: 8px;
    background-color: ${(props) =>
      props.isAdmin
        ? props.inProfile
          ? props.theme.colors.primaryOrange100
          : '#F18200'          
        : props.inProfile
          ? props.theme.colors.secondaryGreen100
          : props.theme.colors.secondaryGreen400};
  }

  .sideButtonFooter {
    display: grid;
    place-items: center;
    border: none;
    background-color: transparent;
    opacity: ${({ menuSidebar }) => (!menuSidebar ? "1" : "0")};
    cursor: pointer;
  }
`;

export const DescriptionProfileFooter = styled.div<Props>`
  display: flex;
  flex-direction: column;

  h2 {
    display: ${({ menuSidebar }) => (!menuSidebar ? "block" : "none")};
    font-size: 1.25rem;
    /* font-size: ${({ menuSidebar }) => (!menuSidebar ? "1.25rem" : "0.854rem")}; */
    white-space: nowrap;
    font-weight: 500;
    color: ${(props) =>
      props.inProfile
        ? props.theme.colors.primaryBlue500
        : props.theme.colors.interfacePure0
    };
    margin-bottom: 2px;
    text-align: ${({ menuSidebar }) => (!menuSidebar ? "initial" : "center")};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    margin-top: 2px;
    font-size: 0.875rem;
    font-weight: 600;
    color: ${(props) =>
      props.isAdmin
        ? props.inProfile
          ? '#F18200'
          : props.theme.colors.primaryOrange100
        : props.inProfile
          ? props.theme.colors.secondaryGreen500
          : '#B8FFF2'};
    text-align: ${({ menuSidebar }) => (!menuSidebar ? "initial" : "center")};
  }

  .sideButtonFooter {
    display: grid;
    place-items: center;
    border: none;
    background-color: transparent;    
  }
`;

export const SectionLogout = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: ${({ menuSidebar }) =>
    !menuSidebar ? "flex-end" : "center"};
  margin-bottom: ${({ menuSidebar }) => (!menuSidebar ? "8px" : "22px")};
  padding: 0 16px;
  /* margin-right: 6px; */

  .sideButtonLogout {
    display: flex;
    align-content: center;
    justify-content: center;
    border: none;
    background-color: transparent;

    svg {
      width: 22px;
      /* transform: rotate(180deg); */
    }
    span {
      padding: 4px;
      text-align: center;
      font-size: 0.875rem;
      color: #fff;
      display: ${({ menuSidebar }) => (!menuSidebar ? "block" : "none")};
    }
  }
`;
