import styled from "styled-components";

export const Container = styled.div`
    width: 1040px;
    /* max-width: 90%; */
    height: 350px;
    min-height: 40vh;
    /* margin: 0px auto;     */
    
    .select-all {
        margin-bottom: 16px;
        padding-left: 32px;

        .label {
           color: ${({ theme }) => theme.colors.interfacePure500};
           font-size: 18px;
           font-weight: 600;
        }

        .checkbox {
            .square {
                border: 1px solid ${(props) => props.theme.colors.interfaceText200};
                background: white;
            }
            &.active {
                .square {
                    background-color: ${({ theme }) => theme.colors.primaryOrange700};
                    border: 1px solid ${(props) => props.theme.colors.primaryOrange700};
                }
            }
        }
    }    
`

export const Archipelago = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
`

export const Island = styled.div`
    display: flex;
    align-items: center;
    width: 336px;
    height: 96px;
    padding: 36px 32px;
    background: ${({ theme }) => theme.colors.background};
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.interfacePure200};

    .checkbox {
        .square {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid ${(props) => props.theme.colors.interfaceText200};
            background: white;
        }
        &.active {
            .square {
                background-color: ${({ theme }) => theme.colors.primaryOrange700};
                border: 1px solid ${(props) => props.theme.colors.primaryOrange700};
            }
        }
    }

    .tag {
        font-weight: 500;
        font-size: 18px;
        color: ${({ theme }) => theme.colors.interfaceText500};
    }
`