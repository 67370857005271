/* eslint-disable react/jsx-no-target-blank */
import { IconAttachment, IconDownload, IconUpload } from "../../icons";
import { WrapperFiles } from "./styles";
import Moment from 'react-moment';
import 'moment/locale/pt-br';

interface FileProps {
    files?: any,
    edit?: any,
    sendFiles?: any,
    hideDate?: boolean,
}

export default function DemandFiles({files, edit, sendFiles, hideDate}: FileProps) {

    return (
        <WrapperFiles>
            <span>Arquivos:</span>
            {
                files?.arquivos.length === 0 &&
                <div className="file">Sem arquivos anexados</div>
            }
            {
                files?.arquivos.length > 0 &&
                files.arquivos.map((row: any) => (
                    <div className="file" key={row}>
                        <div className="file-details">
                            <div className="icon-title">
                                <IconAttachment />
                                {row}
                            </div>
                            {
                                !hideDate &&
                                <div className="date"><Moment locale='pt-br' format="DD/MM/YYYY">{files?.criado}</Moment></div>
                            }
                        </div>
                        <div className="download">
                            <a href={`https://juridicoja.backendtropa.com.br/uploads/docs/${row}`} target="_blank" rel="noreferrer">
                                <IconDownload />
                            </a>                            
                        </div>
                    </div>
                ))
            }
            {
                edit &&
                <div
                    className="add"
                    onClick={() => console.log('Clikei no add files')}
                >
                    <IconUpload />
                    <input
                        type="file"
                        name="Files"
                        // accept="image/*"
                        className="file-input"
                        onChange={sendFiles}
                    />
                </div>
            }

        </WrapperFiles>
    )
}