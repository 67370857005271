import styled from "styled-components";

export const Wrapper = styled.div`

    .bank-account-form {
        .account-data {
            display: flex;
            gap: 16px;
            margin-bottom: 24px;

            .big, .label {
                width: 100%;
                position: relative;
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({ theme }) => theme.colors.interfaceDanger};
                }
            }
        }
        .bank-data {
            display: flex;
            gap: 16px;
            margin-bottom: 24px;

            .big, .label {
                width: 100%;
                position: relative;
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({ theme }) => theme.colors.interfaceDanger};
                }
            }

            .account {
                width: 100%;
                max-width: 248px;
            }
        }
        .pix-data {
            display: flex;
            gap: 16px;
            margin-bottom: 24px;
            .label {
                width: 100%;
                max-width: 336px;
                position: relative;
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({ theme }) => theme.colors.interfaceDanger};
                }
            }
            .big {
                width: 336px;
            }
        }

        .label {
            p {
                margin-bottom: 8px;
                font-size: 14px;
            }
        }

        .css-tlfecz-indicatorContainer,
        .css-1gtu0rj-indicatorContainer {
            svg {
                fill: orange;
            }
        }
    }
`