import styled from "styled-components";

interface Props {
    menuOpen: boolean;
    profileStatus?: any,
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;   
    
    box-shadow: 35px 45px 73px rgba(32, 32, 35, 0.07);
`

export const ProposalWrapper = styled.div<Props>`
    display: flex;
    flex-direction: column;    
    width: 100%;
    height: ${({ menuOpen }) => (menuOpen ? "160px" : "80px")};    
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;     
    
    .title {
        color: ${({ theme }) => theme.colors.interText400};
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 4px;
    }

    .subtitle {
        color: ${({ theme }) => theme.colors.interText400};
        font-size: 14px;
        font-weight: 500;
    }
`

export const ProposalTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding-left: 24px;
    padding-right: 16px;
`

export const ProposalBottom = styled.div<Props>`
    display: ${({ menuOpen }) => (menuOpen ? "flex" : "none")};
    align-items: center;
    justify-content: space-between;
    height: 80px;    
    padding-left: 24px;
    padding-right: 16px;
`

export const DivIcon = styled.div`
    width: 40px;
    svg {
        path {
            fill: ${({ theme }) => theme.colors.primaryBlue500};
        }
    }
`

export const DivCode = styled.div`
    width: 80px;
`

export const DivServices = styled.div`
    width: 225px;
    overflow-x: hidden;
`

export const DivProfit = styled.div`
    width: 80px;
    height: 48px;
`

export const DivProcess = styled.div`
    width: 225px;
`

export const DivProfile = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: ${({ theme }) => theme.colors.primaryBlue500};
    border-radius: 8px;
    cursor: pointer;

    &.off {
        background: none;
        cursor: auto;
    }
`

export const DivArrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;    
    cursor: pointer;

    &.fake {
        cursor: auto;
    }
`

export const DivConclusion = styled.div`
    width: 170px;
`

export const DivProfessional = styled.div`
    width: 250px;
`

export const DivValue = styled.div`
    width: 250px;
`
