// React
import { useEffect, useState, } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Header from "../../../components/HeaderSite";
import Footer from "../../../components/Footer";
import ModalCookies from '../../../components/ModalCookies';

// Images
import imageRevolutionCarrer from "../../../assets/images/Revolution.svg";
import National from "../../../assets/images/National.svg";
import Representation from "../../../assets/images/Representation.svg";
import Professional from "../../../assets/images/Professional.svg";
import Clients from "../../../assets/images/Clients.svg";
import HomeImage from "../../../assets/images/Home.svg";
import RequestImage from "../../../assets/images/Solicite.svg";
import ManagementImage from "../../../assets/images/Management.svg";
import CookieIcon from "../../../assets/images/Cookie.svg";

// Styles
import {
  Container,
  AreaHeader,
  AreaSite,
  AreaImage,
  Image,
  Description,
  AreaDescription,
  TextDescription,
  HeaderWrapper,
  DemandButton,
  DemandTitle,
  WrapperButtons,
  HomeButton,
  RoutineSection,
  RoutineCardWrapper,
  RoutineCard,
  SolicitationCard,
  ManagementCard,
  ManagementWrapper,
  RevolutionWrapper,
  ModalCookiesMain,
  ModalButton,
 
} from "./styles";

export default function Home() {
  const history = useHistory<any>();
  const [cookiesOn, setCookiesOn] = useState<boolean>(true);
  // const [ load, setLoad ] = useState(true);
  
  useEffect(() => {
    function scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    scrollTop();
  }, []);


  return (
    <Container>

      <AreaHeader>
        <Header />
      </AreaHeader>

      <HeaderWrapper>

        <AreaSite>
          <AreaImage>
            <div className='position'>
              <div className='home'>
                <Image src={HomeImage} />
              </div>

              <div className='orange-circle'></div>
            </div>
          </AreaImage>

          <Description>
            <AreaDescription>
              <TextDescription>
                <div className='title'>
                  A solução mais completa do mercado para <span>descomplicar suas demandas jurídicas Já!</span>
                </div>
              </TextDescription>

              <WrapperButtons>
                <HomeButton 
                  className='blue'
                  onClick={() => history.push('/solicitardemanda')}
                >
                  Conheça as vantagens de solicitar
                </HomeButton>

                <HomeButton 
                  className='border'
                  onClick={() => history.push('/receberoportunidades')}
                >
                    Receba novas oportunidades  
                </HomeButton>
              </WrapperButtons>
            </AreaDescription>
          </Description>
        
        </AreaSite>          
       
      </HeaderWrapper>

      <RoutineSection>
        <DemandTitle className='first'>
          Estamos preparados para <span>organizar sua rotina jurídica!</span> 
        </DemandTitle>

        <RoutineCardWrapper>
          <RoutineCard>
            <div className='icon'>
              <Image src={National} />
            </div>
            Atendimento <br />
            Nacional
          </RoutineCard>
          <RoutineCard>
            <div className='icon'>
              <Image src={Representation} />
            </div>
            Representantes <br />
            Regionais
          </RoutineCard>
          <RoutineCard>
            <div className='icon'>
              <Image src={Professional} />
            </div>
              Profissionais <br />
              Jurídicos
          </RoutineCard>
          <RoutineCard>
            <div className='icon'>
              <Image src={Clients} />
            </div>
            Clientes <br /> Satisfeitos
          </RoutineCard>
        </RoutineCardWrapper>

        <div className='text'>
          Nos destacamos por nossa tecnologia, busca de menores custos, evitando <br />
          pedidos de reembolso, foco em resultados e métodos únicos para agilizar o <br />
          envio das diligências. Além disso, Jurídico Já tem como valores a <br />
          <span>transparência,</span> <span>ética,</span> <span>inovação</span> e <span>compromisso.</span> 
        </div>

        <DemandButton 
          className='structure'
          onClick={() => history.push('/quemsomos')}
        >
          Saiba mais sobre a nossa estrutura!
        </DemandButton>
      </RoutineSection>

      <SolicitationCard>
          
        <DemandTitle className='light'>
          <span>Solicite ao longo do mês, pague no final</span> e o resto você deixa com a gente :)
        </DemandTitle>

        <div className='text'>            
          
          <div className='title'>Centralize suas demandas e perceba como fica fácil trabalhar assim!</div>
          <div className='subtitle'>
            Aqui você pode solicitar demandas por preços fixos, acompanhar a realização, contar com o apoio das ilhas de atendimento e resolver todas as suas demandas com uma única fatura mensal.
          </div>

        </div>

        <DemandButton 
          className='easy'
          onClick={() => history.push('/solicitardemanda')}
        >
          Solicitar fácil, Já!
        </DemandButton>

        <div className='image'>
          <Image src={RequestImage} />
        </div>

      </SolicitationCard>
      
      <ManagementWrapper>

        <ManagementCard>

          <DemandTitle className='light'>
            A <span>gestão eficiente</span> das suas <br /> demandas ao seu alcance
          </DemandTitle>

          <div className='subtitle'>
            <span>Receba oportunidades de serviços e gerencie</span>  <br /> 
            <span>demandas</span> em uma plataforma fácil, prática
            e eficiente. <br />
            <div className='bottom'>
              Acompanhe as demandas, receba orientações tudo isso em um só lugar.
            </div>
          </div>

          <DemandButton 
            className='button'
            onClick={() => history.push('/receberoportunidades')}
          >
            Conheça nossa plataforma
          </DemandButton>


          <div className='image'>
            <Image src={ManagementImage} />
          </div>

        </ManagementCard>

        

      </ManagementWrapper>

      <RevolutionWrapper>

        <div className='card'>

          <DemandTitle className='text'>
            Revolucione <br />
            sua <span>carreira jurídica</span>!
          </DemandTitle>

          <div className="subtitle">
            Nossa equipe é composta por profissionais 
            experientes e qualificados, <span>treinados e 
            capacitados tecnologicamente </span> para entregar 
            os melhores resultados do mercado.
          </div>

          <DemandButton 
            className='revolution'
            onClick={() => history.push('/contato')}
          >
            Faça parte da equipe Jurídico Já!
          </DemandButton>

          <div className='image'>
            <Image src={imageRevolutionCarrer} />
          </div>
        </div>

      </RevolutionWrapper>                
      
      <Footer />

      {/* Modal Cookies */}
      <ModalCookies
        id={'overlayModal'}
        onClose={() => setCookiesOn((cookiesOn: any) => !cookiesOn)}
        openModal={cookiesOn}
        width="332px"
      >
        <ModalCookiesMain>
          <div className='icon'>
            <Image src={CookieIcon} />
          </div>

          <div className='description'>
            Usamos cookies para personalizar e
            melhorar a sua experiência no site. Ao
            continuar navegando, você concorda com
            a nossa <span>Política de Cookies e privacidade.</span>
          </div>

          <ModalButton
            onClick={() => setCookiesOn(false)}
          >
            Continuar e fechar            
          </ModalButton>

        </ModalCookiesMain>
      </ModalCookies>
    </Container>
  );
}
