/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// React //
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Libraries //
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';


// Components //
import BankData from "../../components/BankData";
import BannerPlan from "../../components/BannerPlan";
import Counties from "../../components/Counties";
import ButtonIcon from "../../components/form/ButtonIcon";
import Prices from "../../components/Prices/Index";
import AccountTabs from "../../components/Ui/AccountTabs";
import ButtonsCard from "../../components/Ui/ButtonsCard";
import InputDefault from "../../components/form/InputDefault";
import { EstadosBrasileiros } from '../../components/Data/StatesData';
import ModalDefault from "../../components/ModalDefault";
import ButtonDefault from "../../components/form/ButtonDefault";
import RegisterPage5 from "../../components/RegisterPage5";
import ChangePassword from "../../components/ChangePassword";

// Styles //
import { 
    ActivityModal, 
    Container, 
    FreeCardPlan, 
    Menu, 
    ModalMain, 
    ModalPlanMain, 
    ProCardPlan 
} from "./styles";

// Icons //
import { 
    IconActiveUser, 
    IconActivityOne, 
    IconActivityTwo, 
    IconClose, 
    IconInactiveUser, 
    IconNewPlan, 
    IconPlanFree, 
    IconPlanPro, 
    IconWarning 
} from "../../components/icons";

// Services //
import api from "../../services/api";
import axios from "axios";
import { useAuth } from "../../contexts/UserContext";

interface Profile {
    nome?: string,
    email?: string,
    cpf?: string,
    cnpj?: string,
    celular?: string,
    whatsapp?: string,
    razao_social?: string,
    inscricao_oab?: string,
    tipo_oab?: string,
    estado_oab?: string,
    tel_fixo?: string,
    tel_recado?: string,
    cep?: string,
    uf?: string,
    cidade?: string,
    bairro?: string,
    endereco?: string,
    numero?: string,
    complemento?: string,
    id_plano?: number,
    status?: string,
}

export default function ProfessionalProfile() {
    const params = useParams<any>()
    const profileId = params.id;
    const { setPageName, user, pageName } = useAuth();
    const [ loading, setLoading ] = useState<any>(false);
    const [ selected, setSelected ] = useState('1');
    const [ updateUser, setUpdateUser ] = useState<any>();
    const [ updateBankAccount, setUpdateBankAccount ] = useState<any>();
    const [ updateCounties, setUpdateCounties ] = useState<any>();
    const [ updatePrices, setUpdatePrices ] = useState<any>();
    const [ bankId, setBankId ] = useState<any>();
    const [ countyId, setCountyId ] = useState<any>();
    const [ priceId, setPriceId ] = useState<any>();
    const [ editProfile, setEditProfile ] = useState<any>();
    const [ profile, setProfile ] = useState<Profile>();
    const [ cepState, setCepState ] = useState();
    const [ cepCity, setCepCity ] = useState();
    const [ cepCounty, setCepCounty ] = useState();
    const [ cepAddress, setCepAddress ] = useState(); 
    const [ openModalDelete, setOpenModalDelete ] = useState<any>();
    const [ openPlanModal, setOpenPlanModal ] = useState<any>();
    const [ deleteProfile, setDeleteProfile ] = useState<any>(false);
    const [ selectedPlan, setSelectedPlan ] = useState<any>();
    const [ updateData, setUpdateData ] = useState<any>();
    const [ openModalActivity, setOpenModalActivity ] = useState<any>();    
    const [ changeUserActivity, setChangeUserActivity ] = useState<any>();    
    const [ changeActivity, setChangeActivity ] = useState<any>();    
    const [ justicationText, setJustificationText ] = useState<any>();
    const [ priceName, setPriceName ] = useState<any>();

    const oabType = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            }
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    };

    const oabState = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const inscription = [
        { value: 'principal', label: 'Inscrição principal' },
        { value: 'Suplementar', label: 'Inscrição suplementar' },
        { value: 'Transferência', label: 'Inscrição por transferência' },
    ]; 
    
    useEffect(() => {
        async function getProfile() {
            try {

                setLoading(true);
                const response = await api.get('usuario/' + profileId)
                setProfile(response.data.result);     
                
                setLoading(false);
    
            } catch (error) {
                console.log('log dos erros recebendo o profile', error)
                setLoading(false);
            }
        } 
        
        async function getBankId() {
            try {

                setLoading(true);
                const response = await api.get('conta-usuario/' + profileId);
                setBankId(response.data.result[0].id_conta); 
                
                
                if (response.data.status !== 'success') {
                    throw new Error();
                }
                setLoading(false);

            } catch (error: any) {
                console.log('log err getBankId', error);
                setLoading(false);
                
            }
        }
    
        async function getCountyId() {
            try {
                setLoading(true);
                const response = await api.get('comarca-usuario/' + profileId);
                setCountyId(response.data.result[0].id_comarca)
                
                if (response.data.status !== 'success') {
                    throw new Error();
                }
                
                setLoading(false);
                
            } catch (error: any) {
                console.log('log err getCountyId', error)
                setLoading(false);
            }
        }
    
        async function getPricesId() {
            try {
                setLoading(true);
                const response = await api.get('precificacao-usuario/' + profileId);
                setPriceId(response.data.result[0].id_precificacao);
                setPriceName(response.data.result[0].titulo);
                
                if (response.data.status !== 'success') {
                    throw new Error();
                }
            } catch (error: any) {
                console.log('log err getPricesId', error)
            }
        }
        
        getBankId();
        getCountyId();
        getPricesId();   
        getProfile();  

    }, [updateData]);  

    useEffect(() => {  
        
        const first = profile?.nome?.split(' ')[0];
        
        setPageName(`${first} D${profileId}`);                       
        
    }, [profile]);

    function saveUpdate() {

        if (selected === '1') {
            handleUpdateUser();
        }

        if (selected === '2') {
            handleUpdateBankAccount();
        }

        if (selected === '3') {           
            handleUpdateCounties();
        }

        if (selected === '4') {
            handleUpdatePrices();
        }        

    };

    async function handleUpdateUser() {

        try {
            const response = await api.put('usuario/' + profileId, updateUser);

            if (response.data.status !== 'success') {
                throw new Error ('Não foi possível realizar a solicitação, tente novamente mais tarde');
            }

            toast.success('Alterações salvas com sucesso', {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                    background: '#E7F8EB',
                    border: '2px solid #9EE4AF',
                }
            });

            if (response.data.status === 'success') {
                setUpdateData('update please');
            }


        } catch (error: any) {
            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
            console.log('Log do erro', error)
        }
    };

    async function handleUpdateBankAccount() {

        try {
            const response = await api.put('conta/' + bankId, updateBankAccount);

            if (response.data.status !== 'success') {
                throw new Error('Não foi possível realizar a solicitação, tente novamente mais tarde')
            };

            toast.success('Alterações salvas com sucesso', {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                    background: '#E7F8EB',
                    border: '2px solid #9EE4AF',
                }
            });


        } catch (error) {

            console.log('Log do erro', error)
        }
    };

    async function handleUpdateCounties() {

        try {

            const comarcas: any[] = [];
            console.log('log do profile', profile)

            updateCounties.map((row: any) => (

                comarcas.push({
                    uf: row.uf,
                    cidade: row.cidade,
                    precificacao: priceName,
                    principal: row.principal,
                })
            ))

            const response = await api.put('usuario/comarca/' + profileId , {comarcas});

            if (response.data.status !== 'success') throw new Error ('Não foi possível realizar a solicitação, tente novamente mais tarde');

            toast.success('Alterações salvas com sucesso', {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                    background: '#E7F8EB',
                    border: '2px solid #9EE4AF',
                }
            });


        } catch (error: any) {
            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

            console.log('Log do erro', error)
        }
    };

    async function handleUpdatePrices() {

        try {
            console.log('log do updatePrices', updatePrices)
            const response = await api.put('precificacao/' + priceId, updatePrices);

            if (response.data.status !== 'success') {
                throw new Error('Não foi possível realizar a solicitação, tente novamente mais tarde')
            };

            toast.success('Alterações salvas com sucesso', {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                    background: '#E7F8EB',
                    border: '2px solid #9EE4AF',
                }
            });


        } catch (error) {

            console.log('Log do erro', error)
        }
    };

    const [DTOUser, setDTOUser] = useState({
        nome: profile?.nome,
        email: profile?.email,
        cpf: profile?.cpf,
        cnpj: profile?.cnpj,
        celular: profile?.celular,
        whatsapp: profile?.whatsapp,
        razao_social: profile?.razao_social,
        inscricao_oab: profile?.inscricao_oab,
        tipo_oab: profile?.tipo_oab,
        estado_oab: profile?.estado_oab,
        tel_recado: profile?.tel_recado,
        tel_fixo: profile?.tel_fixo,
        cep: profile?.cep,
        uf: profile?.uf,
        cidade: profile?.cidade,
        bairro: profile?.bairro,
        endereco: profile?.endereco,
        numero: profile?.numero,
        complemento: profile?.complemento,
        id_plano: profile?.id_plano,
        status: profile?.status,
    });

    function handleOnChange(name: any, value: any) {        
        
        const newDTO: any = DTOUser
        newDTO[name] = value
        setDTOUser({ ...newDTO })

        setUpdateUser(DTOUser)
    };

    function handleSelect(selected: any) {
        if (selected.value.length > 2) {
            handleOnChange('tipo_oab', selected.value)
        } else {
            handleOnChange('estado_oab', selected.value)
        }
    };

    async function consultCep(value: any) {

        try {

            setLoading(true);
            const response = await axios.get(`https://viacep.com.br/ws/${value}/json/`)
            const data = response.data
            setCepState(data.uf);
            setCepCity(data.localidade);
            setCepCounty(data.bairro);
            setCepAddress(data.logradouro);
            handleOnChange('uf', data.uf);
            handleOnChange('cidade', data.localidade);
            handleOnChange('bairro', data.bairro);
            handleOnChange('endereco', data.logradouro);
            setLoading(false);

        } catch (err: any) {
            console.log('err, cep', err)
            setLoading(false);
        }

    }; 

    useEffect(() => {
        
        handleDeleteProfile()
        
    }, [deleteProfile])
    
    async function handleDeleteProfile() {
        try {
            setLoading(true)
            if (deleteProfile === true) {
                
                const response = await api.delete('/usuario/' + profileId)                
    
                if ( response.data.status !== 'success') {
                    throw new Error()
                }

                if (response.data.status === 'success') {
                    toast.success('Perfil excluído com sucesso.', {
                        style: {
                            padding: '16px',
                            color: '#FA9604',
                        },
                        iconTheme: {
                            primary: '#02E8BE',
                            secondary: '#FFFAEE',
                        },
                    });
                }

                setLoading(false)
                setOpenModalDelete(false)                
            }

            
        } catch (error: any) {

            console.log('log do err delete', error);
            setLoading(false);


        }
    };

    function sendUpdatePlan() {
        saveUpdate();
        setOpenPlanModal(false);
    }

    function updateProfilePlan(value: any) {
        
        setSelectedPlan(value);
        handleOnChange('id_plano', value);                

    }

    function cancelChangeActivity() {
        
        setOpenModalActivity(false);
        setChangeActivity(false);
        setChangeUserActivity('')
    }

    function setUserStatus() {

        if (changeUserActivity === undefined) {
            toast(
            <div className="warning"><span><IconWarning /></span> Escolha ativar ou inativar o usuário antes de continuar.</div>,
                {
                    duration: 3000,
                    style: {
                        padding: '16px',
                        minWidth: '520px',
                        color: '#1A2858',
                        background: '#E4F5FF',
                        border: '2px solid #88D3FE',
                    },
                });            
        } else {
            setChangeActivity(true);
            handleOnChange('status', changeUserActivity)
        }

    }    

    async function updateActivity() {
        try {

            if (justicationText === undefined || justicationText === '') {
                throw new Error ('Escreva uma justificativa antes de solicitar a alteração desse perfil')
            }
            
            const userStatus = changeUserActivity === 'active' ? 'ativo' : 'inativo'            

            const response = await api.put('/usuario/'+ profileId,
                {                    
                    status: userStatus,
                    motivo_alteracao: justicationText,                    
                } 
            )

            if (response.data.status !== 'success') {

                throw new Error()

            } else {

                toast.success('Alterações salvas com sucesso', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                        background: '#E7F8EB',
                        border: '2px solid #9EE4AF',
                    }
                });
                
                cancelChangeActivity();
                setUpdateData('update please');                
            }

        } catch (error: any) {
            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
        }
    }
    
    useEffect(() => {
        
        if (user.profile === 'comum') {
            const user_Id = 'D' + user.id.toString();
            const user_Pagename = pageName.split(' ')[1];
            
            if (user_Id !== user_Pagename) {
    
                setEditProfile(false);
    
            } else {
    
                setEditProfile(true);
            }
        }

        if (user.profile === 'operador') {
            const user_Id = 'P' + user.id.toString();
            const user_Pagename = pageName.split(' ')[1];
            
            if (user_Id !== user_Pagename) {
    
                setEditProfile(false);
    
            } else {
    
                setEditProfile(true);
            }
        }

        if (user.profile === 'admin') {
            const user_Id = 'J' + user.id.toString();
            const user_Pagename = pageName.split(' ')[1];
            
            if (user_Id !== user_Pagename) {
    
                setEditProfile(false);
    
            } else {
    
                setEditProfile(true);
            }
        }



    }, [pageName])  

    function cancelChanges() {

        setUpdateData(new Date());
        window.location.reload();

    }

    return (
        <Container>

            {
                loading &&
                <Oval
                    height={150}
                    width={150}
                    color="#FA9604"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#FA9604"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            }

            {
                !loading &&
                <Menu>

                    <AccountTabs 
                        selected={selected} 
                        setSelected={setSelected} 
                    />
                    <ButtonsCard 
                        setSaveData={() => saveUpdate()} 
                        setEditProfile={() => setEditProfile(!editProfile)} 
                        editProfile={editProfile} 
                        setDelete={() => setOpenModalDelete(true)}
                        setConfigActivity={() => setOpenModalActivity(true)}
                        profileStatus={profile?.status}
                        activeOperator={() => setOpenModalActivity(true)}
                        setSelected={setSelected}
                        cancelChanges={cancelChanges}
                    />

                </Menu>
            }

            {
                selected === '1' &&
                profile?.id_plano === 1 &&
                profile?.status === 'ativo' &&
                !loading &&
                <BannerPlan
                    icon={<IconPlanFree />}
                    title="Jurídico Já Free"
                    subtitle={<div>O plano atual permite <span>visualizar</span> oportunidades</div>}
                    button={
                        <div>
                            <ButtonIcon
                                children={'Alterar plano'}
                                Icon={<IconNewPlan />}
                                styles={'new'}
                                onClick={() => setOpenPlanModal(true)}
                            />
                        </div>
                    }
                    className={'free'}
                />            
            }

            {
                selected === '1' &&
                profile?.id_plano === 1 &&
                profile?.status === 'inativo' &&
                !loading &&
                <BannerPlan
                    icon={<IconPlanFree />}
                    title="Jurídico Já Free"
                    subtitle={<div>Este cadastro foi <span>inativado.</span></div>}
                    button={
                        <div>
                            <ButtonIcon
                                children={'Alterar plano'}
                                Icon={<IconNewPlan />}
                                styles={'help'}
                                onClick={() => setOpenPlanModal(true)}
                            />
                        </div>
                    }
                    className={'danger'}
                />
            }

            {
                selected === '1' &&
                profile?.id_plano === 2 &&
                profile?.status === 'ativo' &&
                !loading &&
                <BannerPlan
                    icon={<IconPlanPro />}
                    title="Jurídico Já Pro"
                    subtitle={<div>O plano atual permite <span>responder</span> oportunidades</div>}
                    button={
                        <div>
                            <ButtonIcon
                                children={'Alterar plano'}
                                Icon={<IconNewPlan />}
                                styles={'pro'}
                                onClick={() => setOpenPlanModal(true)}
                            />
                        </div>
                    }
                    className={'pro'}
                />                    
            }

            {
                selected === '1' &&
                profile?.id_plano === 2 &&
                profile?.status === 'inativo' &&
                !loading &&
                <BannerPlan
                    icon={<IconPlanPro />}
                    title="Jurídico Já Pro"
                    subtitle={<div>Este cadastro foi <span>inativado</span>.</div>}
                    button={
                        <div>
                            <ButtonIcon
                                children={'Alterar plano'}
                                Icon={<IconNewPlan />}
                                styles={'help'}
                                onClick={() => setOpenPlanModal(true)}
                            />
                        </div>
                    }
                    className={'danger'}
                />
            }


            {
                selected === '1' &&
                !loading &&
                <div className="profile">
                    <form className="register-form" >

                        <div className="name">
                            <InputDefault
                                title="Seu nome:"
                                name='nome'
                                value={profile?.nome}
                                onChangeText={(value) => handleOnChange('nome', value)}
                                className='big'
                                disabled={editProfile ? false : true}
                            />

                            <InputDefault
                                title="CPF:"
                                name="cpf"
                                mask="999.999.999-99"
                                value={profile?.cpf}
                                onChangeText={(value) => handleOnChange('cpf', value)}
                                className='small'
                                disabled={editProfile ? false : true}
                            />

                            <InputDefault
                                title="Razão Social:"
                                name="razao_social"
                                value={profile?.razao_social}
                                onChangeText={(value) => handleOnChange('razao_social', value)}
                                className='big' 
                                disabled={editProfile ? false : true}
                            />

                            <InputDefault
                                title="CNPJ:"
                                name="cnpj"
                                mask="99.999.999/9999-99"
                                value={profile?.cnpj}
                                onChangeText={(value) => handleOnChange('cnpj', value)}
                                className='small'
                                disabled={editProfile ? false : true}
                            />

                            <InputDefault
                                title="Inscrição na OAB:"
                                name="inscricao_oab"
                                value={profile?.inscricao_oab}
                                onChangeText={(value) => handleOnChange('inscricao_oab', value)}
                                className='oab'
                                disabled={editProfile ? false : true}
                            />

                            <div className="label-oab">
                                <p>Tipo da inscrição:</p>
                                <Select
                                    name="tipo_oab"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={oabType}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DDFAF4',
                                            primary50: '#DDFAF4',
                                            primary: '#02E8BE',
                                        },
                                    })}
                                    placeholder='Selecionar'
                                    value={profile?.tipo_oab !== '' ? { value: profile?.tipo_oab, label: profile?.tipo_oab } : ''}
                                    onChange={handleSelect}
                                    options={inscription}
                                    isDisabled={editProfile ? false : true}
                                />
                            </div>

                            <div className="label-state">
                                <p>Estado:</p>
                                <Select
                                    name="estado_oab"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={oabState}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DDFAF4',
                                            primary50: '#DDFAF4',
                                            primary: '#02E8BE',
                                        },
                                    })}
                                    placeholder='Selecionar'
                                    defaultValue={profile?.estado_oab !== '' ? { value: profile?.estado_oab, label: profile?.estado_oab } : ''}
                                    onChange={handleSelect}
                                    options={EstadosBrasileiros}
                                    isDisabled={editProfile ? false : true}
                                />
                            </div>
                        </div>

                        <div className="fone">
                            <InputDefault
                                title="Celular com DDD:"
                                name="celular"
                                mask="(99) 99999-9999"
                                value={profile?.celular}
                                onChangeText={(value) => handleOnChange('celular', value)}
                                className='small'
                                disabled={editProfile ? false : true}
                            />

                            <InputDefault
                                title="Telefone para recado:"
                                name="tel_recado"
                                mask="(99) 9999-9999"
                                value={profile?.tel_recado}
                                onChangeText={(value) => handleOnChange('tel_recado', value)}
                                className='small'
                                disabled={editProfile ? false : true}
                            />

                            <InputDefault
                                title="Telefone fixo:"
                                name="tel_fixo"
                                mask="(99) 9999-9999"
                                value={profile?.tel_fixo}
                                onChangeText={(value) => handleOnChange('tel_fixo', value)}
                                className='small'
                                disabled={editProfile ? false : true}
                            />

                            <InputDefault
                                title="E-mail:"
                                name="email"
                                value={profile?.email}
                                onChangeText={(value) => handleOnChange('email', value)}
                                className='big'
                                disabled={editProfile ? false : true}
                            />

                            <InputDefault
                                title="Whatsapp:"
                                name="whatsapp"
                                mask="(99) 99999-9999"
                                value={profile?.whatsapp}
                                onChangeText={(value) => handleOnChange('whatsapp', value)}
                                className='small'
                                disabled={editProfile ? false : true}
                            />
                        </div>

                        <div className="address">
                            <InputDefault
                                title="CEP:"
                                name="cep"
                                mask="99999-999"
                                value={profile?.cep}
                                onChangeText={(value) => handleOnChange('cep', value)}
                                onBlur={(e) => consultCep(e.target.value)}
                                className='small'
                                disabled={editProfile ? false : true}
                            />

                            <InputDefault
                                title="Estado:"
                                name="uf"
                                value={cepState || profile?.uf}
                                onChangeText={() => handleOnChange('uf', cepState)}
                                className='small'
                                disabled={editProfile ? false : true}
                            />


                            <InputDefault
                                title="Cidade:"
                                name="cidade"
                                value={cepCity || profile?.cidade}
                                onChangeText={() => handleOnChange('cidade', cepCity)}
                                className='small'
                                disabled={editProfile ? false : true}
                            />

                            <InputDefault
                                title="Bairro:"
                                name="bairro"
                                value={cepCounty || profile?.bairro}
                                onChangeText={(value) => handleOnChange('bairro', cepCounty)}
                                className='small'
                                disabled={editProfile ? false : true}
                            />

                            <InputDefault
                                title="Endereço:"
                                name="endereco"
                                value={cepAddress || profile?.endereco}
                                onChangeText={(value) => handleOnChange('endereco', cepAddress)}
                                className='big'
                                disabled={editProfile ? false : true}
                            />

                            <InputDefault
                                title="Número:"
                                name="numero"
                                value={profile?.numero}
                                onChangeText={(value) => handleOnChange('numero', value)}
                                className='small'
                                disabled={editProfile ? false : true}
                            />

                            <InputDefault
                                title="Complemento:"
                                name="complemento"
                                value={profile?.complemento}
                                onChangeText={(value) => handleOnChange('complemento', value)}
                                disabled={editProfile ? false : true}
                            />
                        </div>

                    </form >
                </div>
            }

            {
                selected === '2' &&
                !loading &&
                <div>
                    <BankData 
                        setUpdateBankAccount={setUpdateBankAccount} 
                        updateBankAccount={updateBankAccount} 
                        userId={profileId}  
                        editProfile={editProfile}
                    />
                </div>
            }

            {
                selected === '3' &&
                !loading &&
                <div>
                    <Counties 
                        setUpdateCounties={setUpdateCounties}  
                        userId={profileId}
                        editProfile={editProfile}
                    />
                </div>
            }

            {
                selected === '4' &&
                !loading &&
                <div>
                    <Prices 
                        setUpdatePrices={setUpdatePrices} 
                        userId={profileId} 
                        editProfile={editProfile}
                    />
                </div>
            }

            {
                selected === 'plan' &&
                !loading &&
                <div>
                    <div className="plan">
                        <h3>Selecione um novo plano para esta conta!</h3>
                        <p>Certifique-se de que o usuário está ciente e concorda com a alteração.</p>
                    </div>
                    <RegisterPage5 selected={DTOUser.id_plano === undefined ? profile?.id_plano : DTOUser.id_plano} setCard={(value: any) => handleOnChange('id_plano', value)} />
                </div>
            }

            {
                selected === 'pass' &&
                !loading &&
                <ChangePassword id_profile={profileId} setSelected={setSelected} />
            }

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>

            {/* Modal para excluir profissional */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setOpenModalDelete((openModalDelete: any) => !openModalDelete)}
                openModal={openModalDelete}
                width="520px"
            >
                <ModalMain>
                    <div className="title">
                        <div>Deseja excluir a conta?</div>
                        <div
                            className="close"
                            onClick={() => setOpenModalDelete(false)}
                        >
                            <IconClose />
                        </div>
                    </div>
                    <div className="buttons">
                        <ButtonDefault
                            className="cancel"
                            onClick={() => setOpenModalDelete(false)}
                        >
                            Cancelar
                        </ButtonDefault>
                        <ButtonDefault
                            className="danger"
                            onClick={() => setDeleteProfile(true)}
                        >
                            Excluir conta
                        </ButtonDefault>
                    </div>
                </ModalMain>

            </ModalDefault>

            {/* Modal para alterar plano do profissional */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setOpenPlanModal((openPlanModal: any) => !openPlanModal)}
                openModal={openPlanModal}
                width="512px"
            >
                <ModalPlanMain>
                    <div className="title">
                        <div>Configurar plano de assinatura</div>
                        <div
                            className="close"
                            onClick={() => setOpenPlanModal(false)}
                        >
                            <IconClose />
                        </div>
                    </div>

                    <FreeCardPlan
                        onClick={() => updateProfilePlan(1)}
                        selected={selectedPlan}
                    >
                        <div className="free-plan">
                            <div><IconPlanFree /></div>
                            <div>Jurídico Já Free</div>
                        </div>
                        <div className="free-price">
                            <div>Profissional poderá <span>visualizar</span> oportunidades.</div>
                            <div className="price">R$ 0,00</div>
                        </div>
                    </FreeCardPlan>

                    <ProCardPlan
                        onClick={() => updateProfilePlan(2)}
                        selected={selectedPlan}
                    >
                        <div className="pro-plan">
                            <div><IconPlanPro /></div>
                            <div>Jurídico Já Pro</div>
                        </div>
                        <div className="pro-price">
                            <div>Profissional poderá <span>responder</span> oportunidades.</div>
                            <div className="price">R$ 19,90</div>
                        </div>
                    </ProCardPlan>                    

                    <div className="buttons">
                        <ButtonDefault
                            className="cancel"
                            onClick={() => setOpenPlanModal(false)}
                        >
                            Cancelar
                        </ButtonDefault>
                        <ButtonDefault
                            className="save"
                            onClick={() => sendUpdatePlan()}
                        >
                            Alterar
                        </ButtonDefault>
                    </div>
                </ModalPlanMain>

            </ModalDefault>

            {/* Modal config atividade */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setOpenModalActivity((openModalActivity: any) => !openModalActivity)}
                openModal={openModalActivity}
                width="34.5%"
            >
                <ActivityModal>
                    <div className="title">
                        <div className="points">
                            Configurar atividade 
                            <span>{changeActivity ? <IconActivityTwo /> : <IconActivityOne />}</span> 
                        </div>
                        <div
                            className="close"
                            onClick={() => cancelChangeActivity()}
                        >
                            <IconClose />
                        </div>
                    </div>
                    {   changeActivity === false || changeActivity === undefined ?
                        <>
                            <div
                                className={changeUserActivity === 'active' ? "user-status active" : "user-status"}
                                onClick={() => setChangeUserActivity('active')}
                            >
                                <div className="user"><IconActiveUser /> Ativar usuário</div>
                                <div className="description-active">
                                    Permite <span>visualizar e responder</span> oportunidades.
                                </div>
                            </div>   

                            <div
                                className={changeUserActivity === 'inactive' ? "user-status inactive" : "user-status"}
                                onClick={() => setChangeUserActivity('inactive')}
                            >
                                <div className="user"><IconInactiveUser /> Inativar usuário</div>
                                <div className="description-inactive">
                                    <span>Impede o acesso</span> às oportunidades.
                                </div>
                            </div>
                        </>
                        : ''
                    }

                    {
                        changeActivity 
                        ? changeUserActivity === 'active' 
                            ?   <>
                                    <div
                                        className={changeUserActivity === 'active' ? "user-status active" : "user-status"}
                                        onClick={() => setChangeUserActivity('active')}
                                    >
                                        <div className="user"><IconActiveUser /> Ativar usuário</div>
                                        <div className="description-active">
                                            Permite <span>visualizar e responder</span> oportunidades.
                                        </div>
                                    </div>   
                                    <div className="text-area">
                                        <div className="subtitle">Descreva a justificativa da alteração:</div>
                                        <textarea 
                                            name="" 
                                            id="1" 
                                            className="justification"
                                            onChange={(e) => setJustificationText(e.target.value)}
                                        >
                                        </textarea>                                        
                                    </div>
                                </>
                            :   
                                <>
                                    <div
                                        className={changeUserActivity === 'inactive' ? "user-status inactive" : "user-status"}
                                        onClick={() => setChangeUserActivity('inactive')}
                                    >
                                        <div className="user"><IconInactiveUser /> Inativar usuário</div>
                                        <div className="description-inactive">
                                            <span>Impede o acesso</span> às oportunidades.
                                        </div>
                                    </div>
                                    <div className="text-area">
                                        <div className="subtitle">Descreva a justificativa da alteração:</div>
                                        <textarea 
                                            name="" 
                                            id="2" 
                                            className="justification"
                                            onChange={(e) => setJustificationText(e.target.value)}
                                        >
                                        </textarea>
                                    </div>
                                </>
                        : ''
                    }

                    <div className="buttons">
                        <ButtonDefault
                            className="cancel"
                            onClick={() => cancelChangeActivity()}
                        >
                            Cancelar
                        </ButtonDefault>
                        {
                            changeActivity 
                            ?   <ButtonDefault
                                    className="continue"
                                    onClick={() => updateActivity()}
                                >
                                    Alterar atividade
                                </ButtonDefault>
                            :   <ButtonDefault
                                    className="continue"
                                    // disabled={changeUserActivity === undefined ? true : false}
                                    onClick={() => setUserStatus()}
                                >
                                    Continuar
                                </ButtonDefault>

                        }
                    </div>
                </ActivityModal>
            </ModalDefault>


        </Container>
    )
}