import { Link } from "react-router-dom";
import { IconFacebook, IconHeart, IconInsta, IconLinkedin, IconLogoJuridico, IconMail, IconPhoneFooter } from "../icons";
import { Container } from "./styles";


export default function Footer() {
    return (
        <Container>
            <div className='top'>
                <div className='center'>

                    <div className='info'>
                        <div className='text'>
                            <Link
                                to={'/quemsomos'}
                            >
                                Quem somos
                            </Link>
                        </div>
                        <div className='text'>
                            <Link
                                to={'/contato'}
                            >
                                Tornar-se cliente
                            </Link>
                        </div>
                        <div className='text'>
                            <Link
                                to={{
                                    pathname: '/solicitardemanda',
                                    state: 'benefits'
                                }}
                            >
                                Benefícios de solicitar
                            </Link>
                        </div>
                        <div className='text'>
                            <Link
                                to={'/solicitarservico'}
                            >
                                Solicitar uma demanda
                            </Link>
                        </div>
                        <div className='text'>
                            <Link
                                to={'/contato'}
                            >
                                Entrar em contato
                            </Link>
                        </div>
                    </div>

                    <div className='info'>
                        <div className='text'>
                            <Link
                                to={'/receberoportunidades'}
                            >
                                Vantagens de atender
                            </Link>
                        </div>
                        <div className='text'>
                            <Link
                                to={'/cadastrar'}
                            >
                                Cadastrar-se como profissional
                            </Link>
                        </div>
                        <div className='text'>
                            <Link
                                to={'/contato'}
                            >
                                Tornar-se representante
                            </Link>
                        </div>
                        <div className='text'>
                            <Link
                                to={'/login'}
                            >
                                Fazer login
                            </Link>
                        </div>
                        <div className='text'>Políticas de privacidade e cookies</div>
                    </div>

                    <div className='contact'>
                        <div className='logo'><IconLogoJuridico /></div>

                        <div className='mail'>
                            <IconMail />
                            contato@juridicoja.com
                        </div>

                        <div className='social'>
                            <a href="https://www.linkedin.com/company/jur%C3%ADdico-j%C3%A1/about/" target={'_blank'} rel="noreferrer">
                                <IconLinkedin />
                            </a>
                            <a href="https://www.facebook.com/juridicojaoficial" target={'_blank'} rel="noreferrer">
                                <IconFacebook />
                            </a>
                            <a href="https://www.instagram.com/juridicojaoficial/" target={'_blank'} rel="noreferrer">
                                <IconInsta />
                            </a>
                            @juridicojaoficial
                        </div>

                        <div className='phone'>
                            <IconPhoneFooter />
                            <p>(31) 0000-0000</p>
                            <p>(31) 0000-0000</p>
                        </div>

                    </div>
                </div>
            </div>

            <div className='bottom'>
                <div className='left'>
                    <div>© <span>2021 Jurídico Já.</span></div>
                    <div>Todos os direitos reservados</div>
                </div>
                <div className='right'>
                    <div className="heart">
                        Made with
                        <IconHeart />
                        by 
                    </div>
                    <div className="links">
                        <a href="https://littleboat.com.br/" target={'_blank'} rel="noreferrer">
                            Little Boat
                        </a>
                        & 
                        <a href="https://tropa.digital/" target={'_blank'} rel="noreferrer">
                            Tropa Digital
                        </a>
                    </div>
                </div>
            </div>
        </Container>
    )
}