import styled, { keyframes } from "styled-components";

const appearFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }


  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const appearFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
  height: fit-content;
  /* display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  display: block;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.primaryBlue500};
  clipPath: ellipse(120% 100% at 50.00% 0%); */
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 678px;
  padding-top: 5rem;
  padding-bottom: 7rem;
  position: relative;
  align-items: center;
  display: block;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.primaryBlue500};
  clip-path: ellipse(120% 100% at 50% 0%);
  transform: translateZ(0);
`;

export const AreaHeader = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  margin: 0 auto;
  z-index: 2;
  position: fixed;
  top: 0;

  /* @media (max-width: 800px) {
    position: sticky;
  } */
`;

export const AreaSite = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: 6.25rem;
  max-width: 1400px;
  z-index: 2;

  @media (max-width: 800px) {
    max-width: 800px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 3rem;
  }
`;

export const AreaImage2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  .image {
    width: 390px;
  }

  .circle {
    animation: ${appearFromLeft} 0.7s;
    position: absolute;
    top: 150px;

    .green-circle {
      width: 400px;
      height: 400px;
      background-color: ${({ theme }) => theme.colors.secondaryGreen500};
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 10%;
      z-index: -1;
    }
  }
  
  @media (max-width: 800px) {

    .circle {
      width: 60%;  
      top: 70%;
      .green-circle {
        /* width: 120px;
        height: 120px;
        top: 0;
        left: 0; */
        display: none;
      }
    }
  }
`;

export const Image = styled.img`
  width: 100%;  
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  animation: ${appearFromRight} 0.7s;

  @media (max-width: 800px) {
    width: 85%;
  }
`;

export const DescriptionTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 400px;
  color: ${({ theme }) => theme.colors.interfacePure0};
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 24px;
  position: relative;

  span {
    color: ${({ theme }) => theme.colors.secondaryPool600};
  }

  .green-right {
    width: 469px;

    span {
      color: ${({ theme }) => theme.colors.secondaryGreen500};
    }
  }

  .subtitle {
    width: 469px;
    color: ${({ theme }) => theme.colors.interfacePure0};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    &.green {
      margin-top: -8px;
    }
  }

  .icon {
    position: absolute;
    right: 0;
    bottom: 52%;

    &.green {
      margin-right: -50px;
      margin-bottom: 40px;
      svg {
        path {
          fill: ${({ theme }) => theme.colors.secondaryGreen200};
        }
      }
    }
  }  

  @media (max-width: 800px) {
    font-size: 24px;
    gap: 16px;

    .green-right {
      width: 100%;
    }

    .subtitle {
      width: 100%;
    }

    .icon {
      &.green {
        margin-right: 25px;
        margin-bottom: 30px;
      }
    }
  }
`;

export const DemandButton = styled.button`
  width: 469px;
  height: 48px;
  border-radius: 8px;
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: 700;
  transition: all .2s;

  &.first-green {
    background-color: ${({ theme }) => theme.colors.secondaryGreen500};
    color: ${({ theme }) => theme.colors.interfacePure0};
  }
  
  &.second-green {
    border: 2px solid ${({ theme }) => theme.colors.secondaryGreen200};
    color: ${({ theme }) => theme.colors.secondaryGreen200};
  }

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 800px) {
    
    &.first-green,
    &.second-green {
      width: 100%;
      font-size: 14px;
    }

  }
`

export const InfoWrapper = styled.div`
  height: fit-content;
  position: relative;
  
  @media (max-width: 800px) {
    width: 85%;
    margin: 0 auto;
  }
`

export const DemandInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1040px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 324px;   

  @media (max-width: 800px) {
    margin-bottom: 700px;
  }
`

export const DemandTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.primaryBlue500};

  span {
    color: ${({ theme }) => theme.colors.primaryOrange600};
  }

  &.green {
    span {
      color: ${({ theme }) => theme.colors.secondaryGreen500};
    }
  }

  &.light {
    margin-top: 120px;
    
    &.green {
      span {
        color: ${({ theme }) => theme.colors.secondaryGreen500};
      }  
    }

    span {
      color: ${({ theme }) => theme.colors.secondaryPool600};
    }
  }

  @media (max-width: 800px) {
    font-size: 28px;

    &.green {
      line-height: 40px;
    }
  }
`

export const DemandDescription = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.primaryBlue500};

  @media (max-width: 800px) {
    text-align: center;
  }
`

export const StructureCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const DemandCard = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  height: 120px;
  border-radius: 16px;
  padding: 32px;

  &.green {
    background-color: ${({ theme }) => theme.colors.secondaryGreen050};
  }

  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primaryBlue500};
  
  .info {
    max-width: 355px;
  }

  @media (max-width: 800px) {
    
    &.green {
      svg {
        width: 60px;
      }
    }
    
    
    .info {
      max-width: 180px;
      font-size: 14px;
    }
  }
`

export const WideCard = styled.div`
  width: calc(100% - ((100% - 1040px) / 2));  
  height: 296px;
  background-color: ${({ theme }) => theme.colors.secondaryPool100};
  border-radius: 16px 0 0 16px;  
  padding-left: 24px;

  display: flex;
  align-items: center;  

  position: absolute;
  bottom: 0;
  right: 0;

  &.green {
    background-color: ${({ theme }) => theme.colors.secondaryGreen050};
  }
  
  @media (max-width: 800px) {
    width: 100%;
    height: fit-content;
    text-align: center;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    padding: 24px;
    margin-bottom: -650px;    
  }
`

export const VectorColumn = styled.div`
  display: flex;
  align-items: flex-end;  

  .vector {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    .icon {
      width: 64px;
      height: 64px;

      &.people {
        height: 80px;
        img {
          max-height: 80px;
        }
      }     
    
    }

    &.cards {
      margin-left: 18px;
      gap: 40px;
    }

    &.people {
      margin-left: 8px;
    }

  }

  .one {
    align-self: center;
  }

  .vector-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    margin-right: 8px;
    margin-left: -1px;
    z-index: 0;
  }

  .no-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;

    .bottom {
      max-height: 80px;
    }
  }

  @media (max-width: 800px)  {
    flex-direction: column;
    
    .vector {
      flex-direction: row;

      &.cards {
        gap: 25px;
        margin: 0;
      }
    }
    
    .vector-icon {
      transform: rotate(90deg);
      margin-top: -10px;
      margin-right: 50px;
    }

    .vector-double {
      transform: rotateY(180deg) rotateZ(90deg);
      margin-right: 90px;
      margin-top: -30px;
    }

    .no-card {
      flex-direction: row;
      margin-left: 0;
      margin-right: 20px;
      margin-top: -25px;
    }

    .one {
      transform: rotateZ(90deg) rotateX(180deg);
    }
  }
`

export const VectorCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 72px;
  border-radius: 8px;
  z-index: 1;

  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.interfacePure0};

  &.blue {
    background-color: ${({ theme }) => theme.colors.secondaryPool600};
  }

  &.orange {
    background-color: ${({ theme }) => theme.colors.primaryOrange600};
  }

  &.green {
    background-color: ${({ theme }) => theme.colors.secondaryGreen500};
  }
`

export const AttendenceWrapper = styled.div`
  display: flex;
  gap: 20px;

  width: 100%;
  max-width: 1040px;
  height: 280px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 112px;
  /* background-color: red; */

  .left {
    display: flex;
    flex-direction: column;
    gap: 8px;    
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title {
      font-size: 32px;
      font-weight: 700;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.primaryBlue500};

      span {
        color: ${({ theme }) => theme.colors.primaryOrange500};
      }
    }

    .subtitle {
      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 150%;

        color: ${({ theme }) => theme.colors.primaryBlue500};  
        margin-bottom: 12px;
      }

      font-size: 20px;
      font-weight: 500;
      line-height: 150%;

      color: ${({ theme }) => theme.colors.primaryBlue500};

      span {
        font-weight: 700;
      }
    }
  }

  @media (max-width: 800px) {
    width: 85%;
    flex-direction: column;
    height: fit-content;

    .right {
      .title {
        font-size: 24px;
      }

      .subtitle {
        p {
          font-size: 18px;
        }
        font-size: 18px;
      }
    }
  }
`

export const AttendenceCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 424px;
  height: 88px;
  background-color: ${({ theme }) => theme.colors.interfacePure0};
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 35px 45px 73px rgba(32, 32, 35, 0.07);
  padding: 16px;

  &.one {
    border-color: ${({ theme }) => theme.colors.secondaryGreen050};
  }
  &.two {
    border-color: ${({ theme }) => theme.colors.secondaryPool100};
  }
  &.three {
    border-color: ${({ theme }) => theme.colors.interfaceSuccess200};
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      gap: 18px;

      font-size: 12px;
      font-weight: 500;

      &.green {
        color: ${({ theme }) => theme.colors.secondaryGreen400};
        svg {
          max-width: 16px;
          path {
            fill: ${({ theme }) => theme.colors.secondaryGreen400};
          }
        }
      }
      &.blue {
        color: ${({ theme }) => theme.colors.primaryBlue100};
        svg {
          path {
            fill: ${({ theme }) => theme.colors.primaryBlue100};
          }
        }
      }
      &.cash {
        color: ${({ theme }) => theme.colors.interfaceSuccess400};
        svg {
          path {
            fill: ${({ theme }) => theme.colors.interfaceSuccess400};
          }
        }
      }
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background-color: green;
      border-radius: 4px;

      &.green {
        background-color: ${({ theme }) => theme.colors.secondaryGreen600};
      }

      &.blue {
        background-color: ${({ theme }) => theme.colors.primaryBlue100};
      }

      &.cash {
        background-color: ${({ theme }) => theme.colors.interfaceSuccess100};

        svg {
          path {
            fill: ${({ theme }) => theme.colors.interfaceSuccess400};
          }
        }
      }

      svg {
        path {
          fill: white;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-weight: 500;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.primaryBlue600};
    }

    .end {
      display: flex;
      align-items: center;
      gap: 8px;

      .financial {
        display: flex;
        gap: 8px;
        margin-left: 16px;

        .price {
          width: 40px;
          height: 16px;
          border-radius: 4px;
          background-color: ${({ theme }) => theme.colors.secondaryGreen050};

          &.blue {
            background-color: ${({ theme }) => theme.colors.secondaryPool100};
          }
        }
      }

      .subtitle {
        font-size: 12px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.interfaceSuccess400};
      }
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-left: 16px;
      width: 24px;
      height: 24px;
      background-color: grey;
      border-radius: 4px;

      &.grey {
        background-color: ${({ theme }) => theme.colors.interfaceText400};
        svg {
          path {
            fill: white;
          }
        }
      }

      &.pool {
        background-color: ${({ theme }) => theme.colors.secondaryPool100};
      }

      
    }
  }

  @media (max-width: 800px) {
    width: 100%;

    .bottom {
      .end {
        .financial {
          .price {
            width: 35px;
          }
        }
      }
    }
  }
`

export const NotebookCard = styled.div`
  width: calc(80% - ((100% - 1040px) / 2));
  height: 504px;
  background-color: ${({ theme }) => theme.colors.secondaryGreen500};
  border-radius: 0 16px 16px 0;  
  margin-top: 110px;
  
  display: flex;
  align-items: center; 
  
  position: relative;

  .text {
    margin: 0 auto;

    .title {
      max-width: 400px;
      font-size: 32px;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.interfacePure0};
      line-height: 48px;
      margin-bottom: 32px;

      span {
        color: ${({ theme }) => theme.colors.primaryBlue600};
      }
    }

    .subtitle {
      max-width: 425px;
      font-size: 24px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.interfacePure0};
      line-height: 150%;
    }
  }

  .image {
    position: absolute;
    top: 20%;
    right: 0;
    margin-right: -35%;
  }

  @media (max-width: 1400px) and (min-width: 801px) {
    .image {
      width: 60%;
      margin-right: -35%;
    }
  }

  @media (max-width: 800px) {
    width: 85%;
    height: 550px;
    margin-left: auto;
    margin-right: auto;
    align-items: flex-start;

    border-radius: 22px;
    padding: 24px;

    .text {

      .title {
        font-size: 24px;
        line-height: 36px;
      }

      .subtitle {
        font-size: 18px;
      }
    }

    .image {
      width: 100%;
      top: 61%;      
      margin: 0 auto;
    }
  }
`

export const PlanWrapper = styled.div`
  width: 100%;
  max-width: 1040px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 112px;

  .text {
    font-size: 32px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primaryBlue600};
    line-height: 48px;
    margin-bottom: 38px;

    span {
      color: ${({ theme }) => theme.colors.secondaryGreen500};
    }
  }

  .mobile {
    display: none;
  }

  @media (max-width: 800px) {
    width: 85%;

    .text {
      font-size: 28px;
      text-align: center;
      line-height: 40px;
    }

    .web {
      display: none;
    }

    .mobile {
      display: block;

      .carousel {
        .control-dots {
          .dot {
            width: 16px;
            height: 16px;
            background-color: ${({ theme }) => theme.colors.secondaryGreen500};
          }
        }
      }

      .plan {
        padding: 0 20px;
      }
    }
  }
`

export const Representative = styled.div`
  width: 100%;
  max-width: 1040px;
  height: 625px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .title {
    font-size: 32px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primaryBlue500};
    line-height: 48px;
    margin-bottom: 32px;
    max-width: 459px;
    text-align: right;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 335px;
      height: 48px;
      margin-top: 32px;
      background-color: ${({ theme }) => theme.colors.primaryOrange500};
      border-radius: 8px;
      color: ${({ theme }) => theme.colors.interfacePure0};
      margin-left: auto;
      transition: all .3s;
      cursor: pointer;

      font-size: 20px;
      font-weight: 700;
      line-height: 150%;

      &:hover {
        transform: scale(1.05);
      }
    }

  }

  .image {
    max-width: 600px;
    max-height: 500px;
    margin-top: 120px;
    
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (max-width: 800px) {
    width: 85%;
    margin-top: 20px;

    .title {
      font-size: 28px;
      text-align: center;
      
      .button {
        width: 100%;
      }
    }


    .image {
      width: 85%;
      margin-top: 125%;
      margin-left: 7.5%;
      margin-right: 7.5%;
    }
  }
`