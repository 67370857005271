import styled from "styled-components";

export const Container = styled.div`
    width: 1040px;
    height: 450px;
    min-height: 40vh;    
    
    .select-all {
        margin-bottom: 16px;
        padding-left: 32px;

        .label {
           color: ${({ theme }) => theme.colors.interfacePure500};
           font-size: 18px;
           font-weight: 600;
        }

        .checkbox {
            .square {
                border: 1px solid ${(props) => props.theme.colors.interfaceText200};
            }
            &.active {
                .square {
                    background-color: ${({ theme }) => theme.colors.primaryOrange700};
                    border: 1px solid ${(props) => props.theme.colors.primaryOrange700};
                }
            }
        }
    }    
`

export const Permissions = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 1040px;
    gap: 16px;
    padding-bottom: 150px;

`

export const PermissionCard = styled.div`   
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 512px;
    height: 96px;
    padding: 36px 32px;
    background: ${({ theme }) => theme.colors.background};
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.interfacePure200};

    .card-title {
        display: flex;
        align-items: center;

        .checkbox {
            .square {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid ${(props) => props.theme.colors.interfaceText200};
                background: white;
            }
            &.active {
                .square {
                    background-color: ${({ theme }) => theme.colors.primaryOrange700};
                    border: 1px solid ${(props) => props.theme.colors.primaryOrange700};
                }
            }
        }
    
        .tag {
            font-weight: 500;
            font-size: 18px;
            color: ${({ theme }) => theme.colors.interfaceText500};
        }

        &.inactive {
           .checkbox {
                .square {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid ${(props) => props.theme.colors.interfaceText200};
                    background: white;
                }
                &.active {
                    .square {
                        background-color: ${({ theme }) => theme.colors.primaryBlue500};
                        border: 1px solid ${(props) => props.theme.colors.primaryBlue500};
                    }
                }
            } 
        }
    }

    .select-label {
        width: 224px;
        .css-tlfecz-indicatorContainer,
        .css-1gtu0rj-indicatorContainer {
            svg {
                fill: orange;
            }
        }

        .css-1s3jnk5-singleValue {
            text-transform: capitalize;
        }

        /* .css-kiybu7-control {
            background: #FFF;
        } */
    }
`