import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/UserContext";
import ButtonDefault from "../../form/ButtonDefault";
import ButtonIcon from "../../form/ButtonIcon";
import { IconConfigActivity, IconDeleteAccount, IconEdit, IconFloppyDisk, IconThreeDots } from "../../icons";
import { Container, DropDownMenu } from "./styles";

interface SaveProps {
    setSaveData?: any,
    setEditProfile?: any,
    editProfile?: any,
    setDelete?: any,
    setConfigActivity?: any,
    profileStatus?: any,
    activeOperator?: any,
    setSelected?: any,
    cancelChanges?: any,
}

export default function ButtonsCard({
    setSaveData, 
    setEditProfile, 
    editProfile, 
    setDelete, 
    setConfigActivity, 
    profileStatus, 
    activeOperator, 
    setSelected,
    cancelChanges,
}: SaveProps) {
    const { user, pageName } = useAuth()
    const [ openMenu, setOpenMenu] = useState(false);
    const [ profile, setProfile ] = useState<boolean>(true);

    function cancelchanges() {
        setEditProfile(false)
    }

    useEffect(() => {

        const user_Id = user.id.toString();
        const user_Pagename = pageName?.split(' ')[1];

        if (user_Pagename?.includes(user_Id)) {

            setProfile(true);
            
        } else {
            
            setProfile(false);
        }
        
    }, [pageName, user.id, user.profile])    
    
    return (
        <Container
            profile={user.profile}
        >
            {
                profile && user.profile === 'comum' &&
                <>
                    <div>
                        <ButtonDefault
                            className="cancel"
                            onClick={() => cancelChanges()}
                        >
                            Cancelar
                        </ButtonDefault>
                    </div>
                    <div
                        className="activity"
                        onClick={() => setSaveData()}
                    >
                        <ButtonIcon 
                            Icon={<IconFloppyDisk />}
                            children={'Salvar alterações'}
                            styles={'save'}
                        />
                    </div>
                </>
            }

            {
                user.profile !== 'comum' &&
                !editProfile &&
                profileStatus === 'em analise' &&
                <div className="activity"
                    onClick={() => setConfigActivity()}
                >
                    <ButtonIcon
                        Icon={<IconConfigActivity />}
                        children={'Configurar atividade'}
                        styles={'save'}
                    />
                </div>
            }

            {
                user.profile !== 'comum' &&
                !editProfile &&
                profileStatus === 'ativo' &&
                <div className="activity-small"
                    onClick={() => setConfigActivity()}
                >
                    <ButtonIcon
                        // Icon={<IconConfigActivity />}
                        children={'Inativar usuário'}
                        styles={'save'}
                    />
                </div>
            }

            {
                user.profile !== 'comum' &&
                !editProfile &&
                profileStatus === 'inativo' &&
                <div className="activity"
                    onClick={() => activeOperator()}
                >
                    <ButtonIcon
                        // Icon={<IconConfigActivity />}
                        children={'Ativar usuário'}
                        styles={'activate'}
                    />
                </div>
            }

            {
                profile && user.profile !== 'comum' &&
                editProfile &&
                <>
                    <div>
                        <ButtonDefault
                            className="cancel"
                            onClick={() => cancelchanges()}
                        >
                            Cancelar
                        </ButtonDefault>
                    </div>
                    <div
                        className="button-save"
                        onClick={() => setSaveData()}
                    >
                        <ButtonIcon
                            Icon={<IconFloppyDisk />}
                            children={'Salvar alterações'}
                            styles={'save'}
                        />
                    </div>
                </>
            }

            {
                !profile &&
                user.profile !== 'comum' &&
                editProfile &&
                <>
                    <div>
                        <ButtonDefault
                            className="cancel"
                            onClick={() => cancelchanges()}
                        >
                            Cancelar
                        </ButtonDefault>
                    </div>
                    <div
                        className="button-save"
                        onClick={() => setSaveData()}
                    >
                        <ButtonIcon
                            Icon={<IconFloppyDisk />}
                            children={'Salvar alterações'}
                            styles={'save'}
                        />
                    </div>
                </>
            }

            {
                user.profile === 'comum' &&
                <DropDownMenu onClick={() => setOpenMenu(!openMenu)}>
                    <div>
                        <IconThreeDots />
                    </div>
                    <div className={openMenu ? 'hidden-menu' : 'hidden-menu no-show'}>
                        {/* <div className="button"><IconEdit /> Alterar e-mail</div> */}
                        <div 
                            className="button"
                            onClick={() => setSelected('pass')}
                        >
                            <IconEdit /> Alterar senha
                        </div>
                        <div 
                            className="button"
                            onClick={() => setDelete()}
                        >
                            <IconDeleteAccount /> Excluir conta
                        </div>
                    </div>
                </DropDownMenu>
            }

            {
                user.profile === 'operador' && 
                !editProfile &&              
                <DropDownMenu 
                    onClick={() => setOpenMenu(!openMenu)}
                    profile={user.profile}                    
                >
                    <div>
                        <IconThreeDots />
                    </div>
                    <div className={openMenu ? 'hidden-menu' : 'hidden-menu no-show'}>
                        <div 
                            className="button"
                            onClick={() => setEditProfile()}
                        >
                            <IconEdit /> Editar perfil
                        </div>                        
                        <div
                            className="button"
                            onClick={() => setSelected('pass')}
                        >
                            <IconEdit /> Alterar senha
                        </div>       
                        <div 
                            className="button"
                            onClick={() => setDelete()}
                        >
                                <IconDeleteAccount /> 
                                Excluir conta
                        </div>
                    </div>
                </DropDownMenu>
            }

            {
                user.profile === 'admin' &&
                !editProfile &&
                <DropDownMenu 
                    onClick={() => setOpenMenu(!openMenu)}
                    profile={user.profile}                    
                >
                    <div>
                        
                        <IconThreeDots />
                    </div>
                    <div className={openMenu ? 'hidden-menu' : 'hidden-menu no-show'}>
                        <div 
                            className="button"
                            onClick={() => setEditProfile()}
                        >
                            <IconEdit /> Editar perfil
                        </div>   

                        <div 
                            className="button"
                            onClick={() => setSelected('pass')}
                        >
                            <IconEdit /> Alterar senha
                        </div>                     

                        <div 
                            className="button"
                            onClick={() => setDelete()}
                        >
                            <IconDeleteAccount /> 
                            Excluir conta
                        </div>
                    </div>
                </DropDownMenu>
            }
        </Container>
    )
}