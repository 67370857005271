/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useState } from "react";
import { useHistory, useLocation} from "react-router-dom";

// Icons
import { IconAttachment, IconLogoJuridico, IconUpload } from "../../components/icons";

// Components
import InputDefault from "../../components/form/InputDefault";
import ButtonDefault from "../../components/form/ButtonDefault";

// Styles
import { Container, DemandCard, Footer, FormCard, Header, Main } from "./styles";

// Libraries
import Moment from 'react-moment';
import 'moment/locale/pt-br';
import toast, { Toaster } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import * as yup from 'yup';
import getValidationErrors from "../../Utils/validationErrors";

// Service
import api from "../../services/api";

interface DemandProps {
    cidade_comarca?: any,
    uf_comarca?: any,
    numero_processo?: any,
    modalidade?: any,
    tipo_audiencia?: any,
    profissional_necessario?: any,
    data?: any,
    rua?: any,
    numero?: any,
    bairro?: any,
    complemento?: any,
    cep?: any,
    endereco_virtual?: any,
    descricao_categoria_servico?: any,
    titulo_servico?: any,
}

interface OrientationProps {
    parte_representada?: string,
    nome_advogado?: string,
    oab_advogado?: any,
    nome_preposto?: string,
    proposta_acordo?: string,
    requerimentos?: string,
    documentos?: string,
    arquivos?: any,
    testemunhas?: string,
    pedidos?: any,
    resumo_contestacao?: any,
    outras_informacoes?: any,
    id_proposta?: any,
}

interface ErrorsProps {
    parte_representada?: string,
    nome_advogado?: string,
    oab_advogado?: any,
    nome_preposto?: string,
    proposta_acordo?: string,
    requerimentos?: string,
    documentos?: string,
    arquivos?: any,
    testemunhas?: string,
    pedidos?: any,
    resumo_contestacao?: any,
    outras_informacoes?: any,
    id_proposta?: any,
}

export default function Orientations() {
    const location = useLocation<any>();
    const history = useHistory();
    const proposalId = location.state.demandInfo.id_proposta;
    const reader = new FileReader();
    const [ demandInfos, setDemandInfos ] = useState<DemandProps>();
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ orientationFiles, setOrientationFiles ] = useState<any[]>([]);
    const [ DTOOrientations, setDTOOrientations ] = useState<OrientationProps>({
        parte_representada: '',
        nome_advogado: '',
        oab_advogado: '',
        nome_preposto: '',
        proposta_acordo: '',
        requerimentos: '',
        documentos: '',
        arquivos: orientationFiles,
        testemunhas: '',
        pedidos: '',
        resumo_contestacao: '',
        outras_informacoes: '',
        id_proposta: proposalId,
    });
    const [ errors, setErrors ] = useState<ErrorsProps>();
    const [ requierements, setRequirements] = useState<any>('•');
    const [ docs, setDocs] = useState<any>('•');

    const onChangeText = (name: string, newText: string) => {
        if (name === 'requerimentos') {
            const bullet = '\u2022';
            const isLineBreak = newText.lastIndexOf('\n') === newText.length - 1;
            if (isLineBreak) {
                setRequirements(newText + bullet);
            } else {
                setRequirements(newText);
            }
        }
        if(name === 'documentos') {
            const bullet = '\u2022';
            const isLineBreak = newText.lastIndexOf('\n') === newText.length - 1;
            if (isLineBreak) {
                setDocs(newText + bullet);
            } else {
                setDocs(newText);
            }
        }
    };

    useEffect(() => {

        setDemandInfos(location.state.demandInfo);

    }, []);

    async function sendFiles(e: any) {
        try {

            setLoading(true)

            const formData = new FormData();
            formData.append('arquivo', e.target.files[0]);

            reader.addEventListener("load", () => {
            });

            reader.readAsDataURL(e.target.files[0]);

            const response = await api.post('fileupload', formData)

            setOrientationFiles(orientationFiles => [...orientationFiles, response.data.name]);

            setLoading(false)

        } catch (error: any) {

            console.log('log errors upload file', error)

            setLoading(false)

        }
    }

    function handleOnChange(name: any, value: any) {

        const newDTO: any = DTOOrientations
        newDTO[name] = value
        setDTOOrientations({ ...newDTO })

    }

    async function handleSubmit() {
        try {
            
            const response = await api.post('orientacao', DTOOrientations);    
            
            const schema = yup.object().shape({
                parte_representada: yup.string().required('Campo obrigatório'),
                nome_advogado: yup.string().required('Campo obrigatório'),
                oab_advogado: yup.string().required('Campo obrigatório'),
                nome_preposto: yup.string().required('Campo obrigatório'),
                proposta_acordo: yup.string().required('Campo obrigatório'),
                requerimentos: yup.string().required('Campo obrigatório'),
                documentos: yup.string().required('Campo obrigatório'),
                testemunhas: yup.string().required('Campo obrigatório'),
                pedidos: yup.string().required('Campo obrigatório'),
                resumo_contestacao: yup.string().required('Campo obrigatório'),
                outras_informacoes: yup.string(),
            })

            await schema.validate(DTOOrientations, {
                abortEarly: false,
            });                

            if (response.data.status !== 'success') {

                if (response.data.result[0] === undefined) {

                    throw new Error(response.data.message);

                } else {
                    
                    throw new Error(response.data.result[0].error);
                }

            } else {
                
                history.goBack();

                toast.success('Orientações salvas com sucesso', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                        background: '#E7F8EB',
                        border: '2px solid #9EE4AF',
                    }
                });

            }



        } catch (error: any) {

            if (error instanceof yup.ValidationError) {
                const errors = getValidationErrors(error);

                setErrors(errors);

                toast.error('Preencha os campos corretamente.', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });

            } else {

                toast.error(error.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
            }


        }
    }

    return(
        <Container>
            {
                loading &&
                <Oval
                    height={150}
                    width={150}
                    color="#FA9604"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#FA9604"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            }
            <Header>
                <div className="logo"><IconLogoJuridico /></div>                
            </Header>

            <Main>
                <div className="top">
                    <div className="one" >
                        <div className="register">
                            <h3>Orientações sobre a demanda {proposalId}</h3>
                            <p>Instrua o profissional para uma realização adequada.</p>
                        </div>
                    </div>
                </div>

                <div className="cards">
                    <DemandCard>
                        <div className="title">
                            {
                                demandInfos?.descricao_categoria_servico.split(' ')[0] === 'Audiências' ? 'Audiência' : '' ||
                                demandInfos?.descricao_categoria_servico.split(' ')[0] === 'Diligências' ? 'Diligência' : '' ||
                                demandInfos?.descricao_categoria_servico.split(' ')[0] === 'Protocolos' ? demandInfos?.titulo_servico : ''
                            } {demandInfos?.titulo_servico.split(' ')[0] === 'Protocolo' ? '' : demandInfos?.titulo_servico.split(' ')[0]}
                        </div>
                        <div className="info">
                            <span>Comarca:</span>
                            <div className="show-info">{demandInfos?.uf_comarca} | {demandInfos?.cidade_comarca}</div>
                        </div>
                        <div className="info">
                            <span>Número do processo:</span>
                            <div className="show-info">{demandInfos?.numero_processo}</div>
                        </div>
                        <div className="info">
                            <span>Modalidade:</span>
                            <div className="show-info">
                                {
                                    demandInfos?.modalidade === 'online'
                                    ? 'Virtual'
                                    : "Presencial"
                                }
                            </div>
                        </div>
                        <div className="info">
                            <span>Subtipo:</span>
                            <div className="show-info">{demandInfos?.tipo_audiencia}</div>
                        </div>
                        <div className="info">
                            <span>Profissional necessário:</span>
                            <div className="show-info">
                                {
                                    demandInfos?.profissional_necessario === 'Advogado_Preposto'
                                    ?   'Advogado + Preposto'
                                    :   demandInfos?.profissional_necessario
                                }
                            </div>
                        </div>
                        <div className="info">
                            <span>Data e hora da audiência:</span>
                            <div className="show-info">{
                                demandInfos?.data !== null
                                    ? <Moment locale='pt-br' format="DD/MM/YYYY | HH:MM">{demandInfos?.data}</Moment>
                                    : 'Sem data'
                            }</div>
                        </div>
                        {
                            demandInfos?.modalidade !== 'online' &&
                            <div className="address">
                                <span>Endereço físico:</span>
                                <div className="wrap">
                                    <div className="address-info">{demandInfos?.rua}</div>
                                    <div className="address-info">{demandInfos?.numero}</div>
                                    <div className="address-info">{demandInfos?.bairro}</div>
                                    <div className="address-info">{demandInfos?.complemento}</div>
                                    <div className="address-info">{demandInfos?.cep}</div>
                                </div>
                            </div>
                        }
                        <div className="info">
                            <span>Endereço virtual:</span>
                            <div className="show-info">{demandInfos?.endereco_virtual}</div>
                        </div>
                        
                    </DemandCard>

                    <FormCard>
                        <div className="form-line">
                            <div className="field">
                                <InputDefault
                                    title="Qual parte representamos?"
                                    name="parte_representada"
                                    value={DTOOrientations.parte_representada}
                                    onChangeText={(value) => handleOnChange('parte_representada', value)}
                                    className={errors?.parte_representada ? 'big error' : 'big'}
                                    error={errors?.parte_representada ? '*' : ''}
                                />
                            </div>

                            <div className="field">
                                <InputDefault
                                    title="Nome do Advogado:"
                                    name="nome_advogado"
                                    value={DTOOrientations.nome_advogado}
                                    onChangeText={(value) => handleOnChange('nome_advogado', value)}
                                    className={errors?.nome_advogado ? 'name error' : 'name'}
                                    error={errors?.nome_advogado ? '*' : ''}
                                />
                                <InputDefault
                                    title="OAB do advogado:"
                                    name="oab_advogado"
                                    value={DTOOrientations.oab_advogado}
                                    onChangeText={(value) => handleOnChange('oab_advogado', value)}
                                    className={errors?.oab_advogado ? 'big error' : 'big'}
                                    error={errors?.oab_advogado ? '*' : ''}
                                />
                            </div>

                            <div className="field">
                                <InputDefault
                                    title="Nome do preposto:"
                                    name="nome_preposto"
                                    value={DTOOrientations.nome_preposto}
                                    onChangeText={(value) => handleOnChange('nome_preposto', value)}
                                    className={errors?.nome_preposto ? 'big error' : 'big'}
                                    error={errors?.nome_preposto ? '*' : ''}
                                />
                            </div>

                            <div className="texts">
                                <span>Proposta de acordo:</span>
                                <div className={errors?.proposta_acordo ? "text agreement error" : 'text agreement'}>
                                    <textarea
                                        name="Proposta de acordo"
                                        id="1"
                                        className="text-area"
                                        onChange={(e) => handleOnChange('proposta_acordo', e.target.value)}
                                        value={DTOOrientations.proposta_acordo}
                                    />
                                </div>
                            </div>

                            <div className="texts">
                                <span>Requerimentos a realizar em audiência:</span>
                                <div className={errors?.requerimentos ? "text require error" : "text require"}>
                                    <textarea
                                        name="Requerimentos"
                                        id="2"
                                        className="text-area"
                                        onChange={(e) => {handleOnChange('requerimentos', e.target.value); onChangeText('requerimentos', e.target.value)}}
                                        value={requierements}
                                    />
                                </div>
                            </div>

                            <div className="texts">
                                <span>Documentos a juntar:</span>
                                <div className={errors?.documentos ? "text require error" : "text require"}>
                                    <textarea
                                        name="Documentos"
                                        id="3"
                                        className="text-area"
                                        onChange={(e) => {handleOnChange('documentos', e.target.value); onChangeText('documentos', e.target.value)}}
                                        value={docs}
                                    />
                                </div>
                            </div>

                            <div className="add">
                                <IconUpload />
                                <input
                                    type="file"
                                    name="Files"
                                    // accept="image/*"
                                    className="file-input"
                                    onChange={sendFiles}
                                />
                            </div>

                            <div className="files">
                                <span>Arquivos:</span>
                                {
                                    orientationFiles?.length > 0 &&
                                    orientationFiles.map((row: any) => (
                                        <div className="file" key={row}>
                                            <div className="file-details">
                                                <div className="icon-title">
                                                    <IconAttachment />
                                                    {row}
                                                </div>
                                                {/* <div className="date"><Moment locale='pt-br' format="DD/MM/YYYY">{orientationFiles?.criado}</Moment></div> */}
                                            </div>
                                        </div>
                                    ))
                                } 

                            </div>


                            <div className="texts">
                                <span>Testemunhas a ouvir:</span>
                                <div className={errors?.testemunhas ? "text require error" : "text require"}>
                                    <textarea
                                        name="Testemunhas"
                                        id="4"
                                        className="text-area"
                                        onChange={(e) => handleOnChange('testemunhas', e.target.value)}
                                        value={DTOOrientations.testemunhas}
                                    />
                                </div>
                            </div>

                            <div className="texts">
                                <span>Pedidos relevantes da inicial:</span>
                                <div className={errors?.pedidos ? "text require error" : "text require"}>
                                    <textarea
                                        name="Pedidos"
                                        id="5"
                                        className="text-area"
                                        onChange={(e) => handleOnChange('pedidos', e.target.value)}
                                        value={DTOOrientations.pedidos}
                                    />
                                </div>
                            </div>

                            <div className="texts">
                                <span>Resumo da contestação:</span>
                                <div className={errors?.resumo_contestacao ? "text error" : "text"}>
                                    <textarea
                                        name="Resumo"
                                        id="6"
                                        className="text-area"
                                        onChange={(e) => handleOnChange('resumo_contestacao', e.target.value)}
                                        value={DTOOrientations.resumo_contestacao}
                                    />
                                </div>
                            </div>

                            <div className="texts">
                                <span>Outras informações:</span>
                                <div className={errors?.outras_informacoes ? "text error" : "text"}>
                                    <textarea
                                        name=""
                                        id="7"
                                        className="text-area"
                                        onChange={(e) => handleOnChange('outras_informacoes', e.target.value)}
                                        value={DTOOrientations.outras_informacoes}
                                    />
                                </div>
                            </div>

                        </div>
                    </FormCard>
                </div>
            </Main>

            <Footer>
                <div className="container">
                    <div className="progress">
                        <div className="progressbar">
                            {/* <div className={
                                activePage === 1 ? "percent" : '' ||
                                    activePage === 2 ? "percent two" : '' ||
                                        activePage === 3 ? "percent three" : ''
                            }>
                            </div> */}
                        </div>
                        <div>
                            {/* <span>
                                {
                                    activePage === 1 ? "30%" : '' ||
                                        activePage === 2 ? "50%" : '' ||
                                            activePage === 3 ? "80%" : ''
                                }
                            </span> do perfil completo */}
                        </div>
                    </div>
                    
                    <div className="buttons">
                        
                        <ButtonDefault
                            className="cancel"
                            onClick={() => history.goBack()}
                        >
                            Cancelar
                        </ButtonDefault>

                        <ButtonDefault
                            className="continue"
                            type="submit"
                            onClick={handleSubmit}
                        >
                            Enviar
                        </ButtonDefault>
                    </div>
                </div>
            </Footer>

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}