import styled from "styled-components";

interface FilterProps {
    filter?: any,
}

export const Container = styled.div<FilterProps>`
    display: flex;
    justify-content: space-around;
    gap: 16px;
    width: 500px;
    height: 56px;

    .search {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 280px;
        height: 56px;
        padding: 16px;
        background: ${({ theme }) => theme.colors.interfacePure0};
        border-radius: 8px;
        input {
            width: 205px;
            border: none;
            outline: none;
        }

        .buscar {
            cursor: pointer;
        }

        .clean-search {
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfaceText300};
                }
                &:hover {
                    cursor: pointer;
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceText500};
                    }
                }
            }
        }
    }

    .button {
        width: ${({ filter }) => (filter.length > 0 ? '136px' : '126px')};
        position: relative;

        .button-filter {
            display: flex;     
            align-items: center;
            justify-content: center;
            gap: 18px;
            background: ${({ theme }) => theme.colors.primaryBlue500};
            color: ${({ filter }) => (filter.length > 0 ? '#C1E8FF' : '#FFF')};
            font-weight: 700;
            cursor: pointer;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            padding: 18px;
            transition: all .3s;
            &:hover {
                transform: scale(1.1);
            }
            .icon-filter {
                svg {
                    path {
                        fill:  ${({ filter }) => (filter.length > 0 ? '#C1E8FF' : '#FFF')};
                    }
                }
            }
            .clear-icon {
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.interfacePure0};
                    }
                }
            }            
        }

        .hidden-menu {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            gap: 20px;
            width: 344px;
            height: fit-content;
            border-radius: 8px;
            padding: 24px;
            background: ${({ theme }) => theme.colors.interfacePure0};
            cursor: default;
            position: absolute;
            top: 72px;
            right: 0;
            z-index: 99;
            filter: drop-shadow(3px 4px 7px rgba(20, 20, 23, 0.3));
            &.no-show {
                display: none;
            }

            .filter {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 48px;
                border-radius: 8px;
                border: 1px solid ${({ theme }) => theme.colors.interfacePure100};
                color: ${({ theme }) => theme.colors.interfaceText300};
                padding: 16px;
                .button-search {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    height: 32px;
                    background: ${({ theme }) => theme.colors.interfacePure100};
                    border-radius: 8px;                        
                }
            }

            .filters {
                display: flex;
                align-items: center;
                padding-left: 16px;
                width: 100%;
                height: 40px;
                border-radius: 8px;
                color: ${({ theme }) => theme.colors.interfaceText400};
                font-size: 16px;
                font-weight: 500;  
                cursor: pointer;     
                transition: all .2s;
                &:hover {
                    background: ${({ theme }) => theme.colors.interfaceText100};
                }                     
            }

            
        }
    }
`

export const DownloadFile = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.interfacePure0};
    transition: all .3s;
    position: relative;
    &:hover {
        background: ${({ theme }) => theme.colors.interfacePure100};
        /* transform: scale(1.1); */
    }    
`