// React
import { useState } from "react";

// Libraries
import * as yup from 'yup';
import getValidationErrors from "../../Utils/validationErrors";
import toast, { Toaster } from 'react-hot-toast';

// Styles
import { ChangePass, Container } from "./styles";

// Components
import InputDefault from "../form/InputDefault";
import ButtonDefault from "../form/ButtonDefault";

// Icons
import { IconClosedEye, IconEyeOpen } from "../icons";

// Services
import api from "../../services/api";

// Context
import { useAuth } from "../../contexts/UserContext";

interface Errors {
    senha?: string,
    confirmeSenha?: string,    
}

interface Profile {
    id_profile?: any,
    setSelected?: any,
}

export default function ChangePassword({id_profile, setSelected}:Profile) {
    const { user } = useAuth();
    const [ changeVisibility, setChangeVisibility] = useState<boolean>(true);
    const [ DTOPass, setDTOPass] = useState({
        senha: '',
        confirmeSenha: '',        
    })
    const [advancePageError, setAdvancePageError] = useState<Errors>({});

    function handleOnChange(name: any, value: any) {
        const newDTO: any = DTOPass
        newDTO[name] = value
        setDTOPass({ ...newDTO })
    }

    async function updateOperatorPass() {
        try {
            const schema = yup.object().shape({
                senha: yup.string()
                    .required('Senha é obrigatória')
                    .min(6)
                    .matches(
                        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                        "Crie uma senha forte. Conforme texto acima"
                    ),
                confirmeSenha:
                    yup.string()
                        .required('Confirmação de senha é obrigatória')
                        .oneOf([yup.ref('senha')], 'As senhas não conferem!'),
            });

            await schema.validate(DTOPass, {
                abortEarly: false,
            });

            const response = await api.put('admin/senhaoperador/alterar/' + id_profile, DTOPass)

            if (response.data.status !== 'success') {
                // throw new Error('Não foi possível alterar a senha no momento.')
                if (response.data.result[0] === undefined) {
                    throw new Error(response.data.message);

                } else {
                    throw new Error(response.data.result[0].error);
                }
            } else {
                
                toast.success('Alterações salvas com sucesso', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                        background: '#E7F8EB',
                        border: '2px solid #9EE4AF',
                    }
                });

                setTimeout(() => {
                    setSelected('1');
                }, 1000)

            }

        } catch (err: any) {

            if (err instanceof yup.ValidationError) {
                const errors = getValidationErrors(err);

                setAdvancePageError(errors)

                toast.error('Preencha os campos corretamente.', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });

            } else {
                toast.error(err.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });

            }
        }
    }

    async function updateProfessionalPass() {
        try {
            const schema = yup.object().shape({
                senha: yup.string()
                    .required('Senha é obrigatória')
                    .min(6)
                    .matches(
                        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                        "Crie uma senha forte. Conforme texto acima"
                    ),
                confirmeSenha:
                    yup.string()
                        .required('Confirmação de senha é obrigatória')
                        .oneOf([yup.ref('senha')], 'As senhas não conferem!'),
            });

            await schema.validate(DTOPass, {
                abortEarly: false,
            });

            const response = await api.put('usuario/senha/alterar/' + id_profile, DTOPass)

            if (response.data.status !== 'success') {
                if (response.data.result[0] === undefined) {
                    throw new Error(response.data.message);

                } else {
                    throw new Error(response.data.result[0].message);
                }
            } else {
                
                toast.success('Alterações salvas com sucesso', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                        background: '#E7F8EB',
                        border: '2px solid #9EE4AF',
                    }
                });

                setTimeout(() => {
                    setSelected('1');
                }, 1000)

            }

        } catch (err: any) {

            if (err instanceof yup.ValidationError) {
                const errors = getValidationErrors(err);

                setAdvancePageError(errors)

                toast.error('Preencha os campos corretamente.', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });

            }
        }
    }

    return (
        <Container>
            <ChangePass>                
                <div className="pass-title">
                    <h1>Alterar senha do usuário:</h1>

                    <div className="buttons">
                        <ButtonDefault
                            className="cancel"
                            onClick={() => setSelected('1')}
                        >
                            Cancelar
                        </ButtonDefault>
                        {
                            user.profile === 'comum' &&
                            <ButtonDefault
                                className="save"
                                onClick={() => updateProfessionalPass()}
                            >
                                Salvar senha
                            </ButtonDefault>
                        }
                        {
                            user.profile !== 'comum' &&
                            <ButtonDefault
                                className="save"
                                onClick={() => updateOperatorPass()}
                            >
                                Salvar senha
                            </ButtonDefault>
                        }                        
                    </div>
                </div>

                <div className="pass-text">
                    <p>Crie uma senha forte. Utilize ao menos 6 dígitos, contendo 1 letra maíuscula, 1 número e 1 caractere especial.</p>
                </div>

                <div className="email-pass">

                    <div className="password">
                        <InputDefault
                            title="Senha:"
                            placeholder="senha"
                            name="senha"
                            value={DTOPass.senha}
                            type={changeVisibility ? 'password' : 'text'}
                            onChangeText={(value) => handleOnChange('senha', value)}
                            className={advancePageError.senha || advancePageError.confirmeSenha ? 'empty' : ''}
                            error={advancePageError.senha ? advancePageError.senha : ''}
                        />
                        <div
                            className="icon"
                            onClick={() => setChangeVisibility(!changeVisibility)}
                        >
                            {changeVisibility ? <IconEyeOpen /> : <IconClosedEye />}
                        </div>
                    </div>

                    <div className="password">
                        <InputDefault
                            title="Confirme a senha:"
                            placeholder="senha"
                            name="confirmeSenha"
                            type={changeVisibility ? 'password' : 'text'}
                            value={DTOPass.confirmeSenha}
                            onChangeText={(value) => handleOnChange('confirmeSenha', value)}
                            className={advancePageError.confirmeSenha ? 'empty' : ''}
                            error={advancePageError.confirmeSenha ? advancePageError.confirmeSenha : ''}
                        />
                        <div
                            className="icon"
                            onClick={() => setChangeVisibility(!changeVisibility)}
                        >
                            {changeVisibility ? <IconEyeOpen /> : <IconClosedEye />}
                        </div>
                    </div>
                </div>                
            </ChangePass>

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}