import styled from "styled-components";

export const Container = styled.div`
    width: 1040px;
    max-width: 90%;
    height: auto;
    min-height: 100vh;
    margin: 0px auto;
    background: ${({ theme }) => theme.colors.interfacePure0};        
`

export const Header = styled.div`
    width: 100%;
    margin-bottom: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 48px;   

    .status {
        display: flex;
        justify-content: space-between;
        width: 600px;
        position: relative;
        .status-square {
            width: 55px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            
            p {
                font-size: 14px;
                font-weight: 400;
                white-space: nowrap;  
                &.on {
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.primaryBlue600};
                }
            }
            &.first {
                white-space: nowrap;
                p {
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.primaryBlue600};
                }
            }
        }
        .square {
            width: 48px;
            height: 48px;
            border-radius: 8px;
            background: ${({theme}) => theme.colors.interfacePure100};   
            z-index: 5;
            display: flex;
            align-items: center;
            justify-content: center;            
        }        
        .active {
            background: ${({theme}) => theme.colors.secondaryGreen050};
            svg {
                path {
                    fill: ${({theme}) => theme.colors.secondaryGreen600};
                }
            }
        }
    }
`

export const Main = styled.div`
    background: ${({theme}) => theme.colors.interfacePure0};
    
    .top {
        display: flex;
        justify-content: space-between;
        height: auto;
        margin-bottom: 56px;

        .two {
            height: 140px;
        }        

        .five {
            .register {
                margin-bottom: 25px;

                .terms {
                    span {
                        cursor: pointer;
                        text-decoration: underline;
                        text-underline-offset: 2px;
                    }
                }
            }
        }

        .six {
            .register {
                h3 {
                    margin-bottom: 16px;
                }
            }
        }

        .register {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 16px;
            height: 80px;
            max-width: 680px;
            h3 {
                font-size: 24px;
                font-weight: 500;
                line-height: 36px;
                span {
                    font-size: 24px;
                    line-height: 36px;
                    font-weight: 700;
                    color: ${({theme}) => theme.colors.secondaryGreen600};
                }
            }
            &.underline {
                p {
                font-size: 18px;
                font-weight: 400;
                line-height: 27px;
                    span {
                        text-decoration: underline;
                        text-underline-offset: 2px;
                        cursor: pointer;
                    }
                }
            }
            &.services {
                .title {
                    margin-bottom: -10px;
                }
                p {
                    span {
                        font-weight: 500;
                    }
                }
            }
        }

        .subtitle, p {
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            color: ${({ theme }) => theme.colors.interfaceText400};
                span {
                    font-weight: 500;
                }
        }

        .attention {
            border: 1px solid ${({theme}) => theme.colors.primaryOrange300};
            width: 333px;
            height: 88px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 10%;
            border-radius: 8px;
            position: relative;

            .star {
                position: absolute;
                top: 19%;
                left: 4%;
            }

            span {
                margin-top: 5px;
                color: ${({theme}) => theme.colors.primaryOrange600};
                font-weight: 700;
                font-size: 14px;
                line-height: 21px;
            }

            p {
                color: ${({theme}) => theme.colors.interfaceText400};
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
            }

        }
    }

    form {
        padding-bottom: 140px;
        .name {
            display: flex;
            flex-wrap: wrap;
            gap: 24px 16px;
            margin-bottom: 56px;
            justify-content: space-between;
            .big {
                width: 65%;
                &:hover {
                    border-color: red;
                }
            }

            .mid {
                width: 45%;
            }

            .small {
                width: 33%;                
                &:last-child {
                    width: calc(35% - 24px);
                }
            }

            .oab {
                width: 250px;
            }

            .label-oab {
                width: 30%;
                position: relative;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }  
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({ theme }) => theme.colors.interfaceDanger};
                }
            }

            .label-state {
                width: 41%;
                position: relative;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }  
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({ theme }) => theme.colors.interfaceDanger};
                }
            }

            .label {
                width: 31.5%;
                position: relative;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({theme}) => theme.colors.interfaceText400};
                }  
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({theme}) => theme.colors.interfaceDanger};
                }                
            }
        }

        .fone {
            height: 168px;
            margin-bottom: 56px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            justify-content: space-between;
            .small {
                width: 32%;
            }

            .big {
                width: 65%;
            }
        }

        .address {
            height: 168px;
            margin-bottom: 56px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            justify-content: space-between;
            
            .small {
                width: 23.5%;
            }
            .big {
                width: 600px;
            }

            .number {
                width: 160px;
            }

            .comp {
                width: 248px;
            }
        }

        .css-tj5bde-Svg {
            svg {
                fill: orange;
            }
        }

        .css-tlfecz-indicatorContainer,
        .css-1gtu0rj-indicatorContainer {
            svg {
                fill: orange;
            }
        }
    }

    .services-form {
        .services-top {
            display: flex;
            align-items: center;
            height: 72px;
            gap: 16px;
            margin-bottom: 32px;

            .big {
                width: 66%;
            } 

            button {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                gap: 10px;
                padding-bottom: 5px;
                background: none;
                border: none;
                width: 320px;
                height: 48px;
                font-weight: 400;
                font-size: 16px;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .line.services {
            border-top: 1px dashed rgba(0,0,0,0.1);
            padding-top: 20px;
        }
        .services-hearing {
            margin-bottom: 32px;
            .hearing-title {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 15px;
            }

            .hearing-fields {
                display: flex;
                gap: 16px;

                .small {
                    width: 32%;
                }
                .mid {
                    width: 100%;
                }
            }
        }

        .dot-border {
            display: flex;
            align-items: center;
            gap: 16px;
            height: 48px;
            border: 1px dashed ${({theme}) => theme.colors.primaryOrange200};
            border-radius: 8px;
            margin-bottom: 56px;
            padding: 12px;
            cursor: pointer;      
        }

        .footer {
            display: flex;
            flex-direction: column;
            gap: 8px;
            color: ${({theme}) => theme.colors.interfaceText500};
            font-weight: 400;
            font-size: 18px;
        }
    }

    .county-form {

        .line {
            border-top: 1px dashed rgba(0,0,0,0.1);
            padding-top: 20px;
        }
        .county {
            display: flex;
            justify-content: space-evenly;
            gap: 16px;
            width: 100%;
            margin-bottom: 25px;

            .label {
                width: 100%;
                position: relative;
                font-size: 14px;
                p {
                    margin-bottom: 8px;
                } 
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 2%;
                    color: ${({theme}) => theme.colors.interfaceDanger};
                }
            }

            .big {
                width: 65%;
            }

            .mid {
                width: 45%;
            }

            .small {
                width: 33%;
            }

            .select {
                height: 48px;
                width: 100%;
                border-radius: 8px;
                padding: 10px;

            }

        }

        .pricing {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            margin-bottom: 56px;

            .label {
                width: 100%;
                max-width: 512px;
                position: relative;
                font-size: 14px;
                p {
                    margin-bottom: 8px;
                }
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 2%;
                    color: ${({theme}) => theme.colors.interfaceDanger};
                }
            }

            .right {
                display: flex;
                align-items: flex-end;
                width: 50%;
            }

            .radio-input {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                gap: 8px;
                width: 160px;
                height: 48px;
                color: ${({ theme }) => theme.colors.interfaceText400};

                input {
                    margin-bottom: 2px;
                }

            }

            button {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                gap: 10px;
                background: none;
                border: none;
                width: 320px;
                height: 48px;
                font-weight: 400;
                font-size: 16px;
                color: ${({ theme }) => theme.colors.interfaceText400};
                text-decoration: underline;
                text-underline-offset: 2px;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .services {

        }

        .dot-border {
            display: flex;
            align-items: center;
            gap: 16px;
            height: 48px;
            border: 1px dashed ${({theme}) => theme.colors.primaryOrange200};
            border-radius: 8px;
            padding: 12px;
            cursor: pointer;
        }
    }

    .bank-account-form {
        .account-data {
            display: flex;
            gap: 16px;
            margin-bottom: 24px;

            .big, .label {
                width: 310px;
                position: relative;
                font-size: 14px;
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({theme}) => theme.colors.interfaceDanger};
                }
            }

            .select {
                display: flex;
                flex-direction: column;
                justify-content: space-between;                
                height: 70px;
                color: ${({ theme }) => theme.colors.interfaceText400};
                .title {
                    margin-bottom: 8px;
                    font-size: 14px;
                }
                .options {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                }
                .input-radio {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    font-size: 14px;
                }
            }
        }
        .bank-data {
            display: flex;
            gap: 16px;
            margin-bottom: 24px;

            .big, .label {
                width: 310px;
                position: relative;
                font-size: 14px;
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({theme}) => theme.colors.interfaceDanger};
                }
            }
            .small {
                max-width: 60px;
            }
        }
        .pix-data {
            display: flex;
            gap: 16px;
            margin-bottom: 24px;
            .label {
                width: 310px;
                position: relative;
                font-size: 14px;
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({theme}) => theme.colors.interfaceDanger};
                }
            }
            .pix-key {
                width: 100%;
                max-width: 400px;
            }
        }

        .label {
            p {
                margin-bottom: 8px;
            }
        }
    }

    .page-five {
        padding-bottom: 30px;
    }

    .login-form {

        .wrapper-mail {
            max-width: 512px;
            margin-bottom: 56px;
        }

        .pass-text {
            margin-bottom: 24px;
            font-Size: 18px;
        }

        .email-pass {
            display: flex;
            gap: 16px;
        }

        .email {
            width: 100%;
        }
        .password {
            position: relative;
            width: 100%;
            .big {
                width: 100%;
            }
        }
        .icon {
            position: absolute;
            top: 55%;
            right: 5%;
            svg {
                path {
                    fill: ${({theme}) => theme.colors.interfaceText300};
                }
            }
        }
    }
`

export const Footer = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: 0;  
    background: ${({theme}) => theme.colors.interfacePure0};
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
    z-index: 0;
    .container {
        width: 1040px;
        margin: 0px auto;
        display: flex;
        justify-content: space-between;
        max-width: 90%;
    }
    .buttons {
        display: flex;
        gap: 16px;
        button {
            width: 160px;
        }
    }

    .progress {
        display: flex;
        align-items: center;
        gap: 10px;

        .progressbar {
            height: 8px;
            width: 170px;
            border-radius: 8px;
            background: ${({theme}) => theme.colors.interfacePure100};
            position: relative;
            
            .percent {
                transition: all .2s;
                width: 4px;
                height: 8px;
                position: absolute;
                border-radius: 8px;
                top: 0;
                left: 0;
                background: ${({theme}) => theme.colors.secondaryGreen400};
                &.two {
                    width: 60px;
                }
                &.three {
                    width: 85px;
                }
                &.four {
                    width: 127px;
                }
                &.five {
                    width: 153px;
                }
                &.six {
                    width: 160px;
                }
            }
        }
    }
`

export const Hr1 = styled.hr`
    width: 100%;
    max-width: 500px;
    height: 1px;
    border: none;
    background: ${({theme}) => theme.colors.interfaceText200};
    position: absolute;
    top: 25px;
    left: 48px;
    transform: translateY(-50%);
    z-index: 1;
    &.first {
        background: linear-gradient(to right,  #03AD8F 0%,#03AD8F 7%, #C5CED7 7%, #C5CED7 30%);
        background: -moz-linear-gradient(left,  #03AD8F 0%, #03AD8F 7%, #C5CED7 7%, #C5CED7 30%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #03AD8F 0%,#03AD8F 7%,#C5CED7 7%,#C5CED7 30%); /* Chrome10-25,Safari5.1-6 */
    }
    &.second {
        background: linear-gradient(to right,  #03AD8F 0%,#03AD8F 32%, #C5CED7 32%, #C5CED7 60%);
        background: -moz-linear-gradient(left,  #03AD8F 0%, #03AD8F 32%, #C5CED7 32%, #C5CED7 60%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #03AD8F 0%,#03AD8F 32%,#C5CED7 32%,#C5CED7 60%); /* Chrome10-25,Safari5.1-6 */
    }
    &.third {
        background: linear-gradient(to right,  #03AD8F 0%,#03AD8F 42%, #C5CED7 42%, #C5CED7 100%);
        background: -moz-linear-gradient(left,  #03AD8F 0%, #03AD8F 42%, #C5CED7 42%, #C5CED7 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #03AD8F 0%,#03AD8F 42%,#C5CED7 42%,#C5CED7 100%); /* Chrome10-25,Safari5.1-6 */
    }
    &.fourth {
        background: linear-gradient(to right,  #03AD8F 0%,#03AD8F 59%, #C5CED7 59%, #C5CED7 100%);
        background: -moz-linear-gradient(left,  #03AD8F 0%, #03AD8F 59%, #C5CED7 59%, #C5CED7 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #03AD8F 0%,#03AD8F 59%,#C5CED7 59%,#C5CED7 100%); /* Chrome10-25,Safari5.1-6 */
    }
    &.fifth {
        background: linear-gradient(to right,  #03AD8F 0%,#03AD8F 80%, #C5CED7 80%, #C5CED7 100%);
        background: -moz-linear-gradient(left,  #03AD8F 0%, #03AD8F 80%, #C5CED7 80%, #C5CED7 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #03AD8F 0%,#03AD8F 80%,#C5CED7 80%,#C5CED7 100%); /* Chrome10-25,Safari5.1-6 */
    }
    &.sixth {
        background: linear-gradient(to right,  #03AD8F 0%,#03AD8F 100%, #C5CED7 100%, #C5CED7 100%);
        background: -moz-linear-gradient(left,  #03AD8F 0%, #03AD8F 100%, #C5CED7 100%, #C5CED7 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #03AD8F 0%,#03AD8F 100%,#C5CED7 100%,#C5CED7 100%); /* Chrome10-25,Safari5.1-6 */
    }
`

export const ModalHeader = styled.div`
    width: 100%;
    height: 100px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5% 10%;
    position: relative;
    
    button {
        position: fixed;
        top: 10%;
        right: 25%;
        background: transparent;
        border: none;
        outline: none;
        transition: all .3s;
        &:hover {
            transform: scale(1.3);
        }
    }
`

export const ModalMain = styled.div`
    padding: 1% 38% 10% 10%;

    .title {
        max-width: 536px;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 56px;
        color: ${({ theme }) => theme.colors.interfaceText500};
        p {
            font-size: 24px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText500};
        }

        .pro {
            font-weight: 700;
            color: ${({ theme }) => theme.colors.secondaryGreen600};
        }
        .now {
            font-weight: 700;
            color: ${({ theme }) => theme.colors.primaryBlue600};
        }
    }

    .subtitle {
        font-weight: 600;
        color: ${({ theme }) => theme.colors.interfaceText500};
        margin-bottom: 16px;
    }

    p {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        color: ${({theme}) => theme.colors.interfaceText500};
        margin-bottom: 10px;

        &.space {
            margin: 24px 0;
        }
    }

    .considerations {
        margin-bottom: 12px;
        .recoil {
            padding-left: 24px;

            .list {
                display: flex;
                gap: 12px;
            }
        }
    }

    .autorize {
        padding-left: 24px;
        ul {
            li {
                list-style-type: disc;
                font-size: 1rem;
                font-weight: 400;
                line-height: 24px;
                color: ${({ theme }) => theme.colors.interfaceText500};

                margin-bottom: 12px;
            }
        }
    }

    .two {
        margin-bottom: 35px;
    }
    
    .space {
        margin-bottom: 12px;
    }

    .payment {
        display: flex;
        gap: 12px;

        &.two {
            margin-top: 24px;
            margin-bottom: 0;
        }
    }

    .cookies {
        padding-left: 24px;        
    }

    .links {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        a {
            font-size: 1rem;
            font-weight: 400;
            line-height: 24px;
            color: ${({ theme }) => theme.colors.interfaceText500};
            text-decoration: underline;
            text-underline-offset: 2px;
        }
    }

    .underlined {
        
        span {
            text-decoration: underline;
            text-underline-offset: 2px;
        }
    }
`

export const ModalPrecification = styled.div`
    background: ${({ theme }) => theme.colors.interfacePure0};
    width: 520px;
    padding: 8px;
    
    .header {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid ${({ theme }) => theme.colors.interfacePure100};
        padding-bottom: 25px;

        p {
            color: ${({ theme }) => theme.colors.interfaceText400};
            font-weight: 700;
            font-size: 20px;
        }

        .close {
            cursor: pointer;
        }
    }

    .main {
        display: flex;
        justify-content: space-between;
        gap: 25px;
        margin-top: 25px;

        
    }
`

