import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 1040px;
    height: auto;
    /* min-height: 100vh; */
    position: relative;       
    
    .toasted {
        z-index: 99999;        
    }    
`

export const StatusHeader = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`

export const Status = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    width: 600px;
    height: 24px;
    padding-bottom: 8px;

    .waiting {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryBlue500};        
    }

    .canceled {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryBlue500};  
        svg {
            circle {
                fill: ${({ theme }) => theme.colors.interfaceDanger600};
            }
        }
    }

    .recused {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryBlue500}; 
        svg {
            circle {
                fill: ${({ theme }) => theme.colors.interfaceText500};
            }
        }
    }

    .type {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryBlue500};
    }
`

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    width: 320px;
    
    .save {
        width: fit-content;
        padding: 16px;
        svg {
            path {
                fill: ${({ theme }) => theme.colors.interfacePure0};
            }
        }
    }

    .cancel {
        max-width: 105px;
        height: 56px;
    }

    .filter {
        width: 207px;
    }

    .clock {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        background-color: ${({ theme }) => theme.colors.interfacePure0};
        border-radius: 8px;
        cursor: pointer;
        transition: all .3s;
        &:hover {
            transform: scale(1.1);
        }
    }
`

export const DropDownMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.interfacePure0};
    position: relative;
    transition: all .2s;
    &:hover {
        /* transform: scale(1.1); */
        background: ${({ theme }) => theme.colors.interfaceText100};
    }

    .hidden-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 32px;
        width: 272px;
        height: 72px;
        border-radius: 8px;
        padding: 16px;
        background: ${({ theme }) => theme.colors.interfacePure0};
        cursor: default;
        position: absolute;
        top: 64px;
        right: 0;
        z-index: 99;
        filter: drop-shadow(3px 4px 7px rgba(20, 20, 23, 0.3));
        &.no-show {
            display: none;
        }

        .button {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            width: 100%;
            height: 40px;
            border-radius: 8px;
            color: ${({ theme }) => theme.colors.interfaceText400};
            cursor: pointer;
            font-weight: 500;
            font-size: 16px;
            transition: all .2s;
            padding: 11px;
            &:hover {
                background: ${({ theme }) => theme.colors.interfacePure100};
            }
            svg {
                path {
                    fill: #F18F00;
                }
            }
        }
    }

    .hidden-history {
        display: flex;
        flex-direction: column;
        /* justify-content: space-around; */
        /* gap: 32px; */
        width: 512px;
        height: 502px;
        border-radius: 8px;
        padding: 16px;
        background: ${({ theme }) => theme.colors.interfacePure0};
        filter: drop-shadow(3px 4px 7px rgba(20, 20, 23, 0.3));
        cursor: default;
        position: absolute;
        top: 64px;
        right: 0;
        z-index: 99;
        &.no-show {
            display: none;
        }        
    }


`

export const ProposalDetails = styled.div`
    display: flex;
    gap: 16px;
` 

export const ProposalLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 688px;
`

export const ProposalAdress = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: fit-content;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;
    padding: 24px;

    .title-service {
        font-size: 36px;
        font-weight: 300;
        color: ${({ theme }) => theme.colors.primaryBlue500};        
    }

    .countie-process {
        display: grid;
        grid-template-columns: 327px 1fr;
        
        .title-infos {
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            gap: 8px;
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-weight: 400;

            span {
                font-size: 14px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.primaryBlue500};
            }

            .info {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px;
                border-radius: 8px;
                background: ${({ theme }) => theme.colors.background};
                width: fit-content;
                color: ${({ theme }) => theme.colors.interfaceText500};
                font-weight: 400;
            }
        }
    }

    .address {
        display: flex;
        flex-direction: column;
        gap: 8px;

        span {
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.primaryBlue500};
        }

        .address-info {
            display: flex;
            gap: 8px;

            .info {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px;
                border-radius: 8px;
                background: ${({ theme }) => theme.colors.background};
                width: fit-content;
                color: ${({ theme }) => theme.colors.interfaceText500};
                font-weight: 400;
            }
        }

    }

    .line {
        display: flex;
        gap: 16px;

        .label-state {
            width: 100%;
            p {
                margin-bottom: 8px;
                font-size: 14px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.interfaceText400};
            }  
            .error {
                position: absolute;
                display: block;
                top: 5%;
                right: 5%;
                color: ${({ theme }) => theme.colors.interfaceDanger};
            }

            .no-data {
                margin-top: 20px;
                font-weight: 400;
                color: ${({ theme }) => theme.colors.interfaceText400};
            }
        }

        .small {
            width: 312px;
            &.highlight {
                input {
                    border: 1px solid ${({ theme }) => theme.colors.secondaryPool500};
                }
            }
        }

        .mid {
            width: 100%;
            &.highlight {
                input {
                    border: 1px solid ${({ theme }) => theme.colors.secondaryPool500};
                }
            }
        }

        .big {
            width: 100%;
        }

        .date {
            width: 100%;
            height: 48px;

            .react-datetime-picker {
                width: 100%;
                height: 48px;
                display: inline-flex;
                position: relative;
            }
            .react-datetime-picker,
            .react-datetime-picker *,
            .react-datetime-picker *:before,
            .react-datetime-picker *:after {
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
            }
            .react-datetime-picker--disabled {
                background-color: #f0f0f0;
                color: #6d6d6d;
            }
            .react-datetime-picker__wrapper {
                display: flex;
                flex-grow: 1;
                flex-shrink: 0;
                border: thin solid #EAEEF3;
                border-radius: 8px;
                padding: 8px 16px;

            }
            .react-datetime-picker__inputGroup {
                min-width: calc(4px + (4px * 3) +  0.54em * 6  +  0.217em * 2);
                flex-grow: 1;
                padding: 0 2px;
            }
            .react-datetime-picker__inputGroup__divider {
                padding: 8px 0;
                white-space: pre;
                &:nth-child(7) {
                    padding: 8px;
                    &::before {
                        content: '|';
                    }
                }
            }
            .react-datetime-picker__inputGroup__input {
                min-width: 0.54em;
                height: calc(100% - 3px);
                position: relative;
                padding: 1px;
                border: 0;
                background: none;
                font: inherit;
                font-size: 16px;
                font-weight: 400;
                color: ${({ theme }) => theme.colors.interfaceText500};
                box-sizing: content-box;
                -moz-appearance: textfield;
            }
            .react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
            .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            .react-datetime-picker__inputGroup__input:invalid {
                background: rgba(255, 0, 0, 0.1);
            }
            .react-datetime-picker__inputGroup__input--hasLeadingZero {
                margin-left: -0.54em;
                padding-left: calc(1px +  0.54em);
            }
            .react-datetime-picker__inputGroup__amPm {
                font: inherit;
                -moz-appearance: menulist;
            }
            .react-datetime-picker__button {
                border: 0;
                background: transparent;
                padding: 4px 6px;
            }
            .react-datetime-picker__button:enabled {
                cursor: pointer;
            }
            .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
            .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
                stroke: #0078d7;
            }
            .react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
                stroke: #6d6d6d;
            }
            .react-datetime-picker__button svg {
                display: inherit;
            }
            .react-datetime-picker__calendar,
            .react-datetime-picker__clock {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 1;
            }
            .react-datetime-picker__calendar--closed,
            .react-datetime-picker__clock--closed {
                display: none;
            }
            .react-datetime-picker__calendar {
                width: 350px;
                max-width: 100vw;
            }
            .react-datetime-picker__calendar .react-calendar {
                border-width: thin;
            }
            .react-datetime-picker__clock {
                width: 200px;
                height: 200px;
                max-width: 100vw;
                padding: 25px;
                background-color: white;
                border: thin solid #a0a096;
            }                        
        }
    }
`

export const ServiceTags = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;
    padding: 24px;

    span {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryBlue500};
    }

    .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        .tag {
            display: flex;
            align-items: center;
            gap: 2px;
            width: fit-content;
            height: 32px;
            background: ${({ theme }) => theme.colors.background};
            border-radius: 8px;
            padding: 4px 8px;
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-weight: 400;

            &.highlight {
                background: ${({ theme }) => theme.colors.secondaryPool100};
            }

            .close {
                cursor: pointer;
            }
        }
    }

    .add {
        display: flex;
        align-items: center;
        gap: 20px;
        height: 56px;
        padding: 16px;
        border: 2px dotted ${({ theme }) => theme.colors.primaryBlue500};
        border-radius: 8px;
        color: ${({ theme }) => theme.colors.primaryBlue500};
        font-weight: 500;
        cursor: pointer;
        svg {
            path {
                fill: ${({ theme }) => theme.colors.primaryBlue500};
            }
        }
    }
`

export const Orientations = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: fit-content;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;
    padding: 24px;
   
    span {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryBlue500};
    }
    
    .info {
        color: ${({ theme }) => theme.colors.interfaceText500};
        font-weight: 400;
        line-height: 24px;
    }

    .text {
        width: 100%;
        height: 300px;
        border: 1px solid ${({ theme }) => theme.colors.interfaceText100};
        border-radius: 8px;
        padding: 16px;

        .text-area {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 8px;
            resize: none;
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-weight: 400;
            outline: none;
            font-family: Montserrat, sans-serif;
            font-size: 16px;
            line-height: 24px;    
            
            &.highlight {
                background-color: ${({ theme }) => theme.colors.secondaryPool100};
            }
        }
    }

`

export const Files = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: fit-content;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;
    padding: 24px;
    overflow-x: auto;

    span {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryBlue500};
    }

    .file {
        display: flex;
        gap: 8px;
        width: 100%;
        height: 56px;
        color: ${({ theme }) => theme.colors.interfaceText500};
        font-weight: 400;

        .file-details{
            display: flex;
            justify-content: space-between;
            width: 90%;
            border: 2px solid ${({ theme }) => theme.colors.interfacePure100};
            border-radius: 8px;
            padding: 16px;

            &.highlight {
                background-color: ${({ theme }) => theme.colors.secondaryPool100};
            }

            .icon-title {
                display: flex;
                align-items: center;
                gap: 18px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.interfaceText500};
            }

            .date-trash {
                display: flex;
                align-items: center;
                gap: 40px;

                .date {
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText300};
                }

                .icon-trash {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                    svg {
                        width: 100%;
                        height: 100%;
                        path {
                            fill: ${({ theme }) => theme.colors.primaryBlue500};
                        }
                    }
                }
            }

        }

        .download {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            border-radius: 8px;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                background-color: ${({ theme }) => theme.colors.interfaceText100};
            }
        }
    }

    .add {
        display: flex;
        align-items: center;
        gap: 20px;
        height: 56px;
        padding: 16px;
        border: 2px dotted ${({ theme }) => theme.colors.primaryBlue500};
        border-radius: 8px;
        cursor: pointer;
        position: relative;
        svg {
            path {
                fill: ${({ theme }) => theme.colors.primaryBlue500};
            }
        }

        .file-input {
                width: 100%;
                height: 100%;
                color: transparent;
                transition: all .5s;
                border: none;
                padding: 0;
                background: transparent;
                border-radius: 8px;
                position: absolute;
                top: 0;
                left: 0;
            }
            .file-input::-webkit-file-upload-button {
                visibility: hidden;
            }
            .file-input::before {
                content: 'Anexar arquivos';
                color: ${({ theme }) => theme.colors.primaryBlue500};
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border: none;
                padding: 0;
                width: 100%;
                height: 50px;
                outline: none;
                white-space: nowrap;
                -webkit-user-select: none;
                user-select: none;
                cursor: pointer;
                font-weight: 500;
                font-size: 18px;
                padding-left: 60px;
            }
            .file-input:active {
                outline: 0;
            }
    }
`

export const ProposalInfos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 336px;
    
`

export const ServiceValue = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 136px;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;
    padding: 24px;

    span {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryBlue500};
    }

    .value-profit {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        height: 56px;
        background: ${({ theme }) => theme.colors.background};
        border-radius: 8px;
        padding: 8px;
        
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background: ${({ theme }) => theme.colors.interfacePure0};
            border-radius: 8px;

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfaceText200};
                }
            }
        }

        .value {
            font-size: 16px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.interfaceText500};
        }

        .profit {
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.primaryBlue400};
            margin-left: auto;

            &.negative {
                color: ${({ theme }) => theme.colors.interfaceDanger500};
            }
        }

        .code {
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText300};
        }

        .button {
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: auto;
            margin-right: 10px;
            cursor: pointer;
        }
    }

`

export const DeadlineInfos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;
    padding: 24px;

    .deadline {
        display: flex;
        flex-direction: column;
        gap: 16px;

        span {
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.primaryBlue500};
        }

        .date-hour {
            display: flex;
            gap: 8px;

            .info {
                background: ${({ theme }) => theme.colors.background};
                border-radius: 8px;
                padding: 8px;
                font-size: 16px;
                font-weight: 400;
                color: ${({ theme }) => theme.colors.interfaceText500};
            }
        }
    }
`

export const HistoryTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: ${({ theme }) => theme.colors.primaryBlue400};
    margin-bottom: 16px;

    svg {
        cursor: pointer;
        transition: all .3s;
        &:hover {
            transform: scale(1.3);
        }
        path {
            fill: ${({ theme }) => theme.colors.interfacePure200};
        }
    }
`

export const HistorySearch = styled.div`
    width: 100%;
    margin-bottom: 24px;

    .search {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        height: 56px;
        padding: 16px;
        background: ${({ theme }) => theme.colors.interfacePure0};
        border: 1px solid ${({ theme }) => theme.colors.interfaceText100};
        border-radius: 8px;
        input {
            width: 100%;
            border: none;
            outline: none;
        }

        .buscar {
            cursor: pointer;
        }

        .clean-search {
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfaceText300};
                }
                &:hover {
                    cursor: pointer;
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceText500};
                    }
                }
            }
        }
    }
` 

export const HistoryTimeLine = styled.div`
    display: flex;
    flex-direction: column;
    height: 400px;
    gap: 16px;
    overflow-y: auto;

    scrollbar-width: thin;
    scrollbar-color: ${(props) => props.theme.colors.interfaceText200} ${(props) => props.theme.colors.primaryBlue100};
    &::-webkit-scrollbar {
      width: 12px;
      background: ${(props) => props.theme.colors.interfaceText200};
      border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.primaryBlue100};
      border-radius: 12px;
    }

    .line-info {
        display: flex;
        align-items: center;
        gap: 32px;

        &:first-child {
            .bullet {
                &::after {
                    content: '';
                    border-left: none;
                    height: 0;
                    /* position: absolute; */
                    top: 0;
                    left: 0;
                }
            }
        }

        .date {
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 40px;
            font-size: 14px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.interfaceText400};
        }

        .bullet {
            display: flex;
            align-items: center;
            position: relative;

            svg {
                circle {
                    fill: ${({ theme }) => theme.colors.interfaceText400};
                }
            }

            &::after {
                content: '';
                border-left: 2px solid ${({ theme }) => theme.colors.interfaceText400};
                height: 50px;
                position: absolute;
                top: 1px;
                left: 3px;                
                margin-top: -50px;
            }

            

            &.start {
                svg {
                    circle {
                        fill: ${({ theme }) => theme.colors.interfaceSuccess400};
                    }
                }
            }

            &.end {
                svg {
                    circle {
                        fill: ${({ theme }) => theme.colors.interfaceDanger400};
                    }
                }

                &::after {
                    content: '';
                    border-left: none;
                    height: 50px;
                    position: absolute;
                    top: 7px;
                    left: 3px;
                }
            }
        }

        .bullet-start {
            display: flex;
            align-items: center;
            svg {
                circle {
                    fill: ${({ theme }) => theme.colors.interfaceSuccess400};
                }
            }
        }

        .event {
            display: flex;
            align-items: center;
            height: 40px;
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText400};
        }
    }
`

export const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: fit-content;
    padding: 8px;

    .justify {
        height: 56px;
        &.cancel {
            height: 80px;
        }

        p {
            font-weight: 600;
            color: ${({ theme }) => theme.colors.interfacePure500};
            margin-bottom: 16px;
        }
        span {
            font-weight: 400;
            color: ${({ theme }) => theme.colors.interfacePure500};
        }
    }

    .accept {
        font-weight: 500;
        color: ${({ theme }) => theme.colors.interfaceText400}; 

        p {
            margin-bottom: 24px;
        }

        span {
            font-weight: 400;
        }

        div {
            width: 375px;
            margin-bottom: 24px;
        }
    }

    .refuse {
        height: fit-content;

        p {
            font-weight: 400;
        }
    }
`

export const ModalTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.interfacePure100};

    .points {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 20px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.interfaceText400};
    }

    .close {
        cursor: pointer;
        transition: all .2s;
        &:hover {
            transform: scale(1.3);
        }
        svg {
            path {
                fill: ${({ theme }) => theme.colors.interfacePure400};
            }
        }
    }
`

export const ModalJustification = styled.div`
    width: 100%;
    height: 120px;
    border: 1px solid #EAEEF3;
    border-radius: 8px;
    padding: 16px;

    .text-area {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 8px;
        resize: none;
        color: ${({ theme }) => theme.colors.interfaceText500};
        font-weight: 400;
        outline: none;
    }
`

export const ModalButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: space-between;
    width: 100%;

    .continue {
        background: ${({ theme }) => theme.colors.primaryBlue500};
    }

    .cancel {
        border-color: ${({ theme }) => theme.colors.interfacePure200};
        font-weight: 600;
    }
`

export const ModalSearch = styled.div`
   display: flex;
   flex-direction: column;
   gap: 24px;
   height: 380px;

   .title {
        font-weight: 400;
        color: ${({ theme }) => theme.colors.interfacePure500};
   }

   .search {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;
        height: 56px;
        padding: 16px;
        background: ${({ theme }) => theme.colors.background};
        border-radius: 8px;
        margin-bottom: 40px;
        position: relative;
        input {
            width: 400px;
            border: none;
            outline: none;
            background: ${({ theme }) => theme.colors.background};
        }

        .buscar {
            cursor: pointer;
        }

        .clean-search {
            position: absolute;
            top: 15px;
            right: 16px;
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfaceText300};
                }
                &:hover {
                    cursor: pointer;
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceText500};
                    }
                }
            }
        }

        .show-tags {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 290px;
            background: ${({ theme }) => theme.colors.interfacePure0};
            border: 1px solid #EAEEF3;
            border-radius: 8px;
            padding: 16px;
            position: absolute;
            top: 64px;
            left: 0;
            overflow: auto;

            &.off {
                display: none;
            }

            span {
                font-size: 14px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.interfaceText300};
                margin-bottom: 8px;

                &.empty {
                    margin-bottom: 16px;
                }
            }

            .tag-arrays {
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                height: auto;

                .tag {
                    width: 100%;
                    height: 56px;
                    padding: 16px;
                    font-size: 16px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                    cursor: pointer;
                    &:hover {
                        background-color: ${({ theme }) => theme.colors.interfacePure100};
                    }
                }
            }

            .span-no-tag {
                margin-top: 8px;
            }

            .no-tags {
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                height: auto;

                .tag {
                    width: 100%;
                    height: 56px;
                    padding: 16px;
                    font-size: 16px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                    cursor: pointer;
                    &:hover {
                        background-color: ${({ theme }) => theme.colors.interfacePure100};
                    }
                }
            }
        }
    }
`

export const ModalContactHead = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 2px solid ${({ theme }) => theme.colors.interfacePure100};
    color: ${({ theme }) => theme.colors.interfaceText400};  
    padding: 8px;
    padding-bottom: 24px;
    margin-bottom: 24px;

    .close {        
        cursor: pointer;

        svg {
            path {
                fill: ${({ theme }) => theme.colors.interfacePure400};
            }
        }
    }
`

export const ModalChanges = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;

    .adjust {
        font-weight: 400;
        color: ${({ theme }) => theme.colors.interfacePure500};
    }

    .prices {
        display: flex;
        gap: 24px;

        .old-price {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 212px;
            height: 56px;            
            border-radius: 8px;
            background-color: ${({ theme }) => theme.colors.background};

            p {
                color: ${({ theme }) => theme.colors.interfaceText300};
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 8px;
                background-color: ${({ theme }) => theme.colors.interfacePure0};
            }
        }

        .new-price {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 212px;
            height: 56px;
            border-radius: 8px;
            background-color: ${({ theme }) => theme.colors.secondaryPool100};

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 8px;
                background-color: ${({ theme }) => theme.colors.interfacePure0};
           
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.secondaryPool200};
                    }
                }
            }

        }
    }

    .profit {
        font-weight: 400;
        color: ${({ theme }) => theme.colors.interfacePure500};

        span {
            font-weight: 600;
        }
    }
`