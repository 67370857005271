import styled from "styled-components";

interface PageProps {
    type?: any,
}

export const WrapperCard = styled.div<PageProps>`
    width: 35%;
    height: 650px;
    background: ${(props) => props.theme.colors.interfacePure0};
    border-radius: 8px;   
    border: 2px solid transparent;
    padding: 24px;
    margin-bottom: 60px;
    cursor: ${({ type }) => type ? 'pointer' : 'initial'};
    filter: drop-shadow(3px 4px 7px rgba(20, 20, 23, 0.3));

    &.free {
        &:hover {
            /* border: 2px solid ${(props) => props.theme.colors.secondaryPool600}; */
            border: ${({ type }) => type ? '2px solid #1A2858' : ''};
        }
        .value {
            color: ${(props) => props.theme.colors.interfaceText400};
            font-weight: 500;
        } 
        .subtitle {
            span {
                color: ${(props) => props.theme.colors.secondaryPool600};
                font-weight: 600;
            }
        }

        &.selected {
            border: 2px solid ${(props) => props.theme.colors.secondaryPool600};            
        }
    }

    &.pro {
        &:hover {
            /* border: 2px solid ${(props) => props.theme.colors.secondaryGreen600}; */
            border: ${({ type }) => type ? '2px solid #03AD8F' : ''};
        }
        .title {
            color: ${(props) => props.theme.colors.secondaryGreen600};
        }
        .subtitle {
            span {
                color: ${(props) => props.theme.colors.secondaryGreen600};
                font-weight: 600;
            }
        }
        .value {
            color: ${(props) => props.theme.colors.secondaryGreen600};
            font-weight: 500;
        }
        
        &.selected {
            border: 2px solid ${(props) => props.theme.colors.secondaryGreen600};
        }
    }

    &.premium {
        height: 310px;
        cursor: default;
        /* &:hover {
            border: 2px solid ${(props) => props.theme.colors.interfaceText400};
        } */
        .value {
            display: flex;
            gap: 10px;
            .grey {
                width: fit-content;
                height: 32px;
                background-color: #F2F2F2;
                border-radius: 8px;
                padding: 0 8px;

                font-size: 24px;
                font-weight: 300;
                line-height: 36px;
            }
        }
        .benefits {
            display: none;
        }

        /* &.selected {
            /* border: 2px solid ${(props) => props.theme.colors.interfaceText400};
        } */
    }

    .icon-top {
        margin-bottom: 24px;
    }

    .title {
        margin-bottom: 24px;
        font-weight: 500;
        font-size: 24px;
        color: ${(props) => props.theme.colors.interfaceText400};
    }

    .subtitle {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 40px;
        color: ${(props) => props.theme.colors.interfaceText400};
    }

    .value {
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 40px;
        color: ${(props) => props.theme.colors.interfaceText400};
    }

    .benefits {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .benefit-list {
        display: flex;
        gap: 8px;

        span {
            width: 25px;
        }

        p {
            max-width: 234px;
        }
    }

    .list {
        font-weight: 400;
        font-size: 16px;
        color: ${(props) => props.theme.colors.interfaceText500};
    }

    @media (max-width: 800px) {
        height: fit-content;
        
        .benefit-list {
            p {
               max-width: 205px;
               font-size: 12px;
            }
        }
    }
`