/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// React
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Icons
import { 
    IconEditProfile, 
    IconOperator, 
    IconProfileButtonDown, 
    IconProfileButtonUp 
} from "../../icons";

// Styles
import { 
    Container, 
    DivArrow, 
    DivCode, 
    DivSector, 
    DivCity, 
    DivIcon, 
    DivMail, 
    DivName, 
    DivIsland, 
    DivProfile, 
    DivWhats, 
    ProfileBottom, 
    ProfileTop, 
    ProfileWrapper 
} from "./styles";

export default function OperatorCard({ profile }: any) {
    const history = useHistory();    

    const [ profileOpen, setProfileOpen ] = useState<any>(false);
    const [ selected, setSelected ] = useState<any>('');
    const [ filteredSectors, setFilteredSectors ] = useState<any>('');

    const celNumberClear = profile.whatsapp.replace(/\D/g, '');
    let celNumberCorrect = celNumberClear.match(/^(\d{2})(\d{5})(\d{4})$/);

    function handleOpenMenu(item: any) {
        setSelected(item)
        setProfileOpen(!profileOpen)
    }

    function editProfile(value: any) {
        history.push(`/operadores/${value}`)
    }

    useEffect(() => {
        
        const sectors: string[] = []
        
        if (profile.audiencias !== '') {
            sectors.push('Audiências, ')
        } 
        if (profile.acautelamentos !== '') {
            sectors.push('Acautelamentos, ')
        } 
        if (profile.diligencias !== '') {
            sectors.push('Diligências, ')
        } 
        if (profile.peticoes !== '') {
            sectors.push('Petições, ')
        } 
        if (profile.protocolos !== '') {
            sectors.push('Protocolos, ')
        } 

        setFilteredSectors(sectors);

    }, [])

    return (
        <Container>

            <ProfileWrapper
                menuOpen={profileOpen}
                key={profile.id_operador}
            >
                <ProfileTop>
                    <DivIcon>
                        <IconOperator />
                    </DivIcon>

                    <DivCode>
                        <div className="title">Código:</div>
                        <div className="subtitle">P{profile.id_operador}</div>
                    </DivCode>

                    <DivName>
                        <div className="title">Nome:</div>
                        <div className="subtitle">{profile.nome}</div>
                    </DivName>

                    <DivIsland>
                        <div className="title">Ilhas:</div>
                        <div className="subtitle">
                           {profile.ilhas[0].descricao} 
                           {
                            profile.ilhas.length > 1
                            ? `, + ${profile.ilhas.length - 1}`
                            : ''
                           }
                        </div>
                    </DivIsland>

                    <DivSector>
                        <div className="title">Setor:</div>
                        <div className="subtitle">
                            {filteredSectors[0]} {filteredSectors[1]} {filteredSectors.length > 2 ? `+ ${filteredSectors.length - 2}` : ''}                        
                        </div>
                    </DivSector>

                    <DivProfile
                        onClick={() => editProfile(profile.id_operador)}
                    >
                        <IconEditProfile />
                    </DivProfile>

                    <DivArrow
                        onClick={() => handleOpenMenu(profile.id_operador)}
                    >
                        {
                            profileOpen
                                ? <IconProfileButtonUp />
                                : <IconProfileButtonDown />
                        }
                    </DivArrow>
                </ProfileTop>

                <ProfileBottom
                    menuOpen={profileOpen}
                >
                    <DivIcon></DivIcon>
                    
                    <DivWhats>
                        <div className="title">Whatsapp:</div>
                        {
                            celNumberCorrect !== null
                                ? <div className="subtitle">{`(${celNumberCorrect[1]}) ${celNumberCorrect[2]}-${celNumberCorrect[3]}`}</div>
                                : <div className="subtitle">------------</div>
                        }
                    </DivWhats>

                    <DivMail>
                        <div className="title">E-mail pessoal:</div>
                        <div className="subtitle">{profile.email_pessoal}</div>
                    </DivMail>

                    <DivCity>
                        <div className="title">Cidade:</div>
                        <div className="subtitle">{profile.uf} - {profile.cidade}</div>
                    </DivCity>

                    <DivSector>
                        
                    </DivSector>

                    <DivProfile className="fake">                        
                    </DivProfile>

                    <DivArrow className="fake">
                    </DivArrow>

                </ProfileBottom>
            </ProfileWrapper>

        </Container>
    )
}