/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useState } from "react";

// Services
import api from "../../services/api";

// Icons
import { 
    IconActiveProfile, 
    IconAdvancePagination, 
    IconBackPagination,  
    IconClose, 
    IconContact, 
    IconSearch 
} from "../icons";

// Components
import ModalDefault from "../ModalDefault";

// Styles
import { 
    Container, 
    ModalContactHead, 
    ModalcontactMain, 
    Paginate, 
    ProfessionalPrices, 
    ProfessionalTitles, 
    SearchHeader 
} from "./styles";

// Libraries
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import toast, { Toaster } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

interface DataProps {
   uf?: string,
   cidade?: string,
   proposalData?: any,
   proposalType?: any,
   userSelected?: any,
   selected?: any,
   nameUser?: any,
   reuseProfessional?: any,
   reuseProposal?: any,
}

interface UserProps {
    email: string,
    celular: string,
    tel_fixo?: string,
    tel_recado?: string,
}

interface Pagination {
    currentPage: string,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number,
}

export default function SearchProfessionalProposal({ 
    uf, 
    cidade, 
    proposalData, 
    proposalType, 
    userSelected, 
    selected, 
    nameUser,
    reuseProfessional,
    reuseProposal
}:DataProps) {
    const [ loading, setLoading ] = useState<any>();
    const [ modalContact, setModalContact ] = useState<any>();
    const [ professionals, setProfessionals ] = useState<any>();
    const [ totalProfiles, setTotalProfiles ] = useState<any>();
    const [ allCounties, setAllCounties ] = useState<any>();
    const [ DTOSearch, setDTOSearch ] = useState<any>('');
    const [ searchProfiles, setSearchProfiles ] = useState<any>();
    const [ userContacts, setUserContacts ] = useState<UserProps>({
        email: '',
        celular: '',
        tel_fixo: '',
        tel_recado: '',
    });
    // const [ openCounties, setOpenCounties ] = useState<boolean>(false);
    const [ countieSelected, setCountieSelected ] = useState<any>('');
    const [ paginateAllProfiles, setPaginateAllProfiles ] = useState<Pagination>();
    const [ paginateProfiles, setPaginateProfiles ] = useState<Pagination>();
    // const [ professionalReuse, setProfessionalReuse ] = useState<any>();

    const city = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 40,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    } 

    useEffect(() => {
        
        async function getCounties() {
            try {
                
                setLoading(true);
                const countieArray: { value: any, label: any }[] = []

                const response = await api.get('comarca')
                // setAllCounties(response.data.result)                

                if (response.data.status !== 'success') {
                    throw new Error()
                }

                countieArray.push({
                    label:'Todas as Comarcas',
                    value: 'TodasTodas'
                })

                response.data.result.map((row: any) => (
                    countieArray.push({
                        label: `${row.uf} - ${row.cidade}`,
                        value: `${row.uf} - ${row.cidade}`,
                    })
                ))

                setAllCounties(countieArray);

                setLoading(false);

            } catch (err) {

                console.log('log do erro pegando comarcas', err);

                setLoading(false);

            }
            
        }

        getCounties();

        // async function getProfessionalById(id: any) {
        //     try {
                
        //         setLoading(true);

        //         const response = await api.get('usuario/' + id)
        //         setProfessionalReuse(response.data.result)
                
        //         if (response.data.status !== 'success') {

        //             if (response.data.result[0] === undefined) {
        //                 throw new Error(response.data.message);

        //             } else {
        //                 throw new Error(response.data.result[0].message);
        //             }

        //         }
        //         setLoading(false);

        //     } catch (error: any) {  
        //         console.log('log do response error', error);
        //         setLoading(false);
        //         toast.error(error.message, {
        //             duration: 3000,
        //             className: 'toasted',
        //             style: {
        //                 padding: '16px',
        //                 color: '#1A2858',
        //             },
        //             iconTheme: {
        //                 primary: '#F24343',
        //                 secondary: '#FFFAEE',
        //             },
        //         });
        //     }
            
        // }

        // getProfessionalById(reuseProfessional);
        
    }, [cidade])

    useEffect(() => {        
        if (countieSelected === 'Todas') {

            getAllProfessionals(1)
            
        } else {

            getProfessionals(cidade, 1);
        }

    }, [searchProfiles])

    useEffect(() => {
        console.log('log do countie Selected', countieSelected)
    }, [countieSelected])

    async function getProfessionals(city: any, page: any) {

        try {

            if (searchProfiles === undefined) {
                setSearchProfiles('')
            }

            if (city === undefined) {
                city = ''
            }

            if (proposalType === 'Audiência') {
                proposalType = 'Audiencia'
            }

            if (city === 'Todas') {
                
                const response = await api.get(`usuario?page=${page}&limit=10&nome=${searchProfiles}`)

                setProfessionals(response.data.result.data);
                setTotalProfiles(response.data.result.pagination.total);
                setPaginateAllProfiles(response.data.result.pagination);

                if (response.data.status !== 'success') {
                    throw new Error()
                }
                
            } else {

                // const citySliced = city.slice(5);
                console.log('log do citySliced', city)
    
                setLoading(true);
    
                const response = await api.get(`usuario-com-valores/?page=${page}&limit=10&nome=${searchProfiles}&comarca=${city}&categoria_servico=${proposalType}&profissional_necessario=${proposalData.profissional_necessario}&id_comarca=${proposalData.id_comarca}&valor_cliente=${proposalData.valor_cliente}&id_servico=${proposalData.id_servico}&status=ativo`)
    
                setProfessionals(response.data.result.data);
                setTotalProfiles(response.data.result.pagination.total);
                setPaginateProfiles(response.data.result.pagination);
    
                if (response.data.status !== 'success') {
                    throw new Error()
                }
            }


            setLoading(false);

        } catch (err: any) {

            console.log('log do erro pegando profissionais para comarca', err);

            setLoading(false);

        }
    }

    async function getAllProfessionals(page: any) {

        try {

            setLoading(true);
            
            const response = await api.get(`usuario?page=${page}&limit=10&nome=${searchProfiles}`)

            setProfessionals(response.data.result.data);
            setTotalProfiles(response.data.result.pagination.total  )
            setPaginateAllProfiles(response.data.result.pagination)

            if (response.data.status !== 'success') {
                throw new Error()
            }

            setLoading(false);

        } catch (err: any) {

            console.log('log do erro pegando profissionais para comarca', err);

            setLoading(false);

        }
    }

    function searchKeyPressed(event: any) {

        if (event.key === 'Enter') {
            setSearchProfiles(DTOSearch)
        }

    }

    function clearSearch() {
        setDTOSearch('')
        setSearchProfiles('')
        setTotalProfiles('')
    }

    return (
        <Container>

            {
                loading &&
                <Oval
                    height={150}
                    width={150}
                    color="#FA9604"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#FA9604"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            }

            <SearchHeader>
                <div className="search">
                    <div
                        className="buscar"
                        onClick={() => setSearchProfiles(DTOSearch)}
                    >
                        <IconSearch />
                    </div>
                    <input
                        placeholder="Pesquisar"
                        onChange={(e) => setDTOSearch(e.target.value)}
                        onKeyDown={(e) => searchKeyPressed(e)}
                        value={DTOSearch}
                    />
                    {
                        DTOSearch &&
                        <div
                            className="clean-search"
                            onClick={() => clearSearch()}
                        >
                            <IconClose />
                        </div>
                    }
                </div>

                <div className="countie-status">
                    <span>{totalProfiles} resultados para:</span>

                    <div className="select">
                        <Select
                            styles={city}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 8,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#DDFAF4',
                                    primary50: '#DDFAF4',
                                    primary: '#02E8BE',
                                },
                            })}
                            name='id_comarca'
                            placeholder="Selecionar"
                            options={allCounties}
                            defaultValue={uf && cidade ? { value: `${uf} - ${cidade}`, label: `${uf} - ${cidade}`} : ''}
                            onChange={(e: any) => { setCountieSelected(e.value.slice(5)); getProfessionals(e.value.slice(5), 1)}}
                            // onChange={(selected: any) => handleOnChange('cidade_comarca', selected.value)}
                        />  
                    </div>

                    {/* <div 
                        className="countie-selected"
                        onClick={() => setOpenCounties(!openCounties)}
                    >
                        <div>Comarca:</div>
                        {
                            countieSelected 
                            ?   <div>{countieSelected}</div>
                            :   <div>{uf}-{cidade}</div>
                        }
                        <div className="icon"><IconButtonArrowDown /></div>
                    </div> */}

                    {/* <div 
                        className={openCounties ? 'countie-map' : "countie-map no-show"}
                    >
                        <div 
                            className="filters"
                            onClick={() => { 
                                getAllProfessionals(1); 
                                setOpenCounties(false); 
                                setCountieSelected('Todas')
                            }}
                        >
                            Todas as comarcas
                        </div>                
                    </div>                     */}
                </div>
            </SearchHeader>

            <ProfessionalTitles>
                <div className="icon-profile"></div>
                <div className="name">Nome</div>
                <div className="service">Serviço</div>
                <div className="profit">Lucro</div>
                <div className="code">Código</div>
                <div className="contact"></div>
            </ProfessionalTitles>

            {   
                professionals &&
                professionals?.map((row: any) => (
                    <ProfessionalPrices 
                        key={row.id_usuario}
                        onClick={() => { userSelected('id_usuario', row.id_usuario); nameUser(row.nome) }}
                        selected={selected === row.id_usuario}
                    >
                        <div className="icon-profile"><IconActiveProfile /></div>
                        <div className="name">{row.nome}</div>
                        <div className="service">R$ {row.valor_profissional},00</div>
                        <div 
                            className={row.lucro >= 0 ? "profit" : "profit negative" }
                        >
                            R$ {row.lucro},00
                        </div>
                        <div className="code">{row.id_usuario}</div>
                        <div 
                            className="contact"
                            onClick={() => {
                                setModalContact(true); setUserContacts({
                                    email: row.email, 
                                    celular: row.celular.replace(/\D/g, '')
                                        .replace(/(\d{2})(\d)/, '($1) $2')
                                        .replace(/(\d{5})(\d)/, '$1-$2')
                                        .replace(/(-\d{4})\d+?$/, '$1'), 
                                    tel_fixo: row.tel_fixo.replace(/\D/g, '')
                                        .replace(/(\d{2})(\d)/, '($1) $2')
                                        .replace(/(\d{5})(\d)/, '$1-$2')
                                        .replace(/(-\d{4})\d+?$/, '$1'), 
                                    tel_recado: row.tel_recado.replace(/\D/g, '')
                                        .replace(/(\d{2})(\d)/, '($1) $2')
                                        .replace(/(\d{5})(\d)/, '$1-$2')
                                        .replace(/(-\d{4})\d+?$/, '$1') 
                            }) }}
                        >
                            <IconContact />
                        </div>
                    </ProfessionalPrices>                    
                ))
            }

            {/* {
                reuseProposal &&
                <ProfessionalPrices
                    onClick={() => { userSelected('id_usuario', professionalReuse?.id_usuario); nameUser(professionalReuse?.nome) }}
                    selected={selected === professionalReuse?.id_usuario}
                >
                    <div className="icon-profile"><IconActiveProfile /></div>
                    <div className="name">{professionalReuse?.nome}</div>
                    <div className="service">R$ {professionalReuse?.valor_profissional},00</div>
                    <div
                        className={professionalReuse?.lucro >= 0 ? "profit" : "profit negative"}
                    >
                        R$ {professionalReuse?.lucro},00
                    </div>
                    <div className="code">{professionalReuse?.id_usuario}</div>
                    <div
                        className="contact"
                        onClick={() => {
                            setModalContact(true); setUserContacts({
                                email: professionalReuse?.email,
                                celular: professionalReuse?.celular.replace(/\D/g, '')
                                    .replace(/(\d{2})(\d)/, '($1) $2')
                                    .replace(/(\d{5})(\d)/, '$1-$2')
                                    .replace(/(-\d{4})\d+?$/, '$1'),
                                tel_fixo: professionalReuse?.tel_fixo.replace(/\D/g, '')
                                    .replace(/(\d{2})(\d)/, '($1) $2')
                                    .replace(/(\d{5})(\d)/, '$1-$2')
                                    .replace(/(-\d{4})\d+?$/, '$1'),
                                tel_recado: professionalReuse?.tel_recado.replace(/\D/g, '')
                                    .replace(/(\d{2})(\d)/, '($1) $2')
                                    .replace(/(\d{5})(\d)/, '$1-$2')
                                    .replace(/(-\d{4})\d+?$/, '$1')
                            })
                        }}
                    >
                        <IconContact />
                    </div>
                </ProfessionalPrices>      
            } */}

            {
                countieSelected === 'Todas' && paginateAllProfiles &&
                <Paginate>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IconAdvancePagination />}
                        previousLabel={<IconBackPagination />}
                        onPageChange={(value) => getAllProfessionals(value.selected + 1)}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={paginateAllProfiles.lastPage}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="back-page"
                        previousLinkClassName="back-link-page"
                        nextClassName="next-page"
                        nextLinkClassName="next-link-page"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        activeLinkClassName="active"
                    />
                </Paginate>
            }
            {
                countieSelected !== 'Todas' && paginateProfiles &&
                < Paginate >
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IconAdvancePagination />}
                        previousLabel={<IconBackPagination />}
                        onPageChange={(value) => getProfessionals(countieSelected, value.selected + 1)}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={paginateProfiles.lastPage}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="back-page"
                        previousLinkClassName="back-link-page"
                        nextClassName="next-page"
                        nextLinkClassName="next-link-page"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        activeLinkClassName="active"
                    />
                </Paginate>
            }

            <ModalDefault
                id={'overlayModal'}
                onClose={() => setModalContact((modalContact: any) => !modalContact)}
                openModal={modalContact}
                width="30%"
            >
                <ModalContactHead>
                    <div>
                        Entre em contato
                    </div>

                    <div
                        className="close"
                        onClick={() => setModalContact(false)}
                    >
                        <IconClose />
                    </div>
                </ModalContactHead>

                
                <ModalcontactMain>
                    <div>{userContacts.email}</div>
                    <div>{userContacts.celular}</div>
                    <div>{userContacts.tel_fixo ? userContacts.tel_fixo : 'sem telefone fixo'}</div>
                    <div>{userContacts.tel_recado ? userContacts.tel_recado : 'sem telefone de recado'}</div>
                </ModalcontactMain>
            </ModalDefault> 

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}