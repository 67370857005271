import { useHistory } from "react-router-dom";
import { useAuth } from "../../../contexts/UserContext";
import { IconLogout, IconUser } from "../../icons";
import { Button, Container, Divider } from "./styles";

interface MenuProps {
    menuSidebar?: boolean,
}

export default function ProfileMenu({menuSidebar}: MenuProps) {

    const { user, signOut } = useAuth();
    const history = useHistory();

    function handleOnLogout() {
        signOut()
        history.push('/');
    }

    return (
        <Container menuSidebar={menuSidebar}>
            <Button
                onClick={handleOnLogout}
            >
                <IconLogout color={user?.profile !== 'comum' ? "#FA9604" : "#02E8BE"} />
                Sair
            </Button>

            <Divider />           

            {   user.profile !== 'comum' &&
                <Button
                    onClick={() => history.push(`/operadores/`+ user.id)}
                >
                    <IconUser color={user?.profile !== 'comum' ? "#FA9604" : "#02E8BE"} />
                    Meu Perfil
                </Button>
            }

            {   user.profile === 'comum' &&
                <Button
                    onClick={() => history.push(`/profissionais/`+ user.id)}
                >
                    <IconUser color={user?.profile !== 'comum' ? "#FA9604" : "#02E8BE"} />
                    Meu Perfil
                </Button>
            }
        </Container>
    );
}