/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

// React
import { useEffect, useState } from "react";

// Libraries
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";

// Services
import api from "../../services/api";

// Data
import { EstadosBrasileiros } from "../Data/StatesData";

// Icons
import { IconClose, IconPlus, IconTrash, IconWarning } from "../icons";

// Styles
import { ModalPrecification, Wrapper } from "./styles";

// Components
import ButtonDefault from "../form/ButtonDefault";
import ModalDefault from "../ModalDefault";

// Context
import { useAuth } from "../../contexts/UserContext";

interface CountyErrors {
    estado?: string,
    cidade?: string,
    precificacao?: any,
}

interface UpdateCountiesProps {
    setUpdateCounties?: any
    updateCounties?: any
    userId?: any,
    editProfile?: any,
}

export default function Counties({setUpdateCounties, userId, editProfile}: UpdateCountiesProps) {

    const { user } = useAuth()
    const [ cities, setCities ] = useState<any>([]);
    const [ advanceCountyError, setAdvanceCountyError ] = useState<CountyErrors>({});
    const [ openModalDeleteComarca, setOpenModalDeleteComarca ] = useState<boolean>(false);
    const [ deleteComarca, setDeleteComarca ] = useState<any>();
    const [ comarcaItem, setComarcaItem ] = useState<any>();
    const [ pricingCounty, setPricingCounty ] = useState<any>([]);
    const [ precifications, setPrecifications ] = useState<any>();

    const ComarcaDefault = {
        uf: '',
        cidade: '',
        principal: '',
        id_precificacao: '',
    };

    const [DTOComarcas, setDTOComarcas] = useState<any>([ComarcaDefault])

    const countyState = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advanceCountyError.estado ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    };

    const countyCity = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advanceCountyError.cidade ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    };

    const oabPrice = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advanceCountyError.precificacao ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    };

    useEffect(() => {

        handleRemoveComarca(comarcaItem);        

    }, [deleteComarca])

    function handleOnChangeComarcas(key: number, name: any, value: string) {

        if (name === 'principal') {
            DTOComarcas.map((row: any, key: number) => {
                row.principal = false
            })
        }

        const newDTO: any = DTOComarcas
        newDTO[key][name] = value
        setDTOComarcas([...newDTO])

        setUpdateCounties(DTOComarcas)

        if (name === 'uf') getCitiesForUF(DTOComarcas)
    };

    function getCitiesForUF(DTOComarcas: any) {
        const newCities: any = []
        DTOComarcas.map((row: any, key: number) => {
            const cityName: any = {
                uf: row.estado,
                docs: []
            }
            axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${row.uf}/municipios`).then(response => {
                response.data.map((row: any) => {
                    cityName.docs.push({
                        value: row.nome,
                        label: row.nome
                    })
                })
                newCities.push(cityName)
                setCities([...newCities])
            });
        })
    };

    function handleAddComarca() {

        DTOComarcas.push(ComarcaDefault)
        setDTOComarcas([...DTOComarcas])

    };

    function confirmDeleteComarca(item: any) {
        
        if(DTOComarcas.length > 1) {

            console.log('Voce deve manter pelo menos uma Comarca')
            setOpenModalDeleteComarca(true)
            setComarcaItem(item)

        } else {
            toast(<div className="warning"><span><IconWarning /></span> Você precisa ter pelo menos 1 comarca cadastrada.</div>,             
            {
                duration: 3000,
                style: {
                    padding: '16px',
                    minWidth: '520px',
                    color: '#1A2858',
                    background: '#E4F5FF',
                    border: '2px solid #88D3FE',
                },                
            });           
        }

    }
    
    function handleRemoveComarca(indice: number) {
        const newDTO: any = []
        DTOComarcas.map((row: any, key: number) => {
            if (key !== indice) newDTO.push(row)
        })
        setDTOComarcas([...newDTO])
        setOpenModalDeleteComarca(false);
        setDeleteComarca(false);
    };

    useEffect(() => {
        async function getCountiesData() {
            try {

                if(user.profile !== 'comum') {

                    const response = await api.get('comarca-usuario/' + userId)
                    setDTOComarcas(response.data.result)

                    if (response.data.status !== 'success') {
                        throw new Error('Não foi possível encontrar os dados')
                    }

                } else {
                    const response = await api.get('comarca-usuario/' + user.id)
                    setDTOComarcas(response.data.result)
                    if (response.data.status !== 'success') {
                        throw new Error('Não foi possível encontrar os dados')
                    }
                }

            } catch (error: any) {
                toast.error(error.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
            }
            
        }

        async function getUserPrecification() {
            try {

                if(user.profile !== 'comum') {

                    const response = await api.get('usuario/' + userId)
                    setPrecifications(response.data.result.precificacoes)

                    if (response.data.status !== 'success') {
                        throw new Error('Não foi possível encontrar os dados')
                    }

                } 
                // else {
                //     const response = await api.get('usuario/' + user.id)
                //     setPrecifications(response.data.result.precificacoes)
                //     if (response.data.status !== 'succcess') {
                //         throw new Error('Não foi possível encontrar os dados')
                //     }
                // }

            } catch (error: any) {
                toast.error(error.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
            }
            
        }

        getCountiesData();   
        getUserPrecification();            
    }, [])

    useEffect(() => {
        const pricing: { value: any; label: any; }[] = []

        precifications?.map((row: any) => {
            pricing.push({
                value: row.titulo,
                label: row.titulo
            })
        })

        setPricingCounty(pricing);
    }, [precifications])

    return (
        <Wrapper>
            <form className='county-form'>

                {   
                    DTOComarcas ?
                    DTOComarcas?.map((row: any, key: number) =>
                        <div className={`comarca ${key > 0 ? 'line' : 'none'}`} key={key}>
                            <div className="county">
                                <div className="label">
                                    <p>Estado da comarca:</p>
                                    <Select
                                        styles={countyState}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#DDFAF4',
                                                primary50: '#DDFAF4',
                                                primary: '#02E8BE',
                                            },
                                        })}
                                        name='estado_comarca'
                                        placeholder="Selecionar"
                                        value={{ value: row?.uf, label: row?.uf }}
                                        options={EstadosBrasileiros}
                                        onChange={(e: any) => handleOnChangeComarcas(key, 'uf', e.value)}
                                        isDisabled={editProfile ? false : true}
                                    />
                                    <span className="error">{advanceCountyError.estado ? '*' : ''}</span>
                                </div>

                                {cities.filter((obj: any) => obj.uf === row.estado)[0]?.docs.length > 0 &&
                                    <div className="label">
                                        <p>Cidade da comarca:</p>
                                        <Select
                                            styles={countyCity}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 8,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DDFAF4',
                                                    primary50: '#DDFAF4',
                                                    primary: '#02E8BE',
                                                },
                                            })}
                                            name='cidade'
                                            placeholder="Selecionar"
                                            value={{ value: row.cidade, label: row.cidade }}
                                            options={cities.filter((obj: any) =>
                                                obj.uf === row.estado
                                            )[0]?.docs}
                                            onChange={(e: any) => handleOnChangeComarcas(key, 'cidade', e.value)}
                                            isDisabled={editProfile ? false : true}
                                        />
                                        <span className="error">{advanceCountyError.cidade ? '*' : ''}</span>
                                    </div>
                                }
                            </div>

                            <div className="pricing">

                                <div className="label">
                                    <p>Precificação :</p>
                                    <Select
                                        styles={oabPrice}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#DDFAF4',
                                                primary50: '#DDFAF4',
                                                primary: '#02E8BE',
                                            },
                                        })}
                                        placeholder="Selecionar"
                                        value={{ value: row.precificacao, label: row.precificacao }}
                                        options={pricingCounty ? pricingCounty : ''}
                                        onChange={(e: any) => handleOnChangeComarcas(key, 'precificacao', e.value)}
                                        isDisabled={editProfile ? false : true}
                                    />
                                    <span className="error">{advanceCountyError.precificacao ? '*' : ''}</span>
                                </div>

                                <div className="right">
                                    <div className="radio-input">
                                        <input
                                            type="radio"
                                            value="sim"
                                            name="Principal"
                                            checked={row.principal === 'sim' ? true : false}
                                            onChange={(e: any) => handleOnChangeComarcas(key, 'principal', 'sim')}
                                            placeholder="Principal"
                                        />
                                        <span>Principal</span>
                                    </div>

                                    <button onClick={() => confirmDeleteComarca(key)} type="button" className="delete"><IconTrash /> Excluir comarca</button>
                                </div>

                            </div>
                        </div>
                    )
                    : <div>Sem dados</div>
                }

                <div className="dot-border" onClick={handleAddComarca}><IconPlus /> Adicionar comarca</div>

            </form>

            <ModalDefault
                id={'overlayModal'}
                onClose={() => setOpenModalDeleteComarca((openModalDeleteComarca) => !openModalDeleteComarca)}
                openModal={openModalDeleteComarca}
                width="45%"
            >
                <ModalPrecification>
                    <div className="header">
                        <p>Deseja excluir a comarca?</p>
                        <div
                            className="close"
                            onClick={() => setOpenModalDeleteComarca(false)}
                        >
                            <IconClose />
                        </div>
                    </div>
                    <div className="main">
                        <ButtonDefault
                            className="cancel"
                            onClick={() => setOpenModalDeleteComarca(false)}
                        >
                            Cancelar
                        </ButtonDefault>
                        <ButtonDefault
                            className="danger"
                            onClick={() => setDeleteComarca(true)}
                        >
                            Confirmar
                        </ButtonDefault>
                    </div>
                </ModalPrecification>
            </ModalDefault>

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Wrapper>
    )
}