import styled from "styled-components";

interface Props {
    menuOpen: boolean;
    profileStatus?: any,
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    /* max-width: 1040px; */
    box-shadow: 35px 45px 73px rgba(32, 32, 35, 0.07);
`

export const DemandWrapper = styled.div<Props>`
    display: flex;
    flex-direction: column;    
    width: 100%;
    height: ${({ menuOpen }) => (menuOpen ? "160px" : "80px")};    
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;     
    
    .title {
        color: ${({ theme }) => theme.colors.interText400};
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 4px;
    }

    .subtitle {
        color: ${({ theme }) => theme.colors.interText400};
        font-size: 14px;
        font-weight: 500;
    }
`

export const DemandTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding-left: 24px;
    padding-right: 16px;
`

export const DemandBottom = styled.div<Props>`
    display: ${({ menuOpen }) => (menuOpen ? "flex" : "none")};
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding-left: 24px;
    padding-right: 16px;

    /* @media (max-width: 1400px) {
        padding-left: 7.5%;
        padding-right: 14%;
    } */
`

export const DivIcon = styled.div`
    width: 40px;
    svg {
        path {
            fill: ${({ theme }) => theme.colors.primaryBlue500};
        }
    }
`

export const DivCode = styled.div`
    width: 130px;       
    
`

export const DivService = styled.div`
    width: 224px;
`

export const DivDeadline = styled.div`
    width: 170px;
`

export const DivDemand = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: ${({ theme }) => theme.colors.primaryBlue500};
    border-radius: 8px;
    cursor: pointer;
    &.fake {
        background: none;
        cursor: auto;
    }
`

export const DivArrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;    
    cursor: pointer;

    &.fake {
        cursor: auto;
    }
`

export const DivStatus = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .status {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        width: 40px;
        height: 40px;
        border-radius: 8px;  
        padding: 10px;                
        
        svg {
            width: 25px;
            height: 25px;
        }

        &.interrupted {
            background-color: ${({ theme }) => theme.colors.interfaceDanger100};
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfaceDanger400};
                }
            }
        }

        &.pending {
            background-color: ${({ theme }) => theme.colors.primaryOrange50}; 
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.primaryOrange700};
                }
            }
        }

        &.delayed {
           background-color: ${({ theme }) => theme.colors.interfaceDanger100}; 
           svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfaceDanger400};
                }
            }
        }

        &.progress {
           background-color: ${({ theme }) => theme.colors.secondaryPool100};
           svg {
                circle {
                    fill: ${({ theme }) => theme.colors.secondaryPool600};
                }
            }
        }

        &.finished {

            &.accepted {
                background-color: ${({ theme }) => theme.colors.interfaceSuccess100};
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceSuccess400};
                    }
                }

            }

            &.rejected {
                background-color: ${({ theme }) => theme.colors.interfacePure100};
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceText500};
                    }
                }

            }
        }

        &.canceled {
           background-color: ${({ theme }) => theme.colors.interfacePure100};
           svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfaceText500};
                }
            }
        }
    }
`

export const StatusHover = styled.div`
    width: max-content;
    height: 40px;
    border-radius: 8px 8px 0 8px;
    position: absolute;
    top: 0;
    right: 48px;
    display: none;
    padding: 10px;
    transition: ease-in-out .3s;
    color: ${({ theme }) => theme.colors.interfaceText500};
    font-weight: 500;
    
    ${DivStatus}:hover & {
        display: flex;
        cursor: default;
    }

    &.interrupted {
        background-color: ${({ theme }) => theme.colors.interfaceDanger100};
        svg {
            width: 100%;
            height: 100%;
            path {
                fill: ${({ theme }) => theme.colors.interfaceDanger300};
            }
        }
    }

    &.pending {
        background-color: ${({ theme }) => theme.colors.primaryOrange50}; 
        svg {
            path {
                fill: ${({ theme }) => theme.colors.primaryOrange700};
            }
        }
    }

    &.delayed {
        background-color: ${({ theme }) => theme.colors.interfaceDanger100}; 
        svg {
            path {
                fill: ${({ theme }) => theme.colors.interfaceDanger300};
            }
        }
    }

    &.progress {
        background-color: ${({ theme }) => theme.colors.secondaryPool200};
        svg {
            path {
                fill: ${({ theme }) => theme.colors.secondaryPool500};
            }
        }
    }

    &.finished {
        &.accepted {
            background-color: ${({ theme }) => theme.colors.interfaceSuccess100};
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfaceSuccess500};
                }
            }

        }

        &.rejected {
            background-color: ${({ theme }) => theme.colors.interfacePure100};
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfaceText500};
                }
            }

        }
    }

    &.canceled {
        background-color: ${({ theme }) => theme.colors.interfacePure100};
        svg {
            path {
                fill: ${({ theme }) => theme.colors.interfacePure400};
            }
        }
    }
`

export const DivStarts = styled.div`
    width: 170px;
`

export const DivConclusion = styled.div`
    width: 135px;
`

export const DivCounty = styled.div`
    width: 224px;
    height: 48px;
`

export const DivProfessional = styled.div`
    width: 170px;
`
