export const comum = {
    colors: {
        white: '#FFF',
        background: '#F6F8FA',

        primaryBlue100: '#4361CD',
        primaryBlue200: '#3452C0',
        primaryBlue300: '#2E49A9',
        primaryBlue400: '#293F93',
        primaryBlue500: '#23367C',
        primaryBlue600: '#1A2858',

        primaryOrange50: '#FFF2DE',
        primaryOrange100: '#FFDEAE',
        primaryOrange200: '#FFCB7F',
        primaryOrange300: '#FEBE60',
        primaryOrange400: '#FDB141',
        primaryOrange500: '#FFA41F',
        primaryOrange600: '#FA9604',
        primaryOrange700: '#F18F00',

        secondaryGreen050: '#DDFAF4',
        secondaryGreen100: '#B8FFF2',
        secondaryGreen200: '#6DFFE5',
        secondaryGreen300: '#04FDD0',
        secondaryGreen400: '#02E8BE',
        secondaryGreen500: '#00D3AD',
        secondaryGreen600: '#03AD8F',

        secondaryPool100: '#E4F5FF',
        secondaryPool200: '#C1E8FF',
        secondaryPool300: '#ABE0FE',
        secondaryPool400: '#88D3FE',
        secondaryPool500: '#70CBFF',
        secondaryPool600: '#23ACFA',

        interfaceDanger: '#F24343',
        interfaceDanger100: '#FFEAED',
        interfaceDanger200: '#FFCACE',
        interfaceDanger300: '#F39493',
        interfaceDanger400: '#F24343',
        interfaceDanger500: '#E81E26',
        interfaceDanger600: '#BB0007',

        interfaceSuccess: '#0FC758',
        interfaceSuccess100: '#E7F8EB',
        interfaceSuccess200: '#C5EECE',
        interfaceSuccess300: '#9EE4AF',
        interfaceSuccess400: '#0FC758',

        interfaceText100: '#EAEEF3',
        interfaceText200: '#C5CED7',
        interfaceText300: '#8A99A8',
        interfaceText400: '#5A646E',
        interfaceText500: '#353A40',

        interfacePure0: '#FFF',
        interfacePure100: '#F2F2F2',
        interfacePure200: '#CCCCCC',
        interfacePure400: '#707070',
        interfacePure500: '#333333',
    }
}

export const admin = {
    colors: {
        white: '#FFF',
        background: '#F6F8FA',

        primaryBlue100: '#4361CD',
        primaryBlue200: '#3452C0',
        primaryBlue300: '#2E49A9',
        primaryBlue400: '#293F93',
        primaryBlue500: '#23367C',
        primaryBlue600: '#1A2858',

        primaryOrange50: '#FFF2DE',
        primaryOrange100: '#FFDEAE',
        primaryOrange200: '#FFCB7F',
        primaryOrange300: '#FEBE60',
        primaryOrange400: '#FDB141',
        primaryOrange500: '#FFA41F',
        primaryOrange600: '#FA9604',
        primaryOrange700: '#F18F00',

        secondaryGreen050: '#DDFAF4',
        secondaryGreen100: '#B8FFF2',
        secondaryGreen200: '#6DFFE5',
        secondaryGreen300: '#04FDD0',
        secondaryGreen400: '#02E8BE',
        secondaryGreen500: '#00D3AD',
        secondaryGreen600: '#03AD8F',

        secondaryPool100: '#E4F5FF',
        secondaryPool200: '#C1E8FF',
        secondaryPool300: '#ABE0FE',
        secondaryPool400: '#88D3FE',
        secondaryPool500: '#70CBFF',
        secondaryPool600: '#23ACFA',

        interfaceDanger: '#F24343',
        interfaceDanger100: '#FFEAED',
        interfaceDanger200: '#FFCACE',
        interfaceDanger300: '#F39493',
        interfaceDanger400: '#F24343',
        interfaceDanger500: '#E81E26',
        interfaceDanger600: '#BB0007',

        interfaceSuccess: '#0FC758',
        interfaceSuccess100: '#E7F8EB',
        interfaceSuccess200: '#C5EECE',
        interfaceSuccess300: '#9EE4AF',
        interfaceSuccess400: '#0FC758',

        interfaceText100: '#EAEEF3',
        interfaceText200: '#C5CED7',
        interfaceText300: '#8A99A8',
        interfaceText400: '#5A646E',
        interfaceText500: '#353A40',

        interfacePure0: '#FFF',
        interfacePure100: '#F2F2F2',
        interfacePure200: '#CCCCCC',
        interfacePure400: '#707070',
        interfacePure500: '#333333',
    }
}

export const operador = {
    colors: {
        white: '#FFF',
        background: '#F6F8FA',

        primaryBlue100: '#4361CD',
        primaryBlue200: '#3452C0',
        primaryBlue300: '#2E49A9',
        primaryBlue400: '#293F93',
        primaryBlue500: '#23367C',
        primaryBlue600: '#1A2858',

        primaryOrange50: '#FFF2DE',
        primaryOrange100: '#FFDEAE',
        primaryOrange200: '#FFCB7F',
        primaryOrange300: '#FEBE60',
        primaryOrange400: '#FDB141',
        primaryOrange500: '#FFA41F',
        primaryOrange600: '#FA9604',
        primaryOrange700: '#F18F00',

        secondaryGreen050: '#DDFAF4',
        secondaryGreen100: '#B8FFF2',
        secondaryGreen200: '#6DFFE5',
        secondaryGreen300: '#04FDD0',
        secondaryGreen400: '#02E8BE',
        secondaryGreen500: '#00D3AD',
        secondaryGreen600: '#03AD8F',

        secondaryPool100: '#E4F5FF',
        secondaryPool200: '#C1E8FF',
        secondaryPool300: '#ABE0FE',
        secondaryPool400: '#88D3FE',
        secondaryPool500: '#70CBFF',
        secondaryPool600: '#23ACFA',

        interfaceDanger: '#F24343',
        interfaceDanger100: '#FFEAED',
        interfaceDanger200: '#FFCACE',
        interfaceDanger300: '#F39493',
        interfaceDanger400: '#F24343',
        interfaceDanger500: '#E81E26',
        interfaceDanger600: '#BB0007',
        
        interfaceSuccess: '#0FC758',
        interfaceSuccess100: '#E7F8EB',
        interfaceSuccess200: '#C5EECE',
        interfaceSuccess300: '#9EE4AF',
        interfaceSuccess400: '#0FC758',

        interfaceText100: '#EAEEF3',
        interfaceText200: '#C5CED7',
        interfaceText300: '#8A99A8',
        interfaceText400: '#5A646E',
        interfaceText500: '#353A40',

        interfacePure0: '#FFF',
        interfacePure100: '#F2F2F2',
        interfacePure200: '#CCCCCC',
        interfacePure400: '#707070',
        interfacePure500: '#333333',
    }
}