import styled from "styled-components";

export const WrapperFiles = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: fit-content;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;
    padding: 24px;
    overflow-x: auto;

    span {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryBlue500};
    }

    .file {
        display: flex;
        gap: 8px;
        width: 100%;
        height: 56px;
        color: ${({ theme }) => theme.colors.interfaceText500};
        font-weight: 400;

        .file-details{
            display: flex;
            justify-content: space-between;
            width: 90%;
            border: 2px solid ${({ theme }) => theme.colors.interfacePure100};
            border-radius: 8px;
            padding: 16px;

            &.highlight {
                background-color: ${({ theme }) => theme.colors.secondaryPool100};
            }

            .icon-title {
                display: flex;
                align-items: center;
                gap: 18px;
                font-size: 14px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.primaryBlue500};
            }

            .date-trash {
                display: flex;
                align-items: center;
                gap: 40px;

                .date {
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText300};
                }

                .icon-trash {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                    svg {
                        width: 100%;
                        height: 100%;
                        path {
                            fill: ${({ theme }) => theme.colors.primaryBlue500};
                        }
                    }
                }
            }

        }

        .download {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            border-radius: 8px;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                background-color: ${({ theme }) => theme.colors.interfaceText100};
            }
        }
    }

    .add {
        display: flex;
        align-items: center;
        gap: 20px;
        height: 56px;
        padding: 16px;
        border: 2px dotted ${({ theme }) => theme.colors.primaryBlue500};
        color: ${({ theme }) => theme.colors.primaryBlue500};
        font-weight: 500;
        border-radius: 8px;
        cursor: pointer;
        position: relative;
        svg {
            path {
                fill: ${({ theme }) => theme.colors.primaryBlue500};
            }
        }

        .file-input {
                width: 100%;
                height: 100%;
                color: transparent;
                transition: all .5s;
                border: none;
                padding: 0;
                background: transparent;
                border-radius: 8px;
                position: absolute;
                top: 0;
                left: 0;
            }
            .file-input::-webkit-file-upload-button {
                visibility: hidden;
            }
            .file-input::before {
                content: 'Anexar arquivos';
                color: ${({ theme }) => theme.colors.primaryBlue500};
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border: none;
                padding: 0;
                width: 100%;
                height: 50px;
                outline: none;
                white-space: nowrap;
                -webkit-user-select: none;
                user-select: none;
                cursor: pointer;
                font-weight: 500;
                font-size: 18px;
                padding-left: 60px;
            }
            .file-input:active {
                outline: 0;
            }
    }
`