import styled from "styled-components";

interface ActivePage {
    active?: any,
}

export const Container = styled.div`
    background: ${({ theme }) => theme.colors.interfacePure0};
    /* padding-bottom: 120px; */
    /* overflow-y: auto; */
`

export const Header = styled.div`
    width: 1040px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 48px;  
    margin-bottom: 90px;   
`

export const Main = styled.div`
    width: 1040px;
    min-height: 600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 20px;
    padding-bottom: 120px;
`

export const LeftSide = styled.div<ActivePage>`
    width: 100%;        
    max-width: 595px;
    align-self: ${({ active }) => active === 1 ? 'flex-start' : 'center'};    
`

export const RightStatus = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    
    .progress {
        display: flex;
        align-items: center;
        gap: 8px;
        color: ${({ theme }) => theme.colors.interfaceText500};
        margin-bottom: 24px;

        .progressbar {
            height: 8px;
            width: 170px;
            border-radius: 8px;
            background: ${({ theme }) => theme.colors.interfacePure100};
            position: relative;

            .percent {
                transition: all .2s;
                width: 8px;
                height: 8px;
                position: absolute;
                border-radius: 8px;
                top: 0;
                left: 0;
                background: ${({ theme }) => theme.colors.secondaryGreen400};
                &.two {
                    width: 8px;
                }
                &.three {
                    width: 16px;
                }
                &.four {
                    width: 24px;
                }
                &.five {
                    width: 32px;
                }
                &.six {
                    width: 32px;
                }
                &.seven {
                    width: 170px;
                }
            }
        }

        .percentage {
            font-weight: 700;
        }
    }

    .status {
        height: fit-content;
        display: flex;
        flex-direction: column;

        .status-title {
            color: ${({ theme }) => theme.colors.interfaceText400};
            font-size: 24px;
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 48px;
        }
        

        .status-subtitle {
            max-width: 424px;
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-size: 18px;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 32px;

            span {
                font-weight: 600;
            }

            p {
                font-size: 18px;
                font-weight: 400;
                line-height: 150%;
                margin-top: 8px;
            }
        }

        .small-info {
            color: ${({ theme }) => theme.colors.secondaryGreen600};
            font-size: 14px;
            font-weight: 500;
            margin-top: -24px;
            margin-bottom: 32px;
        }

        .list {
            max-width: 424px;
            padding-left: 20px;
            ul {                
                li {
                    list-style: disc;
                    color: ${({ theme }) => theme.colors.interfaceText500};
                    font-size: 16px;
                    font-weight: 400; 
                }
            } 
        }

        .files {
            
            span {
                display: none;
            }

            .file-details {
                width: 360px;
                border: 2px solid ${({ theme }) => theme.colors.secondaryPool200};
                margin-left: -24px;
            }
        }

        .stay-in {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 424px;
            height: 178px;
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-size: 18px;
            font-weight: 400;
            
            div > span {
                font-weight: 600;
            }

            .link {
                span {
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.secondaryGreen400};
                    text-decoration: underline;
                    text-underline-offset: 2px;
                }
            }
        }

        .contact {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            gap: 8px;
            max-width: 424px;
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-size: 16px;
            font-weight: 400;
            
            div > span {
                font-weight: 600;
            }
    
            .link {
                .phones {
                    padding-top: 8px;
                    padding-left: 20px;
                }
            }
        }
    }

`

export const HearingCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;  
    width: 424px;
    height: fit-content;
    border: 2px solid ${({ theme }) => theme.colors.secondaryGreen400};
    border-radius: 8px;
    padding: 24px;

    .title {
        width: 100%;
        height: 48px;
        font-size: 24px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.secondaryGreen600};
        border-bottom: 2px solid ${({ theme }) => theme.colors.secondaryPool100};
        padding-bottom: 16px;
    }

    .info {
        height: 56px;
        width: 100%;

        span{
            height: 16px;
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.primaryBlue500};
        }

        .show-info {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            padding: 8px;
            width: fit-content;
            background-color: ${({ theme }) => theme.colors.secondaryGreen050};
            border-radius: 8px;
            margin-top: 8px;

        }
    }

    .address {
        
        span{
            height: 16px;
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.primaryBlue500};
        }

        .wrap {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;

            .address-info {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                padding: 8px;
                width: fit-content;
                background-color: ${({ theme }) => theme.colors.secondaryGreen050};
                border-radius: 8px;
                margin-top: 8px;    
            }
        }

    }
`

export const ToTheTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 424px;
    height: 56px;
    border: 2px dotted ${({ theme }) => theme.colors.secondaryGreen400};
    border-radius: 8px;
    cursor: pointer;
    margin-top: 16px;

    font-weight: 500;
    color: ${({ theme }) => theme.colors.secondaryGreen400};

    .icon {
        padding-right: 20px;    
        margin-left: 125px;
    }
`

export const Whatsapp = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 424px;
    height: 56px;
    border: 2px dotted ${({ theme }) => theme.colors.interfaceSuccess400};
    border-radius: 8px;
    cursor: pointer;
    margin-top: 24px;

    font-weight: 500;
    color: ${({ theme }) => theme.colors.interfaceSuccess400};

    .icon {
        padding-right: 20px;    
        margin-left: 70px;
    }
`

export const Footer = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: 0;  
    background: ${({ theme }) => theme.colors.interfacePure0};
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
    z-index: 0;

    .container {
        width: 1040px;
        margin: 0px auto;
        display: flex;
        justify-content: space-between;
        max-width: 90%;
    }
    .buttons {
        display: flex;
        gap: 16px;
        button {
            width: 160px;
        }

        .cancel {
            border: 1px solid ${({ theme }) => theme.colors.interfaceText400};
        }

        .continue {
            background: ${({ theme }) => theme.colors.secondaryGreen500};
            width: 250px;
        }
    }

    .progress {
        display: flex;
        align-items: center;
        gap: 10px;

        div {
            color: ${({ theme }) => theme.colors.interfaceText400};
            font-weight: 400;
        }

        div > span {
            font-weight: 600;
            color: ${({ theme }) => theme.colors.secondaryGreen600};
        }

        svg {
            path {
                fill: ${({ theme }) => theme.colors.secondaryGreen600};
            }
        }
    }
`