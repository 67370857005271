/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Librarys
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import * as yup from 'yup';
import getValidationErrors from "../../Utils/validationErrors";

//Services
import api from "../../services/api";
import axios from "axios";
import { useAuth } from "../../contexts/UserContext";

// Styles
import { Container, Footer, Header, Hr1, Main, ModalHeader, ModalMain, ModalPrecification } from "./styles";

// Data
import { EstadosBrasileiros } from "../../components/Data/StatesData";
import { brazilianBanks } from "../../components/Data/BrazilianBanks";

// Components
import Checkbox from "../../components/form/InputCheckbox";
import ButtonDefault from "../../components/form/ButtonDefault";
import ModalDefault from "../../components/ModalDefault";
import InputDefault from "../../components/form/InputDefault";
import RegisterPage5 from "../../components/RegisterPage5";
import { PixKeyData } from "../../components/Data/PixKeyData";
import InputMoney from "../../components/form/InputMoney";
import ModalTerms from "../../components/ModalTerms";

//Icons
import { 
    IconAccount, 
    IconAsteristik, 
    IconClose, 
    IconLogoJuridico, 
    IconPadlock, 
    IconSquarePlus, 
    IconProfile, 
    IconPlus, 
    IconTrash, 
    IconPlan, 
    IconEyeOpen, 
    IconClosedEye, 
} from "../../components/icons";

interface Errors {
    nome?: string,
    email?: string,
    senha?: string,
    confirmeSenha?: string,
    cpf?: string,
    cnpj?: string,
    celular?: string,
    whatsapp?: string,
    razao_social?: string,
    inscricao_oab?: string,
    tipo_oab?: string,
    estado_oab?: string,    
    tel_fixo?: string,
    cep?: string,
    uf?: string,
    cidade?: string,
    bairro?: string,
    endereco?: string,
    numero?: string,
    estado?: string,
    cidade_comarca?: string,
}

interface CountyErrors {
    uf?: string,
    cidade?: string,
    precificacao?: any,
}

interface AccountErrors {
    nome_titular?: string,
    tipo?: string,
    cnpj?: string,
    banco?: string,
    agencia?: string,
    conta?: string,
    digito?: string,
    tipo_chave_pix?: string,
    chave_pix?: string
}

export default function Register() {

    const history = useHistory(); 
    const [ activePage, setActivePage ] = useState<number>(1);
    const [ openModalConfirmation, setOpenModalConfirmation ] = useState<boolean>(false);
    const [ openModalDeleteComarca, setOpenModalDeleteComarca ] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openPaymentTerms, setOpenPaymentTerms] = useState<boolean>(false);
    const [ checked, setChecked ] = useState<boolean>(false);
    const [ cepState, setCepState ] = useState();
    const [ cepCity, setCepCity ] = useState();
    const [ cepCounty, setCepCounty ] = useState();
    const [ cepAddress, setCepAddress ] = useState();
    const [ advancePageError, setAdvancePageError ] = useState<Errors>({});
    const [ advancePrecificationError, setAdvancePrecificationError ] = useState<any>([
        {
            titulo: false,
            outros_servicos: {
                valor_diligencia: false,
                valor_protocolo: false
            },
            servicos: [
                {
                    key: 0,
                    valor_advogado: false,
                },
                {
                    key: 0,
                    valor_preposto: false,
                },
                {
                    key: 0,
                    valor_total: false,
                },
                {
                    key: 1,
                    valor_advogado: false,
                },
                {
                    key: 1,
                    valor_preposto: false,
                },
                {
                    key: 1,
                    valor_total: false,
                },
                {
                    key: 2,
                    valor_advogado: false,
                },
                {
                    key: 2,
                    valor_preposto: false,
                },
                {
                    key: 2,
                    valor_total: false,
                },
                {
                    key: 3,
                    valor_advogado: false,
                },
                {
                    key: 3,
                    valor_preposto: false,
                },
                {
                    key: 3,
                    valor_total: false,
                },
                {
                    key: 4,
                    valor_advogado: false,
                },
                {
                    key: 4,
                    valor_preposto: false,
                },
                {
                    key: 4,
                    valor_total: false,
                },
                {
                    key: 5,
                    valor_advogado: false,
                },
                {
                    key: 5,
                    valor_preposto: false,
                },
                {
                    key: 5,
                    valor_total: false,
                },
            ]
        }
    ]);
    const [ advanceCountyError, setAdvanceCountyError ] = useState<CountyErrors>({});
    const [ advanceAccountError, setAdvanceAccountError ] = useState<AccountErrors>({});
    const [ cities, setCities ] = useState<any>([]);
    const [ pricingCounty, setPricingCounty ] = useState<any>([]);
    const [ changeVisibility, setChangeVisibility ] = useState<boolean>(true);
    const [ validateUser, setValidateUser ] = useState();
    const [ excludePrecification, setExcludePrecification ] = useState<any>();
    const [ deleteComarca, setDeleteComarca ] = useState<any>();
    const [ precificationItem, setPrecificationItem ] = useState<any>();
    const [ comarcaItem, setComarcaItem ] = useState<any>();
    const [ selected, setSelected ] = useState<any>('cpf'); 

    useEffect(() => {

        if ( activePage === 3 ) {
            const newDTOComarcas:any = []
            DTOServices.map((service:any) => {
                DTOComarcas.map((comarca:any) => {
                    if (service.titulo === comarca.precificacao ) {
                        newDTOComarcas.push(comarca)
                    }
                })
            })
            setDTOComarcas([...newDTOComarcas])
        }

    }, [activePage])

    const oabType = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48, 
            boxShadow: "none",
            cursor: 'pointer',
            border: advancePageError.tipo_oab ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            }, 
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',  
            fontSize: '16px',  
            fontWeight: '400',        
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    };

    const oabState = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advancePageError.estado_oab ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }), 
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const oabPrice = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advanceCountyError.precificacao ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }), 
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const countyState = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advanceCountyError.uf ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }), 
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const countyCity = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advanceCountyError.cidade ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }), 
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const accountSelect = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advanceAccountError.tipo ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const bankSelect = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advanceAccountError.banco ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const pixKey = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advanceAccountError.tipo_chave_pix ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const inscription = [
        { value: 'principal', label: 'Inscrição principal' },
        { value: 'suplementar', label: 'Inscrição suplementar' },
        { value: 'transferência', label: 'Inscrição por transferência' },
    ];  
    
    const accounts = [
        { value: 'pj', label: 'Conta PJ' },
        { value: 'corrente', label: 'Conta Corrente' },
        { value: 'poupança', label: 'Conta Poupança' },
        { value: 'pf', label: 'Conta Pessoa Física' },
    ];  
    
    const [DTOUser, setDTOUser] = useState({
        nome: '',
        email: '',
        senha: '',
        confirmeSenha: '',
        cpf: '',
        cnpj: '',
        celular: '',
        whatsapp: '',
        razao_social: '',
        inscricao_oab: '',
        tipo_oab: '',
        estado_oab: '',
        tel_recado: '',
        tel_fixo: '',
        cep: '',
        uf: cepState,
        cidade: cepCity,
        bairro: cepCounty,
        endereco: cepAddress,
        numero: '',
        complemento: '',
        termos: '',  
        id_plano: 1,    
    })

    const ServicesDefault = 
    {
        id: 1,
        titulo: '',
        servicos: [
            {
                id_servico: 2,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',        
            },
            {
                id_servico: 18,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',        
            },
            {
                id_servico: 1,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',        
            },
            {
                id_servico: 17,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',        
            },                
            {
                id_servico: 3,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',        
            },                
            {
                id_servico: 19,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',        
            },                
        ],
        outros_servicos: {
            valor_diligencia: '',
            valor_protocolo: ''
        }
    }
    
    const [ DTOServices, setDTOServices ] = useState<any>([ServicesDefault])

    const ComarcaDefault = {
        uf: '',
        cidade: '',
        principal: '',
        precificacao: '',
    }
    const [ DTOComarcas, setDTOComarcas ] = useState<any>([ComarcaDefault])

    const [ DTOAccount, setDTOAccount ] = useState({
        nome_titular: "",
        tipo: "",
        cnpj_cpf: "",
        banco: "",
        agencia: "",
        conta: "",
        digito: "",
        tipo_chave_pix: "",
        chave_pix: ""    
    })

    useEffect(() => {     

        handleRemovePrecification(precificationItem);

    }, [excludePrecification])

    useEffect(() => {     

        handleRemoveComarca(comarcaItem);

    }, [deleteComarca])

    function handleOnChange(name: any, value: any) {
        
        if (activePage === 1) {
            
            if (name === 'cpf' || name === 'cnpj' || name === 'celular' || name === 'whatsapp' ) {
                let newValue = value.replace(/\D/g, '');

                const newDTO: any = DTOUser
                newDTO[name] = newValue
                setDTOUser({ ...newDTO })
            } else {

                const newDTO: any = DTOUser
                newDTO[name] = value
                setDTOUser({ ...newDTO })

            }           
            

        }

        if (activePage === 4) {
            
            const newDTO: any = DTOAccount
            newDTO[name] = value
            setDTOAccount({ ...newDTO })            
            
        }

        if (activePage === 5) {

            if (value === 1) {
                toast.success('Plano Jurídico Já Free selecionado.', {
                    style: {
                        padding: '16px',
                        color: '#FA9604',
                    },
                    iconTheme: {
                        primary: '#02E8BE',
                        secondary: '#FFFAEE',
                    },
                });
            }
            if (value === 2) {
                toast.success('Plano Jurídico Já Pro selecionado.', {
                    style: {
                        padding: '16px',
                        color: '#FA9604',
                    },
                    iconTheme: {
                        primary: '#02E8BE',
                        secondary: '#FFFAEE',
                    },
                });
            }
            if (value === 3) {
                toast.success('Plano Jurídico Já Premium selecionado.', {
                    style: {
                        padding: '16px',
                        color: '#FA9604',
                    },
                    iconTheme: {
                        primary: '#02E8BE',
                        secondary: '#FFFAEE',
                    },
                });
            }

            const newDTO: any = DTOUser
            newDTO[name] = value
            setDTOUser({ ...newDTO })       

        }

        if (activePage === 6) {
            
            const newDTO: any = DTOUser
            newDTO[name] = value
            setDTOUser({ ...newDTO })

        }
        
        
    }

    function handleOnChangeComarcas(key:number, name:any, value:string){        

        if ( name === 'principal' ){
            DTOComarcas.map((row:any, key:number) => {
                row.principal = false
            })
        }

        const newDTO:any = DTOComarcas
        newDTO[key][name] = value
        setDTOComarcas([...newDTO])

        if ( name === 'uf' ) getCitiesForUF(DTOComarcas)
    }

    function getCitiesForUF(DTOComarcas:any)
    {
        const newCities:any = []
        DTOComarcas.map((row:any, key:number) => {
            const cityName: any = {
                uf: row.uf,
                docs: []
            }
            axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${row.uf}/municipios`).then(response => {
                response.data.map((row: any) => {
                    cityName.docs.push({
                        value: row.nome,
                        label: row.nome
                    })
                })
                newCities.push(cityName)
                setCities([...newCities])
            });
        })
    }

    function handleOnChangeServices(id: any, name: any, value: any, keyObj: any) {
        
        let newValue = value.replace(/\D/g, '');

        if (newValue.charAt(0) === '0') {
            newValue = newValue.substring(1)               
        }

        if (newValue.length <= 3) {
            newValue = newValue + '.00'
            const newDto = DTOServices
            if (id > 0) {
                const filterDto = newDto.map((row: any) => row.servicos)[keyObj].filter((obj: any) => obj.id_servico === id);
                filterDto[0][name] = newValue
            }

            newDto.map((row: any, key: any) => {
                if (key === keyObj && name === 'titulo') {
                    row.titulo = value
                } else if (key === keyObj && name === 'protocolo') {
                    row.outros_servicos.valor_protocolo = newValue
                } else if (key === keyObj && name === 'diligencia') {
                    row.outros_servicos.valor_diligencia = newValue
                }
                return null
            })
           
        } else {
            let txtArray = newValue.split('')
            txtArray.splice(txtArray.length - 2, 0, ".");
            var correctedValue = txtArray.join('')
            
            const newDto = DTOServices
            
            if(id > 0) {
                const filterDto = newDto.map((row: any) => row.servicos)[keyObj].filter((obj: any) => obj.id_servico === id);
                filterDto[0][name] = correctedValue
            }

            newDto.map((row: any, key: any) => {
                if (key === keyObj && name === 'titulo') {
                    row.titulo = value
                } else if (key === keyObj && name === 'protocolo') {
                    row.outros_servicos.valor_protocolo = correctedValue
                } else if (key === keyObj && name === 'diligencia') {
                    row.outros_servicos.valor_diligencia = correctedValue
                }
                return null
            })
            
        }        
        
    }

    function handleSelect(selected: any) {

        if(activePage === 1) {
            if (selected.value.length > 2) {
                handleOnChange('tipo_oab', selected.value)
            } else {
                handleOnChange('estado_oab', selected.value)
            }
        }

        if (activePage === 3) {
                        
            handleOnChange('cidade', selected.value)
            
        }

        if (activePage === 4) {
            if (selected.value === 'corrente' || selected.value === 'pj' || selected.value === 'poupança' || selected.value === 'pf') {
                handleOnChange('tipo', selected.value)
            } else if (selected.value === 'celular' || selected.value === 'chave aleatória' || selected.value === 'email' || selected.value === 'CPF' || selected.value === 'CPNJ') {
                handleOnChange('tipo_chave_pix', selected.value)
            } else {
                handleOnChange('banco', selected.label)
            }
        }

    }

    const { signIn } = useAuth();

    async function handleSubmit() {
        
        try {

            const user:any = {
                usuario: DTOUser,
                comarcas: DTOComarcas,
                conta: DTOAccount,
                precificacoes: DTOServices
            }

            const response = await api.post('usuario', user)

            if (response.data.status === 'error') {
                throw response.data.result.map((row:any) => {
                    throw row.error
                })
            }

            // await signIn({
            //     email: user.usuario.email,
            //     senha: user.usuario.senha
            // })

            history.push({
                pathname: '/cadastro-completo',
                state: {
                    userName: DTOUser.nome
                }
            })

        } catch (error) {

            console.log('log do erro submit', error)
            
        }

        
    }

    function scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    async function advancePage() {

        try {

            if (activePage === 1) {
                handleOnChange('uf', cepState);
                handleOnChange('cidade', cepCity);
                handleOnChange('bairro', cepCounty);
                handleOnChange('endereco', cepAddress); 

                const schema = yup.object().shape({
                    nome: yup.string().required('Nome é obrigatório'),
                    cpf: yup.string().required('CPF é obrigatório').min(11).max(14),
                    razao_social: yup.string(),
                    inscricao_oab: yup.string().required('Inscrição OAB é obrigatório'),
                    estado_oab: yup.string().required('Estado em que a OAB é registrado é obrigatório'),
                    tipo_oab: yup.string().required('Tipo de inscrição é obrigatório'),
                    celular: yup.string().required('Celular é obrigatório').min(11).max(11),
                    email: yup.string().required('Email é obrigatório').email("Digite um e-mail válido"),
                    whatsapp: yup.string().required('Whatsapp é obrigatório').min(11).max(11),
                    cep: yup.string().required('CEP é obrigatório'),
                    uf: yup.string().required('Estado é obrigatório'),
                    cidade: yup.string().required('Cidade é obrigatório'),
                    bairro: yup.string().required('Bairro é obrigatório'),
                    endereco: yup.string().required('Endereço é obrigatório'),
                    numero: yup.number().required('Numero é obrigatório').integer(),
                });
                await schema.validate(DTOUser, {
                    abortEarly: false,
                });                

                if (checked === false) {

                    throw new Error("É necessário ler e concordar com os termos de uso e condições.")

                } else {
                    handleOnChange('termos', 'sim')
                }

                if (DTOUser.cnpj.length < 14) {
                    handleOnChange('cnpj', '')
                }

                if (DTOUser.tel_recado.length < 13) {
                    handleOnChange('tel_recado', '')
                }

                if (DTOUser.tel_fixo.length < 13) {
                    handleOnChange('tel_fixo', '')
                }
                
                setChecked(false);
            }

            if (activePage === 2) {
                const DTOErrors:any = []

                DTOServices.map((row:any, key:number) => {
                    DTOErrors[key] = {
                        servicos: [],
                        outros_servicos: {}
                    }
                    if ( !row.titulo ) DTOErrors[key].titulo = true
                    if ( !row.outros_servicos.valor_diligencia ) DTOErrors[key].outros_servicos.valor_diligencia = true
                    if ( !row.outros_servicos.valor_protocolo ) DTOErrors[key].outros_servicos.valor_protocolo = true
                    row.servicos.map((servico:any, servicoKey:any) => {
                        if ( servico.valor_advogado <= 0) DTOErrors[key].servicos.push({
                            valor_advogado: true,
                            key: servicoKey                            
                        })
                        if ( servico.valor_preposto <= 0 ) DTOErrors[key].servicos.push({
                            valor_preposto: true,
                            key: servicoKey
                        })
                        if ( servico.valor_total <= 0 ) DTOErrors[key].servicos.push({
                            valor_total: true,
                            key: servicoKey
                        })
                        
                    })
                })

                setAdvancePrecificationError(DTOErrors)  
                DTOErrors.map((row:any) => {
                    if ( 
                        row.titulo === true || 
                        row.servicos.length > 0 || 
                        row.outros_servicos.valor_diligencia === true || 
                        row.outros_servicos.valor_protocolo === true 
                    ) 
                    throw new Error ("Para avançar é necessário preencher com valores válidos todos os campos obrigatórios.")
                })

                const pricing: { value: any; label: any; }[] = []

                DTOServices.map((row: any, key: any) => {
                    pricing.push({
                        value: row.titulo,
                        label: row.titulo
                    })
                })

                const DataPrecification = {
                    precificacoes: DTOServices
                }

                const response = await api.post('precificacao-validar', DataPrecification)
                
                if (response.data.status !== "success") {
                    throw new Error (response.data.message)
                } 
                
                setPricingCounty(pricing)

                if (DTOServices.length < 1) {
                    throw new Error ('É necessário adicionar pelo menos uma precificação')
                }                    
            }

            if (activePage === 3) {
                
                if (DTOComarcas.length < 1) {
                    throw new Error ('É necessário adicionar pelo menos uma Comarca')
                }

                const ComarcaSchema = yup.object().shape({
                    uf: yup.string().required('Escolha um estado'),
                    cidade: yup.string().required('Escolha uma cidade'),
                    precificacao: yup.string().required('Escolha uma precificação'),
                });

                await ComarcaSchema.validate(DTOComarcas[0], {
                    abortEarly: false,
                });

                const Data = {
                    comarcas: DTOComarcas
                }

                const response = await api.post('comarca-validar', Data)

                if (response.data.status !== "success") {
                    throw response.data.message
                }                
                setActivePage(activePage + 1);

                
            }

            if (activePage === 4) {
                
                const AccountSchema = yup.object().shape({
                    nome_titular: yup.string().required('Nome do titular obrigatório'),
                    tipo: yup.string().required('Tipo de conta obrigatório'),
                    banco: yup.string().required('Banco obrigatório'),
                    agencia: yup.string().required('Agência obrigatória'),
                    conta: yup.string().required('Conta obrigatória'),
                    digito: yup.string().required('Digito obrigatório'),
                    tipo_chave_pix: yup.string().required('Tipo de chave pix obrigatório'),
                    chave_pix: yup.string().required('Chave pix obrigatória'),
                });

                await AccountSchema.validate(DTOAccount, {
                    abortEarly: false,
                });
                
                const response = await api.post('conta-validar', DTOAccount)

                if (response.data.status !== "success") {
                    throw response.data.message
                }   
                
                if (selected === 'cpf' && DTOAccount.cnpj_cpf.length < 11) {
                    handleOnChange('cnpj_cpf', '')
                }
                
                setActivePage(activePage + 1);
                

            }

            if (activePage === 5) {

                if (!checked) {
                    throw new Error('É necessário ler e concordar com os termos de pagamento e condições.')
                }

                if (DTOUser.id_plano < 1) {
                    throw new  Error('Escolha um plano antes de continuar')
                }
            }

            if (activePage === 6) {
                const schemaSix = yup.object().shape({
                    senha: yup.string()
                            .required('Senha é obrigatória')
                            .min(6)
                            .matches(
                                /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                                "Crie uma senha forte. Conforme texto acima"
                            ),
                    confirmeSenha:
                        yup.string()
                            .required('Confirmação de senha é obrigatória')
                            .oneOf([yup.ref('senha')], 'As senhas não conferem!'), 
                });                
                
                await schemaSix.validate(DTOUser, {
                    abortEarly: false,
                });

                const response = await api.post('usuario-validar', DTOUser)
                if (response.data.status === 'error') {
                    throw response.data.result.map((row:any) => {
                        throw row.error
                    })
                }
                
                setValidateUser(response.data.status)
                handleSubmit();
            }

            //se passar de todas as validações ele faz isso.
            scrollTop();
            setActivePage(activePage + 1);
            setAdvancePageError({})
            setAdvanceAccountError({})
            setAdvanceCountyError({})
            setAdvancePrecificationError({})


        } catch (err: any) {

            if (err instanceof yup.ValidationError) {
                const errors = getValidationErrors(err);

                setAdvancePageError(errors)
                
                if(activePage === 3) {                    
                    setAdvanceCountyError(errors)                    
                }

                if(activePage === 4) {
                    setAdvanceAccountError(errors)                    
                }

                toast.error('Preencha os campos corretamente.', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
                
            } else {
                if(activePage === 2) {
                    toast.error(err.message, {
                        duration: 2500,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                        },
                        iconTheme: {
                            primary: '#F24343',
                            secondary: '#FFFAEE',
                        },
                    });
                } else if(activePage === 3) {
                    toast.error(err.message, {
                        duration: 2500,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                        },
                        iconTheme: {
                            primary: '#F24343',
                            secondary: '#FFFAEE',
                        },
                    });
                } else if(activePage === 5) {
                    toast.error(err.message, {
                        duration: 2500,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                        },
                        iconTheme: {
                            primary: '#F24343',
                            secondary: '#FFFAEE',
                        },
                    });
                } else {
                    toast.error(err.toString(), {
                        duration: 2500,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                        },
                        iconTheme: {
                            primary: '#F24343',
                            secondary: '#FFFAEE',
                        },
                    });
                }
                
            }

        }
    }

    async function consultCep(name: any, value: any) {
        
        try {
            
            handleOnChange('cep', value);
            const response = await axios.get(`https://viacep.com.br/ws/${value}/json/`)
            const data = response.data
            setCepState(data.uf);
            setCepCity(data.localidade);
            setCepCounty(data.bairro);
            setCepAddress(data.logradouro);            

        } catch (err: any) {

            console.log('err, cep', err)
            
        }

    }  
    
    function handleAddPrecification()
    {
        DTOServices.push(ServicesDefault)
        setDTOServices([...DTOServices])
    }

    function confirmDeletePrecification(item: any) {

        setOpenModalConfirmation(true)
        setPrecificationItem(item)       

    }

    function handleRemovePrecification(indice:number)
    {   
        if (excludePrecification === true) {
            
            const newDTO:any = []
            DTOServices.map((row:any, key:number) => {
                if ( key !== indice  ) newDTO.push(row)
            })
            setDTOServices([...newDTO])
            setOpenModalConfirmation(false)
            setExcludePrecification(false)
        }

    }

    function handleAddComarca()
    {
        DTOComarcas.push(ComarcaDefault)
        setDTOComarcas([...DTOComarcas])
    }

    function confirmDeleteComarca(item: any) {

        setOpenModalDeleteComarca(true)
        setComarcaItem(item)

    }

    function handleRemoveComarca(indice:number)
    {
        const newDTO:any = []
        DTOComarcas.map((row:any, key:number) => {
            if ( key !== indice  ) newDTO.push(row)
        })
        setDTOComarcas([...newDTO])
        setOpenModalDeleteComarca(false);
        setDeleteComarca(false);
    }

    return (
        <Container>                          

            <Header>
                <div className="logo"><IconLogoJuridico /></div>

                <div className="status">
                    <Hr1 
                        className={
                            activePage === 1 ? "first" : '' ||
                            activePage === 2 ? "second" : '' ||
                            activePage === 3 ? "third" : '' ||
                            activePage === 4 ? "fourth" : '' ||
                            activePage === 5 ? "fifth" : '' ||
                            activePage === 6 ? "sixth" : '' 
                        } 
                    />
                    <div 
                        className="status-square first"                        
                    >
                        <div className={"square active"}>
                            <IconProfile />
                        </div>
                        <p className="first">Seus dados</p>
                    </div>
                    <div 
                        className="status-square"
                    >
                        <div className={activePage > 1 ? "square active" : 'square'}>
                            <IconSquarePlus />
                        </div>
                        <p className={activePage > 1 ? 'on' : ''} >Serviços</p>
                    </div>
                    <div 
                        className="status-square"
                    >
                        <div className={activePage > 3 ? "square active" : 'square'}>
                            <IconAccount />
                        </div>
                        <p className={activePage > 3 ? 'on' : ''} >Conta</p>
                    </div>
                    <div 
                        className="status-square"
                    >
                        <div className={activePage > 4 ? "square active" : 'square'}>
                            <IconPlan />
                        </div>
                        <p className={activePage > 4 ? 'on' : ''} >Plano</p>
                    </div>                    
                    <div 
                        className="status-square"
                    >
                        <div className={activePage > 5 ? "square active" : 'square'}>
                            <IconPadlock />
                        </div>
                        <p className={activePage > 5 ? 'on' : ''} >Acesso</p>
                    </div>                    
                </div>
            </Header>

            <Main onClick={() => toast.remove()}>
                <div className="top">
                    {
                        activePage === 1 &&
                        <div className="one" >
                            <div className="register underline">
                                <h3>Cadastre-se como <span>profissional jurídico!</span></h3>
                                <p>Informe abaixo seus dados e confira nossos <span
                                    onClick={() => setOpenModal(true)}
                                >
                                    termos de uso e condições
                                </span>.
                                </p>
                            </div>                        
                        </div>
                    }

                    {
                        activePage === 2 &&
                        <div className="two">
                            <div className="register services">
                                <h3>Precifique seus serviços!</h3>
                                    <p className="title">Entendemos que seu valor pode variar de acordo com a comarca atendida!</p>
                                    <p>
                                        Por isso, no Jurídico Já, <span>você pode atribuir uma precificação diferente para cada região!</span> Ex: preços para capital, região metropolitana e interior.
                                    </p>
                            </div>
                        </div>
                    }

                    {
                        activePage === 3 &&
                        <div className="three">
                            <div className="register">
                                <h3>Adicione comarcas!</h3>
                                <div className="subtitle">
                                    <span>Adicione as comarcas</span>  que deseja atender, <span>atribua a precificação</span> mais adequada a cada uma e <span>indique a sua principal comarca</span> de atuação.
                                </div>
                            </div>
                        </div>
                    }

                    {
                        activePage === 4 &&
                        <div className="four">
                            <div className="register">
                                <h3>Adicione uma conta!</h3>
                                <p>Nos informe abaixo <span>sua conta</span> e <span>chave PIX</span> para receber valores referentes aos serviços prestados:
                                </p>
                            </div>
                        </div>
                    }

                    {
                        activePage === 5 &&
                        <div className="five">
                            <div className="register">
                                <h3>Escolha o melhor plano para você!</h3>

                                <div>
                                    <p>Entraremos em contato pare acertar os detalhes de pagamento.</p> 
                                    <p className="terms">Confira nossos <span
                                        onClick={() => setOpenPaymentTerms(true)}
                                    >termos de pagamento.</span></p>
                                    <p>Em caso de dúvidas, entre em contato: (31) 3565-7918.</p>
                                </div>  
                            </div>
                        </div>
                    }

                    {
                        activePage === 6 &&
                        <div className="six">
                            <div className="register">
                                <h3>Defina uma senha de acesso!</h3>

                                <div>
                                    <p>Escolha um email para efetuar o login:</p> 
                                </div>  
                            </div>
                        </div>
                    }

                    {
                        Object.keys(advancePageError).length > 0 ||
                        Object.keys(advanceAccountError).length > 0 ||
                        Object.keys(advanceCountyError).length > 0 
                        ?
                        <div>
                            <div className="attention">
                                <div className="star"><IconAsteristik /></div>
                                <span>Atenção:</span>
                                <p>Existem campos obrigatórios a serem preenchidos</p>
                            </div>
                        </div>
                        : ''
                    }
                </div>
                

                {   //   pagina 1 - cadastro      
                    activePage === 1 &&
                    <form className="register-form" >

                        <div className="name">
                            <InputDefault
                                title="Seu nome:"
                                name='nome'
                                value={DTOUser.nome}
                                onChangeText={(value) => handleOnChange('nome', value)}
                                className={advancePageError.nome ? 'big error' : 'big'}
                                error={advancePageError.nome ? '*' : ''}
                            />

                            <InputDefault
                                title="CPF:"
                                name="cpf"
                                mask="999.999.999-99"
                                value={DTOUser.cpf}
                                onChangeText={(value) => handleOnChange('cpf', value)}
                                className={advancePageError.cpf ? 'small error' : 'small'}
                                error={advancePageError.cpf ? '*' : ''}
                            />

                            <InputDefault
                                title="Razão Social:"
                                name="razao_social"
                                value={DTOUser.razao_social}
                                onChangeText={(value) => handleOnChange('razao_social', value)}
                                className='big'                                
                            />

                            <InputDefault
                                title="CNPJ:"
                                name="cnpj"
                                mask="99.999.999/9999-99"
                                value={DTOUser.cnpj}
                                onChangeText={(value) => handleOnChange('cnpj', value)}
                                className='small'                               
                            />

                            <InputDefault
                                title="Inscrição na OAB:"
                                name="inscricao_oab"
                                value={DTOUser.inscricao_oab}
                                onChangeText={(value) => handleOnChange('inscricao_oab', value)}
                                className={advancePageError.inscricao_oab ? 'oab error' : 'oab'}
                                error={advancePageError.inscricao_oab ? '*' : ''}
                            />

                            <div className="label-oab">
                                <p>Tipo da inscrição:</p>
                                <Select
                                    name="tipo_oab"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={oabType}
                                    placeholder='Selecionar'                                    
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DDFAF4',
                                            primary50: '#DDFAF4',
                                            primary: '#02E8BE',
                                        },
                                    })}
                                    defaultValue={DTOUser.tipo_oab !== '' ? { value: DTOUser.tipo_oab, label: DTOUser.tipo_oab } : ''}
                                    onChange={handleSelect}
                                    options={inscription}
                                />
                                <span className="error">{advancePageError.tipo_oab ? '*' : ''}</span>
                            </div>

                            <div className="label-state">
                                <p>Estado da OAB:</p>
                                <Select
                                    name="estado_oab"
                                    styles={oabState}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DDFAF4',
                                            primary50: '#DDFAF4',
                                            primary: '#02E8BE',
                                        },
                                    })}
                                    placeholder='Selecionar'
                                    defaultValue={DTOUser.estado_oab !== '' ? { value: DTOUser.estado_oab, label: DTOUser.estado_oab } : ''}
                                    onChange={handleSelect}
                                    options={EstadosBrasileiros}
                                />
                                <span className="error">{advancePageError.estado_oab ? '*' : ''}</span>
                            </div>
                        </div>

                        <div className="fone">
                            <InputDefault
                                title="Celular com DDD:"
                                name="celular"
                                mask="(99) 99999-9999"
                                value={DTOUser.celular}
                                onChangeText={(value) => handleOnChange('celular', value)}
                                className={advancePageError.celular ? 'small error' : 'small'}
                                error={advancePageError.celular ? '*' : ''}
                            />

                            <InputDefault
                                title="Telefone para recado:"
                                name="tel_recado"
                                mask="(99) 9999-9999"
                                value={DTOUser.tel_recado}
                                onChangeText={(value) => handleOnChange('tel_recado', value)}
                                className='small'
                            />

                            <InputDefault
                                title="Telefone fixo:"
                                name="tel_fixo"
                                mask="(99) 9999-9999"
                                value={DTOUser.tel_fixo}
                                onChangeText={(value) => handleOnChange('tel_fixo', value)}
                                className='small'
                            />

                            <InputDefault
                                title="E-mail:"
                                name="email"
                                value={DTOUser.email}
                                onChangeText={(value) => handleOnChange('email', value)}
                                className={advancePageError.email ? 'big error' : 'big'}
                                error={advancePageError.email ? '*' : ''}
                            />

                            <InputDefault
                                title="Whatsapp:"
                                name="whatsapp"
                                mask="(99) 99999-9999"
                                value={DTOUser.whatsapp}
                                onChangeText={(value) => handleOnChange('whatsapp', value)}
                                className={advancePageError.whatsapp ? 'small error' : 'small'}
                                error={advancePageError.whatsapp ? '*' : ''}
                            />
                        </div>

                        <div className="address">
                            <InputDefault
                                title="CEP:"
                                name="cep"
                                mask="99999-999"
                                value={DTOUser.cep}
                                onChangeText={(value) => consultCep('cep', value)}                                
                                className={advancePageError.cep ? 'small error' : 'small'}
                                error={advancePageError.cep ? '*' : ''}
                            />

                            <InputDefault
                                title="Estado:"
                                name="uf"
                                value={cepState}
                                disabled={cepState ? true : false}
                                onChangeText={() => handleOnChange('uf', cepState)}
                                className={advancePageError.uf ? 'small error' : 'small'}
                                error={advancePageError.uf ? '*' : ''}
                            />


                            <InputDefault
                                title="Cidade:"
                                name="cidade"
                                value={cepCity}
                                disabled={cepCity ? true : false}
                                onChangeText={() => handleOnChange('cidade', cepCity)}
                                className={advancePageError.cidade ? 'small error' : 'small'}
                                error={advancePageError.cidade ? '*' : ''}
                            />

                            <InputDefault
                                title="Bairro:"
                                name="bairro"
                                value={cepCounty}
                                disabled={cepCounty ? true : false}
                                onChangeText={(value) => handleOnChange('bairro', cepCounty || value)}
                                className={advancePageError.bairro ? 'small error' : 'small'}
                                error={advancePageError.bairro ? '*' : ''}
                            />

                            <InputDefault
                                title="Endereço:"
                                name="endereco"
                                value={cepAddress}
                                disabled={cepAddress ? true : false}
                                onChangeText={(value) => handleOnChange('endereco', cepAddress || value)}
                                className={advancePageError.endereco ? 'big error' : 'big'}
                                error={advancePageError.endereco ? '*' : ''}
                            />

                            <InputDefault
                                title="Número:"
                                name="numero"
                                value={DTOUser.numero}
                                onChangeText={(value) => handleOnChange('numero', value)}
                                className={advancePageError.numero ? 'number error' : 'number'}
                                error={advancePageError.numero ? '*' : ''}
                            />

                            <InputDefault
                                title="Complemento:"
                                name="complemento"
                                value={DTOUser.complemento}
                                className="comp"
                                onChangeText={(value) => handleOnChange('complemento', value)}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label="Concordo com os termos de uso e condições"
                                name="terms"
                                value={checked}
                                onClick={() => setChecked(!checked)}
                            />
                        </div>

                    </form >
                    
                }

                {   //   pagina 2 - Precificação
                    activePage === 2 &&
                    <form className='services-form' id="services-form">

                        { DTOServices.map((service:any, serviceKey:number) =>
                            <div className={`services ${serviceKey > 0 ? 'line' : 'none'}`}>
                                <div className="services-top">
                                    <InputDefault
                                        title="Título da precificação:"
                                        name="titulo"
                                        placeholder="Ex: Capital ou Interior"
                                        value={DTOServices[serviceKey].titulo}
                                        onChangeText={(value) => handleOnChangeServices(0, 'titulo', value, serviceKey)}
                                        className={advancePrecificationError[serviceKey]?.titulo ? 'big error' : 'big'}
                                        error={advancePrecificationError[serviceKey]?.titulo ? '*' : ''}
                                    />

                                    <button 
                                        type="button"
                                        onClick={() => confirmDeletePrecification(serviceKey)}
                                    ><IconTrash /> Excluir precificação</button>
                                </div>

                                <div className="services-hearing">
                                    <p className="hearing-title">AUDIÊNCIA TRABALHISTA PRESENCIAL</p>
                                    
                                    <div className="hearing-fields">
                                        <InputMoney 
                                            title="Somente Advogado:"
                                            name="presencial1"
                                            placeholder="R$ 00,00"
                                            value={DTOServices[serviceKey].servicos[0].valor_advogado}
                                            onChangeText={(value) => handleOnChangeServices(2, 'valor_advogado', value, serviceKey)}
                                            mask="R$ 999.999,99"
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj: any) => obj.key === 0 && obj.valor_advogado === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj: any) => obj.key === 0 && obj.valor_advogado === true).length > 0 ? '*' : ''}
                                        />

                                        <InputMoney
                                            title="Somente Preposto:"
                                            name="preposto1"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[0].valor_preposto}
                                            onChangeText={(value) => handleOnChangeServices(2, 'valor_preposto', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 0 && obj.valor_preposto === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 0 && obj.valor_preposto === true).length > 0 ? '*' : ''}
                                        />
                                        
                                        <InputMoney
                                            title="Advogado + Preposto:"
                                            name="advprep1"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[0].valor_total}
                                            onChangeText={(value) => handleOnChangeServices(2, 'valor_total', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 0 && obj.valor_total === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 0 && obj.valor_total === true).length > 0 ? '*' : ''}
                                        />
                                    </div>

                                </div>

                                <div className="services-hearing">
                                    <p className="hearing-title">AUDIÊNCIA TRABALHISTA VIRTUAL</p>

                                    <div className="hearing-fields">
                                        <InputMoney
                                            title="Somente Advogado:"
                                            name="virtual1"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[1].valor_advogado}
                                            onChangeText={(value) => handleOnChangeServices(18, 'valor_advogado', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 1 && obj.valor_advogado === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 1 && obj.valor_advogado === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Somente Preposto:"
                                            name="preposto2"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[1].valor_preposto}
                                            onChangeText={(value) => handleOnChangeServices(18, 'valor_preposto', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 1 && obj.valor_preposto === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 1 && obj.valor_preposto === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Advogado + Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="advprep2"
                                            value={DTOServices[serviceKey].servicos[1].valor_total}
                                            onChangeText={(value) => handleOnChangeServices(18, 'valor_total', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 1 && obj.valor_total === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 1 && obj.valor_total === true).length > 0 ? '*' : ''}
                                        />
                                    </div>

                                </div>

                                <div className="services-hearing">
                                    <p className="hearing-title">AUDIÊNCIA CÍVEL PRESENCIAL</p>
                                    
                                    <div className="hearing-fields">
                                        <InputMoney
                                            title="Somente Advogado:"
                                            name="presencial2"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[2].valor_advogado}
                                            onChangeText={(value) => handleOnChangeServices(1, 'valor_advogado', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_advogado === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_advogado === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Somente Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="preposto3"
                                            value={DTOServices[serviceKey].servicos[2].valor_preposto}
                                            onChangeText={(value) => handleOnChangeServices(1, 'valor_preposto', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_preposto === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_preposto === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Advogado + Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="advprep3"
                                            value={DTOServices[serviceKey].servicos[2].valor_total}
                                            onChangeText={(value) => handleOnChangeServices(1, 'valor_total', value, serviceKey) }                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_total === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_total === true).length > 0 ? '*' : ''}
                                        />
                                    </div>
                                </div>

                                <div className="services-hearing">
                                    <p className="hearing-title">AUDIÊNCIA CÍVEL VIRTUAL</p>

                                    <div className="hearing-fields">
                                        <InputMoney
                                            title="Somente Advogado:"
                                            name="virtual2"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[3].valor_advogado}
                                            onChangeText={(value) => handleOnChangeServices(17, 'valor_advogado', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_advogado === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_advogado === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Somente Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="preposto4"
                                            value={DTOServices[serviceKey].servicos[3].valor_preposto}
                                            onChangeText={(value) => handleOnChangeServices(17, 'valor_preposto', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_preposto === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_preposto === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Advogado + Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="advprep4"
                                            value={DTOServices[serviceKey].servicos[3].valor_total}
                                            onChangeText={(value) => handleOnChangeServices(17, 'valor_total', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_total === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_total === true).length > 0 ? '*' : ''}
                                        />
                                    </div>
                                </div>

                                <div className="services-hearing">
                                    <p className="hearing-title">AUDIÊNCIA PENAL PRESENCIAL</p>

                                    <div className="hearing-fields">
                                        <InputMoney
                                            title="Somente Advogado:"
                                            name="virtual2"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[4].valor_advogado}
                                            onChangeText={(value) => handleOnChangeServices(3, 'valor_advogado', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_advogado === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_advogado === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Somente Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="preposto4"
                                            value={DTOServices[serviceKey].servicos[4].valor_preposto}
                                            onChangeText={(value) => handleOnChangeServices(3, 'valor_preposto', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_preposto === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_preposto === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Advogado + Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="advprep4"
                                            value={DTOServices[serviceKey].servicos[4].valor_total}
                                            onChangeText={(value) => handleOnChangeServices(3, 'valor_total', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_total === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_total === true).length > 0 ? '*' : ''}
                                        />
                                    </div>
                                </div>

                                <div className="services-hearing">
                                    <p className="hearing-title">AUDIÊNCIA PENAL VIRTUAL</p>

                                    <div className="hearing-fields">
                                        <InputMoney
                                            title="Somente Advogado:"
                                            name="virtual2"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[5].valor_advogado}
                                            onChangeText={(value) => handleOnChangeServices(19, 'valor_advogado', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_advogado === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_advogado === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Somente Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="preposto4"
                                            value={DTOServices[serviceKey].servicos[5].valor_preposto}
                                            onChangeText={(value) => handleOnChangeServices(19, 'valor_preposto', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_preposto === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_preposto === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Advogado + Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="advprep4"
                                            value={DTOServices[serviceKey].servicos[5].valor_total}
                                            onChangeText={(value) => handleOnChangeServices(19, 'valor_total', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_total === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_total === true).length > 0 ? '*' : ''}
                                        />
                                    </div>
                                </div>

                                <div className="services-hearing">
                                    <p className="hearing-title">OUTROS SERVIÇOS</p>

                                    <div className="hearing-fields">
                                        <InputMoney
                                            title="Diligências:"
                                            name="diligencias"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].outros_servicos.valor_diligencia}
                                            onChangeText={(value) => handleOnChangeServices(0, 'diligencia', value, serviceKey) }                                            
                                            className={advancePrecificationError[serviceKey]?.outros_servicos?.valor_diligencia ? 'small error' : 'small'}
                                            error={advancePrecificationError[serviceKey]?.outros_servicos?.valor_diligencia ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Protocolos:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="protocolos"
                                            value={DTOServices[serviceKey].outros_servicos.valor_protocolo}
                                            onChangeText={(value) => handleOnChangeServices(0, 'protocolo', value, serviceKey) }                                            
                                            className={advancePrecificationError[serviceKey]?.outros_servicos?.valor_protocolo ? 'small error' : 'small'}
                                            error={advancePrecificationError[serviceKey]?.outros_servicos?.valor_protocolo ? '*' : ''}
                                        />                                        
                                    </div>
                                </div>

                            </div>
                        )}

                        <div className="dot-border" onClick={handleAddPrecification}><IconPlus /> Adicionar precificação</div>

                        <div className="footer">
                            <p>Contrataremos a partir das precificações que você acaba de criar!</p>
                            <p>Você pode alterá-las nas configurações da sua conta sempre que quiser.</p>
                        </div>

                    </form>
                }

                { // pagina 3 - comarca
                    activePage === 3 &&
                        <form className='county-form'>

                            { DTOComarcas.map((row:any, key:number) =>
                                <div className={`comarca ${key > 0 ? 'line' : 'none'}`} key={key}>

                                    <div className="county">
                                        <div className="label">
                                            <p>Estado da comarca:</p>
                                            <Select
                                                styles={countyState}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 8,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#DDFAF4',
                                                        primary50: '#DDFAF4',
                                                        primary: '#02E8BE',
                                                    },
                                                })}
                                                name='estado_comarca'
                                                placeholder="Selecionar"
                                                defaultValue={row.uf !== '' ? {value: row.uf, label: row.uf} : ''}
                                                options={EstadosBrasileiros}
                                                onChange={(e:any) => handleOnChangeComarcas(key, 'uf', e.value)}
                                            />
                                            <span className="error">{advanceCountyError.uf ? '*' : ''}</span>
                                        </div>

                                        { cities.filter((obj:any) => obj.uf === row.uf )[0]?.docs.length > 0 &&
                                            <div className="label">
                                                <p>Cidade da comarca:</p>
                                                <Select
                                                    styles={countyCity}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 8,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DDFAF4',
                                                            primary50: '#DDFAF4',
                                                            primary: '#02E8BE',
                                                        },
                                                    })}
                                                    name='cidade'
                                                    placeholder="Selecionar"
                                                    defaultValue={row.cidade !== '' ? {value: row.cidade, label: row.cidade} : ''}
                                                    options={cities.filter((obj:any) => 
                                                        obj.uf === row.uf
                                                    )[0]?.docs}
                                                    onChange={(e:any) => handleOnChangeComarcas(key, 'cidade', e.value)}
                                                />
                                                <span className="error">{advanceCountyError.cidade ? '*' : ''}</span>
                                            </div>
                                        }
                                    </div>

                                    <div className="pricing">
                                        <div className="label">
                                            <p>Precificação :</p>
                                            <Select
                                                styles={oabPrice}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 8,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#DDFAF4',
                                                        primary50: '#DDFAF4',
                                                        primary: '#02E8BE',
                                                    },
                                                })}
                                                placeholder="Selecionar"
                                                defaultValue={row.precificacao !== '' ? { value: row.precificacao, label: row.precificacao } : ''}
                                                options={pricingCounty}
                                                onChange={(e:any) => handleOnChangeComarcas(key, 'precificacao', e.value)}
                                            />
                                            <span className="error">{advanceCountyError.precificacao ? '*' : ''}</span>
                                        </div>

                                        <div className="right">
                                            <div className="radio-input">
                                                <input 
                                                    type="radio" 
                                                    value="sim"
                                                    name="Principal" 
                                                    checked={row.principal}                                           
                                                    onChange={(e:any) => handleOnChangeComarcas(key, 'principal', 'true')}
                                                    placeholder="Principal" 
                                                />
                                                <span>Principal</span>                                    
                                            </div>

                                            <button onClick={() => confirmDeleteComarca(key)} type="button"><IconTrash /> Excluir comarca</button>
                                        </div>

                                    </div>
                                </div>
                            )}

                            <div className="dot-border" onClick={handleAddComarca}><IconPlus /> Adicionar comarca</div>

                        </form>
                        // <CountyRegister />
                }

                {   // pagina 4 - Conta bancária
                    activePage === 4 &&
                    <form className='bank-account-form' >

                        <div className="account-data">
                            
                            <InputDefault
                                title="Nome do titular:"
                                placeholder="Nome"
                                name="nome_titular"
                                value={DTOAccount.nome_titular}
                                onChangeText={(value) => handleOnChange('nome_titular', value)}
                                className={advanceAccountError.nome_titular ? 'big error' : 'big'}
                                error={advanceAccountError.nome_titular ? '*' : ''}
                            />                            

                            <div className="label">
                                <p>Tipo de conta:</p>
                                <Select
                                    styles={accountSelect}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DDFAF4',
                                            primary50: '#DDFAF4',
                                            primary: '#02E8BE',
                                        },
                                    })}
                                    name='tipo'
                                    placeholder="Selecionar"
                                    defaultValue={DTOAccount.tipo !== '' ? { value: DTOAccount.tipo, label: DTOAccount.tipo } : ''}
                                    onChange={handleSelect}
                                    options={accounts}
                                />
                                <span className="error">{advanceAccountError.tipo ? '*' : ''}</span>
                            </div>
                            
                            <div className="select">
                                <div className="title">Escolha:</div>
                                <div className="options">
                                    <div className="input-radio">
                                        <input 
                                            type={'radio'}
                                            placeholder='CPF'
                                            value='CPF'
                                            checked={selected === 'cpf' ? true : false}
                                            onChange={() => setSelected('cpf')}
                                        />
                                        CPF
                                    </div>
                                    <div className="input-radio"> 
                                        <input 
                                            type={'radio'}
                                            placeholder='cnpj'
                                            value='CNPJ'
                                            checked={selected === 'cnpj' ? true : false}
                                            onChange={() => setSelected('cnpj')}
                                        /> 
                                        CNPJ                                        
                                    </div>
                                </div>
                            </div>
                            {
                                selected === 'cnpj' &&
                                <InputDefault
                                    title="CNPJ:"
                                    mask="99.999.999/9999-99"
                                    placeholder="CNPJ"
                                    name="cnpj"
                                    value={DTOAccount.cnpj_cpf}
                                    onChangeText={(value) => handleOnChange('cnpj_cpf', value)}
                                    className={advanceAccountError.cnpj ? 'big error' : 'big'}
                                    error={advanceAccountError.cnpj ? '*' : ''}
                                />
                            }
                            {
                                selected === 'cpf' &&
                                <InputDefault
                                    title="CPF:"
                                    mask="999.999.999-99"
                                    placeholder="CPF"
                                    name="cpf"
                                    value={DTOAccount.cnpj_cpf}
                                    onChangeText={(value) => handleOnChange('cnpj_cpf', value)}
                                    className={advanceAccountError.cnpj ? 'big error' : 'big'}
                                    error={advanceAccountError.cnpj ? '*' : ''}
                                />
                            }
                        </div>

                        <div className="bank-data">
                            <div className="label">
                                <p>Banco:</p>
                                <Select
                                    styles={bankSelect}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DDFAF4',
                                            primary50: '#DDFAF4',
                                            primary: '#02E8BE',
                                        },
                                    })}
                                    name='banco'
                                    placeholder="Selecionar"
                                    defaultValue={DTOAccount.banco !== '' ? { value: DTOAccount.banco, label: DTOAccount.banco } : ''}
                                    onChange={handleSelect}
                                    options={brazilianBanks}
                                />
                                <span className="error">{advanceAccountError.banco ? '*' : ''}</span>
                            </div>                            
                            <InputDefault
                                title="Agência:"
                                placeholder="0000"
                                name="agencia"
                                value={DTOAccount.agencia}
                                onChangeText={(value) => handleOnChange('agencia', value)}
                                className={advanceAccountError.agencia ? 'big error' : 'big'}
                                error={advanceAccountError.agencia ? '*' : ''}
                            />
                            <InputDefault
                                title="Conta:"
                                placeholder="0000000"
                                name="conta"
                                value={DTOAccount.conta}
                                onChangeText={(value) => handleOnChange('conta', value)}
                                className={advanceAccountError.conta ? 'big error' : 'big'}
                                error={advanceAccountError.conta ? '*' : ''}
                            />
                            <InputDefault
                                title="Dígito:"
                                placeholder="00"
                                name="digito"
                                value={DTOAccount.digito}
                                onChangeText={(value) => handleOnChange('digito', value)}
                                className={advanceAccountError.digito ? 'small error' : 'small'}
                                error={advanceAccountError.digito ? '*' : ''}
                            />
                        </div>

                        <div className="pix-data">
                            <div className="label">
                                <p>Tipo de chave do PIX:</p>
                                <Select
                                    styles={pixKey}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DDFAF4',
                                            primary50: '#DDFAF4',
                                            primary: '#02E8BE',
                                        },
                                    })}
                                    name='tipo_chave_pix'
                                    placeholder="Selecionar"
                                    defaultValue={DTOAccount.tipo_chave_pix !== '' ? { value: DTOAccount.tipo_chave_pix, label: DTOAccount.tipo_chave_pix } : ''}
                                    onChange={handleSelect}
                                    options={PixKeyData}
                                />
                                <span className="error">{advanceAccountError.tipo_chave_pix ? '*' : ''}</span>
                            </div>     

                            <InputDefault
                                title="Chave PIX:"
                                placeholder="Chave pix"
                                name="chave_pix"
                                value={DTOAccount.chave_pix}                                
                                onChangeText={(value) => handleOnChange('chave_pix', value)}
                                className={advanceAccountError.chave_pix ? 'error pix-key' : 'pix-key'}
                                error={advanceAccountError.chave_pix ? '*' : ''}
                            />
                        </div>
                    </form>
                }

                {   // pagina 5 - Escolha de plano
                    activePage === 5 &&
                    <div>
                        <div className="page-five">
                            <Checkbox
                                label="Concordo com os termos de pagamento e condições"
                                name="terms"
                                value={checked}
                                onClick={() => setChecked(!checked)}
                            />
                        </div>
                        <RegisterPage5 selected={DTOUser.id_plano} setCard={(value: any) => handleOnChange('id_plano', value)} />
                    </div>
                }

                {   // pagina 6 - Senha  
                    activePage === 6 &&
                    <form className="login-form">
                        <div className="wrapper-mail">
                            <InputDefault
                                title="E-mail:"
                                placeholder="E-mail"
                                value={DTOUser.email}
                                onChangeText={(value) => handleOnChange('email', value)}
                                className='email'
                            />
                        </div>

                        <div className="pass-text">
                            <p>Crie uma senha forte. Utilize ao menos 6 dígitos, contendo 1 letra maíuscula, 1 número e 1 caractere especial.</p>
                        </div>

                        <div className="email-pass">

                            <div className="password">
                                <InputDefault
                                    title="Crie uma senha:"
                                    placeholder="senha"
                                    name="senha"
                                    value={DTOUser.senha}
                                    type={changeVisibility ? 'password' : 'text'}
                                    onChangeText={(value) => handleOnChange('senha', value)}
                                    className={advancePageError.senha || advancePageError.confirmeSenha ? 'empty' : ''}
                                    error={advancePageError.senha ? advancePageError.senha : ''}                               
                                />
                                <div
                                    className="icon"
                                    onClick={() => setChangeVisibility(!changeVisibility)}
                                >
                                    {changeVisibility ? <IconEyeOpen /> : <IconClosedEye />}
                                </div>
                            </div>
                            
                            <div className="password">
                                <InputDefault
                                    title="Confirme a senha:"
                                    placeholder="senha"
                                    name="confirmeSenha"
                                    type={changeVisibility ? 'password' : 'text'}
                                    value={DTOUser.confirmeSenha}
                                    onChangeText={(value) => handleOnChange('confirmeSenha', value)}
                                    className={advancePageError.confirmeSenha ? 'empty' : ''}
                                    error={advancePageError.confirmeSenha ? advancePageError.confirmeSenha : ''}
                                />
                                <div
                                    className="icon"
                                    onClick={() => setChangeVisibility(!changeVisibility)}
                                >
                                    {changeVisibility ? <IconEyeOpen /> : <IconClosedEye />}
                                </div>
                            </div>                            
                        </div>
                    </form>
                }
               
            </Main>

            <Footer>
                <div className="container">
                    <div className="buttons">
                        {
                            activePage === 1 &&
                                <ButtonDefault
                                    className="cancel"
                                    onClick={() => history.push('/')}
                                >
                                    Cancelar
                                </ButtonDefault>
                        }
                        {
                            activePage > 1 &&
                            <ButtonDefault
                                className="cancel"
                                onClick={() => setActivePage(activePage - 1)}
                            >
                                Voltar
                            </ButtonDefault>
                        }

                        <ButtonDefault
                            className="continue"
                            type="submit"
                            onClick={() => advancePage()}
                        >
                            Continuar
                        </ButtonDefault>
                    </div>

                    <div className="progress">
                        <div className="progressbar">
                            <div className={
                                activePage === 1 ? "percent" : '' ||
                                activePage === 2 ? "percent two" : '' ||
                                activePage === 3 ? "percent three" : '' ||
                                activePage === 4 ? "percent four" : '' ||
                                activePage === 5 ? "percent five" : '' ||
                                activePage === 6 ? "percent six" : ''
                                }>
                            </div>
                        </div>
                        <div>
                            <span>
                                {
                                    activePage === 1 ? "20%" : '' ||
                                    activePage === 2 ? "35%" : '' ||
                                    activePage === 3 ? "50%" : '' ||
                                    activePage === 4 ? "75%" : '' ||  
                                    activePage === 5 ? "90%" : '' ||  
                                    activePage === 6 ? "98%" : ''   
                                }
                            </span> do perfil completo
                        </div>
                    </div>
                </div>
            </Footer>  
            
            {/* Modal termos para cadastro JurídicoJá */}
            <ModalTerms
                id={'overlayModal'}
                onClose={() => setOpenModal((openModal) => !openModal)}
                openModal={openModal}
                width="100%"
            >
                <ModalHeader>
                    <div><IconLogoJuridico /></div>

                    <button
                        onClick={() => setOpenModal(false)}
                    >
                        <IconClose />
                    </button>

                </ModalHeader>

                <ModalMain>
                    <div className="title">
                        <p>Termos e condições</p>
                        para se tornar um <span className="pro">profissional jurídico</span> <span className="now">Já!</span>
                    </div>

                    <p>
                        Os Termos e Condições gerais de uso apresentados se aplicam aos serviços prestados pela plataforma JURÍDICO JÁ, empresa devidamente inscrita no CNPJ sob o nº 30.660.444/0001-14, situada na Rua Paraíba, nº 330, Sala 1205, Funcionários, Belo Horizonte/MG, bem como seus colaboradores por meio do site www.juridicoja.com, aplicativo e endereços de e-mail. Para suporte entre em contato com contato@juridicoja.com.
                    </p>

                    <p>
                        Qualquer usuário, doravante nominados CONTRATANTE ou CORRESPONDENTE, que pretenda utilizar a plataforma poderão ou não aceitar os Termos e Condições Gerais e todas as demais políticas e princípios que o regem. Caso optem por não aceitar basta não fazer uso da plataforma. A sua aceitação é indispensável à utilização, devendo o usuário ler com atenção, certificar-se de haver entendido e aceitar todas as condições estabelecidas nos Termos e Condições Gerais, nas Políticas de privacidade, Termos de Serviço e Termo de Parceiros, além de outras regras que possam ser aplicáveis, tais como, mas não somente, (i) Código de Ética e Disciplina da Ordem dos Advogados do Brasil (ii) Lei nº 8.904/1994 (“Estatuto da Ordem dos Advogados do Brasil”), (iii) a Lei nº 8.078/1990 (“Código de Defesa do Consumidor”), (iv) Lei nº 10.406/2002 (“Código Civil Brasileiro”), antes de concluir o cadastro.
                    </p>

                    <p className="two">
                        Ao acessar/cadastrar/utilizar a plataforma JURÍDICO JÁ o Utilizador declara ser civilmente capaz nos termos da Lei nº 10.406/2002 (“Código Civil Brasileiro”) para compreender, aceitar e cumprir os Termos de Uso e a Política de Privacidade. No caso de pessoas jurídicas, estas devem estar devidamente representadas. No ato da realização do cadastro o Usuário em nome de pessoa jurídica declara estar apto para representá-la nos termos que seguem e em outras ações relacionadas ao uso do site e aplicativo Jurídico Já. O Usuário responderá solidariamente perante a Plataforma por violações aos Termos ou das legislações vigentes aplicáveis. O cadastro de utilizador com idade inferior a 18 (dezoito) anos não será de responsabilidade da Plataforma e sim de seus responsáveis legais, respondendo inclusive por danos eventualmente causados e ou serviços contratados.
                    </p>

                    <p className="two">
                        Os Termos de Uso citados poderão sofrer mudanças a qualquer momento, bem como as funcionalidades do sistema, sem aviso prévio. O utilizador será notificado a aceitar os termos atualizados e estando em desacordo com as alterações deverá encerrar sua conta imediatamente. Caso não seja feito incidirá em aceitação automática do novo regulamento. A alteração dos termos poderá ser substituída por avisos e notificações dispostos no site e no aplicativo da plataforma. As alterações não vigorarão sob as negociações já iniciadas antes da publicação, sendo, neste caso, vigente a redação anterior.
                    </p>

                    <p>DAS PARTES</p>
                    <p>
                        De um lado, JURIDICO JA TECNOLOGIA E SERVICOS DE INTERNET LTDA, denominada JURÍDICO JÁ ou simplesmente “Plataforma”, acima já qualificada, e de outro lado, Advogado Correspondente ou outras pessoas físicas ou jurídicas que desejem atuar como correspondente, todos denominados neste ato CORRESPONDENTE(S) que são advogados, pessoas físicas ou jurídicas em geral que atuem prestando serviços ou escritórios de advocacia, doravante denominados CONTRATANTE, pessoa física ou jurídica que solicitam realização e serviços na plataforma ou email, afirmam terem pactuado entre si o disposto.
                    </p>
                    <p>
                        Estes Termos também se aplicam a todos os estudantes, pessoas físicas, advogados em formação, que busquem ofertar ou contratar serviços. É recomendável que os denominados ESTUDANTES possuam a Carteira de Estagiário da Ordem dos Advogados do Brasil (“OAB-E”) para prestarem serviços com excelência.
                    </p>
                    <p className="two">
                        A parte que acessa a plataforma de modo geral será denominada nestes termos como Usuário ou Utilizador.
                    </p>

                    <p>DO OBJETO</p>
                    <p>
                        A Jurídico Já oferta serviços direcionados para a realização de diligências, pela qual advogados e sociedades de advogados, Correspondente ou Contratante (conforme item 1), que estejam interessados na realização ou contratação de demandas e atividades de suporte à prática jurídica o façam através da plataforma. O Contratante terá a sua disposição, sempre que disponíveis na comarca, Correspondentes para atendimento de acordo com o pactuado entre as partes.
                    </p>
                    <p>
                        A Plataforma, quando utilizada apenas como meio de contratação, não será parte ativa na contratação de correspondentes. A Jurídico Já se responsabiliza apenas por disponibilizar a listagem de serviços e colaboradores para que o Contratante encontre e efetue a contratação do Correspondente com eficácia e rapidez. Portanto, a Jurídico Já não estará envolvida no negócio jurídico praticado entre as partes denominadas Contratante e Correspondente, bem como não será responsável pela aquisição de direitos ou obrigações originadas pela contratação através da plataforma, como pagamento de honorários ou cumprimento das diligências contratadas.
                    </p>
                    <p>
                        A Plataforma ainda não se responsabiliza pela qualidade dos serviços ofertados pelos correspondentes, pela veracidade de informações fornecidas pelas partes. E;
                    </p>

                    <p>
                        Pela observância do disposto em: Código de Ética e Disciplina da Ordem dos Advogados do Brasil, Lei nº 8.904/1994 (“Estatuto da Ordem dos Advogados do Brasil”) e Tabela de Honorários da Ordem dos Advogados do Brasil. No entanto, a Jurídico Já recomenda que a utilização do site e aplicativo não esteja em desacordo com os dispositivos no que se refere à prestação de serviços jurídicos.
                    </p>

                    <p>
                        A Plataforma declara que não há relação trabalhista entre as partes que pactuam entre si, bem como não há vínculo societário, agenciamento, consórcio, ou relação de qualquer natureza que não seja apenas o negócio jurídico.
                    </p>

                    <p className="two">
                        Contratações que ocorram em desacordo com o objetivo desta plataforma não serão de responsabilidade da empresa. Contudo, a Jurídico Já se reserva no direito de atuar nestes casos, a fim de impedir a transgressão dos termos estabelecidos.
                    </p>

                    <p>
                        DA PLATAFORMA
                    </p>
                    <p>
                        A Jurídico Já irá se empenhar a fim de garantir a completa disponibilidade, na medida do possível, da plataforma do Jurídico Já e dos serviços ofertados. Porém, a Plataforma não se responsabiliza por indisponibilidade decorrente de falhas no sistema, seja em períodos longos ou curtos.
                    </p>

                    <p>
                        Os usuários devem estar cientes de que a qualquer momento o acesso pode ser interrompido temporariamente sem aviso prévio em caso de falhas, manutenção ou alterações no sistema.
                    </p>

                    <p>
                        O Jurídico Já não se responsabiliza por danos causados em caso de prejuízo, perda ou danos em função da interrupção do sistema.
                    </p>

                    <p>
                        Para tanto, recomenda-se que os usuários mantenham forma sobressalente de contratação, sendo certo que não haverá indenização ou reparação por parte do Jurídico Já, sendo isenta a responsabilidade da empresa.
                    </p>

                    <p>
                        O Jurídico Já não se responsabiliza por qualquer vírus que possa atacar o equipamento do usuário em decorrência do acesso, utilização ou navegação na internet ou como consequência da transferência de dados, arquivos, imagens, textos vídeos ou áudio. Todavia os Usuários se comprometem a fazer o uso de arquivos livres de vírus ou tecnicamente danosos, incluindo, mas não se limitando a vírus como "cavalo de Tróia", worms, macros, componentes maliciosos, dados corrompidos e outros programas maliciosos ou projetados para alterar o bom funcionamento do sistema ou operações comuns de computadores.
                    </p>

                    <p>
                        As publicações feitas por Usuários em forma de artigos ou textos não representam a opinião ou posição da Empresa que não possui responsabilidade em relação ao conteúdo disponibilizado por terceiros.
                    </p>

                    <p>
                        A reprodução de conteúdo depende de autorização prévia e permissão expressa dos detentores de direitos e, quando couber, da Jurídico Já. Caso algum Usuário identifique algum conteúdo que se comprove plágio ou invasão de direito de terceiro solicitamos que encaminhe uma denúncia para contato@juridicoja.com.
                    </p>

                    <p>
                        A Plataforma poderá realizar triagens periódicas no servidor acerca dos conteúdos publicados, a critério exclusivo, o que não significará concordância automática com o disponibilizado.
                    </p>

                    <p>
                        De acordo com os Termos, os Usuários assumem a obrigação de usar o site e o aplicativo de forma positiva, respeitando o princípio da boa-fé e sem enviar ao sistema material que seja ameaçador, violento, difamatório, pornográfico, abusivo, ofensivo, racista, discriminatório ou em desacordo com os direitos fundamentais resguardados pelo texto constitucional de 1988 ("Constituição da República Federativa do Brasil") ou outra legislação vigente;
                    </p>

                    <p>
                        O Usuário ainda manifesta que só fará uso de materiais que tenham todas licenças e/ou aprovações necessárias;
                    </p>

                    <p>
                        Não irá incitar condutas que possam ser consideradas ilícitas na esfera civil e criminal ou que viole direito de terceiros em território nacional ou internacional, ou ilegais de qualquer forma;
                    </p>

                    <p>
                        Não fará o uso de fraudes ou dissimulações a fim de viciar as contratações ou escolhas dos Usuários ou funcionamento do sistema, como violações de segurança, protocolos, perfis falsos, ou qualquer outro meio.
                    </p>

                    <p>
                        Os Usuários que não agirem em conformidade e zelo para o bom funcionamento da plataforma serão responsabilizados por qualquer danos e prejuízos que causarem.
                    </p>

                    <p>
                        A Plataforma informa que irá cooperar com autoridades que necessitarem identificar Usuários que estejam em desconformidade com os dispostos nestes Termos.
                    </p>

                    <p>
                        O Usuário assume total responsabilidade por prejuízos, diretos ou indiretos, incluindo indenizações, lucros cessantes, honorários advocatícios e demais encargos judiciais ou extrajudiciais que o Jurídico Já seja obrigado a incorrer em razão de ato ou omissão do Usuário.
                    </p>

                    <p>
                        O Jurídico Já terá direito a indenização por danos causados pelo Usuário decorrentes de atividades no site ou por descumprimento dos termos gerais de uso e demais políticas, ou direitos de terceiros, incluindo honorários de advogados.
                    </p>

                    <p>
                        A manutenção do conteúdo será feita a critério exclusivo da Plataforma, sem que caracterize violação aos direitos adquiridos pelos Usuários.
                    </p>

                    <p>
                        Em caso de descumprimento dos dispostos nestes Termos por parte da Jurídico Já não deverá ser interpretado como desconsideração dos constantes neste dispositivo.
                    </p>
                    <p>
                        A plataforma está em acordo com todas as leis e tratados vigentes em território brasileiro. Caso o Usuário utilize a plataforma em outro local, estará sujeito ao cumprimento das normas locais.
                    </p>

                    <p className="two">
                        Para manter a qualidade dos serviços ofertados pela plataforma, a Jurídico Já possui o direito de impedir ou interromper acesso de determinado Usuário que atue em desconformidade com o que consta nos Termos e Condições de Uso, da Política de Privacidade ou contratos que se celebrem através do uso da plataforma, sem a necessidade de notificação prévia.
                    </p>

                    <p>DA PROPRIEDADE INTELECTUAL E PRIVACIDADE</p>

                    <p>
                        O uso do nome “Jurídico Já”, bem como os conteúdos que o acompanhe na prática empresarial como nome empresarial, marca, domínio, programas, banco de dados, documentos, são de propriedade exclusiva do Jurídico Já e estão protegidos por leis e tratados, visto registro em órgão competente. A reprodução ou uso sem a devida autorização legal é expressamente proibido, acarretando responsabilidade penal e civil.
                    </p>
                    <p>
                        A página dos usuários pode expor links de outros sites, o que não significa que o Jurídico Já é proprietário dos referidos.
                    </p>

                    <p>
                        A Jurídico Já não possui responsabilidade pelo conteúdo, prática e serviços ofertados em site de terceiros, ainda que apresentados em sua plataforma.
                    </p>
                    <p>
                        Os links de outros sites apresentados na plataforma não incidem em relação de sociedade, supervisão, cumplicidade, solidariedade ou posse.
                    </p>
                    <p>
                        Parte das informações disponíveis são geradas e transmitidas por terceiros, sem o envolvimento ou responsabilidade da Plataforma.
                    </p>
                    <p>
                        A Jurídico Já se compromete a nunca alterar o conteúdo que for produzido por terceiros.
                    </p>
                    <p className="two">
                        A privacidade dos Usuários está protegida, podendo os dados pessoais serem usados somente com consentimento prévio do usuário. No entanto, há exceções por razões práticas onde o Jurídico Já se reserva no direito de fazer o uso dessas informações quando necessário se não houver meios de obter o consentimento prévio.
                    </p>


                    <p>OUTRAS INFORMAÇÕES</p>

                    <p className="two">
                        Fica eleito o Foro da cidade de Belo Horizonte no Estado de Minas Gerais para resolução de controvérsias. Entretanto, em conformidade com o artigo 101 do Código de Defesa do Consumidor, serão feitas exceções àquelas reclamações de usuários que se enquadrem legalmente como consumidores, podendo suas queixas serem submetidas ao foro da cidade que residirem, caso não ocorra solução amigável entre as partes.
                    </p>


                    <p>TERMOS E CONDIÇÕES DOS USUÁRIOS</p>

                    <p className="two">
                        Uma vez conectados na plataforma da empresa Jurídico Já por meio de uma Conta de Usuário Contratante, os utilizadores deverão zelar pelo cumprimento dos Termos e Condições de Uso cabíveis as partes.
                    </p>

                    <p>USUÁRIO CONTRANTE</p>

                    <p>DA RELAÇÃO</p>

                    <p className="two">
                        O Contratante, expressamente qualificado nestes Termos, afirma e reconhece que não há vínculos além do negócio jurídico, reiterando que não existe relação trabalhista ou societária. A prestação de serviço por parte da Jurídico Já se dá de igual forma para o Contratante e para o Correspondente.
                    </p>

                    <p>DO CADASTRO</p>

                    <p>Cada usuário poderá manter somente um cadastro junto a plataforma Jurídico Já.</p>
                    <p>O cadastro é pessoal e intransferível.</p>
                    <p>
                        Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra forma de transferência da conta. Também não se permitirá a criação de novos cadastros por pessoas cujos cadastros originais tenham sido suspensos de alguma forma por infração às políticas do site.
                    </p>
                    <p>
                        O cadastro é confirmado após o usuário preencher todos os campos obrigatórios, com informações exatas, precisas e verdadeiras. O usuário declarara e assume o compromisso de atualizar os dados inseridos em seu cadastro sempre que necessário ou tão logo houver modificações.
                    </p>
                    <p>
                        Os Usuários não devem cadastrar apelidos que possuam qualquer semelhança com o nome Jurídico Já ou qualquer outro nome de propriedade intelectual, tampouco deverá insinuar ou sugerir que os serviços anunciados na plataforma são de autoria da Jurídico Já. Também serão desconsiderados quaisquer atos ofensivos ao pleno funcionamento do sistema.
                    </p>
                    <p>
                        A Jurídico Já se reserva no direito de recusar solicitações de cadastros que estejam em desacordos com as políticas e regras dos presentes Termos e Condições Gerais, assim como possui o direito de suspender um cadastro previamente aceito.
                    </p>
                    <p>
                        A Jurídico Já poderá, a seu exclusivo critério, realizar buscas a fim de verificar os dados fornecidos pelos parceiros, julgar os serviços prestados e solicitar dados adicionais e documentos que se fizerem necessários para conferir as informações cadastradas, podendo ainda utilizar-se de base de dados públicas ou privadas.
                        Fornecer informações incompletas, falsas ou imprecisas violam toda a política dos Termos e Condições de Uso desta plataforma, estando o Usuário plenamente responsável pelos danos que vierem a ser causados por tal transgressão.
                    </p>
                    <p>
                        A Plataforma se reserva no direito de solicitar mais informações e documentos sobre o Usuário Contratante a qualquer tempo. Uma vez que se recuse a fornecer tais informações o usuário estará ferindo com os Termos.
                    </p>
                    <p>
                        A concessão de prazo para pagamento dos serviços poderá ou não ser concedido em função de análise do perfil do contratante, bem como documentos. Podendo tal concessão ser suspensa ou cancelada a qualquer tempo.
                    </p>
                    <p>
                        Qualquer problema encontrado no cadastro do usuário poderá trazer sanções a serem aplicadas pelo Jurídico Já, sendo essas a suspensão temporária ou definitiva da conta, sem prejuízo de outras medidas que entender necessárias e oportunas. Uma vez aplicada qualquer sanção será, automaticamente, cancelada os anúncios dos respectivos usuários, não cabendo a esta qualquer indenização ou ressarcimento.
                    </p>
                    <p>
                        A Jurídico Já não se responsabiliza pelos Dados Pessoais inseridos pelos Usuários. Esses devem garantir a sua veracidade e respondem, em qualquer caso, civil e criminalmente pelas informações cadastradas.
                        2.9.1 É de responsabilidade do usuário garantir a proteção de seus dados para acesso à plataforma, não podendo nenhuma contratação ser cancelada em razão do uso incorreto dos dados de acesso.
                    </p>
                    <p className="two">
                        O usuário acessará a sua conta através do apelido/e-mail (login) e senha, comprometendo-se a não informar a terceiros desautorizados esses dados, responsabilizando-se integralmente pelo uso que deles seja feito.
                    </p>
                    <p>DO PAGAMENTO FEITO PELO CONTRATANTE</p>
                    <p>
                        3.1.1 As transações de pagamentos online descritas neste mesmo Termo serão feitas pela Jurídico Já, a critério da Jurídico Já. Portanto, quando a pactuação de pagamento for feita foram do Jurídico Já, ou esta não for a responsável, não havendo envolvimento da Jurídico Já com o procedimento, não se responsabiliza por falhas ou erros apresentados durante o processamento de pagamentos que podem vir a prejudicar a prestação de serviços pelo Correspondente.
                    </p>
                    <p>
                        A Jurídico Já não autoriza que nenhuma taxa seja cobrada em seu nome, sem prévia e expressa autorização legal, sob pena de sanções penais e civis.
                    </p>
                    <p>
                        Quando os serviços forem solicitados ao Jurídico Já e não diretamente ao Correspondente, a Jurídico Já poderá cobrar o valor que considerar justo e estiver dentro do usualmente praticado em função da comarca e complexidade do ato, fazendo o Correspondente jus àquilo que foi pactuado na sua proposta de serviços ou valores previamente cadastrados no site e podendo o Contratante aceitar ou não o valor proposto.
                    </p>
                    <p>
                        O pagamento deverá ser efetuado em até 5 dias úteis após o recebimento da planilha ou fatura para os CONTRATANTES que tem prazo aprovado no momento do cadastro, em caso de boleto este deve ser pago data de vencimento, para CONTRATATES que dispõe de prazo.
                    </p>
                    <p className="two">
                        Aqueles que não tiverem cadastro aprovado para tal modalidade de pagamento postergado, deverão realizar o pagamento a vista por um dos meios disponibilizados pela Jurídico Já.
                    </p>
                    <p>DAS OBRIGAÇÕES E DIREITOS</p>
                    <p>
                        O Contratante alega ter conhecimento de que durante o uso da plataforma da Jurídico Já não cabe à Empresa responsabilização por questões que estejam intrínsecas a negociação jurídica ou prestação de serviços que por meio da plataforma são contratados.
                    </p>
                    <p>
                        Reconhece ainda que danos que sejam eventualmente causados ao Contratante ou a Terceiro pelo Correspondente ou por terceiro não são de responsabilidade da Plataforma; E
                        Indenizações ou reparações por danos sofridos por outros Contratantes, Correspondentes ou terceiro não são da competência da Empresa.
                    </p>
                    <p>
                        É responsabilidade única e exclusiva do Contratante garantir fundos para contratação de Correspondente.
                    </p>
                    <p>
                        Ao Contratante é garantida a livre escolha no processo de contratação de Correspondente na plataforma.
                    </p>
                    <p>São práticas vedadas ao usuário:</p>
                    <p>
                        Os usuários se comprometem e aceitam de boa-fé utilizar a plataforma sem violar os termos e condições de uso ou qualquer outra disposição referente a plataforma;
                    </p>
                    <p>Violar qualquer norma ou lei atualmente vigente no ordenamento jurídico brasileiro.</p>
                    <p>Os usuários não podem interferir nas negociações de outros usuários;</p>
                    <p>Não é permitido aos usuários manipular os preços das prestações de serviço anunciadas;</p>
                    <p>
                        Divulgar dados pessoais de contato por qualquer meio sem autorização prévia, inclusive de terceiro;
                    </p>
                    <p>Anunciar serviços proibidos pela legislação;</p>
                    <p>Agredir, caluniar, injuriar ou difamar outros usuários;</p>
                    <p className="two">
                        Não é permitido usar dispositivo, software ou outro recurso que interfira nas atividades da plataforma. Qualquer intromissão acarretará nas devidas medidas legais e sanções anteriormente previstas.
                    </p>
                    <p>DO CONTEÚDO DE TERCEIROS</p>
                    <p>
                        De acordo com a Lei n° 12.965/2014 ("Uso da Internet no Brasil"), artigo 18: “O provedor de conexão à internet não será responsabilizado civilmente por danos decorrentes de conteúdo gerado por terceiros”. Portanto, as informações providas pelo Correspondentes, as quais o Contratante possui amplo acesso, como nome, telefone de contato, localização, entre outras, não são responsabilidade da Jurídico Já.
                    </p>
                    <p className="two">
                        O disposto no item anterior se aplica igualmente a qualquer informação fornecida sobre serviços, tais como valores cobrados, características dos serviços, etc., são de responsabilidade única de seu anunciante, sem envolvimento por parte da Plataforma.
                    </p>
                    <p>DA RESCISÃO</p>
                    <p>
                        O Contratante tem o direito de cancelar sua conta ou assinatura na plataforma a qualquer momento.
                    </p>
                    <p>O cancelamento não incide em desobrigação de cumprimento das obrigações anteriormente assumidas.</p>
                    <p>Condições de cancelamento são tratadas entre Contratante e Correspondente.</p>
                    <p>
                        A Jurídico Já se reserva no direito de suspender, cancelar, impedir ou interromper o acesso do Usuário Contratante a plataforma, sem aviso prévio, uma vez não seguidas as regras dispostas nos Termos e Condições de Uso, contratos celebrados por meio da plataforma, ou qualquer outro dispositivo que viole o bom funcionamento do site e aplicativo.
                    </p>
                    <p>O usuário infrator poder ser notificado sobre o uso inadequado, à critério da Jurídico Já.</p>
                    <p>
                        Na suspeita de uso inadequado ou reincidência o usuário Contratante poderá ter o cadastrado cancelado.
                    </p>
                    <p>
                        Uma vez cancelado o cadastro do Contratante as obrigações contraídas pendentes devem ser concluídas com totalidade de pagamentos devidos aos Correspondentes.
                    </p>
                    <p>
                        A Empresa se reserva no direito de interromper o acesso e uso do Serviço do Usuário Contratante caso este tenha decretado falência ou perca autorização para exercer sua atividade.
                    </p>
                    <p className="two">
                        A Empresa não se responsabiliza por eventual perda de prazos processuais, erros na realização, demais danos por atrasos ou não realização dos atos contratados, devendo o CONTRATANTE, solicitar os serviços com prazo adequado, para caso não possam serem realizados buscar outra meio de realização em tempo hábil.
                    </p>
                    <p>USUÁRIO CORRESPONDENTE</p>
                    <p className="two">
                        Uma vez conectado na plataforma da empresa Jurídico Já por meio de uma Conta de Usuário de Usuário Correspondente, os utilizadores deverão zelar pelo cumprimento dos Termos e Condições de Uso cabíveis as partes.
                    </p>
                    <p>DA RELAÇÃO</p>
                    <p className="two">
                        O Correspondente, expressamente qualificado nestes Termos, afirma e reconhece que não há vínculos além do negócio jurídico, reiterando que não existe relação trabalhista ou societária. A prestação de serviço por parte da Jurídico Já se dá de igual forma para o Contratante e para o Correspondente.
                    </p>
                    <p>DO CADASTRO</p>
                    <p>Cada usuário poderá manter somente um cadastro junto a plataforma Jurídico Já.</p>
                    <p>O cadastro é pessoal e intransferível.</p>
                    <p>
                        Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra forma de transferência da conta. Também não se permitirá a criação de novos cadastros por pessoas cujos cadastros originais tenham sido suspensos de alguma forma por infração às políticas do site.
                    </p>
                    <p>
                        O cadastro é confirmado após o usuário preencher todos os campos obrigatórios, com informações exatas, precisas e verdadeiras. O usuário declarara e assume o compromisso de atualizar os dados inseridos em seu cadastro sempre que necessário ou tão logo houver modificações.
                    </p>
                    <p>
                        Os Usuários não devem cadastrar apelidos que possuam qualquer semelhança com o nome Jurídico Já ou qualquer outro nome de propriedade intelectual, tampouco deverá insinuar ou sugerir que os serviços anunciados na plataforma são de autoria da Jurídico Já. Também serão desconsiderados quaisquer atos ofensivos ao pleno funcionamento do sistema.
                    </p>
                    <p>
                        A Jurídico Já se reserva no direito de recusar solicitações de cadastros que estejam em desacordos com as políticas e regras dos presentes Termos e Condições Gerais, assim como possui o direito de suspender um cadastro previamente aceito.
                    </p>
                    <p>
                        A Jurídico Já poderá, a seu exclusivo critério, realizar buscas a fim de verificar os dados fornecidos pelos parceiros, julgar os serviços prestados e solicitar dados adicionais e documentos que se fizerem necessários para conferir as informações cadastradas, podendo ainda utilizar-se de base de dados públicas ou privadas.
                    </p>
                    <p>
                        Fornecer informações incompletas, falsas ou imprecisas violam toda a política dos Termos e Condições de Uso desta plataforma, estando o Usuário plenamente responsável pelos danos que vierem a ser causados por tal transgressão.
                    </p>
                    <p>
                        A Plataforma se reserva no direito de solicitar mais informações e documentos sobre o Usuário Correspondente a qualquer tempo. Uma vez que se recuse a fornecer tais informações o usuário estará ferindo com os Termos.
                    </p>
                    <p>
                        Qualquer problema encontrado no cadastro do usuário poderá trazer sanções a serem aplicadas pelo Jurídico Já, sendo essas a suspensão temporária ou definitiva da conta, sem prejuízo de outras medidas que entender necessárias e oportunas. Uma vez aplicada qualquer sanção será, automaticamente, cancelada os anúncios dos respectivos usuários, não cabendo a esta qualquer indenização ou ressarcimento.
                    </p>
                    <p>
                        A Jurídico Já não se responsabiliza pelos Dados Pessoais inseridos pelos Usuários. Esses devem garantir a sua veracidade e respondem, em qualquer caso, civil e criminalmente pelas informações cadastradas.
                    </p>
                    <p>
                        É de responsabilidade do usuário garantir a proteção de seus dados para acesso à plataforma, não podendo nenhuma contratação ser cancelada em razão do uso incorreto dos dados de acesso.
                    </p>
                    <p>
                        O usuário acessará a sua conta através do apelido/e-mail (login) e senha, comprometendo-se a não informar a terceiros desautorizados esses dados, responsabilizando-se integralmente pelo uso que deles seja feito.
                    </p>
                    <p>Requerimentos para Usuário Correspondente:</p>
                    <p>
                        Para efetivar o cadastro como Correspondente, o usuário deverá adquirir um plano junto a plataforma, podendo escolher o meio de pagamento pelo qual será cobrado.
                    </p>
                    <p>
                        Será cobrada uma taxa mensal, podendo o valor ser modificado a qualquer tempo, mediante aviso prévio por qualquer meio de comunicação entre a plataforma e o usuário.
                    </p>
                    <p>
                        A cobrança se dará no início de um período de 30 dias, que pode ou não coincidir com o ciclo de cobrança. Uma vez pago, não haverá reembolso em casos de cancelamento, ficando o acesso disponível até o fim do ciclo.
                    </p>
                    <p>
                        A cobrança será suspensa no mês subsequente ao cancelamento feito pelos meios disponíveis na plataforma.
                    </p>
                    <p>
                        A exposição do Correspondente está relacionada ao pagamento dos valores relativos ao plano escolhido. Caso seja identificado a falta de pagamento ou de renovação, o serviço estará suspenso para o usuário.
                    </p>
                    <p>
                        De acordo com a OAB, Provimento n. 94/2000, existem condições para manter os perfis de correspondentes, essas devem ser observadas a todo tempo.
                    </p>
                    <p className="two">
                        O uso da plataforma em desacordo com as regras dispostas no item anterior pode acarretar sanções disciplinares, bem como responsabilização no âmbito civil e penal. O Usuário Correspondente reconhece que é o responsável por todas as sanções aplicadas, sendo a Jurídico Já alheia aos prejuízos de ação ou omissão por parte do colaborador.
                    </p>
                    <p>DO PAGAMENTO</p>
                    <p>
                        Correspondente poderá receber por seus serviços anunciados via cartão de crédito, débito em conta corrente, boleto bancário ou outros meios de pagamento online que estejam disponíveis na plataforma, à critério da Jurídico Já.
                    </p>
                    <p>
                        As transações de pagamentos online descritas neste mesmo Termo serão feitas por terceiros prestadores unicamente desses serviços. Portanto, não há envolvimento da Jurídico Já com o procedimento, não sendo este responsável por falhas ou erros apresentados durante o processamento de pagamentos que podem vir a prejudicar a prestação de serviços pelo Correspondente.
                    </p>
                    <p>
                        A Plataforma recebe sobre a remuneração do Correspondente uma taxa de 9% sobre o valor recebido por este, quando feito o pagamento através dos meios disponibilizados pelo próprio site ou aplicativo e a contratação se der diretamente na página individual do Correspondente.
                        Pagamentos que tenham sido acordados fora dos meios citados neste termo não são de responsabilidade da empresa e sua transação depende única e exclusivamente das partes e do pactuado entre elas.
                    </p>
                    <p>
                        A Jurídico Já não autoriza que nenhuma taxa seja cobrada em seu nome, sem prévia e expressa autorização legal, sob pena de sanções penais e civis.
                    </p>
                    <p className="two">
                        Quando os serviços forem solicitados ao Jurídico Já e não diretamente ao Correspondente, a Jurídico Já poderá cobrar o valor que considerar justo, fazendo o Correspondente jus àquilo que foi pactuado na sua proposta de serviços ou valores previamente cadastrados no site e podendo o Contratante aceitar ou não o valor proposto. Podendo a Jurídico Já abater impostos ou mesmo realizar a retenção na fonte, à seu critério.
                    </p>
                    <p>DAS OBRIGAÇÕES E DIREITOS</p>
                    <p>
                        Ao usuário não será garantido exclusividade, direito de reembolso por arrependimento – além dos previstos em lei –, incremento em sua clientela e a boa-fé dos Contratantes.
                    </p>
                    <p>
                        Entre os planos supramencionados, há a oferta de prioridade de exibição que está condicionada aos seguintes critérios: Plano adquirido, recomendações, localização da demanda e disponibilidade por parte do Usuário Correspondente.
                    </p>
                    <p>A demanda a ser realizada será recebida pelo Correspondente através da plataforma.</p>
                    <p>
                        As reclamações e mudanças que podem ocorrer durante a prestação de serviço, tais como mudança no serviço ou valor acordado, deverão ser tratados entre o Contratante e o Usuário Correspondente.
                    </p>
                    <p>
                        As reclamações relacionadas às contratações serão de responsabilidade exclusiva do Correspondente que se compromete em resolver e arcar com todas as hipóteses e possibilidade de ressarcimento pelo serviço não concluído conforme acordado entre as partes.
                    </p>
                    <p>
                        Se a Plataforma se ver obrigada a arcar com custos gerados por inadimplemento do Correspondente, este estará obrigado a reembolsar todos os valores despendidos pela Jurídico Já, que poderá inclusive descontar dos eventuais valores à receber do CORRESPONDENTE.
                    </p>
                    <p>
                        O Usuário se compromete a manter o seu perfil atualizado. As informações necessárias são nome, telefone, endereço eletrônico, região de atuação, e entre outras.
                    </p>
                    <p>
                        Ainda se obriga a realizar o atendimento aos Contratantes de acordo com o previsto nas políticas de boa-fé desta plataforma, expressas em todo os Termos de Uso.
                    </p>
                    <p>
                        Será responsabilidade do Correspondente arcar com os ônus incididos sobre ocorridos da prestação de serviço, como inconformidade com os padrões esperados de qualidade, etc.
                    </p>
                    <p>
                        O Usuário, ainda, se obrigada perante a Jurídico Já a indenizar por perdas relativas ao descumprimento dos termos ou pelas obrigações assumidas na plataforma ou outros documentos vinculativos.
                    </p>
                    <p>
                        De acordo com a Lei nº 13.105 de 2015 (“Código de Processo Civil”), artigo 125, o Usuário reconhece que será denunciado à lide em qualquer ação que a Jurídico Já venha fazer parte no polo passivo em razão da prestação de serviço do Correspondente.
                    </p>
                    <p>
                        O Correspondente afirma ciência quanto a não relação da Jurídico Já com as tratativas das contratações, incluindo:
                    </p>
                    <p>Capacidade dos usuários da plataforma para contratar os serviços e efetuar o pagamento por eles.</p>
                    <p>
                        Danos sofridos pelo Correspondente ou Terceiros pelos Contratantes ou por Terceiros na Contratação e/ou na prestação de serviços de Correspondentes.
                    </p>
                    <p>Indenizações ou reparação de danos causados por qualquer usuário da plataforma.</p>
                    <p className="two">É assegurado à Jurídico Já regresso em qualquer caso de reparação que a empresa tenha que arcar.</p>
                    <p>DO CONTEÚDO DE TERCEIROS</p>
                    <p>
                        De acordo com a Lei n° 12.965/2014 ("Uso da Internet no Brasil"), artigo 18: “O provedor de conexão à internet não será responsabilizado civilmente por danos decorrentes de conteúdo gerado por terceiros”. Portanto, as informações providas pelo Contratante, as quais o Correspondente possui amplo acesso, como nome, telefone de contato, localização, entre outras, não são responsabilidade da Jurídico Já.
                    </p>
                    <p className="two">
                        O disposto no item anterior se aplica igualmente a qualquer informação fornecida sobre serviços, tais como valores cobrados, características dos serviços, etc., são de responsabilidade única de seu anunciante, sem envolvimento por parte da Plataforma.
                    </p>
                    <p>DA RESCISÃO</p>
                    <p>
                        O Correspondente tem o direito de cancelar sua conta ou assinatura na plataforma a qualquer momento.
                    </p>
                    <p>O cancelamento não incide em desobrigação de cumprimento das obrigações anteriormente assumidas.</p>
                    <p>Condições de cancelamento são tratadas entre Contratante e Correspondente.</p>
                    <p>
                        A Jurídico Já se reserva no direito de suspender, cancelar, impedir ou interromper o acesso do Usuário Correspondente a plataforma, sem aviso prévio, uma vez não seguidas as regras dispostas nos Termos e Condições de Uso, contratos celebrados por meio da plataforma, ou qualquer outro dispositivo que viole o bom funcionamento do site e aplicativo.
                    </p>
                    <p>O usuário infrator será notificado sobre o uso inadequado.</p>
                    <p>
                        Na suspeita de uso inadequado ou reincidência o usuário Correspondente poderá ter o cadastrado cancelado.
                    </p>
                    <p>
                        Uma vez cancelado o cadastro do Correspondente as obrigações contraídas pendentes devem ser concluídas em totalidade.
                    </p>
                    <p className="two">
                        A Empresa se reserva no direito de interromper o acesso e uso do Serviço do Usuário Correspondente caso este tenha decretado falência ou perca autorização para exercer sua atividade.
                    </p>
                    <p>TERMOS DE SERVIÇO</p>
                    <p className="two">
                        O site www.juridicoja.com e seu respectivo aplicativo, os correspondentes e os contratantes resolvem contratar entre si, mediante as seguintes cláusulas e condições:
                    </p>
                    <p>DOS SERVIÇOS</p>
                    <p>Distribuição de ações ou protocolos;</p>
                    <p>Realização de audiências;</p>
                    <p>Relatórios processuais;</p>
                    <p>Despachar petições;</p>
                    <p>
                        Interceder perante juízes, desembargadores, ministros e cartórios nos serviços que dependem de uma melhor compreensão oral da matéria alvejada;
                    </p>
                    <p>Cópias de acórdãos, de peças e de andamentos de processos;</p>
                    <p>Realizar consulta de autos e de protocolos;</p>
                    <p>Retirar certidões nos Tribunais, Fóruns e demais Órgãos Públicos;</p>
                    <p>
                        Realizar diligências até delegacias para verificação de inquéritos policiais; acompanhar cumprimento de determinações judiciais; dentre outros serviços jurídicos aqui não especificados;
                    </p>
                    <p>Enviar as publicações recebidas diariamente ao e-mail indicado pelo solicitante;</p>
                    <p className="two">Outras a serem definidas e pelo CONTRATANTE e aceitas pela Jurídico Já e/ou correspondentes.</p>

                    <p>DA SOLICITAÇÃO</p>
                    <p>Os serviços deverão ser solicitados, obrigatoriamente pelo site juridicoja.com.</p>
                    <p>
                        Para casos urgentes, que envolvam prazos exíguos, o escritório solicitante ou advogado deve informar através do botão para pedidos urgentes, pois em condições normais os serviços terão início após a confirmação de recebimento e serão finalizados em até 7 dias úteis. Nos casos urgentes a contratada fará todo o possível para atender dentro do solicitado, contudo não sendo garantido o cumprimento antes do prazo de 7 dias úteis.
                    </p>
                    <p>
                        A data solicitação será considerada dentro do horário de 8h às 16h, a partir deste horário será considerada como recebida no dia seguinte.
                    </p>
                    <p>
                        Os serviços solicitados possuem duas modalidades de entrega: Via e-mail, plataforma ou via correios, neste caso com acréscimo do valor cobrado pelo mesmo.
                    </p>
                    <p>
                        Para receber os serviços por Sedex 10, é necessário confirmar no site dos Correios se o CEP de destino é atendido pelo respectivo serviço e realizar pagamento prévio do valor da postagem.
                    </p>
                    <p className="two">
                        Caso o solicitante não tenha quitado débitos de solicitações anteriores ou não tenha comprovado seu efetivo pagamento, sua nova solicitação de serviços não poderá ser atendida antes da quitação.
                    </p>

                    <p>DO PREÇO E DA FORMA DE PAGAMENTO</p>
                    <p>
                        Os preços das diligências obedecem às tabelas da Ordem dos Advogados quando no Estado correspondente ao serviço houver, levando em conta ainda fatores como local, dificuldade de acesso, valores praticados pelos demais advogados, simplicidade ou complexidade do ato. Podendo haver desconto por eventual simplicidade do ato praticado.
                    </p>
                    <p className="two">
                        O Contratante, nos casos de depósito, deverá enviar o comprovante do mesmo para fins de baixa junto à Contratada.
                    </p>

                    <p>DA EXECUÇÃO</p>
                    <p className="two">
                        Os serviços são realizados nas comarcas, nos fóruns e respectivas varas e Cartórios extrajudiciais; PROCON; Serasa; SPC; na Junta Comercial; nos Ministérios; nas Embaixadas; nos Consulados; nos órgãos Legislativo (Câmara dos Deputados); nos órgãos do Executivo; nos Bancos; nos Conselhos e Órgãos de Classe; no Tribunal de Contas do Estado; na OAB; no CRC; CRM; CRO; nas Procuradorias e em todos os demais órgãos administrativos, desde que localizados na Capital de cidade adjacentes.
                    </p>

                    <p>DAS CÓPIAS</p>
                    <p>
                        As cópias solicitadas por meio de fotografia, ou por e-mail, serão encaminhadas via e-mail ou sistema a depender do tamanho.
                    </p>
                    <p>
                        As imagens ficarão disponíveis pelo prazo de 1 (um) dia. A Contratada não fará backup das imagens, que serão descartadas após o período mencionado.
                    </p>
                    <p>
                        Cópias, por meio de fotografia, serão realizadas até o limite de 100 páginas. Caso esse número seja ultrapassado, as cópias excedentes serão reprografadas e enviadas por e-mail ou sistema, no prazo previsto para esta modalidade, podendo haver cobrança extra mediante prévia autorização.
                    </p>
                    <p>
                        A fotografia de documentos pode apresentar eventual perda de qualidade, em razão de uma série de fatores (qualidade e estado de conservação do original, falta de iluminação do ambiente, espaço físico, movimentação de pessoas). Caberá exclusivamente ao Solicitante, no momento em que optar pela modalidade fotográfica, analisar a viabilidade do procedimento para o fim a que se destina. É imprescindível que o Solicitante peça em tempo hábil a extração das cópias capa a capa, ou das peças essenciais para formação do recurso de agravo ou qualquer outro recurso cujo prazo seja peremptório.
                    </p>
                    <p>
                        Em razão do volume de folhas, a cópia do processo capa a capa não serão encaminhadas por fax ou outra plataforma.
                    </p>
                    <p className="two">
                        Dependendo do Tribunal ou órgão público, será necessária autorização específica “substabelecimento com reserva de poderes com a especificação do ato a ser praticado” para que os advogados, estagiários e colaboradores do Escritório possam extrair cópias, o prazo de entrega será contado a partir do recebimento deste.
                    </p>

                    <p>DO PROTOCOLO E DISTRIBUIÇÃO</p>
                    <p>
                        Nos casos de protocolo de petições e de distribuição de ações, é essencial que as peças estejam acompanhadas de procurações, das cópias suficientes para o protocolo e contrafé (quando necessário), e das guias devidamente pagas e preenchidas. A petição deve estar corretamente endereçada e conter as demais informações imprescindíveis à realização do protocolo e da distribuição, o local de protocolo levará em conta o endereçamento da peça. Não são efetuados recolhimentos de custas. Estes deverão ser realizados previamente pelo Solicitante.
                    </p>
                    <p className="two">
                        É responsabilidade do Solicitante encaminhar pelo correio, para o Escritório, em tempo hábil, os documentos para protocolo ou distribuição, logo após o requerimento via e-mail. Cabe exclusivamente ao Solicitante remeter o protocolo devidamente instruído e endereçado para que os Advogados, Estagiários e demais colaboradores para realizem o protocolo em tempo hábil. Os protocolos devem ser enviados em tempo hábil para cumprimento de prezo processual, podendo a CONTRATADA realizá-lo em até 7 dias úteis da data de recebimento.
                    </p>
                    <p>DAS CERTIDÕES</p>
                    <p>
                        As certidões do TJ, TRE, TRT, TRF que forem onerosas deverão os valores das mesmas serem reembolsados à Contratada, quando solicitadas pelo Contratante.
                    </p>
                    <p className="two">
                        Dependendo do Tribunal ou órgão público, será necessária autorização específica para que os Advogados, Estagiários e Colaboradores do Escritório retirem a certidão.
                    </p>
                    <p>DA AUDIÊNCIA</p>
                    <p className="two">
                        A audiência é um ato processual importante para o bom resultado do processo e da ação, por isso, é importante que o escritório solicitante ou advogado estabeleça para uso dessa modalidade de serviço um contato com no mínimo 07 (sete) dias de antecedência de sua realização, deverá ser encaminhado via site informações sobre o processo e o local e horário para encontro com as partes, além do horário, dia e local da audiência, devendo as orientações serem enviadas com no mínimo 24 horas antes do início da audiência. Caso seja audiência de instrução o escritório solicitante ou advogado deverá encaminhar um rol de perguntas a serem feitas as testemunhas que serão ouvidas em juízo, deverá também o solicitante encaminhar o competente substabelecimento com reserva de poderes para a realização do ato.
                    </p>
                    <p>DOS DESPACHOS</p>
                    <p className="two">
                        Existem casos na prática forense que exige ante a busca de resultados positivos e mais céleres que o instrumental jurídico seja despachado, por esta razão que o serviço despachar petição oferece a possibilidade do solicitante realizar este ato mesmo sem estar presente, o qual após sua concretização será encaminhado nas modalidades já previstas para o solicitante pelos meios já estipulados. Importante que seja encaminhado para o cumprimento do ato (resumo dos fatos, pontos controvertidos, e as solicitações e requerimentos a serem feitos para o destinatário).
                    </p>
                    <p>DO COMPARECIMENTO EM DELEGACIA</p>
                    <p className="two">
                        Por esta modalidade de serviço, contamos com profissionais preparados para dar todo o suporte de acompanhamento, verificação e tomada de providências perante as Delegacias de Polícia (Estadual ou Federal), outrossim, disponibilizamos profissionais para o acompanhamento de cumprimento de ordem judicial acertando os horários, verificando o oficial responsável pela diligência e acompanhando o cumprimento do ato, sendo que após o cumprimento do ato será encaminhado um relatório e os documentos envolvidos na diligência pelos meios já estipulados. Sendo essencial que o solicitante encaminhe o (resumo dos fatos, substabelecimento, as solicitações e requerimentos a serem feitos para os destinatários).
                    </p>
                    <p>DOS PRAZOS</p>
                    <p>Solicitação por meio de fotografias para Belo Horizonte – 7 dias úteis.</p>
                    <p>Solicitação por meio de fotografias para outras comarcas e Estados – 7 dias úteis.</p>
                    <p>
                        Solicitações em Embaixadas, Consulados e demais órgãos que dependam da juntada do original da procuração ou do substabelecimento e pedidos de certidão – 7 dias úteis.
                    </p>
                    <p>Solicitação para a realização de audiência no mínimo 05 dias de antecedência.</p>
                    <p>Observações:</p>
                    <p>
                        a) os prazos terão início no dia útil seguinte à confirmação do recebimento, e, nos casos específicos, após o recebimento da autorização exigida pelo Tribunal para extração das cópias.
                    </p>
                    <p>
                        b) os prazos poderão sofrer alterações (para maior ou menor) em virtude da complexidade do pedido ou da facilidade da diligência, considerando (outros serviços em destacamento, saída para diligência, etc.), bem como em decorrência dos procedimentos administrativos adotados pelos órgãos responsáveis, além dos casos em que para cumprimento da medida, o correspondente tiver que aguardar a devolução do processo e/ou procedimento da conclusão, da ausência de localização, indisponibilidade, entre outros fatores de força maior e caso fortuito.
                    </p>
                    <p>
                        c) para as solicitações de protocolo e de distribuição, além da confirmação de recebimento, o prazo estará condicionado, também, à chegada de documentos ao Escritório, quando forem imprescindíveis.
                    </p>
                    <p className="two">
                        d) execução de relatórios processuais está atrelada a quantidade de processo a serem consultados, a quantidade de volume e a concentração de cartório.
                    </p>
                    <p>DOS PREÇOS</p>
                    <p>
                        Os valores são fixos, de acordo com o serviço solicitado. Os valores das postagens (Sedex, Sedex 10, Carta Registrada ou retirada na Sede do Escritório) não estão computados no valor fixo e também serão pagos e apresentados com o pagamento.
                    </p>
                    <p>
                        Os valores atribuídos às despesas com postagens podem sofrer alterações, sem prévio aviso, de acordo com a prática adotada pela Empresa Brasileira de Correios e Telégrafos – EBCT.
                    </p>
                    <p className="two">
                        Os valores fixos correspondem às diligências realizadas pelos Advogados, Estagiários e Colaboradores da Contratada e não serão cobrados nos casos de impossibilidade de obtenção das cópias e/ou informações em razão de o processo, procedimento ou diligência estar indisponível ou se frustrar por fatores alheios ao solicitado (exemplos: carga realizada por Advogado, autos conclusos ao Magistrado, processo não localizado no Cartório, delegado, escrivão, juiz, desembargador ou serventuário ausente, queda de sistema, queda de energia, greve, caso fortuito ou de força maior, etc.).
                    </p>
                    <p>DO CANCELAMENTO</p>
                    <p>
                        O Solicitante deverá formalizar o pedido de cancelamento da solicitação até o início deslocamento para realização dos serviços, o qual se dá a partir da confirmação do recebimento, com a indicação dos fatos que ensejaram o pedido.
                    </p>
                    <p className="two">
                        Será desconsiderado para fins de pagamento somente nos casos em que os serviços não tiverem sido iniciados.
                    </p>
                    <p>OUTRAS CONSIDERAÇÕES</p>
                    <p>
                        Para a extração de cópias de autos de procedimentos administrativos que tramitam nos órgãos públicos ou paraestatais, faz-se necessário o envio de procuração original, assinada por Advogado com procuração nos autos.
                    </p>
                    <p>
                        Muitos Tribunais só autorizam a extração de cópias de autos de processos para o Advogado com procuração nos autos, mediante apresentação da carteira da OAB, portanto, o substabelecimento será essencial.
                    </p>
                    <p>
                        Os TRF’s só autorizam consulta de processos e extração de cópias mediante formulário próprio de Requerimento de Cadastro de Representantes de Advogados e Procuradores, disponível no portal do Tribunal, na Internet, original e devidamente assinado por advogados com procuração nos autos.
                    </p>
                    <p>
                        Cabe única e exclusivamente ao solicitante encaminhar as solicitações em tempo hábil e seguro para a execução dos serviços. A Contratada não se responsabiliza pelos prazos dos solicitantes, apenas pelo envio das publicações na data em que disponibilizadas e pela realização dos serviços solicitados por e-mail em tempo hábil.
                    </p>
                    <p>
                        Está ciente o Escritório solicitante ou Advogado que qualquer questão que envolva o presente termo de solicitação e seus regramentos serão dirimidos perante a Comarca de Belo Horizonte, por mais privilegiada que seja uma outra.
                    </p>
                    <p className="two">
                        Declara o solicitante que as informações solicitadas são lícitas e que os serviços solicitados não alteram o respectivo conteúdo, outrossim, que aceita os termos aqui propostos.
                    </p>
                    <p className="two">TERMOS DE USO E CONDIÇÕES GERAIS PARA PAGAMENTO</p>
                    <p>DO PAGAMENTO DAS DEMANDAS CONTRATADAS VIA SITE OU APP</p>
                    <p>
                        Os Usuários aceitam, expressamente, pagar uma taxa de licenciamento para uso da plataforma, bem como comissões e demais taxas e encargos que acompanharem o processo. Concordam a pagar ao Jurídico Já remuneração que envolverá: tarifa fixa de transação, taxa do meio de pagamento e outros serviços, em porcentagem declarada, por transação. Bem como, mensalidade para manutenção de conta, tarifa de adesão, mensalidade de licenciamento. Os valores serão celebrados no acordo de tarifas ofertadas no site e aceitas entre as partes.
                    </p>
                    <p>
                        A verificação de conta e preenchimento de formulário são fatores importantes para a utilização dos serviços. Os Usuários irão fornecer informações próprias: nome/razão social, CPF/CNPJ, endereço, endereço de cobrança, endereço eletrônico, números de telefones, conta bancária.
                    </p>
                    <p>A falta de veracidade nestas informações acarretará sanções ao Usuário.</p>
                    <p>
                        Todos os pagamentos devidos serão efetuados através da conta bancária informada no formulário de cadastro, sendo a remuneração do Jurídico Já automaticamente descontada do valor, conforme item 1.1, podendo ocorrer diferenças em razão do tipo de transação, meio de pagamento, segmento de atuação, forma de captura ou plano contratado.
                    </p>
                    <p>Os pagamentos poderão ser realizados das formas que seguem:</p>
                    <p>
                        Boleto Bancário: A liberação irá depender da data de liquidação do boleto bancário pelo consumidor. Deverão ser observadas as regras de compensação do banco ao qual o pagamento foi efetuado.
                    </p>
                    <p>
                        Cartão de Crédito: A liberação será feita a cada fim do ciclo de cobrança (30 dias), por parcela, contando a partir da data de aprovação do pagamento efetuado.
                    </p>
                    <p>
                        Débito em Conta: A liberação será feita a cada fim do ciclo de cobrança (30 dias), por parcela, contando a partir da data de aprovação do pagamento efetuado.
                    </p>
                    <p>Ou outra a critério da Jurídico Já.</p>
                    <p>
                        Os pagamentos serão realizados após a conclusão da demanda e entrega do serviço solicitado, podendo haver prévio pagamento de despesas a serem pactuadas. O comprovante de pagamento será disponibilizado e anexado ao número de solicitação da demanda ou fatura mensal, conforme o caso.
                    </p>
                    <p>
                        A Plataforma possui o prazo de 45 dias úteis para disponibilizar o pagamento ao Correspondente, após o pagamento pelo contratante.
                    </p>
                    <p className="two">
                        As transações bancárias que darão processamento ao pagamento, serão feitas no dia útil subsequente ao da solicitação de saque e estará totalmente sujeita ao funcionamento do banco.
                    </p>
                    <p>DA RESTITUIÇÃO</p>
                    <p>
                        A Plataforma possui políticas a respeito do cancelamento de transações e restituição. Essas deverão ser feitas no valor exato da transação.
                    </p>
                    <p>O Usuário deverá restituir a sua exclusiva responsabilidade.</p>
                    <p>
                        Em casos onde a Jurídico Já seja intimada a efetuar restituições, o Usuário será imediatamente notificado a fim de que este efetue a transação, a sua exclusiva responsabilidade.
                    </p>
                    <p className="two">
                        Caso a restituição não seja feita nos prazos e condições estipulados, será demandado processo de cobrança dos valores devidos.
                    </p>
                    <p>DOS CHARGEBACKS E ESTORNOS</p>
                    <p>
                        O presente item trata sobre o procedimento realizado pelo consumidor junto a operadora de cartão de débito ou crédito, alegando desconhecimento de transação específica efetuada com o cartão de sua titularidade.
                    </p>
                    <p>
                        Deverão ser observadas regras de estorno de cada bandeira, mantendo as boas práticas comerciais a fim de que se evite o tratado no item 3.1. Recomendamos que os comprovantes de transações sejam conservados.
                    </p>
                    <p>
                        Em caso de notificações de Chargerback recebidas pela Jurídico Já, o Usuário será notificado para realizar as devidas providências junto ao reclamante, no prazo MÁXIMO de 30 dias e iniciará o devido processo de cobrança de valores devidos.
                    </p>
                    <p>
                        No ato de descumprimento do prazo definido no item anterior, bem como falta de atualização à Plataforma sobre os trâmites, a Jurídico Já iniciará processo de cobrança.
                    </p>
                    <p>
                        O Usuário terá o limite máximo de 1% de pedidos de Chargerback no mês, em relação ao número máximo de transações, do contrário sua conta será suspensa pelo prazo de 60 dias.
                    </p>
                    <p>
                        Para reincidentes no disposto no item 3.4 será aplicada multa de 25% sob o valor do Chargerback e a suspensão se dará por 90 dias.
                    </p>
                    <p className="two">Para reincidentes em dobro, as sanções citadas no item 3.5 serão aplicadas em dobro.</p>
                    <p>DAS DISPUSTAS E RETENÇÕES</p>
                    <p>
                        A Jurídico Já reconhece a observância das regras de disputas quando em desacordo comercial e Chargebacks iniciados por seus Usuários. Em caso de disputas, os saldos estarão retidos até a sua resolução.
                    </p>
                    <p>
                        A disputas devem ser solucionadas em um prazo máximo de 30 dias, podendo variar de acordo com cada banco ou administradora de cartão. Durante o período de disputa, todo saldo a ela relacionado estará retido.
                    </p>
                    <p>
                        Se o Usuário perder a disputa, autoriza previamente que o saldo anteriormente retido seja usado para dar quitação a disputa ou qualquer outro débito proveniente ou junto ao Jurídico Já.
                    </p>
                    <p>
                        Os saldos dos Usuários poderão ser retidos para auditoria e apuração de conduta, quando o referido atuar em desacordo com as Políticas desta Plataforma e de estabelecimentos bancários, administradoras de cartão, lei e práticas de Mercado e/ou Normas do BACEN.
                    </p>
                    <p className="two">
                        Condutas em que forem constatadas fraude em termos gerais, lavagem de dinheiro, etc., ocasionarão cancelamento da conta do Usuário na Plataforma Jurídico Já. Nestes casos, os saldos serão retidos e as informações do infrator serão entregues de boa-fé às autoridades.
                    </p>
                    <p>DO DOMICÍLIO BANCÁRIO E COBRANÇA DE VALORES</p>
                    <p>
                        Por meio deste o Usuário expressamente autorizada a Plataforma Jurídico Já efetuar operações de débito automático em conta bancária vinculada à conta utilizada na Plataforma, conforme formulário de cadastro, para as finalidades definidas em itens anteriores.
                    </p>
                    <p>
                        Quando ocorrerem restituições, estornos ou cancelamentos em favor da Plataforma, o valor exato da respectiva transação deverá ser entregue a Jurídico Já pelo Usuário, sendo o valor corrigido sobre IGP-M/FGV (ou outro índice), contando a data do repasse, com juros de 1% (um por cento) a.m. ou fração pró-rata, com o acréscimo de encargos operacionais e qualquer perdas e danos ocorridos.
                    </p>
                    <p>Podem ser adotadas as seguintes medidas com fins de cobrança de valores devidos a Jurídico Já:</p>
                    <p>Compensação de valores através de outros créditos, presentes ou futuros, devidos.</p>
                    <p>Lançamentos de débitos na conta bancária vinculada.</p>
                    <p>
                        Compensação por meio de cheque, boleto, TED, DOC ou depósito identificado, de acordo com tratativas entre as partes.
                    </p>
                    <p>Escritórios de cobrança especializados.</p>
                    <p>Ações Judicial, protestos e negativações.</p>
                    <p>
                        Falta ou atraso de pagamento, seja total ou parcial, dentro dos prazos previamente acordados em contrato ou em respectivas atualizações, podem sujeitar o Usuário ao pagamento dos seguintes encargos:
                    </p>
                    <p>Atualização monetária das dívidas, baseando-se no IPC/FGV, ou outro índice.</p>
                    <p>
                        Acréscimo de juros de 2% (um por cento) a.m. Não obstante, o Jurídico Já se reserva no direito de incluir os débitos do Usuário no cadastro de Pendências Financeiras (PEFIN) e qualquer outro órgão de proteção de crédito.
                    </p>
                    <p className="two">Inscrição no órgãos de cadastro e proteção ao crédito.</p>
                    <p>DO PRAZO DE PAGAMENTO DAS DEMANDAS SOLICITADAS VIA EMAIL</p>
                    <p>
                        6.1. O correspondente contratado via email deverá enviar no mês subsequente à realização dos serviços contratado, planilha, conforme modelo fornecido pela JURÍDICO JÁ no ato do cadastro, com todas as demandas realizados no mês anterior.
                    </p>
                    <p>
                        6.2. A planilha deve ser enviada até o dia 10 de cada mês para financeiro@juridicoja.com, sendo o pagamento efetuado 40 dias depois do último dia para envio, qual seja dia 10 (dez) do mês subsequente à realização. O envio fora do prazo enseja pagamento junto com as diligências do mês subsequente.
                    </p>
                    <p>
                        6.3. O envio da planilha preenchida corretamente para o setor responsável é essencial para que seu pagamento seja efetuado, portanto, pedimos atenção quanto ao procedimento.
                    </p>
                    <p className="two">
                        6.4. O envio da ficha cadastral, bem como o da planilha padrão para o financeiro, constituem aceite e ciência das condições de pagamento supramencionadas. Caso hajam dúvidas quanto ao procedimento não aceitar este termo ou serviços e buscar esclarecimentos prévios.
                    </p>
                    <p>POLÍTICA DE PRIVACIDADE</p>
                    <p>
                        A Política de Privacidade que segue é um compromisso da Plataforma Jurídico Já com a privacidade de seus usuários. Além dessas regras, podem ser aplicadas a legislação vigente, tais como Lei n° 12.965/2014 (“Marco Civil da Internet”) e a Lei nº 8.078/1990 (“Código de Defesa do Consumidor”), e outras normas cabíveis. A plataforma se reserva no direito de alterar e atualizar essas regras em qualquer tempo, com aviso prévio aos usuários. Caso não concorde com alguma modificação, recomendamos que cancele a conta de usuário junto a plataforma.
                    </p>
                    <p>
                        As Políticas de Privacidade da Plataforma serão aplicadas a todos os Usuários, cadastrados ou não, e fazem parte dos Termos e Condições de Uso Gerais. Com a finalidade de ser transparente, a Jurídico Já apresenta informações claras a respeito da coleta dos dados pessoais e não pessoais dos seus usuários, bem como o seu uso, armazenamento e segurança.
                    </p>
                    <p>
                        A Plataforma adianta que todas as medidas necessárias para proteger as informações dos usuários foram utilizadas, utilizando eficientes mecanismos de informática.
                    </p>
                    <p>
                        Em razão dos serviços prestados pela Jurídico Já, a empresa se reserva no direito de revelar determinadas informações cadastrais e registro de acesso de seus usuários, em situações específicas, para terceiros. Consideram-se terceiros interessados: Empresas integrante do grupo econômico, parceiros comerciais, fornecedores, autoridades, pessoas físicas ou jurídicas que tenham sido lesadas por algum usuário cadastro e membros do Programa de Proteção à Propriedade Intelectual.
                    </p>
                    <p className="two">
                        O site e aplicativo Jurídico Já pertencem a plataforma JURÍDICO JÁ, empresa devidamente inscrita no CNPJ sob o nº 30.660.444/0001-14, situada na Rua Araguari, nº 1685, Sala 305, Santo Agostinho, Belo Horizonte/MG, bem como seus colaboradores por meio do site www.juridicoja.com ou respectivos endereços de e-mail. Para suporte entre em contato com contato@juridicoja.com
                    </p>
                    <p>DAS INFORMAÇÕES COLETADAS E ARMAZENADAS</p>
                    <p>
                        No momento do primeiro do acesso do usuário é necessário que seja feito um cadastro a fim de usufruir das funcionalidades da plataforma. Para tanto, coletamos dados pessoais (“Informação Pessoal") que devem ser exatos, completos, verídicos e atualizados. Os dados que a plataforma necessita coletar são:
                    </p>
                    <p>Nome;</p>
                    <p>Endereço Eletrônico;</p>
                    <p>Endereço;</p>
                    <p>Telefone de contato;</p>
                    <p>CPF;</p>
                    <p>RG;</p>
                    <p>Data de nascimento;</p>
                    <p>Sexo;</p>
                    <p>Profissão; E</p>
                    <p>Outras informações necessárias para manutenção do perfil.</p>
                    <p>
                        A Jurídico Já se reserva no direito de verificar a veracidade das informações fornecidas pelo Usuário, com consulta a entidades públicas, companhias especializadas ou banco de dados. Ao fazer uso da plataforma, o Usuário autoriza expressamente o feito.
                    </p>
                    <p>Não consideramos pessoal qualquer informação que já era de domínio público no momento da coleta.</p>
                    <p>
                        Os dados bancários ou de cartão de crédito tem seus dados coletados para pagamento, contudo é feito pelas administradoras de cartão, instituições financeiras ou outras. Portanto, não há envolvimento da plataforma. Não é recomendado o uso de meio de pagamento que não esteja de acordo com a posição do Usuário sobre o uso destas informações.
                    </p>
                    <p>
                        A Jurídico Já coleta e armazena, com o mais alto grau de proteção, algumas informações ("Informações Não Pessoais") a respeito das atividades praticadas pelos usuários cadastrados e visitantes. Entre estas informações estão os dados de navegação, tais como características da máquina e celular utilizados para acesso, histórico de acesso, Internet Protocol (IP), Cookies, entre outros.
                    </p>
                    <p>
                        Os Cookies são arquivos presentes no disco rígido, com duração limitada e que contribuem para a personalização do serviço oferecido. Os Cookies são empregados a fim de conhecer quais são os interesses de determinado usuário, bem como seu comportamento. Desta forma, iremos compreender melhor a necessidade de cada um e oferecer um melhor serviço ou prover informações que estejam interligadas.
                    </p>
                    <p>
                        A utilização dos Cookies será de exclusiva vontade do usuário que pode, a qualquer tempo, os eliminar de seu dispositivo de acesso. Para retirar os cookies o usuário deverá estudar o caso específico de seu navegador. Contudo, oferecemos um breve tutorial disponibilizado pelos provedores mais comuns:
                    </p>
                    <p>(I) Microsoft Windows Explorer;</p>
                    <p>(II) Google Chrome;</p>
                    <p>(III) Mozilla Firefox; E</p>
                    <p>(IV) Apple Safari.</p>
                    <p>
                        A fim também de contribuir com uma melhor experiência e captação de demandas a Plataforma solicita acesso aos sinais de Global Positioning System (GPS), podendo, desta forma, localizar o usuário com precisão e lhe enviar maiores ofertas de serviços.
                    </p>
                    <p>
                        O usuário que realiza o cadastro na plataforma Jurídico Já reconhece e autoriza expressamente a intervenção da empresa em trocas de mensagens eletrônicas entre os usuários, de forma a manter o objetivo da plataforma e proporcionar maior eficácia nos serviços, com proteção e segurança.
                    </p>
                    <p>
                        Outros usuários só terão acesso a informação pessoal de outro usuário para comunicação, utilização de serviços oferecidos ou outra finalidade a qual o Usuário consinta expressamente.
                    </p>
                    <p>Sob nenhuma hipótese deve-se compartilhar informação de usuário da plataforma com terceiro.</p>
                    <p>
                        A Jurídico Já poderá fazer uso dos dados dos usuários a fim de gerar e divulgar estatísticas em redes sociais, mídias ou parceiros comerciais.
                    </p>
                    <p>Outros acessos serão concedidos mediante autorização expressa dos usuários.</p>
                    <p>As informações pessoais e não-pessoais podem ser cruzadas a qualquer momento.</p>
                    <p>
                        A fim de identificar o Usuário poderá ser feita análise isolada de informações ou dados, sendo essas consideradas informação pessoal.
                    </p>
                    <p>
                        A forma de armazenamento da Plataforma é amplamente segura, sendo o acesso às informações coletadas restrito a profissionais capacitados, com sua confidencialidade garantida por contrato legal e vigente, com duras sanções civis e criminais em caso de quebra de alguma cláusula ou divulgação indevida de informações por parte do prestador.
                    </p>
                    <p>
                        A Jurídico Já se compromete a empenhar seus melhores esforços para a proteção das informações de seus usuários. Contudo, deve-se considerar que a Internet não é totalmente segura e, caso ocorra a violação ou interceptação ilegal do nosso sistema ou banco de dados, por pessoas não autorizadas a Plataforma não poderá ser responsabilizada pelo uso indevido de informações.
                    </p>
                    <p>
                        A qualquer tempo o usuário poderá requerer a exclusão definitiva de seus dados do banco de dados da plataforma.
                    </p>
                    <p>
                        O item anterior possui casos excepcionais, onde a plataforma manterá os dados pessoais com objetivo de utilizá-los em solução de litígios ou reclamações, problemas ou incidentes detectados e para dar cumprimento aos Termos e Condições de Uso aceitos pelo Usuário.
                    </p>
                    <p className="two">
                        Uma vez coletadas as informações do Usuário, a Jurídico Já se compromete a não vender, alugar ou compartilhar estas informações, exceto nos casos anteriormente citados nesta mesma Política de Privacidade.
                    </p>
                    <p>DA COLETA DE INFORMAÇÕES</p>
                    <p>As informações serão coletadas:</p>
                    <p>Do cadastro na plataforma;</p>
                    <p>Do uso das funcionalidades;</p>
                    <p className="two">
                        Através do cruzamento de informações entre a Plataforma, publicidades e dados de navegação do usuário (“Cookies”).
                    </p>
                    <p>DO USO DAS INFORMAÇÕES</p>
                    <p>As informações pessoais que solicitamos possuem as seguintes finalidades:</p>
                    <p>
                        Auxiliar o contato direto entre o Contratante e o Correspondente após manifestada intenção de contratação. A Jurídico Já não autoriza o uso das informações para fins alternativos a conclusão do negócio, como, mas não se limitando, publicidade, promoção ou outras atividades não relacionadas a plataforma, salvo expressa autorização das partes. A Plataforma se reserva no direito de entrar em contato com os usuários para averiguar informações fornecidas.
                    </p>
                    <p>
                        Desenvolver estudos internos e externos sobre os interesses, comportamentos, demografia, entre outros tópicos que sejam relevantes para a compreensão das necessidades e interesses de nossos usuários, a fim de prover um serviço qualificado e garantir eficiência da nossa plataforma.
                    </p>
                    <p>
                        Notificar, através do endereço eletrônico fornecido, o recebimento de demandas, contratações ou evoluções nas tratativas das diligências.
                    </p>
                    <p>
                        Aprimorar o serviço ofertado pela plataforma, a partir de iniciativas comerciais e promocionais, com análise às páginas visitadas, buscas realizadas. Desta forma, iremos melhorar a apresentação de nossos serviços, personalizando conteúdo à cada usuário e mantendo a plataforma útil e relevante para a prática jurídica, incluindo serviços e produtos de terceiros.
                    </p>
                    <p className="two">
                        A Jurídico Já informa que as informações dos usuários poderão ser fornecidas para entidades que intervenham em relação as ações movidas em função de serviços prestados ou contratados através da plataforma.
                    </p>
                    <p>DAS AUTORIDADES COMPETENTES</p>
                    <p>
                        A Jurídico Já coopera com todas as autoridades competentes e com terceiros que precisem resolver litígios relacionados a prestação de serviços que ocorrem em sua plataforma. A empresa preza pelo cumprimento das leis vigentes, bem como pretende sempre guardar a integridade e segurança de seus Usuários. A Jurídico Já irá sempre se posicionar a fim de evitar atividades ilegais.
                    </p>
                    <p>
                        A Plataforma irá revelar informações pessoais e registros de acesso ou de navegação, dentro dos termos da legislação brasileira.
                    </p>
                    <p>
                        A Jurídico Já atuará em conjunto aos participantes do Programa de Proteção de Propriedade Intelectual, comunicando os dados pessoais dos usuários, caso ocorra necessidade expressa de fazê-lo.
                    </p>
                    <p className="two">
                        Se verificado que a atividade de determinado usuário é prejudicial ou ilícita a outras pessoas, a Plataforma se reserva no direito de fornecer as informações destes usuários aos órgãos e terceiros competentes para fazer com que as Políticas do site e do aplicativo sejam rigorosamente seguidas.
                    </p>
                    <p>OUTRAS CONSIDERAÇÕES</p>
                    <p>
                        Os Usuários expressamente autorizam o uso de Web Beacons, utilizados com o fim de medir padrões de tráfego dos Usuários de uma página a outra, com o objetivo único de maximizar tal fluxo por meio da internet. Muito semelhante aos Cookies.
                    </p>
                    <p>
                        A Jurídico Já considera violação aos Termos e Condições de Uso condutas classificadas como "Spamming", de qualquer forma possível, dentro da plataforma. Fica proibido o envio indiscriminado de mensagens a qualquer usuário.
                    </p>
                    <p>
                        A Plataforma se reserva no direito de limitar ações de usuários que estejam em desacordo com o citado no item anterior e até mesmo suspender a conta dos mesmos.
                    </p>
                    <p>
                        A Jurídico Já tem a intenção de manter seus usuários atualizados sobre novidades relacionadas ao mundo jurídico ou não, podendo ser relacionado unicamente a plataforma. Para tanto, os usuários podem optar por selecionar quais e-mails desejam receber, incluindo anúncios promocionais. Caso o usuário não deseje receber tais e-mails, possui totalmente liberdade para fazê-lo, sem prejuízos ao uso da plataforma.
                    </p>
                    <p>
                        O Usuário se compromete a não repassar suas informações de acesso para terceiros. Se feito, a responsabilidade é unicamente do Usuário.
                    </p>
                </ModalMain>
            </ModalTerms> 
            
            {/* Modal para os termos de pagamento */}
            <ModalTerms
                id={'overlayModal'}
                onClose={() => setOpenPaymentTerms((openPaymentTerms) => !openPaymentTerms)}
                openModal={openPaymentTerms}
                width="100%"
            >
                <ModalHeader>
                    <div><IconLogoJuridico /></div>

                    <button
                        onClick={() => setOpenPaymentTerms(false)}
                    >
                        <IconClose />
                    </button>

                </ModalHeader>

                <ModalMain>
                    <div className="title">
                        <p>Termos e condições</p>
                        para se tornar um <span className="pro">profissional jurídico</span> <span className="now">Já!</span>
                    </div>

                    <p>
                        Os Termos e Condições gerais de uso apresentados se aplicam aos serviços prestados pela plataforma JURÍDICO JÁ, empresa devidamente inscrita no CNPJ sob o nº 30.660.444/0001-14, situada na Rua Paraíba, nº 330, Sala 1205, Funcionários, Belo Horizonte/MG, bem como seus colaboradores por meio do site www.juridicoja.com, aplicativo e endereços de e-mail. Para suporte entre em contato com contato@juridicoja.com.
                    </p>

                    <p>
                        Qualquer usuário, doravante nominados CONTRATANTE ou CORRESPONDENTE, que pretenda utilizar a plataforma poderão ou não aceitar os Termos e Condições Gerais e todas as demais políticas e princípios que o regem. Caso optem por não aceitar basta não fazer uso da plataforma. A sua aceitação é indispensável à utilização, devendo o usuário ler com atenção, certificar-se de haver entendido e aceitar todas as condições estabelecidas nos Termos e Condições Gerais, nas Políticas de privacidade, Termos de Serviço e Termo de Parceiros, além de outras regras que possam ser aplicáveis, tais como, mas não somente, (i) Código de Ética e Disciplina da Ordem dos Advogados do Brasil (ii) Lei nº 8.904/1994 (“Estatuto da Ordem dos Advogados do Brasil”), (iii) a Lei nº 8.078/1990 (“Código de Defesa do Consumidor”), (iv) Lei nº 10.406/2002 (“Código Civil Brasileiro”), antes de concluir o cadastro.
                    </p>

                    <p className="two">
                        Ao acessar/cadastrar/utilizar a plataforma JURÍDICO JÁ o Utilizador declara ser civilmente capaz nos termos da Lei nº 10.406/2002 (“Código Civil Brasileiro”) para compreender, aceitar e cumprir os Termos de Uso e a Política de Privacidade. No caso de pessoas jurídicas, estas devem estar devidamente representadas. No ato da realização do cadastro o Usuário em nome de pessoa jurídica declara estar apto para representá-la nos termos que seguem e em outras ações relacionadas ao uso do site e aplicativo Jurídico Já. O Usuário responderá solidariamente perante a Plataforma por violações aos Termos ou das legislações vigentes aplicáveis. O cadastro de utilizador com idade inferior a 18 (dezoito) anos não será de responsabilidade da Plataforma e sim de seus responsáveis legais, respondendo inclusive por danos eventualmente causados e ou serviços contratados.
                    </p>

                    <p className="two">
                        Os Termos de Uso citados poderão sofrer mudanças a qualquer momento, bem como as funcionalidades do sistema, sem aviso prévio. O utilizador será notificado a aceitar os termos atualizados e estando em desacordo com as alterações deverá encerrar sua conta imediatamente. Caso não seja feito incidirá em aceitação automática do novo regulamento. A alteração dos termos poderá ser substituída por avisos e notificações dispostos no site e no aplicativo da plataforma. As alterações não vigorarão sob as negociações já iniciadas antes da publicação, sendo, neste caso, vigente a redação anterior.
                    </p>

                    <p>DAS PARTES</p>
                    <p>
                        De um lado, JURIDICO JA TECNOLOGIA E SERVICOS DE INTERNET LTDA, denominada JURÍDICO JÁ ou simplesmente “Plataforma”, acima já qualificada, e de outro lado, Advogado Correspondente ou outras pessoas físicas ou jurídicas que desejem atuar como correspondente, todos denominados neste ato CORRESPONDENTE(S) que são advogados, pessoas físicas ou jurídicas em geral que atuem prestando serviços ou escritórios de advocacia, doravante denominados CONTRATANTE, pessoa física ou jurídica que solicitam realização e serviços na plataforma ou email, afirmam terem pactuado entre si o disposto.
                    </p>
                    <p>
                        Estes Termos também se aplicam a todos os estudantes, pessoas físicas, advogados em formação, que busquem ofertar ou contratar serviços. É recomendável que os denominados ESTUDANTES possuam a Carteira de Estagiário da Ordem dos Advogados do Brasil (“OAB-E”) para prestarem serviços com excelência.
                    </p>
                    <p className="two">
                        A parte que acessa a plataforma de modo geral será denominada nestes termos como Usuário ou Utilizador.
                    </p>

                    <p>DO OBJETO</p>
                    <p>
                        A Jurídico Já oferta serviços direcionados para a realização de diligências, pela qual advogados e sociedades de advogados, Correspondente ou Contratante (conforme item 1), que estejam interessados na realização ou contratação de demandas e atividades de suporte à prática jurídica o façam através da plataforma. O Contratante terá a sua disposição, sempre que disponíveis na comarca, Correspondentes para atendimento de acordo com o pactuado entre as partes.
                    </p>
                    <p>
                        A Plataforma, quando utilizada apenas como meio de contratação, não será parte ativa na contratação de correspondentes. A Jurídico Já se responsabiliza apenas por disponibilizar a listagem de serviços e colaboradores para que o Contratante encontre e efetue a contratação do Correspondente com eficácia e rapidez. Portanto, a Jurídico Já não estará envolvida no negócio jurídico praticado entre as partes denominadas Contratante e Correspondente, bem como não será responsável pela aquisição de direitos ou obrigações originadas pela contratação através da plataforma, como pagamento de honorários ou cumprimento das diligências contratadas.
                    </p>
                    <p>
                        A Plataforma ainda não se responsabiliza pela qualidade dos serviços ofertados pelos correspondentes, pela veracidade de informações fornecidas pelas partes. E;
                    </p>

                    <p>
                        Pela observância do disposto em: Código de Ética e Disciplina da Ordem dos Advogados do Brasil, Lei nº 8.904/1994 (“Estatuto da Ordem dos Advogados do Brasil”) e Tabela de Honorários da Ordem dos Advogados do Brasil. No entanto, a Jurídico Já recomenda que a utilização do site e aplicativo não esteja em desacordo com os dispositivos no que se refere à prestação de serviços jurídicos.
                    </p>

                    <p>
                        A Plataforma declara que não há relação trabalhista entre as partes que pactuam entre si, bem como não há vínculo societário, agenciamento, consórcio, ou relação de qualquer natureza que não seja apenas o negócio jurídico.
                    </p>

                    <p className="two">
                        Contratações que ocorram em desacordo com o objetivo desta plataforma não serão de responsabilidade da empresa. Contudo, a Jurídico Já se reserva no direito de atuar nestes casos, a fim de impedir a transgressão dos termos estabelecidos.
                    </p>

                    <p>
                        DA PLATAFORMA
                    </p>
                    <p>
                        A Jurídico Já irá se empenhar a fim de garantir a completa disponibilidade, na medida do possível, da plataforma do Jurídico Já e dos serviços ofertados. Porém, a Plataforma não se responsabiliza por indisponibilidade decorrente de falhas no sistema, seja em períodos longos ou curtos.
                    </p>

                    <p>
                        Os usuários devem estar cientes de que a qualquer momento o acesso pode ser interrompido temporariamente sem aviso prévio em caso de falhas, manutenção ou alterações no sistema.
                    </p>

                    <p>
                        O Jurídico Já não se responsabiliza por danos causados em caso de prejuízo, perda ou danos em função da interrupção do sistema.
                    </p>

                    <p>
                        Para tanto, recomenda-se que os usuários mantenham forma sobressalente de contratação, sendo certo que não haverá indenização ou reparação por parte do Jurídico Já, sendo isenta a responsabilidade da empresa.
                    </p>

                    <p>
                        O Jurídico Já não se responsabiliza por qualquer vírus que possa atacar o equipamento do usuário em decorrência do acesso, utilização ou navegação na internet ou como consequência da transferência de dados, arquivos, imagens, textos vídeos ou áudio. Todavia os Usuários se comprometem a fazer o uso de arquivos livres de vírus ou tecnicamente danosos, incluindo, mas não se limitando a vírus como "cavalo de Tróia", worms, macros, componentes maliciosos, dados corrompidos e outros programas maliciosos ou projetados para alterar o bom funcionamento do sistema ou operações comuns de computadores.
                    </p>

                    <p>
                        As publicações feitas por Usuários em forma de artigos ou textos não representam a opinião ou posição da Empresa que não possui responsabilidade em relação ao conteúdo disponibilizado por terceiros.
                    </p>

                    <p>
                        A reprodução de conteúdo depende de autorização prévia e permissão expressa dos detentores de direitos e, quando couber, da Jurídico Já. Caso algum Usuário identifique algum conteúdo que se comprove plágio ou invasão de direito de terceiro solicitamos que encaminhe uma denúncia para contato@juridicoja.com.
                    </p>

                    <p>
                        A Plataforma poderá realizar triagens periódicas no servidor acerca dos conteúdos publicados, a critério exclusivo, o que não significará concordância automática com o disponibilizado.
                    </p>

                    <p>
                        De acordo com os Termos, os Usuários assumem a obrigação de usar o site e o aplicativo de forma positiva, respeitando o princípio da boa-fé e sem enviar ao sistema material que seja ameaçador, violento, difamatório, pornográfico, abusivo, ofensivo, racista, discriminatório ou em desacordo com os direitos fundamentais resguardados pelo texto constitucional de 1988 ("Constituição da República Federativa do Brasil") ou outra legislação vigente;
                    </p>

                    <p>
                        O Usuário ainda manifesta que só fará uso de materiais que tenham todas licenças e/ou aprovações necessárias;
                    </p>

                    <p>
                        Não irá incitar condutas que possam ser consideradas ilícitas na esfera civil e criminal ou que viole direito de terceiros em território nacional ou internacional, ou ilegais de qualquer forma;
                    </p>

                    <p>
                        Não fará o uso de fraudes ou dissimulações a fim de viciar as contratações ou escolhas dos Usuários ou funcionamento do sistema, como violações de segurança, protocolos, perfis falsos, ou qualquer outro meio.
                    </p>

                    <p>
                        Os Usuários que não agirem em conformidade e zelo para o bom funcionamento da plataforma serão responsabilizados por qualquer danos e prejuízos que causarem.
                    </p>

                    <p>
                        A Plataforma informa que irá cooperar com autoridades que necessitarem identificar Usuários que estejam em desconformidade com os dispostos nestes Termos.
                    </p>

                    <p>
                        O Usuário assume total responsabilidade por prejuízos, diretos ou indiretos, incluindo indenizações, lucros cessantes, honorários advocatícios e demais encargos judiciais ou extrajudiciais que o Jurídico Já seja obrigado a incorrer em razão de ato ou omissão do Usuário.
                    </p>

                    <p>
                        O Jurídico Já terá direito a indenização por danos causados pelo Usuário decorrentes de atividades no site ou por descumprimento dos termos gerais de uso e demais políticas, ou direitos de terceiros, incluindo honorários de advogados.
                    </p>

                    <p>
                        A manutenção do conteúdo será feita a critério exclusivo da Plataforma, sem que caracterize violação aos direitos adquiridos pelos Usuários.
                    </p>

                    <p>
                        Em caso de descumprimento dos dispostos nestes Termos por parte da Jurídico Já não deverá ser interpretado como desconsideração dos constantes neste dispositivo.
                    </p>
                    <p>
                        A plataforma está em acordo com todas as leis e tratados vigentes em território brasileiro. Caso o Usuário utilize a plataforma em outro local, estará sujeito ao cumprimento das normas locais.
                    </p>

                    <p className="two">
                        Para manter a qualidade dos serviços ofertados pela plataforma, a Jurídico Já possui o direito de impedir ou interromper acesso de determinado Usuário que atue em desconformidade com o que consta nos Termos e Condições de Uso, da Política de Privacidade ou contratos que se celebrem através do uso da plataforma, sem a necessidade de notificação prévia.
                    </p>

                    <p>DA PROPRIEDADE INTELECTUAL E PRIVACIDADE</p>

                    <p>
                        O uso do nome “Jurídico Já”, bem como os conteúdos que o acompanhe na prática empresarial como nome empresarial, marca, domínio, programas, banco de dados, documentos, são de propriedade exclusiva do Jurídico Já e estão protegidos por leis e tratados, visto registro em órgão competente. A reprodução ou uso sem a devida autorização legal é expressamente proibido, acarretando responsabilidade penal e civil.
                    </p>
                    <p>
                        A página dos usuários pode expor links de outros sites, o que não significa que o Jurídico Já é proprietário dos referidos.
                    </p>

                    <p>
                        A Jurídico Já não possui responsabilidade pelo conteúdo, prática e serviços ofertados em site de terceiros, ainda que apresentados em sua plataforma.
                    </p>
                    <p>
                        Os links de outros sites apresentados na plataforma não incidem em relação de sociedade, supervisão, cumplicidade, solidariedade ou posse.
                    </p>
                    <p>
                        Parte das informações disponíveis são geradas e transmitidas por terceiros, sem o envolvimento ou responsabilidade da Plataforma.
                    </p>
                    <p>
                        A Jurídico Já se compromete a nunca alterar o conteúdo que for produzido por terceiros.
                    </p>
                    <p className="two">
                        A privacidade dos Usuários está protegida, podendo os dados pessoais serem usados somente com consentimento prévio do usuário. No entanto, há exceções por razões práticas onde o Jurídico Já se reserva no direito de fazer o uso dessas informações quando necessário se não houver meios de obter o consentimento prévio.
                    </p>


                    <p>OUTRAS INFORMAÇÕES</p>

                    <p className="two">
                        Fica eleito o Foro da cidade de Belo Horizonte no Estado de Minas Gerais para resolução de controvérsias. Entretanto, em conformidade com o artigo 101 do Código de Defesa do Consumidor, serão feitas exceções àquelas reclamações de usuários que se enquadrem legalmente como consumidores, podendo suas queixas serem submetidas ao foro da cidade que residirem, caso não ocorra solução amigável entre as partes.
                    </p>


                    <p>TERMOS E CONDIÇÕES DOS USUÁRIOS</p>

                    <p className="two">
                        Uma vez conectados na plataforma da empresa Jurídico Já por meio de uma Conta de Usuário Contratante, os utilizadores deverão zelar pelo cumprimento dos Termos e Condições de Uso cabíveis as partes.
                    </p>

                    <p>USUÁRIO CONTRANTE</p>

                    <p>DA RELAÇÃO</p>

                    <p className="two">
                        O Contratante, expressamente qualificado nestes Termos, afirma e reconhece que não há vínculos além do negócio jurídico, reiterando que não existe relação trabalhista ou societária. A prestação de serviço por parte da Jurídico Já se dá de igual forma para o Contratante e para o Correspondente.
                    </p>

                    <p>DO CADASTRO</p>

                    <p>Cada usuário poderá manter somente um cadastro junto a plataforma Jurídico Já.</p>
                    <p>O cadastro é pessoal e intransferível.</p>
                    <p>
                        Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra forma de transferência da conta. Também não se permitirá a criação de novos cadastros por pessoas cujos cadastros originais tenham sido suspensos de alguma forma por infração às políticas do site.
                    </p>
                    <p>
                        O cadastro é confirmado após o usuário preencher todos os campos obrigatórios, com informações exatas, precisas e verdadeiras. O usuário declarara e assume o compromisso de atualizar os dados inseridos em seu cadastro sempre que necessário ou tão logo houver modificações.
                    </p>
                    <p>
                        Os Usuários não devem cadastrar apelidos que possuam qualquer semelhança com o nome Jurídico Já ou qualquer outro nome de propriedade intelectual, tampouco deverá insinuar ou sugerir que os serviços anunciados na plataforma são de autoria da Jurídico Já. Também serão desconsiderados quaisquer atos ofensivos ao pleno funcionamento do sistema.
                    </p>
                    <p>
                        A Jurídico Já se reserva no direito de recusar solicitações de cadastros que estejam em desacordos com as políticas e regras dos presentes Termos e Condições Gerais, assim como possui o direito de suspender um cadastro previamente aceito.
                    </p>
                    <p>
                        A Jurídico Já poderá, a seu exclusivo critério, realizar buscas a fim de verificar os dados fornecidos pelos parceiros, julgar os serviços prestados e solicitar dados adicionais e documentos que se fizerem necessários para conferir as informações cadastradas, podendo ainda utilizar-se de base de dados públicas ou privadas.
                        Fornecer informações incompletas, falsas ou imprecisas violam toda a política dos Termos e Condições de Uso desta plataforma, estando o Usuário plenamente responsável pelos danos que vierem a ser causados por tal transgressão.
                    </p>
                    <p>
                        A Plataforma se reserva no direito de solicitar mais informações e documentos sobre o Usuário Contratante a qualquer tempo. Uma vez que se recuse a fornecer tais informações o usuário estará ferindo com os Termos.
                    </p>
                    <p>
                        A concessão de prazo para pagamento dos serviços poderá ou não ser concedido em função de análise do perfil do contratante, bem como documentos. Podendo tal concessão ser suspensa ou cancelada a qualquer tempo.
                    </p>
                    <p>
                        Qualquer problema encontrado no cadastro do usuário poderá trazer sanções a serem aplicadas pelo Jurídico Já, sendo essas a suspensão temporária ou definitiva da conta, sem prejuízo de outras medidas que entender necessárias e oportunas. Uma vez aplicada qualquer sanção será, automaticamente, cancelada os anúncios dos respectivos usuários, não cabendo a esta qualquer indenização ou ressarcimento.
                    </p>
                    <p>
                        A Jurídico Já não se responsabiliza pelos Dados Pessoais inseridos pelos Usuários. Esses devem garantir a sua veracidade e respondem, em qualquer caso, civil e criminalmente pelas informações cadastradas.
                        2.9.1 É de responsabilidade do usuário garantir a proteção de seus dados para acesso à plataforma, não podendo nenhuma contratação ser cancelada em razão do uso incorreto dos dados de acesso.
                    </p>
                    <p className="two">
                        O usuário acessará a sua conta através do apelido/e-mail (login) e senha, comprometendo-se a não informar a terceiros desautorizados esses dados, responsabilizando-se integralmente pelo uso que deles seja feito.
                    </p>
                    <p>DO PAGAMENTO FEITO PELO CONTRATANTE</p>
                    <p>
                        3.1.1 As transações de pagamentos online descritas neste mesmo Termo serão feitas pela Jurídico Já, a critério da Jurídico Já. Portanto, quando a pactuação de pagamento for feita foram do Jurídico Já, ou esta não for a responsável, não havendo envolvimento da Jurídico Já com o procedimento, não se responsabiliza por falhas ou erros apresentados durante o processamento de pagamentos que podem vir a prejudicar a prestação de serviços pelo Correspondente.
                    </p>
                    <p>
                        A Jurídico Já não autoriza que nenhuma taxa seja cobrada em seu nome, sem prévia e expressa autorização legal, sob pena de sanções penais e civis.
                    </p>
                    <p>
                        Quando os serviços forem solicitados ao Jurídico Já e não diretamente ao Correspondente, a Jurídico Já poderá cobrar o valor que considerar justo e estiver dentro do usualmente praticado em função da comarca e complexidade do ato, fazendo o Correspondente jus àquilo que foi pactuado na sua proposta de serviços ou valores previamente cadastrados no site e podendo o Contratante aceitar ou não o valor proposto.
                    </p>
                    <p>
                        O pagamento deverá ser efetuado em até 5 dias úteis após o recebimento da planilha ou fatura para os CONTRATANTES que tem prazo aprovado no momento do cadastro, em caso de boleto este deve ser pago data de vencimento, para CONTRATATES que dispõe de prazo.
                    </p>
                    <p className="two">
                        Aqueles que não tiverem cadastro aprovado para tal modalidade de pagamento postergado, deverão realizar o pagamento a vista por um dos meios disponibilizados pela Jurídico Já.
                    </p>
                    <p>DAS OBRIGAÇÕES E DIREITOS</p>
                    <p>
                        O Contratante alega ter conhecimento de que durante o uso da plataforma da Jurídico Já não cabe à Empresa responsabilização por questões que estejam intrínsecas a negociação jurídica ou prestação de serviços que por meio da plataforma são contratados.
                    </p>
                    <p>
                        Reconhece ainda que danos que sejam eventualmente causados ao Contratante ou a Terceiro pelo Correspondente ou por terceiro não são de responsabilidade da Plataforma; E
                        Indenizações ou reparações por danos sofridos por outros Contratantes, Correspondentes ou terceiro não são da competência da Empresa.
                    </p>
                    <p>
                        É responsabilidade única e exclusiva do Contratante garantir fundos para contratação de Correspondente.
                    </p>
                    <p>
                        Ao Contratante é garantida a livre escolha no processo de contratação de Correspondente na plataforma.
                    </p>
                    <p>São práticas vedadas ao usuário:</p>
                    <p>
                        Os usuários se comprometem e aceitam de boa-fé utilizar a plataforma sem violar os termos e condições de uso ou qualquer outra disposição referente a plataforma;
                    </p>
                    <p>Violar qualquer norma ou lei atualmente vigente no ordenamento jurídico brasileiro.</p>
                    <p>Os usuários não podem interferir nas negociações de outros usuários;</p>
                    <p>Não é permitido aos usuários manipular os preços das prestações de serviço anunciadas;</p>
                    <p>
                        Divulgar dados pessoais de contato por qualquer meio sem autorização prévia, inclusive de terceiro;
                    </p>
                    <p>Anunciar serviços proibidos pela legislação;</p>
                    <p>Agredir, caluniar, injuriar ou difamar outros usuários;</p>
                    <p className="two">
                        Não é permitido usar dispositivo, software ou outro recurso que interfira nas atividades da plataforma. Qualquer intromissão acarretará nas devidas medidas legais e sanções anteriormente previstas.
                    </p>
                    <p>DO CONTEÚDO DE TERCEIROS</p>
                    <p>
                        De acordo com a Lei n° 12.965/2014 ("Uso da Internet no Brasil"), artigo 18: “O provedor de conexão à internet não será responsabilizado civilmente por danos decorrentes de conteúdo gerado por terceiros”. Portanto, as informações providas pelo Correspondentes, as quais o Contratante possui amplo acesso, como nome, telefone de contato, localização, entre outras, não são responsabilidade da Jurídico Já.
                    </p>
                    <p className="two">
                        O disposto no item anterior se aplica igualmente a qualquer informação fornecida sobre serviços, tais como valores cobrados, características dos serviços, etc., são de responsabilidade única de seu anunciante, sem envolvimento por parte da Plataforma.
                    </p>
                    <p>DA RESCISÃO</p>
                    <p>
                        O Contratante tem o direito de cancelar sua conta ou assinatura na plataforma a qualquer momento.
                    </p>
                    <p>O cancelamento não incide em desobrigação de cumprimento das obrigações anteriormente assumidas.</p>
                    <p>Condições de cancelamento são tratadas entre Contratante e Correspondente.</p>
                    <p>
                        A Jurídico Já se reserva no direito de suspender, cancelar, impedir ou interromper o acesso do Usuário Contratante a plataforma, sem aviso prévio, uma vez não seguidas as regras dispostas nos Termos e Condições de Uso, contratos celebrados por meio da plataforma, ou qualquer outro dispositivo que viole o bom funcionamento do site e aplicativo.
                    </p>
                    <p>O usuário infrator poder ser notificado sobre o uso inadequado, à critério da Jurídico Já.</p>
                    <p>
                        Na suspeita de uso inadequado ou reincidência o usuário Contratante poderá ter o cadastrado cancelado.
                    </p>
                    <p>
                        Uma vez cancelado o cadastro do Contratante as obrigações contraídas pendentes devem ser concluídas com totalidade de pagamentos devidos aos Correspondentes.
                    </p>
                    <p>
                        A Empresa se reserva no direito de interromper o acesso e uso do Serviço do Usuário Contratante caso este tenha decretado falência ou perca autorização para exercer sua atividade.
                    </p>
                    <p className="two">
                        A Empresa não se responsabiliza por eventual perda de prazos processuais, erros na realização, demais danos por atrasos ou não realização dos atos contratados, devendo o CONTRATANTE, solicitar os serviços com prazo adequado, para caso não possam serem realizados buscar outra meio de realização em tempo hábil.
                    </p>
                    <p>USUÁRIO CORRESPONDENTE</p>
                    <p className="two">
                        Uma vez conectado na plataforma da empresa Jurídico Já por meio de uma Conta de Usuário de Usuário Correspondente, os utilizadores deverão zelar pelo cumprimento dos Termos e Condições de Uso cabíveis as partes.
                    </p>
                    <p>DA RELAÇÃO</p>
                    <p className="two">
                        O Correspondente, expressamente qualificado nestes Termos, afirma e reconhece que não há vínculos além do negócio jurídico, reiterando que não existe relação trabalhista ou societária. A prestação de serviço por parte da Jurídico Já se dá de igual forma para o Contratante e para o Correspondente.
                    </p>
                    <p>DO CADASTRO</p>
                    <p>Cada usuário poderá manter somente um cadastro junto a plataforma Jurídico Já.</p>
                    <p>O cadastro é pessoal e intransferível.</p>
                    <p>
                        Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra forma de transferência da conta. Também não se permitirá a criação de novos cadastros por pessoas cujos cadastros originais tenham sido suspensos de alguma forma por infração às políticas do site.
                    </p>
                    <p>
                        O cadastro é confirmado após o usuário preencher todos os campos obrigatórios, com informações exatas, precisas e verdadeiras. O usuário declarara e assume o compromisso de atualizar os dados inseridos em seu cadastro sempre que necessário ou tão logo houver modificações.
                    </p>
                    <p>
                        Os Usuários não devem cadastrar apelidos que possuam qualquer semelhança com o nome Jurídico Já ou qualquer outro nome de propriedade intelectual, tampouco deverá insinuar ou sugerir que os serviços anunciados na plataforma são de autoria da Jurídico Já. Também serão desconsiderados quaisquer atos ofensivos ao pleno funcionamento do sistema.
                    </p>
                    <p>
                        A Jurídico Já se reserva no direito de recusar solicitações de cadastros que estejam em desacordos com as políticas e regras dos presentes Termos e Condições Gerais, assim como possui o direito de suspender um cadastro previamente aceito.
                    </p>
                    <p>
                        A Jurídico Já poderá, a seu exclusivo critério, realizar buscas a fim de verificar os dados fornecidos pelos parceiros, julgar os serviços prestados e solicitar dados adicionais e documentos que se fizerem necessários para conferir as informações cadastradas, podendo ainda utilizar-se de base de dados públicas ou privadas.
                    </p>
                    <p>
                        Fornecer informações incompletas, falsas ou imprecisas violam toda a política dos Termos e Condições de Uso desta plataforma, estando o Usuário plenamente responsável pelos danos que vierem a ser causados por tal transgressão.
                    </p>
                    <p>
                        A Plataforma se reserva no direito de solicitar mais informações e documentos sobre o Usuário Correspondente a qualquer tempo. Uma vez que se recuse a fornecer tais informações o usuário estará ferindo com os Termos.
                    </p>
                    <p>
                        Qualquer problema encontrado no cadastro do usuário poderá trazer sanções a serem aplicadas pelo Jurídico Já, sendo essas a suspensão temporária ou definitiva da conta, sem prejuízo de outras medidas que entender necessárias e oportunas. Uma vez aplicada qualquer sanção será, automaticamente, cancelada os anúncios dos respectivos usuários, não cabendo a esta qualquer indenização ou ressarcimento.
                    </p>
                    <p>
                        A Jurídico Já não se responsabiliza pelos Dados Pessoais inseridos pelos Usuários. Esses devem garantir a sua veracidade e respondem, em qualquer caso, civil e criminalmente pelas informações cadastradas.
                    </p>
                    <p>
                        É de responsabilidade do usuário garantir a proteção de seus dados para acesso à plataforma, não podendo nenhuma contratação ser cancelada em razão do uso incorreto dos dados de acesso.
                    </p>
                    <p>
                        O usuário acessará a sua conta através do apelido/e-mail (login) e senha, comprometendo-se a não informar a terceiros desautorizados esses dados, responsabilizando-se integralmente pelo uso que deles seja feito.
                    </p>
                    <p>Requerimentos para Usuário Correspondente:</p>
                    <p>
                        Para efetivar o cadastro como Correspondente, o usuário deverá adquirir um plano junto a plataforma, podendo escolher o meio de pagamento pelo qual será cobrado.
                    </p>
                    <p>
                        Será cobrada uma taxa mensal, podendo o valor ser modificado a qualquer tempo, mediante aviso prévio por qualquer meio de comunicação entre a plataforma e o usuário.
                    </p>
                    <p>
                        A cobrança se dará no início de um período de 30 dias, que pode ou não coincidir com o ciclo de cobrança. Uma vez pago, não haverá reembolso em casos de cancelamento, ficando o acesso disponível até o fim do ciclo.
                    </p>
                    <p>
                        A cobrança será suspensa no mês subsequente ao cancelamento feito pelos meios disponíveis na plataforma.
                    </p>
                    <p>
                        A exposição do Correspondente está relacionada ao pagamento dos valores relativos ao plano escolhido. Caso seja identificado a falta de pagamento ou de renovação, o serviço estará suspenso para o usuário.
                    </p>
                    <p>
                        De acordo com a OAB, Provimento n. 94/2000, existem condições para manter os perfis de correspondentes, essas devem ser observadas a todo tempo.
                    </p>
                    <p className="two">
                        O uso da plataforma em desacordo com as regras dispostas no item anterior pode acarretar sanções disciplinares, bem como responsabilização no âmbito civil e penal. O Usuário Correspondente reconhece que é o responsável por todas as sanções aplicadas, sendo a Jurídico Já alheia aos prejuízos de ação ou omissão por parte do colaborador.
                    </p>
                    <p>DO PAGAMENTO</p>
                    <p>
                        Correspondente poderá receber por seus serviços anunciados via cartão de crédito, débito em conta corrente, boleto bancário ou outros meios de pagamento online que estejam disponíveis na plataforma, à critério da Jurídico Já.
                    </p>
                    <p>
                        As transações de pagamentos online descritas neste mesmo Termo serão feitas por terceiros prestadores unicamente desses serviços. Portanto, não há envolvimento da Jurídico Já com o procedimento, não sendo este responsável por falhas ou erros apresentados durante o processamento de pagamentos que podem vir a prejudicar a prestação de serviços pelo Correspondente.
                    </p>
                    <p>
                        A Plataforma recebe sobre a remuneração do Correspondente uma taxa de 9% sobre o valor recebido por este, quando feito o pagamento através dos meios disponibilizados pelo próprio site ou aplicativo e a contratação se der diretamente na página individual do Correspondente.
                        Pagamentos que tenham sido acordados fora dos meios citados neste termo não são de responsabilidade da empresa e sua transação depende única e exclusivamente das partes e do pactuado entre elas.
                    </p>
                    <p>
                        A Jurídico Já não autoriza que nenhuma taxa seja cobrada em seu nome, sem prévia e expressa autorização legal, sob pena de sanções penais e civis.
                    </p>
                    <p className="two">
                        Quando os serviços forem solicitados ao Jurídico Já e não diretamente ao Correspondente, a Jurídico Já poderá cobrar o valor que considerar justo, fazendo o Correspondente jus àquilo que foi pactuado na sua proposta de serviços ou valores previamente cadastrados no site e podendo o Contratante aceitar ou não o valor proposto. Podendo a Jurídico Já abater impostos ou mesmo realizar a retenção na fonte, à seu critério.
                    </p>
                    <p>DAS OBRIGAÇÕES E DIREITOS</p>
                    <p>
                        Ao usuário não será garantido exclusividade, direito de reembolso por arrependimento – além dos previstos em lei –, incremento em sua clientela e a boa-fé dos Contratantes.
                    </p>
                    <p>
                        Entre os planos supramencionados, há a oferta de prioridade de exibição que está condicionada aos seguintes critérios: Plano adquirido, recomendações, localização da demanda e disponibilidade por parte do Usuário Correspondente.
                    </p>
                    <p>A demanda a ser realizada será recebida pelo Correspondente através da plataforma.</p>
                    <p>
                        As reclamações e mudanças que podem ocorrer durante a prestação de serviço, tais como mudança no serviço ou valor acordado, deverão ser tratados entre o Contratante e o Usuário Correspondente.
                    </p>
                    <p>
                        As reclamações relacionadas às contratações serão de responsabilidade exclusiva do Correspondente que se compromete em resolver e arcar com todas as hipóteses e possibilidade de ressarcimento pelo serviço não concluído conforme acordado entre as partes.
                    </p>
                    <p>
                        Se a Plataforma se ver obrigada a arcar com custos gerados por inadimplemento do Correspondente, este estará obrigado a reembolsar todos os valores despendidos pela Jurídico Já, que poderá inclusive descontar dos eventuais valores à receber do CORRESPONDENTE.
                    </p>
                    <p>
                        O Usuário se compromete a manter o seu perfil atualizado. As informações necessárias são nome, telefone, endereço eletrônico, região de atuação, e entre outras.
                    </p>
                    <p>
                        Ainda se obriga a realizar o atendimento aos Contratantes de acordo com o previsto nas políticas de boa-fé desta plataforma, expressas em todo os Termos de Uso.
                    </p>
                    <p>
                        Será responsabilidade do Correspondente arcar com os ônus incididos sobre ocorridos da prestação de serviço, como inconformidade com os padrões esperados de qualidade, etc.
                    </p>
                    <p>
                        O Usuário, ainda, se obrigada perante a Jurídico Já a indenizar por perdas relativas ao descumprimento dos termos ou pelas obrigações assumidas na plataforma ou outros documentos vinculativos.
                    </p>
                    <p>
                        De acordo com a Lei nº 13.105 de 2015 (“Código de Processo Civil”), artigo 125, o Usuário reconhece que será denunciado à lide em qualquer ação que a Jurídico Já venha fazer parte no polo passivo em razão da prestação de serviço do Correspondente.
                    </p>
                    <p>
                        O Correspondente afirma ciência quanto a não relação da Jurídico Já com as tratativas das contratações, incluindo:
                    </p>
                    <p>Capacidade dos usuários da plataforma para contratar os serviços e efetuar o pagamento por eles.</p>
                    <p>
                        Danos sofridos pelo Correspondente ou Terceiros pelos Contratantes ou por Terceiros na Contratação e/ou na prestação de serviços de Correspondentes.
                    </p>
                    <p>Indenizações ou reparação de danos causados por qualquer usuário da plataforma.</p>
                    <p className="two">É assegurado à Jurídico Já regresso em qualquer caso de reparação que a empresa tenha que arcar.</p>
                    <p>DO CONTEÚDO DE TERCEIROS</p>
                    <p>
                        De acordo com a Lei n° 12.965/2014 ("Uso da Internet no Brasil"), artigo 18: “O provedor de conexão à internet não será responsabilizado civilmente por danos decorrentes de conteúdo gerado por terceiros”. Portanto, as informações providas pelo Contratante, as quais o Correspondente possui amplo acesso, como nome, telefone de contato, localização, entre outras, não são responsabilidade da Jurídico Já.
                    </p>
                    <p className="two">
                        O disposto no item anterior se aplica igualmente a qualquer informação fornecida sobre serviços, tais como valores cobrados, características dos serviços, etc., são de responsabilidade única de seu anunciante, sem envolvimento por parte da Plataforma.
                    </p>
                    <p>DA RESCISÃO</p>
                    <p>
                        O Correspondente tem o direito de cancelar sua conta ou assinatura na plataforma a qualquer momento.
                    </p>
                    <p>O cancelamento não incide em desobrigação de cumprimento das obrigações anteriormente assumidas.</p>
                    <p>Condições de cancelamento são tratadas entre Contratante e Correspondente.</p>
                    <p>
                        A Jurídico Já se reserva no direito de suspender, cancelar, impedir ou interromper o acesso do Usuário Correspondente a plataforma, sem aviso prévio, uma vez não seguidas as regras dispostas nos Termos e Condições de Uso, contratos celebrados por meio da plataforma, ou qualquer outro dispositivo que viole o bom funcionamento do site e aplicativo.
                    </p>
                    <p>O usuário infrator será notificado sobre o uso inadequado.</p>
                    <p>
                        Na suspeita de uso inadequado ou reincidência o usuário Correspondente poderá ter o cadastrado cancelado.
                    </p>
                    <p>
                        Uma vez cancelado o cadastro do Correspondente as obrigações contraídas pendentes devem ser concluídas em totalidade.
                    </p>
                    <p className="two">
                        A Empresa se reserva no direito de interromper o acesso e uso do Serviço do Usuário Correspondente caso este tenha decretado falência ou perca autorização para exercer sua atividade.
                    </p>
                    <p>TERMOS DE SERVIÇO</p>
                    <p className="two">
                        O site www.juridicoja.com e seu respectivo aplicativo, os correspondentes e os contratantes resolvem contratar entre si, mediante as seguintes cláusulas e condições:
                    </p>
                    <p>DOS SERVIÇOS</p>
                    <p>Distribuição de ações ou protocolos;</p>
                    <p>Realização de audiências;</p>
                    <p>Relatórios processuais;</p>
                    <p>Despachar petições;</p>
                    <p>
                        Interceder perante juízes, desembargadores, ministros e cartórios nos serviços que dependem de uma melhor compreensão oral da matéria alvejada;
                    </p>
                    <p>Cópias de acórdãos, de peças e de andamentos de processos;</p>
                    <p>Realizar consulta de autos e de protocolos;</p>
                    <p>Retirar certidões nos Tribunais, Fóruns e demais Órgãos Públicos;</p>
                    <p>
                        Realizar diligências até delegacias para verificação de inquéritos policiais; acompanhar cumprimento de determinações judiciais; dentre outros serviços jurídicos aqui não especificados;
                    </p>
                    <p>Enviar as publicações recebidas diariamente ao e-mail indicado pelo solicitante;</p>
                    <p className="two">Outras a serem definidas e pelo CONTRATANTE e aceitas pela Jurídico Já e/ou correspondentes.</p>

                    <p>DA SOLICITAÇÃO</p>
                    <p>Os serviços deverão ser solicitados, obrigatoriamente pelo site juridicoja.com.</p>
                    <p>
                        Para casos urgentes, que envolvam prazos exíguos, o escritório solicitante ou advogado deve informar através do botão para pedidos urgentes, pois em condições normais os serviços terão início após a confirmação de recebimento e serão finalizados em até 7 dias úteis. Nos casos urgentes a contratada fará todo o possível para atender dentro do solicitado, contudo não sendo garantido o cumprimento antes do prazo de 7 dias úteis.
                    </p>
                    <p>
                        A data solicitação será considerada dentro do horário de 8h às 16h, a partir deste horário será considerada como recebida no dia seguinte.
                    </p>
                    <p>
                        Os serviços solicitados possuem duas modalidades de entrega: Via e-mail, plataforma ou via correios, neste caso com acréscimo do valor cobrado pelo mesmo.
                    </p>
                    <p>
                        Para receber os serviços por Sedex 10, é necessário confirmar no site dos Correios se o CEP de destino é atendido pelo respectivo serviço e realizar pagamento prévio do valor da postagem.
                    </p>
                    <p className="two">
                        Caso o solicitante não tenha quitado débitos de solicitações anteriores ou não tenha comprovado seu efetivo pagamento, sua nova solicitação de serviços não poderá ser atendida antes da quitação.
                    </p>

                    <p>DO PREÇO E DA FORMA DE PAGAMENTO</p>
                    <p>
                        Os preços das diligências obedecem às tabelas da Ordem dos Advogados quando no Estado correspondente ao serviço houver, levando em conta ainda fatores como local, dificuldade de acesso, valores praticados pelos demais advogados, simplicidade ou complexidade do ato. Podendo haver desconto por eventual simplicidade do ato praticado.
                    </p>
                    <p className="two">
                        O Contratante, nos casos de depósito, deverá enviar o comprovante do mesmo para fins de baixa junto à Contratada.
                    </p>

                    <p>DA EXECUÇÃO</p>
                    <p className="two">
                        Os serviços são realizados nas comarcas, nos fóruns e respectivas varas e Cartórios extrajudiciais; PROCON; Serasa; SPC; na Junta Comercial; nos Ministérios; nas Embaixadas; nos Consulados; nos órgãos Legislativo (Câmara dos Deputados); nos órgãos do Executivo; nos Bancos; nos Conselhos e Órgãos de Classe; no Tribunal de Contas do Estado; na OAB; no CRC; CRM; CRO; nas Procuradorias e em todos os demais órgãos administrativos, desde que localizados na Capital de cidade adjacentes.
                    </p>

                    <p>DAS CÓPIAS</p>
                    <p>
                        As cópias solicitadas por meio de fotografia, ou por e-mail, serão encaminhadas via e-mail ou sistema a depender do tamanho.
                    </p>
                    <p>
                        As imagens ficarão disponíveis pelo prazo de 1 (um) dia. A Contratada não fará backup das imagens, que serão descartadas após o período mencionado.
                    </p>
                    <p>
                        Cópias, por meio de fotografia, serão realizadas até o limite de 100 páginas. Caso esse número seja ultrapassado, as cópias excedentes serão reprografadas e enviadas por e-mail ou sistema, no prazo previsto para esta modalidade, podendo haver cobrança extra mediante prévia autorização.
                    </p>
                    <p>
                        A fotografia de documentos pode apresentar eventual perda de qualidade, em razão de uma série de fatores (qualidade e estado de conservação do original, falta de iluminação do ambiente, espaço físico, movimentação de pessoas). Caberá exclusivamente ao Solicitante, no momento em que optar pela modalidade fotográfica, analisar a viabilidade do procedimento para o fim a que se destina. É imprescindível que o Solicitante peça em tempo hábil a extração das cópias capa a capa, ou das peças essenciais para formação do recurso de agravo ou qualquer outro recurso cujo prazo seja peremptório.
                    </p>
                    <p>
                        Em razão do volume de folhas, a cópia do processo capa a capa não serão encaminhadas por fax ou outra plataforma.
                    </p>
                    <p className="two">
                        Dependendo do Tribunal ou órgão público, será necessária autorização específica “substabelecimento com reserva de poderes com a especificação do ato a ser praticado” para que os advogados, estagiários e colaboradores do Escritório possam extrair cópias, o prazo de entrega será contado a partir do recebimento deste.
                    </p>

                    <p>DO PROTOCOLO E DISTRIBUIÇÃO</p>
                    <p>
                        Nos casos de protocolo de petições e de distribuição de ações, é essencial que as peças estejam acompanhadas de procurações, das cópias suficientes para o protocolo e contrafé (quando necessário), e das guias devidamente pagas e preenchidas. A petição deve estar corretamente endereçada e conter as demais informações imprescindíveis à realização do protocolo e da distribuição, o local de protocolo levará em conta o endereçamento da peça. Não são efetuados recolhimentos de custas. Estes deverão ser realizados previamente pelo Solicitante.
                    </p>
                    <p className="two">
                        É responsabilidade do Solicitante encaminhar pelo correio, para o Escritório, em tempo hábil, os documentos para protocolo ou distribuição, logo após o requerimento via e-mail. Cabe exclusivamente ao Solicitante remeter o protocolo devidamente instruído e endereçado para que os Advogados, Estagiários e demais colaboradores para realizem o protocolo em tempo hábil. Os protocolos devem ser enviados em tempo hábil para cumprimento de prezo processual, podendo a CONTRATADA realizá-lo em até 7 dias úteis da data de recebimento.
                    </p>
                    <p>DAS CERTIDÕES</p>
                    <p>
                        As certidões do TJ, TRE, TRT, TRF que forem onerosas deverão os valores das mesmas serem reembolsados à Contratada, quando solicitadas pelo Contratante.
                    </p>
                    <p className="two">
                        Dependendo do Tribunal ou órgão público, será necessária autorização específica para que os Advogados, Estagiários e Colaboradores do Escritório retirem a certidão.
                    </p>
                    <p>DA AUDIÊNCIA</p>
                    <p className="two">
                        A audiência é um ato processual importante para o bom resultado do processo e da ação, por isso, é importante que o escritório solicitante ou advogado estabeleça para uso dessa modalidade de serviço um contato com no mínimo 07 (sete) dias de antecedência de sua realização, deverá ser encaminhado via site informações sobre o processo e o local e horário para encontro com as partes, além do horário, dia e local da audiência, devendo as orientações serem enviadas com no mínimo 24 horas antes do início da audiência. Caso seja audiência de instrução o escritório solicitante ou advogado deverá encaminhar um rol de perguntas a serem feitas as testemunhas que serão ouvidas em juízo, deverá também o solicitante encaminhar o competente substabelecimento com reserva de poderes para a realização do ato.
                    </p>
                    <p>DOS DESPACHOS</p>
                    <p className="two">
                        Existem casos na prática forense que exige ante a busca de resultados positivos e mais céleres que o instrumental jurídico seja despachado, por esta razão que o serviço despachar petição oferece a possibilidade do solicitante realizar este ato mesmo sem estar presente, o qual após sua concretização será encaminhado nas modalidades já previstas para o solicitante pelos meios já estipulados. Importante que seja encaminhado para o cumprimento do ato (resumo dos fatos, pontos controvertidos, e as solicitações e requerimentos a serem feitos para o destinatário).
                    </p>
                    <p>DO COMPARECIMENTO EM DELEGACIA</p>
                    <p className="two">
                        Por esta modalidade de serviço, contamos com profissionais preparados para dar todo o suporte de acompanhamento, verificação e tomada de providências perante as Delegacias de Polícia (Estadual ou Federal), outrossim, disponibilizamos profissionais para o acompanhamento de cumprimento de ordem judicial acertando os horários, verificando o oficial responsável pela diligência e acompanhando o cumprimento do ato, sendo que após o cumprimento do ato será encaminhado um relatório e os documentos envolvidos na diligência pelos meios já estipulados. Sendo essencial que o solicitante encaminhe o (resumo dos fatos, substabelecimento, as solicitações e requerimentos a serem feitos para os destinatários).
                    </p>
                    <p>DOS PRAZOS</p>
                    <p>Solicitação por meio de fotografias para Belo Horizonte – 7 dias úteis.</p>
                    <p>Solicitação por meio de fotografias para outras comarcas e Estados – 7 dias úteis.</p>
                    <p>
                        Solicitações em Embaixadas, Consulados e demais órgãos que dependam da juntada do original da procuração ou do substabelecimento e pedidos de certidão – 7 dias úteis.
                    </p>
                    <p>Solicitação para a realização de audiência no mínimo 05 dias de antecedência.</p>
                    <p>Observações:</p>
                    <p>
                        a) os prazos terão início no dia útil seguinte à confirmação do recebimento, e, nos casos específicos, após o recebimento da autorização exigida pelo Tribunal para extração das cópias.
                    </p>
                    <p>
                        b) os prazos poderão sofrer alterações (para maior ou menor) em virtude da complexidade do pedido ou da facilidade da diligência, considerando (outros serviços em destacamento, saída para diligência, etc.), bem como em decorrência dos procedimentos administrativos adotados pelos órgãos responsáveis, além dos casos em que para cumprimento da medida, o correspondente tiver que aguardar a devolução do processo e/ou procedimento da conclusão, da ausência de localização, indisponibilidade, entre outros fatores de força maior e caso fortuito.
                    </p>
                    <p>
                        c) para as solicitações de protocolo e de distribuição, além da confirmação de recebimento, o prazo estará condicionado, também, à chegada de documentos ao Escritório, quando forem imprescindíveis.
                    </p>
                    <p className="two">
                        d) execução de relatórios processuais está atrelada a quantidade de processo a serem consultados, a quantidade de volume e a concentração de cartório.
                    </p>
                    <p>DOS PREÇOS</p>
                    <p>
                        Os valores são fixos, de acordo com o serviço solicitado. Os valores das postagens (Sedex, Sedex 10, Carta Registrada ou retirada na Sede do Escritório) não estão computados no valor fixo e também serão pagos e apresentados com o pagamento.
                    </p>
                    <p>
                        Os valores atribuídos às despesas com postagens podem sofrer alterações, sem prévio aviso, de acordo com a prática adotada pela Empresa Brasileira de Correios e Telégrafos – EBCT.
                    </p>
                    <p className="two">
                        Os valores fixos correspondem às diligências realizadas pelos Advogados, Estagiários e Colaboradores da Contratada e não serão cobrados nos casos de impossibilidade de obtenção das cópias e/ou informações em razão de o processo, procedimento ou diligência estar indisponível ou se frustrar por fatores alheios ao solicitado (exemplos: carga realizada por Advogado, autos conclusos ao Magistrado, processo não localizado no Cartório, delegado, escrivão, juiz, desembargador ou serventuário ausente, queda de sistema, queda de energia, greve, caso fortuito ou de força maior, etc.).
                    </p>
                    <p>DO CANCELAMENTO</p>
                    <p>
                        O Solicitante deverá formalizar o pedido de cancelamento da solicitação até o início deslocamento para realização dos serviços, o qual se dá a partir da confirmação do recebimento, com a indicação dos fatos que ensejaram o pedido.
                    </p>
                    <p className="two">
                        Será desconsiderado para fins de pagamento somente nos casos em que os serviços não tiverem sido iniciados.
                    </p>
                    <p>OUTRAS CONSIDERAÇÕES</p>
                    <p>
                        Para a extração de cópias de autos de procedimentos administrativos que tramitam nos órgãos públicos ou paraestatais, faz-se necessário o envio de procuração original, assinada por Advogado com procuração nos autos.
                    </p>
                    <p>
                        Muitos Tribunais só autorizam a extração de cópias de autos de processos para o Advogado com procuração nos autos, mediante apresentação da carteira da OAB, portanto, o substabelecimento será essencial.
                    </p>
                    <p>
                        Os TRF’s só autorizam consulta de processos e extração de cópias mediante formulário próprio de Requerimento de Cadastro de Representantes de Advogados e Procuradores, disponível no portal do Tribunal, na Internet, original e devidamente assinado por advogados com procuração nos autos.
                    </p>
                    <p>
                        Cabe única e exclusivamente ao solicitante encaminhar as solicitações em tempo hábil e seguro para a execução dos serviços. A Contratada não se responsabiliza pelos prazos dos solicitantes, apenas pelo envio das publicações na data em que disponibilizadas e pela realização dos serviços solicitados por e-mail em tempo hábil.
                    </p>
                    <p>
                        Está ciente o Escritório solicitante ou Advogado que qualquer questão que envolva o presente termo de solicitação e seus regramentos serão dirimidos perante a Comarca de Belo Horizonte, por mais privilegiada que seja uma outra.
                    </p>
                    <p className="two">
                        Declara o solicitante que as informações solicitadas são lícitas e que os serviços solicitados não alteram o respectivo conteúdo, outrossim, que aceita os termos aqui propostos.
                    </p>
                    <p className="two">TERMOS DE USO E CONDIÇÕES GERAIS PARA PAGAMENTO</p>
                    <p>DO PAGAMENTO DAS DEMANDAS CONTRATADAS VIA SITE OU APP</p>
                    <p>
                        Os Usuários aceitam, expressamente, pagar uma taxa de licenciamento para uso da plataforma, bem como comissões e demais taxas e encargos que acompanharem o processo. Concordam a pagar ao Jurídico Já remuneração que envolverá: tarifa fixa de transação, taxa do meio de pagamento e outros serviços, em porcentagem declarada, por transação. Bem como, mensalidade para manutenção de conta, tarifa de adesão, mensalidade de licenciamento. Os valores serão celebrados no acordo de tarifas ofertadas no site e aceitas entre as partes.
                    </p>
                    <p>
                        A verificação de conta e preenchimento de formulário são fatores importantes para a utilização dos serviços. Os Usuários irão fornecer informações próprias: nome/razão social, CPF/CNPJ, endereço, endereço de cobrança, endereço eletrônico, números de telefones, conta bancária.
                    </p>
                    <p>A falta de veracidade nestas informações acarretará sanções ao Usuário.</p>
                    <p>
                        Todos os pagamentos devidos serão efetuados através da conta bancária informada no formulário de cadastro, sendo a remuneração do Jurídico Já automaticamente descontada do valor, conforme item 1.1, podendo ocorrer diferenças em razão do tipo de transação, meio de pagamento, segmento de atuação, forma de captura ou plano contratado.
                    </p>
                    <p>Os pagamentos poderão ser realizados das formas que seguem:</p>
                    <p>
                        Boleto Bancário: A liberação irá depender da data de liquidação do boleto bancário pelo consumidor. Deverão ser observadas as regras de compensação do banco ao qual o pagamento foi efetuado.
                    </p>
                    <p>
                        Cartão de Crédito: A liberação será feita a cada fim do ciclo de cobrança (30 dias), por parcela, contando a partir da data de aprovação do pagamento efetuado.
                    </p>
                    <p>
                        Débito em Conta: A liberação será feita a cada fim do ciclo de cobrança (30 dias), por parcela, contando a partir da data de aprovação do pagamento efetuado.
                    </p>
                    <p>Ou outra a critério da Jurídico Já.</p>
                    <p>
                        Os pagamentos serão realizados após a conclusão da demanda e entrega do serviço solicitado, podendo haver prévio pagamento de despesas a serem pactuadas. O comprovante de pagamento será disponibilizado e anexado ao número de solicitação da demanda ou fatura mensal, conforme o caso.
                    </p>
                    <p>
                        A Plataforma possui o prazo de 45 dias úteis para disponibilizar o pagamento ao Correspondente, após o pagamento pelo contratante.
                    </p>
                    <p className="two">
                        As transações bancárias que darão processamento ao pagamento, serão feitas no dia útil subsequente ao da solicitação de saque e estará totalmente sujeita ao funcionamento do banco.
                    </p>
                    <p>DA RESTITUIÇÃO</p>
                    <p>
                        A Plataforma possui políticas a respeito do cancelamento de transações e restituição. Essas deverão ser feitas no valor exato da transação.
                    </p>
                    <p>O Usuário deverá restituir a sua exclusiva responsabilidade.</p>
                    <p>
                        Em casos onde a Jurídico Já seja intimada a efetuar restituições, o Usuário será imediatamente notificado a fim de que este efetue a transação, a sua exclusiva responsabilidade.
                    </p>
                    <p className="two">
                        Caso a restituição não seja feita nos prazos e condições estipulados, será demandado processo de cobrança dos valores devidos.
                    </p>
                    <p>DOS CHARGEBACKS E ESTORNOS</p>
                    <p>
                        O presente item trata sobre o procedimento realizado pelo consumidor junto a operadora de cartão de débito ou crédito, alegando desconhecimento de transação específica efetuada com o cartão de sua titularidade.
                    </p>
                    <p>
                        Deverão ser observadas regras de estorno de cada bandeira, mantendo as boas práticas comerciais a fim de que se evite o tratado no item 3.1. Recomendamos que os comprovantes de transações sejam conservados.
                    </p>
                    <p>
                        Em caso de notificações de Chargerback recebidas pela Jurídico Já, o Usuário será notificado para realizar as devidas providências junto ao reclamante, no prazo MÁXIMO de 30 dias e iniciará o devido processo de cobrança de valores devidos.
                    </p>
                    <p>
                        No ato de descumprimento do prazo definido no item anterior, bem como falta de atualização à Plataforma sobre os trâmites, a Jurídico Já iniciará processo de cobrança.
                    </p>
                    <p>
                        O Usuário terá o limite máximo de 1% de pedidos de Chargerback no mês, em relação ao número máximo de transações, do contrário sua conta será suspensa pelo prazo de 60 dias.
                    </p>
                    <p>
                        Para reincidentes no disposto no item 3.4 será aplicada multa de 25% sob o valor do Chargerback e a suspensão se dará por 90 dias.
                    </p>
                    <p className="two">Para reincidentes em dobro, as sanções citadas no item 3.5 serão aplicadas em dobro.</p>
                    <p>DAS DISPUSTAS E RETENÇÕES</p>
                    <p>
                        A Jurídico Já reconhece a observância das regras de disputas quando em desacordo comercial e Chargebacks iniciados por seus Usuários. Em caso de disputas, os saldos estarão retidos até a sua resolução.
                    </p>
                    <p>
                        A disputas devem ser solucionadas em um prazo máximo de 30 dias, podendo variar de acordo com cada banco ou administradora de cartão. Durante o período de disputa, todo saldo a ela relacionado estará retido.
                    </p>
                    <p>
                        Se o Usuário perder a disputa, autoriza previamente que o saldo anteriormente retido seja usado para dar quitação a disputa ou qualquer outro débito proveniente ou junto ao Jurídico Já.
                    </p>
                    <p>
                        Os saldos dos Usuários poderão ser retidos para auditoria e apuração de conduta, quando o referido atuar em desacordo com as Políticas desta Plataforma e de estabelecimentos bancários, administradoras de cartão, lei e práticas de Mercado e/ou Normas do BACEN.
                    </p>
                    <p className="two">
                        Condutas em que forem constatadas fraude em termos gerais, lavagem de dinheiro, etc., ocasionarão cancelamento da conta do Usuário na Plataforma Jurídico Já. Nestes casos, os saldos serão retidos e as informações do infrator serão entregues de boa-fé às autoridades.
                    </p>
                    <p>DO DOMICÍLIO BANCÁRIO E COBRANÇA DE VALORES</p>
                    <p>
                        Por meio deste o Usuário expressamente autorizada a Plataforma Jurídico Já efetuar operações de débito automático em conta bancária vinculada à conta utilizada na Plataforma, conforme formulário de cadastro, para as finalidades definidas em itens anteriores.
                    </p>
                    <p>
                        Quando ocorrerem restituições, estornos ou cancelamentos em favor da Plataforma, o valor exato da respectiva transação deverá ser entregue a Jurídico Já pelo Usuário, sendo o valor corrigido sobre IGP-M/FGV (ou outro índice), contando a data do repasse, com juros de 1% (um por cento) a.m. ou fração pró-rata, com o acréscimo de encargos operacionais e qualquer perdas e danos ocorridos.
                    </p>
                    <p>Podem ser adotadas as seguintes medidas com fins de cobrança de valores devidos a Jurídico Já:</p>
                    <p>Compensação de valores através de outros créditos, presentes ou futuros, devidos.</p>
                    <p>Lançamentos de débitos na conta bancária vinculada.</p>
                    <p>
                        Compensação por meio de cheque, boleto, TED, DOC ou depósito identificado, de acordo com tratativas entre as partes.
                    </p>
                    <p>Escritórios de cobrança especializados.</p>
                    <p>Ações Judicial, protestos e negativações.</p>
                    <p>
                        Falta ou atraso de pagamento, seja total ou parcial, dentro dos prazos previamente acordados em contrato ou em respectivas atualizações, podem sujeitar o Usuário ao pagamento dos seguintes encargos:
                    </p>
                    <p>Atualização monetária das dívidas, baseando-se no IPC/FGV, ou outro índice.</p>
                    <p>
                        Acréscimo de juros de 2% (um por cento) a.m. Não obstante, o Jurídico Já se reserva no direito de incluir os débitos do Usuário no cadastro de Pendências Financeiras (PEFIN) e qualquer outro órgão de proteção de crédito.
                    </p>
                    <p className="two">Inscrição no órgãos de cadastro e proteção ao crédito.</p>
                    <p>DO PRAZO DE PAGAMENTO DAS DEMANDAS SOLICITADAS VIA EMAIL</p>
                    <p>
                        6.1. O correspondente contratado via email deverá enviar no mês subsequente à realização dos serviços contratado, planilha, conforme modelo fornecido pela JURÍDICO JÁ no ato do cadastro, com todas as demandas realizados no mês anterior.
                    </p>
                    <p>
                        6.2. A planilha deve ser enviada até o dia 10 de cada mês para financeiro@juridicoja.com, sendo o pagamento efetuado 40 dias depois do último dia para envio, qual seja dia 10 (dez) do mês subsequente à realização. O envio fora do prazo enseja pagamento junto com as diligências do mês subsequente.
                    </p>
                    <p>
                        6.3. O envio da planilha preenchida corretamente para o setor responsável é essencial para que seu pagamento seja efetuado, portanto, pedimos atenção quanto ao procedimento.
                    </p>
                    <p className="two">
                        6.4. O envio da ficha cadastral, bem como o da planilha padrão para o financeiro, constituem aceite e ciência das condições de pagamento supramencionadas. Caso hajam dúvidas quanto ao procedimento não aceitar este termo ou serviços e buscar esclarecimentos prévios.
                    </p>
                    <p>POLÍTICA DE PRIVACIDADE</p>
                    <p>
                        A Política de Privacidade que segue é um compromisso da Plataforma Jurídico Já com a privacidade de seus usuários. Além dessas regras, podem ser aplicadas a legislação vigente, tais como Lei n° 12.965/2014 (“Marco Civil da Internet”) e a Lei nº 8.078/1990 (“Código de Defesa do Consumidor”), e outras normas cabíveis. A plataforma se reserva no direito de alterar e atualizar essas regras em qualquer tempo, com aviso prévio aos usuários. Caso não concorde com alguma modificação, recomendamos que cancele a conta de usuário junto a plataforma.
                    </p>
                    <p>
                        As Políticas de Privacidade da Plataforma serão aplicadas a todos os Usuários, cadastrados ou não, e fazem parte dos Termos e Condições de Uso Gerais. Com a finalidade de ser transparente, a Jurídico Já apresenta informações claras a respeito da coleta dos dados pessoais e não pessoais dos seus usuários, bem como o seu uso, armazenamento e segurança.
                    </p>
                    <p>
                        A Plataforma adianta que todas as medidas necessárias para proteger as informações dos usuários foram utilizadas, utilizando eficientes mecanismos de informática.
                    </p>
                    <p>
                        Em razão dos serviços prestados pela Jurídico Já, a empresa se reserva no direito de revelar determinadas informações cadastrais e registro de acesso de seus usuários, em situações específicas, para terceiros. Consideram-se terceiros interessados: Empresas integrante do grupo econômico, parceiros comerciais, fornecedores, autoridades, pessoas físicas ou jurídicas que tenham sido lesadas por algum usuário cadastro e membros do Programa de Proteção à Propriedade Intelectual.
                    </p>
                    <p className="two">
                        O site e aplicativo Jurídico Já pertencem a plataforma JURÍDICO JÁ, empresa devidamente inscrita no CNPJ sob o nº 30.660.444/0001-14, situada na Rua Araguari, nº 1685, Sala 305, Santo Agostinho, Belo Horizonte/MG, bem como seus colaboradores por meio do site www.juridicoja.com ou respectivos endereços de e-mail. Para suporte entre em contato com contato@juridicoja.com
                    </p>
                    <p>DAS INFORMAÇÕES COLETADAS E ARMAZENADAS</p>
                    <p>
                        No momento do primeiro do acesso do usuário é necessário que seja feito um cadastro a fim de usufruir das funcionalidades da plataforma. Para tanto, coletamos dados pessoais (“Informação Pessoal") que devem ser exatos, completos, verídicos e atualizados. Os dados que a plataforma necessita coletar são:
                    </p>
                    <p>Nome;</p>
                    <p>Endereço Eletrônico;</p>
                    <p>Endereço;</p>
                    <p>Telefone de contato;</p>
                    <p>CPF;</p>
                    <p>RG;</p>
                    <p>Data de nascimento;</p>
                    <p>Sexo;</p>
                    <p>Profissão; E</p>
                    <p>Outras informações necessárias para manutenção do perfil.</p>
                    <p>
                        A Jurídico Já se reserva no direito de verificar a veracidade das informações fornecidas pelo Usuário, com consulta a entidades públicas, companhias especializadas ou banco de dados. Ao fazer uso da plataforma, o Usuário autoriza expressamente o feito.
                    </p>
                    <p>Não consideramos pessoal qualquer informação que já era de domínio público no momento da coleta.</p>
                    <p>
                        Os dados bancários ou de cartão de crédito tem seus dados coletados para pagamento, contudo é feito pelas administradoras de cartão, instituições financeiras ou outras. Portanto, não há envolvimento da plataforma. Não é recomendado o uso de meio de pagamento que não esteja de acordo com a posição do Usuário sobre o uso destas informações.
                    </p>
                    <p>
                        A Jurídico Já coleta e armazena, com o mais alto grau de proteção, algumas informações ("Informações Não Pessoais") a respeito das atividades praticadas pelos usuários cadastrados e visitantes. Entre estas informações estão os dados de navegação, tais como características da máquina e celular utilizados para acesso, histórico de acesso, Internet Protocol (IP), Cookies, entre outros.
                    </p>
                    <p>
                        Os Cookies são arquivos presentes no disco rígido, com duração limitada e que contribuem para a personalização do serviço oferecido. Os Cookies são empregados a fim de conhecer quais são os interesses de determinado usuário, bem como seu comportamento. Desta forma, iremos compreender melhor a necessidade de cada um e oferecer um melhor serviço ou prover informações que estejam interligadas.
                    </p>
                    <p>
                        A utilização dos Cookies será de exclusiva vontade do usuário que pode, a qualquer tempo, os eliminar de seu dispositivo de acesso. Para retirar os cookies o usuário deverá estudar o caso específico de seu navegador. Contudo, oferecemos um breve tutorial disponibilizado pelos provedores mais comuns:
                    </p>
                    <p>(I) Microsoft Windows Explorer;</p>
                    <p>(II) Google Chrome;</p>
                    <p>(III) Mozilla Firefox; E</p>
                    <p>(IV) Apple Safari.</p>
                    <p>
                        A fim também de contribuir com uma melhor experiência e captação de demandas a Plataforma solicita acesso aos sinais de Global Positioning System (GPS), podendo, desta forma, localizar o usuário com precisão e lhe enviar maiores ofertas de serviços.
                    </p>
                    <p>
                        O usuário que realiza o cadastro na plataforma Jurídico Já reconhece e autoriza expressamente a intervenção da empresa em trocas de mensagens eletrônicas entre os usuários, de forma a manter o objetivo da plataforma e proporcionar maior eficácia nos serviços, com proteção e segurança.
                    </p>
                    <p>
                        Outros usuários só terão acesso a informação pessoal de outro usuário para comunicação, utilização de serviços oferecidos ou outra finalidade a qual o Usuário consinta expressamente.
                    </p>
                    <p>Sob nenhuma hipótese deve-se compartilhar informação de usuário da plataforma com terceiro.</p>
                    <p>
                        A Jurídico Já poderá fazer uso dos dados dos usuários a fim de gerar e divulgar estatísticas em redes sociais, mídias ou parceiros comerciais.
                    </p>
                    <p>Outros acessos serão concedidos mediante autorização expressa dos usuários.</p>
                    <p>As informações pessoais e não-pessoais podem ser cruzadas a qualquer momento.</p>
                    <p>
                        A fim de identificar o Usuário poderá ser feita análise isolada de informações ou dados, sendo essas consideradas informação pessoal.
                    </p>
                    <p>
                        A forma de armazenamento da Plataforma é amplamente segura, sendo o acesso às informações coletadas restrito a profissionais capacitados, com sua confidencialidade garantida por contrato legal e vigente, com duras sanções civis e criminais em caso de quebra de alguma cláusula ou divulgação indevida de informações por parte do prestador.
                    </p>
                    <p>
                        A Jurídico Já se compromete a empenhar seus melhores esforços para a proteção das informações de seus usuários. Contudo, deve-se considerar que a Internet não é totalmente segura e, caso ocorra a violação ou interceptação ilegal do nosso sistema ou banco de dados, por pessoas não autorizadas a Plataforma não poderá ser responsabilizada pelo uso indevido de informações.
                    </p>
                    <p>
                        A qualquer tempo o usuário poderá requerer a exclusão definitiva de seus dados do banco de dados da plataforma.
                    </p>
                    <p>
                        O item anterior possui casos excepcionais, onde a plataforma manterá os dados pessoais com objetivo de utilizá-los em solução de litígios ou reclamações, problemas ou incidentes detectados e para dar cumprimento aos Termos e Condições de Uso aceitos pelo Usuário.
                    </p>
                    <p className="two">
                        Uma vez coletadas as informações do Usuário, a Jurídico Já se compromete a não vender, alugar ou compartilhar estas informações, exceto nos casos anteriormente citados nesta mesma Política de Privacidade.
                    </p>
                    <p>DA COLETA DE INFORMAÇÕES</p>
                    <p>As informações serão coletadas:</p>
                    <p>Do cadastro na plataforma;</p>
                    <p>Do uso das funcionalidades;</p>
                    <p className="two">
                        Através do cruzamento de informações entre a Plataforma, publicidades e dados de navegação do usuário (“Cookies”).
                    </p>
                    <p>DO USO DAS INFORMAÇÕES</p>
                    <p>As informações pessoais que solicitamos possuem as seguintes finalidades:</p>
                    <p>
                        Auxiliar o contato direto entre o Contratante e o Correspondente após manifestada intenção de contratação. A Jurídico Já não autoriza o uso das informações para fins alternativos a conclusão do negócio, como, mas não se limitando, publicidade, promoção ou outras atividades não relacionadas a plataforma, salvo expressa autorização das partes. A Plataforma se reserva no direito de entrar em contato com os usuários para averiguar informações fornecidas.
                    </p>
                    <p>
                        Desenvolver estudos internos e externos sobre os interesses, comportamentos, demografia, entre outros tópicos que sejam relevantes para a compreensão das necessidades e interesses de nossos usuários, a fim de prover um serviço qualificado e garantir eficiência da nossa plataforma.
                    </p>
                    <p>
                        Notificar, através do endereço eletrônico fornecido, o recebimento de demandas, contratações ou evoluções nas tratativas das diligências.
                    </p>
                    <p>
                        Aprimorar o serviço ofertado pela plataforma, a partir de iniciativas comerciais e promocionais, com análise às páginas visitadas, buscas realizadas. Desta forma, iremos melhorar a apresentação de nossos serviços, personalizando conteúdo à cada usuário e mantendo a plataforma útil e relevante para a prática jurídica, incluindo serviços e produtos de terceiros.
                    </p>
                    <p className="two">
                        A Jurídico Já informa que as informações dos usuários poderão ser fornecidas para entidades que intervenham em relação as ações movidas em função de serviços prestados ou contratados através da plataforma.
                    </p>
                    <p>DAS AUTORIDADES COMPETENTES</p>
                    <p>
                        A Jurídico Já coopera com todas as autoridades competentes e com terceiros que precisem resolver litígios relacionados a prestação de serviços que ocorrem em sua plataforma. A empresa preza pelo cumprimento das leis vigentes, bem como pretende sempre guardar a integridade e segurança de seus Usuários. A Jurídico Já irá sempre se posicionar a fim de evitar atividades ilegais.
                    </p>
                    <p>
                        A Plataforma irá revelar informações pessoais e registros de acesso ou de navegação, dentro dos termos da legislação brasileira.
                    </p>
                    <p>
                        A Jurídico Já atuará em conjunto aos participantes do Programa de Proteção de Propriedade Intelectual, comunicando os dados pessoais dos usuários, caso ocorra necessidade expressa de fazê-lo.
                    </p>
                    <p className="two">
                        Se verificado que a atividade de determinado usuário é prejudicial ou ilícita a outras pessoas, a Plataforma se reserva no direito de fornecer as informações destes usuários aos órgãos e terceiros competentes para fazer com que as Políticas do site e do aplicativo sejam rigorosamente seguidas.
                    </p>
                    <p>OUTRAS CONSIDERAÇÕES</p>
                    <p>
                        Os Usuários expressamente autorizam o uso de Web Beacons, utilizados com o fim de medir padrões de tráfego dos Usuários de uma página a outra, com o objetivo único de maximizar tal fluxo por meio da internet. Muito semelhante aos Cookies.
                    </p>
                    <p>
                        A Jurídico Já considera violação aos Termos e Condições de Uso condutas classificadas como "Spamming", de qualquer forma possível, dentro da plataforma. Fica proibido o envio indiscriminado de mensagens a qualquer usuário.
                    </p>
                    <p>
                        A Plataforma se reserva no direito de limitar ações de usuários que estejam em desacordo com o citado no item anterior e até mesmo suspender a conta dos mesmos.
                    </p>
                    <p>
                        A Jurídico Já tem a intenção de manter seus usuários atualizados sobre novidades relacionadas ao mundo jurídico ou não, podendo ser relacionado unicamente a plataforma. Para tanto, os usuários podem optar por selecionar quais e-mails desejam receber, incluindo anúncios promocionais. Caso o usuário não deseje receber tais e-mails, possui totalmente liberdade para fazê-lo, sem prejuízos ao uso da plataforma.
                    </p>
                    <p>
                        O Usuário se compromete a não repassar suas informações de acesso para terceiros. Se feito, a responsabilidade é unicamente do Usuário.
                    </p>
                </ModalMain>

            </ModalTerms>        
            
            {/* Modal para excluir a precificação */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setOpenModalConfirmation((openModalConfirmation) => !openModalConfirmation)}
                openModal={openModalConfirmation}
                width="45%"
            >
                <ModalPrecification>
                    <div className="header">
                        <p>Deseja excluir a precificação?</p>
                        <div
                            className="close"
                            onClick={() => setOpenModalConfirmation(false)}
                        >
                            <IconClose />
                        </div>
                    </div>
                    <div className="main">
                        <ButtonDefault
                            className="cancel"
                            onClick={() => setOpenModalConfirmation(false)}
                        >
                            Cancelar
                        </ButtonDefault>
                        <ButtonDefault
                            className="danger"
                            onClick={() => setExcludePrecification(true)}
                        >
                            Confirmar
                        </ButtonDefault>
                    </div>
                </ModalPrecification>
            </ModalDefault>

            {/* Modal para excluir a Comarca */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setOpenModalDeleteComarca((openModalDeleteComarca) => !openModalDeleteComarca)}
                openModal={openModalDeleteComarca}
                width="45%"
            >
                <ModalPrecification>
                    <div className="header">
                        <p>Deseja excluir a comarca?</p>
                        <div
                            className="close"
                            onClick={() => setOpenModalDeleteComarca(false)}
                        >
                            <IconClose />
                        </div>
                    </div>
                    <div className="main">
                        <ButtonDefault
                            className="cancel"
                            onClick={() => setOpenModalDeleteComarca(false)}
                        >
                            Cancelar
                        </ButtonDefault>
                        <ButtonDefault
                            className="danger"
                            onClick={() => setDeleteComarca(true)}
                        >
                            Confirmar
                        </ButtonDefault>
                    </div>
                </ModalPrecification>
            </ModalDefault>

            <div onClick={() => toast.remove()}>                            
                <Toaster  />
            </div>
        </Container>
    )
}