import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    height: auto;
    max-width: 1040px;

    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 50%;
        margin-top: 200px;
        transform: translateX(-50%);
    }

    .op {
        position: absolute;
        width: 215px;
        right: 0;
        margin-top: -72px;
    }
`;

export const Menu = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .initial {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 500px;        
    }
`

export const Filter = styled.div`
    display: flex ;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    margin-bottom: 32px;

    .results {
        font-size: 16px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.interfaceText400};
    }

    .buttons {
        display: flex;
        gap: 10px;
        .island {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            min-width: 120px;
            height: 32px;
            padding: 10px;
            background: ${({ theme }) => theme.colors.secondaryPool100};
            border-radius: 8px;
            color: ${({ theme }) => theme.colors.primaryBlue300};
            font-size: 14px;
            cursor: pointer;
            filter: drop-shadow(3px 4px 7px rgba(20, 20, 23, 0.3));
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.primaryBlue300};
                }
            }
           
            .island-map {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 20px;
                width: 344px;
                height: 300px;
                border-radius: 8px;
                padding: 24px;
                background: ${({ theme }) => theme.colors.interfacePure0};
                cursor: default;
                position: absolute;
                top: 40px;
                right: 0;
                z-index: 99;
                overflow-y: auto;
                &.no-show {
                    display: none;
                }

                .filter {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 48px;
                    border-radius: 8px;
                    border: 1px solid ${({ theme }) => theme.colors.interfacePure100};
                    padding: 16px;
                    color: ${({ theme }) => theme.colors.interfaceText300};
                    .button-filter {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 32px;
                        height: 32px;
                        background: ${({ theme }) => theme.colors.interfacePure100};
                        border-radius: 8px;
                    }
                }

                .filters {
                    display: flex;
                    align-items: center;
                    padding-left: 16px;
                    width: 100%;
                    height: 48px;
                    border-radius: 8px;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                    font-size: 16px;
                    font-weight: 500;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    cursor: pointer;
                    transition: all .2s;
                    &:hover {
                        background: ${({ theme }) => theme.colors.interfaceText100};
                    }
                }

            }

            .island-selected {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;
                gap: 8px;
                width: max-content;
                height: 32px;
                border-radius: 8px;
                padding: 5px 20px;
                background: ${({ theme }) => theme.colors.primaryBlue500};
                color: ${({ theme }) => theme.colors.interfacePure0};
                margin-left: 10px;
                .close-icon {
                    width: 24px;
                    svg {
                        path {
                            fill: ${({ theme }) => theme.colors.interfacePure0};
                        }
                    }
                }
            }
        }

        .sector {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            min-width: 110px;
            height: 32px;
            background: ${({ theme }) => theme.colors.secondaryPool100};
            border-radius: 8px;
            padding: 10px;
            color: ${({ theme }) => theme.colors.primaryBlue300};
            font-size: 14px;
            cursor: pointer;
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.primaryBlue300};
                }
            }

            .sector-map {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                gap: 20px;
                width: 344px;
                height: 300px;
                border-radius: 8px;
                padding: 24px;
                background: ${({ theme }) => theme.colors.interfacePure0};
                cursor: default;
                filter: drop-shadow(3px 4px 7px rgba(20, 20, 23, 0.3));
                position: absolute;
                top: 40px;
                right: 0;
                z-index: 99;
                overflow-y: auto;
                &.no-show {
                    display: none;
                }

                .filters {
                    display: flex;
                    align-items: center;
                    padding-left: 16px;
                    width: 100%;
                    height: 52px;
                    border-radius: 8px;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                    font-size: 16px;
                    font-weight: 500;
                    cursor: pointer;
                    transition: all .2s;
                    &:hover {
                        background: ${({ theme }) => theme.colors.interfaceText100};
                    }
                }
            }

            .sector-selected {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                position: absolute;
                top: 0;
                right: 0;
                width: max-content;
                height: 32px;
                border-radius: 8px;
                padding: 5px 20px;
                background: ${({ theme }) => theme.colors.primaryBlue500};
                color: ${({ theme }) => theme.colors.interfacePure0};
                .close-icon {
                    width: 24px;
                    svg {
                        path {
                            fill: ${({ theme }) => theme.colors.interfacePure0};
                        }
                    }
                }
            }
        }

        .date {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 32px;
            background: ${({ theme }) => theme.colors.secondaryPool100};
            border-radius: 8px;
            color: ${({ theme }) => theme.colors.primaryBlue300};
            font-size: 14px;
            cursor: pointer;
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.primaryBlue300};
                }
            }
        }        
    }
`

export const Paginate = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 24px;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: -50px;
    padding-bottom: 20px;

    .pagination {
        display: flex;
        gap: 8px;
        list-style-type: none;
        .page-link {
            display: flex;
            align-items: center;
            justify-content: center;            
            width: 24px;
            height: 24px;
            background: transparent;
            border: 1px solid ${({ theme }) => theme.colors.interfaceText200};
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText400};
            transition: all .2s;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            &:hover {
                color: ${({ theme }) => theme.colors.primaryBlue500};
                border: 1px solid ${({ theme }) => theme.colors.primaryBlue500};
            }
            &.active {
                display: flex;
                align-items: center;
                justify-content: center;            
                width: 24px;
                height: 24px;
                color: ${({ theme }) => theme.colors.primaryBlue500};
                border: 1px solid ${({ theme }) => theme.colors.primaryBlue500};
            }
        }
        .page-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            color: ${({ theme }) => theme.colors.primaryBlue500};
        }
        .back-page,
        .next-page {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border: 1px solid ${({ theme }) => theme.colors.interfaceText200};
            cursor: pointer;
            &:hover {
                color: ${({ theme }) => theme.colors.primaryBlue500};
                border: 1px solid ${({ theme }) => theme.colors.primaryBlue500};
            }
            &.active {
                color: ${({ theme }) => theme.colors.primaryBlue500};
                border: 1px solid ${({ theme }) => theme.colors.primaryBlue500};
            }
        }

        .back-link-page,
        .next-link-page {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
        }

    }
`