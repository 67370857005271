import { useState } from "react";
import { useAuth } from "../../../contexts/UserContext";
import api from "../../../services/api";
import { IconClose, IconDownload, IconFilter, IconSearch } from "../../icons";
import { Container, DownloadFile } from "./styles";

interface FilterProps {
    filters?: any,
    setFilters?: any,
    setSearchProfiles?: any,
    setClean?: any,
}

export default function ProfessionalsFilter(
    {
        filters, 
        setFilters, 
        setSearchProfiles,
        setClean,
        
    }: FilterProps) {
    
    const { pageName } = useAuth()
    const [ DTOSearch, setDTOSearch ] = useState<any>();
    const [ openFiltersMenu, setOpenFiltersMenu ] = useState<boolean>(false);

    function openFilters() {

        if (filters.length > 0) {

        } else {

            setOpenFiltersMenu(!openFiltersMenu);
        }
    }

    function handleOnChanges(value: any) {
        setDTOSearch(value)
    }
    
    function clearSearch() {
        setDTOSearch('')
        setSearchProfiles('')
    }

    function searchKeyPressed(event: any) {
        
        if (event.key === 'Enter') {
            setSearchProfiles(DTOSearch)
        }

    }

    async function downloadProfessionals() {
        try {

            const response = await api.get('usuario-csv');
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'profissionais.csv');
            document.body.appendChild(link);
            link.click();            

        } catch (error: any) {

            console.log('log error download csv', error)

        }
    }

    async function downloadOperators() {
        try {

            const response = await api.get('operador-csv');
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'operadores.csv');
            document.body.appendChild(link);
            link.click();

        } catch (error: any) {

            console.log('log error download csv', error)

        }
    }
        
    return (
        <Container filter={filters}>
            <div className="search">
                <div
                    className="buscar"
                    onClick={() => setSearchProfiles(DTOSearch)}                    
                >
                    <IconSearch/>
                </div>
                <input
                    placeholder="Pesquisar"
                    onChange={(e) => handleOnChanges(e.target.value)}
                    onKeyPress={(e) => searchKeyPressed(e)}
                    value={DTOSearch}
                />
                {
                    DTOSearch &&
                    <div 
                        className="clean-search"
                        onClick={() => clearSearch()}
                    >
                        <IconClose />
                    </div>
                }
            </div>

            <div
                className="button"
                onClick={() => openFilters()}
            >   
                {
                    filters?.length < 1 
                    ?   <div className="button-filter">
                            <IconFilter />
                            Filtrar
                        </div>
                    :   <div className="button-filter">
                            <div className="icon-filter">
                                <IconFilter />
                            </div>
                            <div>
                                {filters?.length}
                            </div>
                            <div
                                className="clear-icon"
                                onClick={() => { setFilters([]); setClean(); }}
                            >
                                <IconClose />                                
                            </div>
                        </div>
                }

                {
                    pageName === 'Profissionais' &&
                    <div className={openFiltersMenu ? 'hidden-menu' : 'hidden-menu no-show'}>
                        <div className="filter">
                            Filtrar por... 
                            <div className="button-search">
                                <IconSearch />
                            </div>
                        </div>
                        <div 
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'plano'])}
                        >
                            Plano
                        </div>
                        <div 
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'comarca'])}
                        >
                            Comarca
                        </div>
                        <div 
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'periodo'])}
                        >
                            Período de cadastro
                        </div>
                    </div>
                }

                {
                    pageName === 'Operadores' &&
                    <div className={openFiltersMenu ? 'hidden-menu' : 'hidden-menu no-show'}>
                        <div className="filter">
                            Filtrar por...
                            <div className="button-search">
                                <IconSearch />
                            </div>
                        </div>
                        <div
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'ilhas'])}
                        >
                            Ilha
                        </div>
                        <div
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'setor'])}
                        >
                            Setor
                        </div>
                        {/* <div
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'periodo'])}
                        >
                            Período de cadastro
                        </div> */}
                    </div>
                }
               
            </div>

            {
                pageName === 'Profissionais'
                ?   <DownloadFile
                        onClick={() => downloadProfessionals()}
                    >
                        <IconDownload />
                    </DownloadFile>
                :   <DownloadFile
                        onClick={() => downloadOperators()}
                    >
                        <IconDownload />
                    </DownloadFile>
            }            
            
        </Container>
    )
}