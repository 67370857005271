// Context
import { useAuth } from "../../../contexts/UserContext";

// Styles
import { Container } from "./styles";

interface PropTabs {
    selected?: any,
    setSelected?: any,
    waiting?: any,
    canceled?: any,
    sketched?: number,
}

export default function ProposalsTab({ selected, setSelected, canceled, waiting, sketched }: PropTabs) {
    
    const { user } = useAuth();

    return (
        <Container>
            <div
                onClick={(value) => setSelected(value.currentTarget.id)}
                className={selected === '1' ? 'selected tab' : 'tab'}
                id='1'
            >
                Aguardando aceite
                <div
                    className={selected === '1' ? 'selected notification' : 'notification'}
                >
                    {waiting}
                </div>
            </div>
            <div
                onClick={(value) => setSelected(value.currentTarget.id)}
                className={selected === '2' ? 'selected tab' : 'tab'}
                id='2'
            >
                Canceladas
                <div
                    className={selected === '2' ? 'selected notification' : 'notification'}
                >
                    {canceled}
                </div>
            </div>
            {
                user.profile !== 'comum' &&
                <div
                    onClick={(value) => setSelected(value.currentTarget.id)}
                    className={selected === '3' ? 'selected tab' : 'tab'}
                    id='3'
                >
                    Rascunhos
                    <div
                        className={selected === '3' ? 'selected notification' : 'notification'}
                    >
                        {sketched ? sketched : 0}
                    </div>
                </div>
            }
        </Container>
    )
}