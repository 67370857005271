/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Components
import CardWelcomeOperator from "../../components/Ui/CardWelcomeOperator";

// Context
import { useAuth } from "../../contexts/UserContext";

// Styles
import { BottomContainer, Container, FinancialReports, InfoCard, MensalButton, PersonalizeButton, TopContaineir } from "./styles";

// Libraries
import { Oval } from 'react-loader-spinner';
import toast, { Toaster } from 'react-hot-toast';

// Icons
import { IconArrowRigthDown, IconArrowRigthUp, IconCalendar, IconExternalLink, IconFilter, IconFinishedAccept } from "../../components/icons";

// Images
import Reports from '../../assets/images/FinancialReport.svg';
import api from "../../services/api";

interface FinancialProps {
    custoTotalProfissionais: number,
    demandasFinalizadas: number,
    lucroTotal: number,
    receberClientes: number,
    valorAdiantadoDuranteAndamento: number,
    valorRestantePagarProfissionais: number,
}

export default function Financial() {
    const [ loading, setLoading ] = useState<boolean>(false);
    const { setPageName, setPageType } = useAuth();
    const [ financialData, setFinancialData] = useState<FinancialProps>()
    const date = new Date();
    const month = date.toLocaleString('pt-BR', { month: 'long' });
    const year = date.toLocaleString('pt-BR', { year: 'numeric' });
    

    useEffect(() => {
        setLoading(true);

        setPageName('Financeiro');
        setPageType('normal');        

        setLoading(false);

        getFinancialData();
    }, [])
    
    async function getFinancialData() {
        try {
            
            const response = await api.get('/financeiro/dashboard');

            if (response.data.status !== 'success') {

                throw new Error(response.data.message);

            } else {

                setFinancialData(response.data.result);

            }
            
        } catch (error: any) {

            console.log('log do erro financeiro', error);

        }
        
    }

    async function downloadFinancial() {
        try {

            const response = await api.get('/financeiro/exportar');
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `financeiro/${month}-${year}.csv`);
            document.body.appendChild(link);
            link.click();

        } catch (error: any) {

            console.log('log error download csv', error)

        }
    }
    
    return (
        <Container>

            {
                loading &&
                <Oval
                    height={150}
                    width={150}
                    color="#FA9604"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#FA9604"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            }

            {
                !loading &&
                <>
                    <TopContaineir>
                        <CardWelcomeOperator month={month} year={year} financial={true} />
                        <FinancialReports>
                            <div className="left">
                                <div className="circle">
                                    <img src={Reports} alt="icon" />
                                </div>
                            </div>
                            <div className="infos">
                                <div className="title">Relatórios financeiros</div>
                                <div className="subtitle">
                                    Você pode <span>exportar um relatório geral</span> <br />
                                    <span>do mês atual</span> ou <span>personalizá-lo com filtros</span>.
                                </div>
                                <div className="buttons">
                                    {/* Aqui vai exportar CSV do mês */}
                                    <MensalButton
                                        onClick={downloadFinancial}
                                    >
                                        <IconCalendar />
                                        Mensal
                                    </MensalButton>
                                    {/* Aqui vai para lista de demandas com o filtro do mês */}
                                    <Link
                                        to={{
                                            pathname: '/demandas',
                                            state: {
                                                filter: 'month'
                                            }
                                        }}
                                    >
                                        <PersonalizeButton>
                                            <IconFilter />
                                            Personalizar
                                        </PersonalizeButton>
                                    </Link>
                                </div>
                            </div>
                        </FinancialReports>
                    </TopContaineir>  

                    <BottomContainer>
                        <InfoCard>
                            <div className="icons">
                                <div className="icon accept">
                                    <IconFinishedAccept />
                                </div>
                                <Link
                                    to={{
                                        pathname: '/demandas',
                                        state: {
                                            selected: '3',
                                            demands: 'accepted',
                                        }
                                    }}
                                >
                                    <div className="button">
                                        <IconExternalLink />
                                    </div>
                                </Link>
                            </div>

                            <div className="title">
                                Demandas finalizadas <br /> 
                                com aceite
                            </div>
                            <div className="numbers accept">
                                {financialData?.demandasFinalizadas} <span>total</span>
                            </div>
                        </InfoCard>

                        <InfoCard>
                            <div className="icons">
                                <div className="icon receive">
                                    <IconArrowRigthDown />
                                </div>                        
                            </div>

                            <div className="title">
                                A receber <br />
                                dos clientes
                            </div>
                            <div className="numbers receive">
                                <span>R$</span> {
                                    financialData?.receberClientes.toString().includes('.')
                                    ? `${financialData.receberClientes.toString().replace('.', ',')}`
                                    : `${financialData?.receberClientes},00`
                                } <span>total</span>
                            </div>
                        </InfoCard>

                        <InfoCard>
                            <div className="icons">
                                <div className="icon success">
                                    <IconArrowRigthDown />
                                </div>                        
                            </div>

                            <div className="title">
                                Lucro total
                            </div>
                            <div className="numbers sucess">
                            <span>R$</span> {
                                    financialData?.lucroTotal.toString().includes('.')
                                        ? `${financialData.lucroTotal.toString().replace('.', ',')}`
                                        : `${financialData?.lucroTotal},00`
                                } <span>total</span>
                            </div>
                        </InfoCard>

                        <InfoCard>
                            <div className="icons">
                                <div className="icon prof">
                                    <IconArrowRigthUp />
                                </div>                        
                            </div>

                            <div className="title">
                                Custo total <br />
                                dos profissionais
                            </div>
                            <div className="numbers prof">
                                <span>R$</span> {
                                    financialData?.custoTotalProfissionais.toString().includes('.')
                                        ? `${financialData.custoTotalProfissionais.toString().replace('.', ',')}`
                                        : `${financialData?.custoTotalProfissionais},00`
                                } <span>total</span>
                            </div>
                        </InfoCard>

                        <InfoCard>
                            <div className="icons">
                                <div className="icon accept">
                                    <IconArrowRigthUp />
                                </div>
                            </div>

                            <div className="title">
                                Valor adiantado <br />
                                durante o andamento
                            </div>
                            <div className="numbers ongoing">
                                <span>R$</span> {
                                    financialData?.valorAdiantadoDuranteAndamento.toString().includes('.')
                                        ? `${financialData.valorAdiantadoDuranteAndamento.toString().replace('.', ',')}`
                                        : `${financialData?.valorAdiantadoDuranteAndamento},00`
                                } <span>total</span>
                            </div>
                        </InfoCard>

                        <InfoCard>
                            <div className="icons">
                                <div className="icon to-pay">
                                    <IconArrowRigthUp />
                                </div>
                            </div>

                            <div className="title">
                                Valor restante <br />
                                a pagar aos profissionais
                            </div>
                            <div className="numbers to-pay">
                                <span>R$</span> {
                                    financialData?.valorRestantePagarProfissionais.toString().includes('.')
                                        ? `${financialData.valorRestantePagarProfissionais.toString().replace('.', ',')}`
                                        : `${financialData?.valorRestantePagarProfissionais},00`
                                } <span>total</span>
                            </div>
                        </InfoCard>
                    </BottomContainer>          
                </>
            }            

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}