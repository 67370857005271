// React
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Libraries
import Moment from "react-moment";

// Components
import ButtonDefault from "../../components/form/ButtonDefault";

// Styles
import { Container, Header, Main } from "./styles";

// Icons
import { IconLogoJuridicoWhite } from "../../components/icons";

// Assets
import ProposalComplete from '../../assets/images/ProposalComplete.png';

export interface User {
    userName: string,
    id: number,
    prazo_aceite: string,
}

export default function CreateProposalComplete() {
    const history = useHistory();
    const location = useLocation<User>();
    const [ user, setUser ] = useState<any>();
    const fullName = user?.userName;
    const splitOnSpace = fullName?.split(' ');
    const firstName = splitOnSpace ? splitOnSpace[0] : '';

    useEffect(() => {

        setUser(location?.state)                

    }, [location.state])


    return (
        <Container>

            <Header>
                <div className="logo"><IconLogoJuridicoWhite /></div>
            </Header>

            <Main>
                <div className="image">
                    <img src={ProposalComplete} alt="Imagem de cadastro concluído" />
                </div>

                <div className="status">
                    <div className="progress">
                        <div className="progress-bar" />
                        <div>100%</div>
                    </div>
                    <div className="status-title">Proposta criada com sucesso!</div>
                    
                    <div className="status-subtitle">
                        {
                            firstName && user.id &&
                            <>
                                Enviamos a oportunidade para <span>{firstName} {user?.id}</span>.<br />
                                Você deve ter um retorno em até <Moment locale='pt-br' fromNow ago>{user?.prazo}</Moment>.
                            </>
                        }
                    </div>

                    <div className="buttons">
                        <div
                            className="new"
                            onClick={() => history.push('/nova-proposta')}
                        >
                            Nova proposta
                        </div>
                        <ButtonDefault
                            className="continue"
                            onClick={() => history.push('/propostas')}
                        >
                            Concluir
                        </ButtonDefault>
                    </div>
                </div>
            </Main>
        </Container>
    )
}