// React //
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// Components //
import ButtonDefault from "../../components/form/ButtonDefault";
import Checkbox from "../../components/form/InputCheckbox";
import InputDefault from "../../components/form/InputDefault";

// Styles //
import { Container, Header, MainLeft, MainRight, MainWrapper } from "./styles";

// Images //
import LoginImage from "../../assets/images/Frame.png"

// Icons //
import { 
    IconArrowLeft, 
    IconClosedEye, 
    IconEyeOpen, 
    IconFacebook, 
    IconInsta, 
    IconLinkedin,
} from "../../components/icons";

// Services //
import api from "../../services/api";

// Libraries //
import toast, { Toaster } from 'react-hot-toast';
import * as yup from 'yup';
import getValidationErrors from "../../Utils/validationErrors";

interface Errors {
    senha?: string,
    confirmeSenha?: string, 
}

export default function Recover() {
    const params = useParams<any>();
    const history = useHistory<any>();
    const token = params.id;
    const [ checked, setChecked ] = useState<boolean>(false);    
    const [ checkPassword, setCheckPassword ] = useState<any>('');
    const [ loading, setLoading ] = useState<any>(false);
    const [ changeVisibility, setChangeVisibility] = useState<boolean>(true);
    const [ advancePageError, setAdvancePageError] = useState<Errors>({});
    const [DTO, setDTO] = useState({
        token: token,
        senha: '',
        confirmeSenha: '',
    });

    function handleOnChange(name: any, value: any) {
        const newDTO: any = DTO
        newDTO[name] = value
        setDTO({ ...newDTO })        
    }

    function handleSubmit(e: any) {
        e.preventDefault();

        if (DTO.senha === DTO.confirmeSenha) {
            setCheckPassword('ok')
            changePassword()
        }
        else {
            setCheckPassword('check')
        }        
    }

    async function changePassword() {
        try {

            setLoading(true)

            const schemaSix = yup.object().shape({
                senha: yup.string()
                    .required('Senha é obrigatória')
                    .min(6)
                    .matches(
                        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                        "A senha deve conter 1 maiuscula e 1 caractere especial"
                    ),
                confirmeSenha:
                    yup.string()
                        .required('Confirmação de senha é obrigatória')
                        .oneOf([yup.ref('senha')], 'As senhas não conferem!'),
            });

            await schemaSix.validate(DTO, {
                abortEarly: false,
            });

            const response = await api.post('/usuarios/senha/alterar', DTO)
            
            if (response.data.status !== 'success') {
                
                if (response.data.result[0] === undefined) {
                    throw new Error(response.data.message);

                } else {
                    throw new Error(response.data.result[0].message);
                }

            } else {

                toast.success('Senha alterada com sucesso, faça o login novamente', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#FA9604',
                    },
                    iconTheme: {
                        primary: '#02E8BE',
                        secondary: '#FFFAEE',
                    },
                });

                setTimeout(() => {
                    history.push('/login')
                }, 3000)

            }
            
            setLoading(false)

        } catch (err: any) {

            if (err instanceof yup.ValidationError) {
                const errors = getValidationErrors(err);

                setAdvancePageError(errors)

                toast.error('Preencha os campos corretamente.', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });

            } else {

                toast.error(err.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
                setLoading(false)
            }
            
        }
        
    }

    return (
        <Container>
            <MainWrapper>
                <div className="header">
                    <button className="back"
                        onClick={() => history.push('/')}
                    >
                        <span><IconArrowLeft /> </span>Voltar para o site
                    </button>
                    <div className="icons">
                        <div className="card">
                            <a href="https://www.facebook.com/juridicojaoficial" target={'_blank'} rel="noreferrer">
                                <IconFacebook />
                            </a>
                        </div>
                        <div className="card">
                            <a href="https://www.instagram.com/juridicojaoficial/" target={'_blank'} rel="noreferrer">
                                <IconInsta />
                            </a>
                        </div>
                        <div className="card">
                            <a href="https://www.linkedin.com/company/jur%C3%ADdico-j%C3%A1/about/" target={'_blank'} rel="noreferrer">
                                <IconLinkedin />
                            </a>
                        </div>
                    </div>
                </div>
                <MainLeft>

                    <Header>
                        <h1 className="forgot">Redefinir Senha</h1>
                    </Header>

                    <form onSubmit={handleSubmit}>

                        <div className="password">
                            <InputDefault
                                title="Crie uma senha:"
                                name="senha"
                                type={changeVisibility ? 'password' : 'text'}
                                className={
                                    checkPassword === 'check' ? 'danger' : '' 
                                    || checkPassword === 'ok' ? 'strong' : ''
                                }
                                value={DTO.senha}
                                onChangeText={(value) => handleOnChange('senha', value)}
                                error={advancePageError.senha ? advancePageError.senha : ''}
                            />
                            <div
                                className="icon"
                                onClick={() => setChangeVisibility(!changeVisibility)}
                            >
                                {changeVisibility ? <IconEyeOpen /> : <IconClosedEye />}
                            </div>
                        </div>
                        
                        <div className="confirm">
                            <div>
                                <InputDefault
                                    title="Confirme a senha:"
                                    name="confirmeSenha"
                                    type={changeVisibility ? 'password' : 'text'}
                                    className={
                                        checkPassword === 'check' ? 'danger' : '' 
                                        || checkPassword === 'ok' ? 'strong' : ''
                                    }
                                    value={DTO.confirmeSenha}
                                    onChangeText={(value) => handleOnChange('confirmeSenha', value)}
                                    error={advancePageError.confirmeSenha ? advancePageError.confirmeSenha : ''}
                                />
                                <div
                                    className="icon"
                                    onClick={() => setChangeVisibility(!changeVisibility)}
                                >
                                    {changeVisibility ? <IconEyeOpen /> : <IconClosedEye />}
                                </div>
                            </div>
                        </div>

                        <div className="checkbox">
                            <Checkbox
                                label="Lembrar da senha"
                                name="Lembrar"
                                value={checked}
                                onClick={() => setChecked(!checked)}
                            />
                        </div>

                        <ButtonDefault>Redefinir</ButtonDefault>

                    </form>
                    
                </MainLeft>

                <MainRight>
                    <img src={LoginImage} alt="Imagem da tela de login" /> 
                </MainRight>
            </MainWrapper>
            
            {
                !loading &&
                
                <div onClick={() => toast.remove()}>
                    <Toaster />
                </div>
            }
        </Container>
    )
}